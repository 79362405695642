import { useEffect } from 'react';

const makeSquareByWidth = ($el: HTMLElement) => {
  const width = $el.clientWidth;
  $el.style.height = `${width}px`;
  $el.style.transition = 'all 150ms cubic-bezier(0.0, 0, 0.25, 1) 0ms';
};

export default ($el: HTMLElement | null, viewport?: Element) => {
  useEffect(() => {
    // handle resize events
    const handleResize = () => {
      if ($el) {
        makeSquareByWidth($el);
      }
    };

    const observer = new ResizeObserver(handleResize);

    handleResize();
    if (viewport) {
      observer.observe(viewport);
    } else {
      visualViewport.addEventListener('resize', handleResize);
    }

    return () => {
      if (viewport) {
        observer.unobserve(viewport);
      } else {
        visualViewport.removeEventListener('resize', handleResize);
      }
    };
  }, [$el, viewport]);
};
