import { ThemeOptions } from '@mui/material/styles';
import { colors } from './common';

export default {
  palette: {
    mode: 'dark',
    primary: {
      main: colors.primary,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: colors.accent,
    },
    vivid: {
      main: colors.vivid,
    },
    vivid2: {
      main: colors.vivid2,
    },
    background: {
      paper: '#131A22',
      default: '#080B0F',
      sidebar: '#131A22',
    },
  },
  shadows: [
    'none',
    '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    '0 0 1px 0 rgba(90,90,90,0.21), 0 2px 2px -2px rgba(90,90,90,0.15)',
    '0 0 1px 0 rgba(90,90,90,0.21), 0 3px 4px -2px rgba(90,90,90,0.15)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 3px 4px -2px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 4px 6px -2px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 4px 6px -2px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 4px 8px -2px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 5px 8px -2px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 6px 12px -4px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 7px 12px -4px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 6px 16px -4px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 7px 16px -4px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 8px 18px -8px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 9px 18px -8px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 10px 20px -8px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 11px 20px -8px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 12px 22px -8px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 13px 22px -8px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 14px 24px -8px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 16px 28px -8px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 18px 30px -8px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 20px 32px -8px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 22px 34px -8px rgba(90,90,90,0.25)',
    '0 0 1px 0 rgba(90,90,90,0.31), 0 24px 36px -8px rgba(90,90,90,0.25)',
  ],
} as ThemeOptions;
