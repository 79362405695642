import { useMemo } from 'react';
import { useFetchMintedTokensQuery } from 'src/state/api';
import type { NFTArtBase, ThumbnailSize } from 'src/types';
import useProfile from './useProfile';
import useInjectLikes from './useInjectLikes';

interface AccountTokenQuery {
  thumbSize?: ThumbnailSize;
  address: string;
  type: 721 | 1155;
  sortby?: string;
}

export default (query: AccountTokenQuery & {count: number, from: number}, o?: {skip?: boolean}) => {
  const { isAuthenticated, isLoading: isProfileLoading } = useProfile();
  const {
    data: result,
    isLoading: initLoading,
    isFetching: loading,
    requestId,
  } = useFetchMintedTokensQuery(
    query,
    {
      skip: Boolean(isProfileLoading || o?.skip),
      refetchOnMountOrArgChange: true,
    }
  );

  const injectedResult = useInjectLikes(result);

  const data = useMemo<NFTArtBase[]>(
    () => (
      (
        isAuthenticated
          ? injectedResult?.tokens || []
          : result?.data?.tokens || []
      ) as NFTArtBase[]
    ).map(
      (src: NFTArtBase) => ({
        ...src,
      } as NFTArtBase)
    ),
    [injectedResult, result]
  );

  return {
    requestId,
    data,
    isLoading: initLoading || loading || isProfileLoading,
    total: result?.data?.total || 0,
    offset: result?.data?.offset || 0,
  };
};
