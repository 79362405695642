import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import {
  IconButton, useMediaQuery, Button, MenuItem,
} from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'src/hooks';
import MenuDropdown, { DropdownContext } from 'src/components/@ui/MenuDropdown';
import { useConnector } from 'src/lib/web3/components/ConnectorSelect';
import { isMobile } from 'src/utils';
import ShinyStar from 'src/assets/shiny-stars.png';

interface MintBntProps extends Omit<ButtonProps, 'onClick'> {
  onClick?: () => void;
  responsive?: boolean;
}

export default ({ onClick, responsive, ...btnProps }: MintBntProps) => {
  const theme = useTheme();
  const navigateTo = useNavigate();
  const { wrapInConnector } = useConnector();
  const isSmDown = useMediaQuery((t: Theme) => t.breakpoints.down('sm'));
  const isVerySmall = isMobile();

  const handleOptionClick = (link: string) => wrapInConnector((e: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick();
    } else {
      navigateTo(link);
    }
  });

  return (
    <MenuDropdown
      id="mint-button-dropdown"
      Anchor={({ onClick: onPopover, open }) => (
        <>
          {isVerySmall && responsive ? (
            <IconButton onClick={onPopover}>
              <AddIcon />
            </IconButton>
          ) : (
            <Button
              id="mint-menu-button"
              aria-controls={open ? 'mint-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              size={isSmDown ? 'small' : 'medium'}
              variant="contained"
              color={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
              endIcon={<img crossOrigin="anonymous" src={ShinyStar} alt="stars" style={{ height: '23px' }} />}
              {...btnProps}
              {...{ onClick: onPopover }}
            >
              Create
            </Button>
          )}
        </>
      )}
      sx={{
        '&#mint-button-dropdown .MuiPaper-root': {
          mt: 1.25,
        },
        pointerEvents: 'none',
        '& .MuiPopover-paper': {
          pointerEvents: 'auto',
        },
      }}
      hover
    >
      <DropdownContext.Consumer>
        {({ wrapClose }) => (
          <>
            <MenuItem onClick={wrapClose(handleOptionClick('/home/assets/mint'))} disableRipple>
              Image
            </MenuItem>
            <MenuItem onClick={wrapClose(handleOptionClick('/cinema/create'))} disableRipple>
              Video
            </MenuItem>
          </>
        )}
      </DropdownContext.Consumer>
    </MenuDropdown>
  );
};
