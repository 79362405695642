/* eslint-disable max-len */
import React from 'react';
import { useFormikContext } from 'formik';
import { Box, FormControl } from '@mui/material';
import Select, { SelectOption } from 'src/components/form/Select';
import {
  SliderFieldInput, TextFieldInput, StableDiffusionModel, ModelSelection,
} from '../type';
import SliderInput from '../fields/SliderInput';
import TextInput from '../fields/TextInput';
import ImageInput from '../fields/ImageInput';
import { models } from '../constants';

const FormConfig = () => {
  const { values, setFieldValue, isSubmitting, errors } = useFormikContext<StableDiffusionModel & ModelSelection>();

  const fieldInputs = React.useMemo(() => models.find((m) => m.model === values.modelName)?.inputs, [values.modelName]);

  const renderContent = (f: SliderFieldInput | TextFieldInput) => {
    switch (f.type) {
      case 'slider':
        return (
          <SliderInput
            value={values[f.key]}
            onChange={(e: Event, value: number, activeThumb: number) => setFieldValue(f.key, value)}
            {...f}
          />
        );

      case 'text':
        return (
          <TextInput
            {...f}
            value={values[f.key]}
            size="small"
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(f.key, e.target.value)}
            type="text"
          />
        );

      case 'number':
        return (
          <TextInput
            {...f}
            value={values[f.key]}
            size="small"
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(f.key, e.target.value)}
            type="number"
          />
        );

      case 'image':
        return <ImageInput image={values[f.key]} onChange={(v: File) => setFieldValue(f.key, v)} error={errors[f.key]} {...f} />;

      default:
        return null;
    }
  };

  return (
    <>
      <Box sx={{ pr: 2, pt: 2 }}>
        <Select.Popper
          rounded
          fullWidth
          size="small"
          value={values.modelName}
          onChange={(_, model: SelectOption<string>) => {
            setFieldValue('modelName', model.value);
          }}
          options={models
            .filter((m) => m.type === values.modelType)
            .map(({ label, model }) => ({
              label,
              value: model,
            }))}
        />
      </Box>
      <Box sx={{ px: 2 }}>
        {fieldInputs
          .sort((a, b) => a.order - b.order)
          .map((f: SliderFieldInput) => (
            <FormControl
              key={`slider.$.${f.key}`}
              sx={{ my: 1 }}
              fullWidth
              disabled={isSubmitting}
              error={Boolean(errors[f.key])}
            >
              {renderContent(f)}
            </FormControl>
          ))}
      </Box>
    </>
  );
};

export default FormConfig;
