import { Web3Provider } from '@ethersproject/providers';

export enum ConnectorName {
  Metamask = 'Metamask',
  WalletConnect = 'WalletConnect',
  ElastosDID = 'Elastos DID',
}

export interface Connector {
  name: ConnectorName;
  // @todo: change 'any' to the appropriate type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler: any;
  icon?: string;
  // Use this to auto switch network and add if not there yet
  networkSwitcher?: (chainId: number, library: Web3Provider) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConnected?: (connector: any) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onQuit?: (connector: any) => Promise<void>;
}

export interface INetworkConfig {
  name: string;
  symbol: string;
  chainID: number;
  rpcUrl: string;
  explorerUrl: string;
  decimals: number;
}

export interface INetworkCollection {
  [chainId: number]: INetworkConfig;
}

export const NETWORKS: INetworkCollection = {
  20: {
    name: 'Elastos Smart Chain',
    symbol: 'ELA',
    chainID: 20,
    rpcUrl: 'https://api.ela.city/esc',
    explorerUrl: 'https://eth.elastos.io',
    decimals: 18,
  },
  21: {
    name: 'ESC Testnet',
    symbol: 'ELA',
    chainID: 21,
    rpcUrl: 'https://api2-testnet.elastos.net/eth',
    explorerUrl: 'https://esc-testnet.elastos.io',
    decimals: 18,
  },
  1337: {
    name: 'Development Network',
    symbol: 'ETH',
    chainID: 1337,
    rpcUrl: 'http://localhost:8545',
    explorerUrl: 'http://localhost:7545',
    decimals: 18,
  },
};
