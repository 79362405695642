import type { ICollection, ICategory } from 'src/types/collection';

export const DEFAULT_COLLECTION: ICollection = {
  address: '0x0000000000000000000000000',
  name: 'Elacity Genesis',
  symbol: 'AART',
  isVerified: true,
  isVisible: true,
};

export const CATEGORIES: ICategory[] = [
  {
    id: 10,
    icon: 'pie_chart',
    label: '404',
  },
  {
    id: 0,
    icon: 'palette',
    label: 'Art',
  },
  {
    id: 1,
    icon: 'add_reaction',
    label: 'Collectibles',
  },
  // {
  //   id: 2,
  //   icon: 'sports_soccer',
  //   label: 'Sports',
  // },
  {
    id: 5,
    icon: 'photography',
    label: 'Photography',
  },
  {
    id: 3,
    icon: 'construction',
    label: 'Utility',
  },
  // {
  //   id: 4,
  //   icon: 'filter_none',
  //   label: 'Trading Cards',
  // },
  {
    id: 6,
    icon: 'ens',
    label: 'Domain Name Service',
  },
  {
    id: 7,
    icon: 'ai',
    label: 'AI / Generative Image',
  },
  // {
  //   id: 8,
  //   icon: 'gaming',
  //   label: 'Gaming',
  // },
  {
    id: 9,
    icon: 'memberships',
    label: 'Memberships',
  },
];
