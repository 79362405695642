import * as React from 'react';
import Stack from '@mui/material/Stack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

interface DateInputProps {
  type: 'desktop' | 'mobile' | 'time' | 'datetime' | 'mobile-datetime';
  label?: string;
  placeholder?: string;
  format: string;
  value?: Date | null | number;
  minDate?: Date | null;
  onChange?: (value: Date | null) => void;
  disabled?: boolean;
}

const DateInput: React.FC<DateInputProps> = ({
  label,
  type,
  placeholder,
  minDate,
  format,
  value,
  disabled,
  onChange,
}: DateInputProps) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={3}>
      {type === 'desktop' && (
        <DesktopDatePicker
          label={placeholder || label}
          format={format}
          value={value}
          onChange={onChange}
          disabled={Boolean(disabled)}
        />
      )}
      {type === 'mobile' && (
        <MobileDatePicker
          label={label}
          format={format}
          value={value}
          onChange={onChange}
          disabled={Boolean(disabled)}
        />
      )}
      {type === 'time' && (
        <TimePicker
          label={label}
          format={format}
          value={value}
          onChange={onChange}
          disabled={Boolean(disabled)}
        />
      )}
      {type === 'mobile-datetime' && (
        <MobileDateTimePicker
          label={label}
          format={format}
          value={value}
          minDateTime={minDate}
          onChange={onChange}
          disabled={Boolean(disabled)}
        />
      )}
      {type === 'datetime' && (
        <DateTimePicker
          label={label}
          format={format}
          value={value}
          minDateTime={minDate}
          onChange={onChange}
          disabled={Boolean(disabled)}
        />
      )}
    </Stack>
  </LocalizationProvider>
);

export default DateInput;
