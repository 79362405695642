/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { isAddress } from '@ethersproject/address';
import { ContractReceipt } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';
import { TokenID } from '@elacity-js/lib';
import { TxExecutable } from 'src/lib/web3/executable/tx';
import events from '../events';
import { ABIS } from '../constants';

// @todo [tkid]
interface TransferOptions<T = any> {
  tokenID?: TokenID;
  amount?: number;
  data?: T;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type GenericConstructor<T = {}> = new (...args: any[]) => T;

export default <T extends GenericConstructor<any>>(BaseClass: T) => class MixedClass extends BaseClass {
  /**
   * Transer a token to another recipient
   *
   * @param to
   * @param params
   * @returns
   */
  public async transfer<Op = any>(to: string, params: TransferOptions<Op>): Promise<ContractReceipt> {
    if (!isAddress(to)) {
      return Promise.reject(new Error(`The recipient address ${to} is not valid`));
    }

    const args: any[] = [
      this.account,
      to,
    ];

    if (params.tokenID) {
      args.push(
        params.tokenID.toBigNumber()
      );
    }

    if (params.amount) {
      args.push(
        BigNumber.from(params.amount)
      );
    }

    if (params.data) {
      args.push(
        BigNumber.from(params.data)
      );
    }

    const contract = this._loadContract(
      this._nftAddress,
      this._type === '721' ? ABIS.SINGLE_NFT_ABI : ABIS.MULTI_NFT_ABI
    );

    this.emit(events.CONFIRMING_TX);
    try {
      const { receipt } = await TxExecutable.invoke(
        'transfer an NFT',
        { callee: () => contract, method: 'safeTransferFrom' },
        { args: () => args }
      );

      this.emit(events.NFT_TRANSFER_TX_CONFIRMED, receipt);
      this.emit(events.NFT_TRANSFER_DONE);

      return receipt;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);
      return Promise.reject(e);
    }
  }
};
