/* eslint-disable no-case-declarations */
/* eslint-disable no-underscore-dangle */
import { INTERFACE_AUCTION, TokenID } from '@elacity-js/lib';
import { parseUnits, formatUnits } from '@ethersproject/units';
import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import {
  CallOverrides,
  ContractReceipt,
  ContractTransaction,
} from '@ethersproject/contracts';
import { selectCurrency } from 'src/constants/currencies';
import { TxExecutable } from 'src/lib/web3/executable/tx';
import type {
  IAuction, ICreateAuctionParams, IUpdateAuctionParams, IPlaceBidFormData, IAuctionResultFormData, IAuctionWithdrawBidFormData,
} from '../types';
import events from '../events';
import { ADDRESS } from '../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericConstructor<T = unknown> = new (...args: any[]) => T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default <T extends GenericConstructor<any>>(BaseClass: T) => class MixedClass extends BaseClass {
  /**
  * Create auction
  * @param params
  */
  public async createAuction(params: ICreateAuctionParams): Promise<ContractReceipt> {
    this.emit(events.AUCTION_CREATE_START, { params });

    try {
      const auctionContract = this._loadContract(this._auctionContractAddress, INTERFACE_AUCTION);
      const payToken = selectCurrency(this.chainId, params.payToken);

      const args = [
        this._nftAddress,
        params.tokenId.toBigNumber(),
        payToken.address,
        parseUnits(`${params.reservePrice}`, payToken.decimals),
        Math.round(params.startTime.getTime() / 1000),
        params.minBidReserve,
        Math.round(params.endTime.getTime() / 1000),
      ];

      this.emit(events.AUCTION_CREATE_WAIT_TX);
      const { receipt } = await TxExecutable.invoke(
        'create auction',
        { callee: auctionContract, method: 'createAuction' },
        { args: () => args }, { estimateGas: true }
      );

      this.emit(events.AUCTION_CREATE_TX_CONFIRMED, receipt);

      this.emit(events.AUCTION_CREATE_DONE);
      return receipt;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);
      return Promise.reject(e);
    }
  }

  public async getAuction(nftAddress: string, tokenId: TokenID): Promise<IAuction> {
    if (!this._auctionContractAddress || !nftAddress) {
      return null;
    }

    try {
      const auctionContract = this._loadContract(this._auctionContractAddress, INTERFACE_AUCTION);

      // get bid data
      const res = await auctionContract.getAuction(nftAddress, tokenId.toBigNumber());

      if (!res || (res && res[0] === ADDRESS.ZERO)) {
        return null;
      }

      const payToken = selectCurrency(this.chainId, res[1]);

      const owner = res[0];
      const _payToken = res[1];
      const reservePrice = parseFloat(formatUnits(res[2], payToken.decimals));
      const startTime = parseFloat(res[3].toString());
      const endTime = parseFloat(res[4].toString());
      const resulted = res[5];
      const minBid = parseFloat(formatUnits(res[6], payToken.decimals));

      const auction: IAuction = {
        owner,
        payToken: _payToken,
        reservePrice,
        startTime,
        endTime,
        resulted,
        minBid,
      };

      // get the highest bid
      try {
        const res2: [string, BigNumber, BigNumber] = await auctionContract.getHighestBidder(
          this._nftAddress,
          tokenId.toBigNumber()
        );
        if (res2) {
          auction.highestBid = {
            bidder: res2[0],
            bid: parseFloat(formatUnits(res2[1], payToken.decimals)),
            lastBidTime: res2[2].toNumber(),
          };
        }
      } catch (e) {
        console.error(e);
      }

      return auction;
    } catch (e) {
      console.error('failed to execute [getAuction] method, returning NULL', e);
      this.emit(events.PROCESS_ERRORED, e);
      return null;
    }
  }

  public async cancelAuction(tokenId: TokenID): Promise<ContractReceipt> {
    this.emit(events.AUCTION_CANCEL_START, { nftAddress: this._nftAddress, tokenId });
    try {
      const auctionContract = this._loadContract(this._auctionContractAddress, INTERFACE_AUCTION);

      this.emit(events.AUCTION_CANCEL_WAIT_TX);
      const { receipt } = await TxExecutable.invoke(
        'cancel Auction',
        { callee: () => auctionContract, method: 'cancelAuction' },
        { args: () => [this._nftAddress, tokenId.toBigNumber()] },
        { estimateGas: true }
      );

      this.emit(events.AUCTION_CANCEL_TX_CONFIRMED, receipt);
      this.emit(events.AUCTION_CANCEL_DONE, { tokenId, contractAddress: this._nftAddress });
      return receipt;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);
      return Promise.reject(e);
    }
  }

  public async updateAuction(params: IUpdateAuctionParams, updatedFields: string[]): Promise<ContractReceipt[]> {
    this.emit(events.AUCTION_UPDATE_START, { params });
    try {
      const auctionContract = this._loadContract(this._auctionContractAddress, INTERFACE_AUCTION);
      const payToken = selectCurrency(this.chainId, params?.payToken);

      this.emit(events.AUCTION_UPDATE_WAIT_TX);

      const updateField = async (field: string) => {
        let receipt = null;
        let args = [this._nftAddress, params.tokenId.toBigNumber()];
        // eslint-disable-next-line default-case
        switch (field) {
          case 'reservePrice':
            args = [...args, parseUnits(`${params.reservePrice}`, payToken.decimals)];
            const r1 = await TxExecutable.invoke(
              'update reserve price',
              { callee: () => auctionContract, method: 'updateAuctionReservePrice' },
              { args: () => args }, { estimateGas: true }
            );
            receipt = r1.receipt;
            break;
          case 'startTime':
            args = [...args, Math.round(params.startTime.getTime() / 1000)];
            const r2 = await TxExecutable.invoke(
              'update start time',
              { callee: () => auctionContract, method: 'updateAuctionStartTime' },
              { args: () => args }, { estimateGas: true }
            );
            receipt = r2.receipt;
            break;
          case 'endTime':
            args = [...args, Math.round(params.endTime.getTime() / 1000)];
            const r3 = await TxExecutable.invoke(
              'update end time',
              { callee: () => auctionContract, method: 'updateAuctionEndTime' },
              { args: () => args }, { estimateGas: true }
            );
            receipt = r3.receipt;
            break;
        }

        return receipt;
      };
      const confirmedTx = await Promise.all(updatedFields.map((f: string) => updateField(f)));

      this.emit(events.AUCTION_UPDATE_TX_CONFIRMED, confirmedTx);
      this.emit(events.AUCTION_UPDATE_DONE, { tokenId: params.tokenId, contractAddress: this._auctionContractAddress });
      return confirmedTx;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);
      return Promise.reject(e);
    }
  }

  /**
   * Place a bid on an active auction
   *
   * @param params
   * @returns
   */
  public async placeBid(params: IPlaceBidFormData): Promise<ContractReceipt> {
    this.emit(events.AUCTION_PLACE_BID_START, { params });

    try {
      const auctionContract = this._loadContract(this._auctionContractAddress, INTERFACE_AUCTION);
      const payToken = selectCurrency(this.chainId, params.payToken);
      this.emit(events.AUCTION_PLACE_BID_WAIT_TX);

      let contractMethod: string;

      const options: CallOverrides = {
        from: this.account,
      };

      const args: BigNumberish[] = [
        this._nftAddress,
        params.tokenID.toBigNumber(),
      ];

      if (['', ADDRESS.ZERO].includes(params.payToken)) {
        options.value = parseUnits(params.amount.toString(), payToken.decimals);
        contractMethod = 'placeBid(address,uint256)';
      } else {
        contractMethod = 'placeBid(address,uint256,uint256)';
        args.push(
          parseUnits(params.amount.toString(), payToken.decimals)
        );
      }

      const { receipt } = await TxExecutable.invoke(
        'place a bid',
        { callee: () => auctionContract, method: contractMethod },
        { args: () => args }, { estimateGas: true, ...options }
      );

      this.emit(events.AUCTION_PLACE_BID_TX_CONFIRMED, receipt);
      this.emit(events.AUCTION_PLACE_BID_DONE);

      return receipt;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);

      return Promise.reject(e);
    }
  }

  /**
   * Result an auction
   *
   * @param values
   * @returns
   */
  public async resultAuction(values: IAuctionResultFormData): Promise<ContractReceipt> {
    this.emit(events.AUCTION_RESULT_START, values);
    try {
      const auctionContract = this._loadContract(this._auctionContractAddress, INTERFACE_AUCTION);

      this.emit(events.AUCTION_RESULT_WAIT_TX);
      const { receipt } = await TxExecutable.invoke(
        'end auction and collect payment',
        { callee: () => auctionContract, method: 'resultAuction' },
        { args: () => [this._nftAddress, values.tokenID.toBigNumber()] },
        { estimateGas: true }
      );

      this.emit(events.AUCTION_RESULT_TX_CONFIRMED, receipt);
      this.emit(events.AUCTION_RESULT_DONE, { tokenId: values.tokenID, contractAddress: this._nftAddress });

      return receipt;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);
      return Promise.reject(e);
    }
  }

  public async withdrawBid(values: IAuctionWithdrawBidFormData): Promise<ContractReceipt> {
    this.emit(events.AUCTION_WITHDRAW_BID_START, values);
    try {
      const auctionContract = this._loadContract(this._auctionContractAddress, INTERFACE_AUCTION);

      this.emit(events.AUCTION_WITHDRAW_BID_WAIT_TX);
      const { receipt } = await TxExecutable.invoke(
        'withdraw aution',
        { callee: () => auctionContract, method: 'withdrawBid' },
        { args: () => [this._nftAddress, values.tokenID.toBigNumber()] },
        { estimateGas: true }
      );

      this.emit(events.AUCTION_WITHDRAW_BID_TX_CONFIRMED, receipt);
      this.emit(events.AUCTION_WITHDRAW_BID_DONE);

      return receipt;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);
      return Promise.reject(e);
    }
  }
};
