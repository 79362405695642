/* eslint-disable no-underscore-dangle */
import { CollectionOf } from '@elacity-js/lib';
import {
  GraphqQLResponse, PaginatedQuery, Playlist, PlaylistItemInput,
} from 'src/types';
import { nftFragment, profileFragment } from './query.base';
import { authenticatedApi } from './query.private';

interface FetchPlaylistContentReq {
  id?: string | number;
  filters?: PaginatedQuery;
}

enum Tags {
  PlayList = 'Playlist',
  PlayListMedia = 'PlaylistMedia',
}

const playlistApi = authenticatedApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PlayList, Tags.PlayListMedia],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUserPlaylist: builder.query<Playlist[], void>({
        query: () => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            query {
              getUserPlaylist {
                _id
                name
                contents {
                  contractAddress
                  tokenId
                }
              }
            }
          `,
            variables: {},
          },
        }),
        transformResponse: async (r: GraphqQLResponse<Playlist[]>): Promise<Playlist[]> => r.data?.getUserPlaylist || [],
      }),
      createPlaylist: builder.mutation<GraphqQLResponse<Playlist>, { name: string }>({
        query: ({ name }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            mutation CreatePlaylist($name: String) {
              createPlaylist(name: $name) {
                _id
                name
                contents {
                  contractAddress
                  tokenId
                }
              }
            }`,
            variables: {
              name,
            },
          },
        }),
      }),
      addPlaylistItem: builder.mutation<GraphqQLResponse<Playlist>, PlaylistItemInput>({
        query: ({ id, item }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            mutation AddPlaylistItem($id: String!, $item: PlaylistItemInput!) {
              addPlaylistItem(id: $id, item: $item) {
                _id
                name
                contents {
                  contractAddress
                  tokenId
                }
              }
            }
            `,
            variables: {
              id,
              item,
            },
          },
        }),
        invalidatesTags: (result, error, { id }) => [
          { type: Tags.PlayListMedia, id },
        ],
      }),
      removePlaylistItem: builder.mutation<GraphqQLResponse<Playlist>, PlaylistItemInput>({
        query: ({ id, item }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            mutation RemovePlaylistItem($id: String!, $item: PlaylistItemInput!) {
              removePlaylistItem(id: $id, item: $item) {
                _id
                name
                contents {
                  contractAddress
                  tokenId
                }
              }
            }
            `,
            variables: {
              id,
              item,
            },
          },
        }),
        invalidatesTags: (result, error, { id, item }) => [
          { type: Tags.PlayListMedia, id },
          { type: Tags.PlayListMedia, id: `${id}.${item.contractAddress}.${String(item.tokenId)}` },
        ],
      }),
      removePlaylist: builder.mutation<GraphqQLResponse<Playlist>, { id: string }>({
        query: ({ id }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            mutation RemovePlaylist($id: String!) {
              removePlaylist(id: $id) {
                _id
                name
                contents {
                  contractAddress
                  tokenId
                }
              }
            }
            `,
            variables: {
              id,
            },
          },
        }),
      }),
      isSaveToLater: builder.query<GraphqQLResponse<boolean>, Pick<PlaylistItemInput, 'item'>>({
        query: ({ item }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
              query IsSavedToLater($item: PlaylistItemInput!) {
                isSavedToLater(item: $item)
              }`,
            variables: {
              item,
            },
          },
        }),
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fetchPlaylistContent: builder.query<any, FetchPlaylistContentReq>({
        query: ({ id, filters }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            ${profileFragment}
            ${nftFragment}
                
            query getPlaylistContent($id: String!, $filters: FilterPaginationInput) {
              getPlaylistContent(id: $id, filters: $filters) {
                total
                offset
                limit
                metadata {
                  _id
                  name
                }
                data {
                  __typename
                  ...on ProtectedAsset {
                    ...nftFields
                    metadata {
                      kid
                      iscc
                      media {
                        contentType
                        protectionType
                      }
                      properties {
                        publisher {
                          ...profileFields
                        }
                        authority
                        labelType
                        distribution
                      }
                      attributes {
                        trait_type
                        value
                      }
                    }
                    operative {
                      opType
                      resellerCut
                      access {
                        totalSupply
                        listings {
                          seller
                          quantity
                          price
                          payToken
                        }
                      }
                    }
                  }
                }
              }
            }`,
            variables: {
              filters,
              id,
            },
          },
        }),
        transformResponse: async (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
          r: GraphqQLResponse<any>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ): Promise<CollectionOf<any>> => {
          if (r.data?.getPlaylistContent) {
            const { data, ...raw } = r.data?.getPlaylistContent;
            return {
              ...raw,
              items: (data || []).map((item) => ({
                ...item,
                tokenId: item.tokenID,
                image: item.imageURL,
              })),
            };
          }

          return Promise.reject(r.errors[0]);
        },
        providesTags: (result, error) => [
          ...(result && !error ? (
            [
              { type: Tags.PlayListMedia, id: result.metadata._id },
              ...result.items?.map(({ hexTokenID, contractAddress }) => ({
                type: Tags.PlayListMedia,
                id: `${result.metadata._id}.${contractAddress}.${hexTokenID}`,
              })),
            ]
          ) : []),
        ],
      }),
    }),
  });

export { playlistApi };

export const {
  useGetUserPlaylistQuery,
  useCreatePlaylistMutation,
  useAddPlaylistItemMutation,
  useRemovePlaylistItemMutation,
  useRemovePlaylistMutation,
  useIsSaveToLaterQuery,
  useFetchPlaylistContentQuery,
} = playlistApi;
