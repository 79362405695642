import { useCallback } from 'react';
import { ContractReceipt } from '@ethersproject/contracts';
import { ADDRESS } from 'src/lib/nfts/constants';
import useWeb3Application from './useWeb3Application';
import usePayment from './usePayment';

interface SwapOptions {
  /**
   * human readable amount to swap from
   */
  fromAmount: number;

  /**
   * Token from which we want to swap
   */
  fromToken: string;

  /**
   * Token to which we want to swap
   */
  toToken: string;
}

export default () => {
  const { chainId, library, payment } = useWeb3Application();
  const { supportedMap } = usePayment();
  const execSwap = useCallback(
    // For now , only wrap / unwrap are supported
    async ({ fromAmount, fromToken, toToken }: SwapOptions): Promise<ContractReceipt> => {
      console.log({ fromAmount, fromToken, toToken });
      if (fromToken === ADDRESS.ZERO && supportedMap[toToken].isWrapped) {
        // wrap
        return payment?.useWrapped(supportedMap[toToken].address).deposit(fromAmount);
      } if (supportedMap[fromToken].isWrapped && toToken === ADDRESS.ZERO) {
        // unwrap
        return payment?.useWrapped(supportedMap[fromToken].address).withdraw(fromAmount);
      }
      // not supported
      return Promise.reject(new Error(`Swapping from ${fromToken} to ${toToken} is not yet supported`));
    }, [chainId, library]
  );

  return {
    execSwap,
  };
};
