import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { ContractTransaction } from '@ethersproject/contracts';
import useWeb3Application from './useWeb3Application';
import useErrorHandler from './useErrorHandler';

export default () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { library, account } = useWeb3Application();
  const { throwError } = useErrorHandler();

  const ensureApproval = useCallback(async (
    approvalStatus: boolean,
    approveFunc: () => Promise<ContractTransaction>,
    onApproveFail: (e: Error) => void,
    next?: () => void
  ) => {
    const throwApproveError = (e: Error) => {
      if (typeof onApproveFail === 'function') {
        onApproveFail(e);
      }
      closeSnackbar();
      throwError(e);
    };

    if (typeof approveFunc !== 'function') {
      throwApproveError(new Error('approveFunc arg must be a function'));
    }
    let approved = approvalStatus;
    if (approvalStatus === false) {
      enqueueSnackbar('Approval ongoing, please wait until Tx confirmed ...', {
        persist: true,
        variant: 'warning',
      });
      try {
        const tx = await approveFunc();
        await tx.wait();
        approved = true;
      } catch (e) {
        return throwApproveError(e);
      }
    }
    if (approved) {
      closeSnackbar();
      return next();
    }

    return throwApproveError(new Error('Approval failed'));
  }, [library, account]);

  return {
    ensureApproval,
  };
};
