/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { CSSProperties } from 'react';
import { Tooltip } from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Model3dViewer, { loaders } from 'src/components/@material-extend/Model3dViewer';
import c from 'src/config';
import { baseURL } from 'src/utils';

export type FileType = 'image' | 'video' | '3d' | 'default';
export type ViewMode = 'view' | 'fullscreen' | 'preview';

// @ts-ignore
const MediaImage = styled('img')(({ theme, mode }) => ({
  height: '100%',
  width: '100%',
  // objectFit: 'cover',
  objectFit: 'contain',
  pointerEvents: 'all',
  [theme.breakpoints.between(c.layout.CARD_MOBILE_XS_WIDTH, 'sm')]: {
    ...(mode === 'preview' &&
      {
        // height: 145,
      }),
  },
  ...(mode === 'fullscreen' && {
    objectFit: 'none',
    [theme.breakpoints.down('md')]: {
      objectFit: 'contain',
    },
  }),
}));

interface MediaPreviewProps {
  mode?: ViewMode;
  src: string;
  type: FileType;
  mimeType?: string;
  style?: CSSProperties;
  sxVideo?: CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement | HTMLDivElement>;
  Container?: React.ComponentType;
}

const Preview = ({ src, type, style, sxVideo, mimeType, mode, onClick, Container }: MediaPreviewProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const propsMap: Record<typeof type, Record<typeof mode, any>> = {
    video: {
      view: {
        muted: false,
        volume: 20,
        autoPlay: false,
        loop: false,
        controls: true,
      },
      preview: {
        muted: true,
        autoPlay: true,
        loop: true,
        preload: 'metadata',
      },
      fullscreen: {
        muted: false,
        volume: 50,
        autoPlay: true,
        loop: true,
        controls: true,
      },
    },
    image: {
      view: {
        style: {
          height: 'auto',
        },
      },
      preview: {},
      fullscreen: {
        style: {
          objectFit: 'none',
        },
      },
    },
    default: {
      view: {},
      preview: {},
      fullscreen: {},
    },
    '3d': {
      view: {
        sx: {
          minHeight: 400,
        },
      },
      preview: {
        preload: true,
        concurrent: true,
        sx: {
          minHeight: 200,
        },
      },
      fullscreen: {},
    },
  };

  switch (type) {
    case 'video':
      if (src.endsWith('.gif')) {
        // probably a thumbnail
        return (
          <Container>
            <MediaImage
              crossOrigin="anonymous"
              src={src}
              alt="Video Thumbnail"
              className="thumbnail"
              onClick={onClick}
              mode={mode}
              {...{ ...propsMap.image[mode], ...{ style } }}
            />
          </Container>
        );
      }

      return (
        <Container>
          <video
            {...(propsMap.video[mode] || {})}
            onClick={onClick}
            style={{ ...sxVideo }}
            crossOrigin="anonymous"
          >
            <track kind="captions" />
            <source src={`${src}${mode === 'preview' ? '#t=0,3' : ''}`} type="video/mp4" />
          </video>
        </Container>
      );
    case '3d':
      // if (Object.keys(loaders).includes(mimeType)) {
      //   return (
      //     <div style={{ width: '100%', height: '100%' }}>
      //       <Model3dViewer
      //         mimeType={mimeType}
      //         src={src}
      //         {...propsMap['3d'][mode] || {}}
      //       />
      //     </div>
      //   );
      // }

      // when not supported 3d model
      return (
        <Container>
          <div style={{ width: '100%', height: '100%' }} onClick={onClick}>
            <Tooltip title={`We failed to load your 3D model with format ${mimeType}`}>
              <div style={{ float: 'left', position: 'absolute', marginLeft: 4, marginTop: 4 }}>
                <WarningIcon sx={{ color: 'warning.main', fontSize: '1rem' }} />
              </div>
            </Tooltip>
            <img
              crossOrigin="anonymous"
              alt="3d format not supported"
              src={baseURL('/static/filetype/3d.png')}
              style={{ width: '100%' }}
            />
          </div>
        </Container>
      );

    default:
      return (
        <Container>
          <MediaImage
            crossOrigin="anonymous"
            src={src}
            alt="Media"
            className="media-img"
            onClick={onClick}
            mode={mode}
            {...{ ...propsMap.image[mode], ...{ style } }}
          />
        </Container>
      );
  }
};

Preview.defaultProps = {
  mode: 'preview',
  Container: React.Fragment,
};

export default {
  Preview,
};
