/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import type { Currency } from '@elacity-js/lib';
import Box, { BoxProps } from '@mui/material/Box';
import MuiOutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import MuiInputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import {
  InputAdornment,
  FormControl,
  Popover,
  Select as MuiSelect,
  Button as MuiButton,
  ClickAwayListener,
  Divider,
  Typography,
  Tooltip,
  SxProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SelectProps, SelectChangeEvent } from '@mui/material/Select';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ElastosLogoMark } from 'src/assets/elacity';
import {
  EthereumLogo,
  TokenUSDC,
  TokenUSDT,
  TokenBUSD,
  TokenBunny,
  TokenGlide,
  TokenElk,
  TokenFilDA,
  TokenGold,
  TokenCreda,
  BinanceLogo,
  PolygonLogo,
  EvmosLogo,
  WingsLogo,
} from 'src/assets/others';
import { nFormat } from 'src/utils';
import {
  usePayment, useWeb3Application, useProfile, useUSDCPrice,
} from 'src/hooks';
import { ADDRESS } from 'src/lib/nfts/constants';
import { nativeCurrencies } from 'src/constants';
import type { PriceRange } from 'src/types';
import SelectContext from 'src/contexts/InputContext';
import type { SelectOption } from 'src/components/form/Select';

const InputLabel = styled(MuiInputLabel, {
  shouldForwardProp: (prop: string) => prop !== 'size',
})<InputLabelProps & { size?: OutlinedInputProps['size'] }>(({ size }) => ({
  ...(size === 'small' && {
    fontSize: '0.9rem!important',
    '&.MuiInputLabel-shrink': {
      top: 2,
    },
  }),
}));

const OutlinedInput = styled(MuiOutlinedInput, {
  shouldForwardProp: (prop: string) => prop !== 'rounded',
})<OutlinedInputProps & { rounded?: boolean }>(({ theme, rounded, size }) => ({
  ...(rounded && {
    borderRadius: theme.spacing(10),
  }),
  ...(size === 'small' && {
    fontSize: '0.85rem',
  }),
}));

const Button = styled(MuiButton)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  padding: theme.spacing(1, 3),
}));

const PriceContainer = styled(Box, {
  name: 'UiPriceView-container',
})(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'right',
}));

const Select = styled(MuiSelect)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

const PriceTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(30),
    },
  })
);

interface TokenLogoProps {
  currency: string;
  size?: number;
  variant?: 'normal' | 'text';
}

export const TokenLogo = ({ currency, size, variant }: TokenLogoProps) => {
  const fontSize = size || 24;
  const { chainId } = useWeb3Application();
  const { currencies, lookup } = usePayment();
  const payToken = lookup(currency);

  const displayer = [
    variant === 'normal' &&
      (payToken?.logo === 'USDC' ||
        currencies
          .filter((c) => c?.symbol === 'USDC')
          .map((c) => c.address)
          .includes(payToken?.address?.toLowerCase())),
    variant === 'normal' &&
      (payToken?.logo === 'USDT' ||
        currencies
          .filter((c) => c?.symbol === 'USDT')
          .map((c) => c.address)
          .includes(payToken?.address?.toLowerCase())),
    variant === 'normal' &&
      (payToken?.logo === 'BUSD' ||
        currencies
          .filter((c) => c?.symbol === 'BUSD')
          .map((c) => c.address)
          .includes(payToken?.address?.toLowerCase())),
    variant === 'normal' &&
      (payToken?.logo === 'BUNNY' ||
        currencies
          .filter((c) => c?.symbol === 'BUNNY')
          .map((c) => c.address)
          .includes(payToken?.address?.toLowerCase())),
    variant === 'normal' &&
      (payToken?.logo === 'GLIDE' ||
        currencies
          .filter((c) => c?.symbol === 'GLIDE')
          .map((c) => c.address)
          .includes(payToken?.address?.toLowerCase())),
    variant === 'normal' &&
      (payToken?.logo === 'ELK' ||
        currencies
          .filter((c) => c?.symbol === 'ELK')
          .map((c) => c.address)
          .includes(payToken?.address?.toLowerCase())),
    variant === 'normal' &&
      (payToken?.logo === 'GOLD' ||
        currencies
          .filter((c) => c?.symbol === 'GOLD')
          .map((c) => c.address)
          .includes(payToken?.address?.toLowerCase())),
    variant === 'normal' &&
      (payToken?.logo === 'FilDA' ||
        currencies
          .filter((c) => c?.symbol === 'FilDA')
          .map((c) => c.address)
          .includes(payToken?.address?.toLowerCase())),
    variant === 'normal' &&
      (payToken?.logo === 'CREDA' ||
        currencies
          .filter((c) => c?.symbol === 'CREDA')
          .map((c) => c.address)
          .includes(payToken?.address?.toLowerCase())),
    variant === 'normal' &&
          (payToken?.logo === 'WINGS' ||
            currencies
              .filter((c) => c?.symbol === 'WINGS')
              .map((c) => c?.address)
              .includes(payToken?.address?.toLowerCase())),
    variant === 'normal' && (payToken?.logo === 'ELA' || ([20, 21].includes(chainId) && currency === ADDRESS.ZERO)),
    variant === 'normal' && (payToken?.logo === 'ETH' || ([1, 3, 5].includes(chainId) && currency === ADDRESS.ZERO)),
    variant === 'normal' && (payToken?.logo === 'BNB' || ([56, 57].includes(chainId) && currency === ADDRESS.ZERO)),
    variant === 'normal' && (payToken?.logo === 'MATIC' || ([137].includes(chainId) && currency === ADDRESS.ZERO)),
    variant === 'normal' && (payToken?.logo === 'EVMOS' || ([9001].includes(chainId) && currency === ADDRESS.ZERO)),
    variant === 'normal' && !payToken?.logo && currency !== ADDRESS.ZERO,
  ];
  let index: number = displayer.indexOf(true);
  if (index < 0) {
    index = displayer.length - 1;
  }

  const tokenDisplay: React.ReactNode[] = [
    <TokenUSDC
      viewBox={`0 0 ${fontSize} ${fontSize}`}
      sx={{
        width: fontSize,
        height: fontSize,
      }}
    />,
    <TokenUSDT
      viewBox={`0 0 ${fontSize} ${fontSize}`}
      sx={{
        width: fontSize,
        height: fontSize,
      }}
    />,
    <TokenBUSD
      viewBox={`0 0 ${fontSize} ${fontSize}`}
      sx={{
        width: fontSize,
        height: fontSize,
      }}
    />,
    <TokenBunny
      style={{
        width: fontSize,
        height: fontSize,
      }}
    />,
    <TokenGlide
      style={{
        width: fontSize,
        height: fontSize,
      }}
    />,
    <TokenElk
      style={{
        width: fontSize,
        height: fontSize,
      }}
    />,
    <TokenGold
      style={{
        width: fontSize,
        height: fontSize,
      }}
    />,
    <TokenFilDA
      style={{
        width: fontSize,
        height: fontSize,
      }}
    />,
    <TokenCreda
      style={{
        width: fontSize,
        height: fontSize,
      }}
    />,
    <WingsLogo
      style={{
        width: fontSize,
        height: fontSize,
      }}
    />,
    <ElastosLogoMark
      viewBox={`0 0 ${fontSize} ${fontSize}`}
      sx={{
        width: fontSize,
        height: fontSize,
        ...(currency !== ADDRESS.ZERO && {
          fill: 'primary.main',
          color: 'error.main',
        }),
      }}
    />,
    <EthereumLogo
      viewBox={`0 0 ${fontSize} ${fontSize}`}
      sx={{
        width: fontSize,
        height: fontSize,
        ...(currency !== ADDRESS.ZERO && {
          fill: 'primary.main',
          color: 'error.main',
        }),
      }}
    />,
    <BinanceLogo viewBox={`0 0 ${fontSize} ${fontSize}`} sx={{ width: fontSize, height: fontSize, fill: 'grey' }} />,
    <PolygonLogo viewBox={`0 0 ${fontSize} ${fontSize}`} sx={{ width: fontSize, height: fontSize, fill: 'grey' }} />,
    <EvmosLogo viewBox={`0 0 ${fontSize} ${fontSize}`} sx={{ width: fontSize, height: fontSize, fill: 'grey' }} />,
    <ElastosLogoMark viewBox={`0 0 ${fontSize} ${fontSize}`} sx={{ width: fontSize, height: fontSize, fill: 'grey' }} />,
  ];

  return <>{tokenDisplay[index]}</>;
};

TokenLogo.defaultProps = {
  variant: 'normal',
  size: 24,
};

interface PriceViewProps extends BoxProps {
  value?: number;
  size?: number;
  fontWeight?: CSSProperties['fontWeight'];
  variant?: 'normal' | 'text';
  currency?: string;
  precision?: number;
  hideUSD?: boolean;
}

const PriceView: React.FC<PriceViewProps> = ({
  value,
  size,
  fontWeight,
  variant,
  currency: _currency,
  precision,
  hideUSD,
  ...props
}: PriceViewProps) => {
  const currency = ['ftm', 'wftm', null, undefined, ''].includes(_currency) ? ADDRESS.ZERO : _currency;
  const fontSize = size || 24;
  const { native, lookupV2: lookup } = usePayment();
  const payToken = React.useMemo(() => lookup(currency), [currency]);

  const priceUSD = useUSDCPrice(currency);

  return (
    <PriceContainer {...(props as BoxProps)}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          <Tooltip title={payToken?.symbol || native?.symbol} placement="top" aria-label="Currency">
            <div style={{ display: 'flex' }}>
              <TokenLogo currency={currency} size={size} variant={variant} />
            </div>
          </Tooltip>
          {priceUSD ? (
            <PriceTooltip
              title={(
                <>
                  <Typography component="div" sx={{ textAlign: 'right', mt: -0.15 }}>
                    &#8770; $
                    {nFormat(value * parseFloat(priceUSD?.toFixed(5)), 2)}
                  </Typography>
                </>
              )}
              placement="top"
            >
              <span
                className="UikitPrice-Value"
                style={{
                  marginLeft: 4,
                  fontSize,
                  fontWeight,
                }}
              >
                {' '}
                {nFormat(value, precision || 2)}
              </span>
            </PriceTooltip>
          ) : (
            <span
              className="UikitPrice-Value"
              style={{
                marginLeft: 4,
                fontSize,
                fontWeight,
              }}
            >
              {' '}
              {nFormat(value, precision || 2)}
            </span>
          )}
          {Boolean(variant === 'text') && <span>{payToken?.symbol}</span>}
        </Box>
        {Boolean(priceUSD && !hideUSD) && (
          <Typography component="div" fontSize="0.65rem" sx={{ textAlign: 'right', mt: -0.15 }}>
            &#8770; $
            {nFormat(value * parseFloat(priceUSD.toFixed(5)), 2)}
          </Typography>
        )}
      </Box>
    </PriceContainer>
  );
};

PriceView.defaultProps = {
  hideUSD: true,
  variant: 'normal',
  size: 24,
};

interface PriceInputProps extends Omit<OutlinedInputProps, 'error'> {
  helpText?: string | React.ReactNode;
  currency: string;
  handleCurrencyChange?: SelectProps['onChange'];
  preventCurrencyChange?: boolean;
  preventInputChange?: boolean;
  error?: string;
  swap?: boolean;
  nativeSupport?: boolean;
  fullWidth?: boolean;
}

export const PriceInput: React.FC<PriceInputProps> = ({
  fullWidth,
  helpText,
  error,
  sx,
  label,
  disabled,
  currency,
  handleCurrencyChange,
  preventCurrencyChange,
  preventInputChange,
  readOnly,
  swap,
  nativeSupport,
  ...props
}: PriceInputProps) => {
  const { chainId } = useWeb3Application();
  const { balance } = useProfile();
  const { supportedArr: currencies, supportedMap: _currenciesMap } = usePayment();
  const currenciesMap = {
    ..._currenciesMap,
    [ADDRESS.ZERO]: nativeCurrencies[chainId],
  };
  const options = [
    ...(swap || nativeSupport ? [nativeCurrencies[chainId]] : []),
    ...currencies.filter((c: Currency) => Boolean(swap ? !!c?.swapSupports : true)),
  ].map(({ symbol, address }) => ({
    value: address,
    label: symbol,
  }));

  return (
    <FormControl className={classNames('Uikit-Price-Control')} sx={sx} variant="outlined" fullWidth={fullWidth}>
      <InputLabel className={classNames('Uikit-Price-Label')}>{label}</InputLabel>
      <OutlinedInput
        className={classNames('Uikit-Price-Input')}
        disabled={disabled}
        readOnly={readOnly}
        inputProps={{
          'aria-readonly': preventInputChange,
        }}
        label={label}
        endAdornment={(
          <InputAdornment position="end">
            <Select
              className={classNames('Uikit-Price-Select')}
              disabled={disabled}
              readOnly={readOnly || preventCurrencyChange}
              value={currency || ''}
              onChange={handleCurrencyChange}
              required
              renderValue={(val?: string) => {
                if (!val) {
                  return null;
                }

                return (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'normal' }}>
                    <TokenLogo currency={val} size={16} />
                    <span style={{ marginLeft: 5 }}>{currenciesMap[val.toLowerCase()]?.symbol}</span>
                  </Box>
                );
              }}
            >
              {options.map((o: SelectOption<string>) => (
                <MenuItem key={o.value} value={o.value}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'normal' }}>
                    <TokenLogo currency={o.value} size={16} />
                    <span style={{ marginLeft: 5, minWidth: 60 }}>{o.label}</span>
                    <span style={{ marginLeft: 5, textAlign: 'right', color: balance[o.value] > 0 ? 'inherit' : '#888' }}>
                      {nFormat(balance[o.value], 3)}
                    </span>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </InputAdornment>
        )}
        error={Boolean(error)}
        {...props}
      />
      {helpText && <FormHelperText component="div">{helpText}</FormHelperText>}
      {error && <FormHelperText error={Boolean(error)}>{error}</FormHelperText>}
    </FormControl>
  );
};

PriceInput.defaultProps = {
  fullWidth: true,
};

interface PriceRangeContextValue {
  innerValue: PriceRange;
  setValue: React.Dispatch<React.SetStateAction<PriceRange>>;
  textValue?: string;
}
const PriceRangeContext = React.createContext<PriceRangeContextValue>({ innerValue: null, setValue: () => {} });

interface PriceRangeProviderProps {
  value?: PriceRange;
  defaultValue?: PriceRange;
  label?: OutlinedInputProps['label'];
}

export const PriceRangeProvider = ({
  children,
  value,
  defaultValue,
  label,
}: React.PropsWithChildren<PriceRangeProviderProps>) => {
  const { supportedMap, prefered } = usePayment();
  const [innerValue, setValue] = React.useState<PriceRange>(value || defaultValue || { paymentToken: prefered?.address });

  const textValue = React.useMemo((): string => {
    if (!innerValue?.from && !innerValue?.to) {
      return String(label || '');
    }
    if (!innerValue?.from && innerValue?.to) {
      return `To ${innerValue?.to} ${supportedMap[innerValue?.paymentToken]?.symbol || ''}`;
    }
    if (innerValue?.from && !innerValue?.to) {
      return `From ${innerValue?.from} ${supportedMap[innerValue?.paymentToken]?.symbol || ''}`;
    }
    if (innerValue?.from && innerValue?.to) {
      return `${innerValue?.from} - ${innerValue?.to} ${supportedMap[innerValue?.paymentToken]?.symbol || ''}`;
    }
    return String(label || '');
  }, [innerValue]);

  return (
    <PriceRangeContext.Provider
      value={{
        innerValue,
        setValue,
        textValue,
      }}
    >
      {children}
    </PriceRangeContext.Provider>
  );
};

export const PriceRangeConsumer = PriceRangeContext.Consumer;

interface PriceRangeInputGroupProps {
  value?: PriceRange;
  defaultValue?: PriceRange;
  onChange?: (e: React.MouseEvent<HTMLElement> | null, value: PriceRange) => void;

  size?: OutlinedInputProps['size'];
  setAnchorEl?: (el: HTMLElement) => void;
  sx?: SxProps;

  noDivider?: boolean;
}

const PriceRangeInputGroup = ({
  children,
  onChange,
  value,
  defaultValue,
  size,
  setAnchorEl,
  noDivider,
  sx,
}: React.PropsWithChildren<PriceRangeInputGroupProps>) => {
  const { innerValue, setValue } = React.useContext<PriceRangeContextValue>(PriceRangeContext);
  const { supportedArr: currencies, prefered, native } = usePayment();
  const options = [
    ...(native
      ? [
        {
          value: native.address,
          label: native?.symbol,
        },
      ]
      : []),
    ...currencies.map(({ symbol, address }) => ({
      value: address,
      label: symbol,
    })),
  ];

  React.useEffect(() => {
    if (!innerValue?.paymentToken && prefered?.address) {
      setValue((prev) => ({
        ...prev,
        paymentToken: prefered?.address,
      }));
    }
  }, [prefered]);

  React.useEffect(() => {
    setValue(value);
  }, [value]);

  const handleTokenChange = (e: SelectChangeEvent<HTMLInputElement>) => {
    setValue(
      (prev) => ({
        ...prev,
        paymentToken: e.target.value,
      } as PriceRange)
    );
  };

  const handleInputChange = (key: 'from' | 'to') => (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      (prev) => ({
        ...prev,
        [key]: e.target.value,
      } as PriceRange)
    );
  };

  return (
    <>
      <Box sx={sx}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <InputLabel>Token</InputLabel>
              <Select
                native
                variant="outlined"
                size="small"
                value={innerValue?.paymentToken}
                onChange={handleTokenChange}
                inputProps={{
                  name: 'Token',
                }}
              >
                {options.map((o: SelectOption<string>) => (
                  <option key={o.value} value={o.value}>
                    {o.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel margin="dense" sx={{ top: -8, '&.MuiInputLabel-shrink': { top: 0 } }}>
                From
              </InputLabel>
              <OutlinedInput
                label="From"
                type="number"
                size="small"
                value={innerValue?.from || ''}
                onChange={handleInputChange('from')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel margin="dense" sx={{ top: -8, '&.MuiInputLabel-shrink': { top: 0 } }}>
                To
              </InputLabel>
              <OutlinedInput
                label="To"
                type="number"
                size="small"
                value={innerValue?.to || ''}
                onChange={handleInputChange('to')}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {children}

      {!noDivider && <Divider />}

      <Box sx={{ display: 'flex', justifyContent: 'space-evenly', p: 1 }}>
        {typeof defaultValue !== 'undefined' && (
          <Button
            variant="text"
            size={size || 'medium'}
            onClick={() => {
              onChange?.(null, defaultValue);
              setAnchorEl?.(null);
            }}
          >
            Clear
          </Button>
        )}
        <Button
          variant="contained"
          size={size || 'medium'}
          onClick={() => {
            onChange?.(null, innerValue);
            setAnchorEl?.(null);
          }}
        >
          Apply
        </Button>
      </Box>
    </>
  );
};

interface PriceRangeSelectorProps extends Omit<OutlinedInputProps, 'onChange' | 'value'> {
  fullWidth?: boolean;
  value?: PriceRange;
  defaultValue?: PriceRange;
  onChange?: (e: React.MouseEvent<HTMLElement> | null, value: PriceRange) => void;
  applyChangeOnBlur?: boolean;
  rounded?: boolean;
}

const PriceRangeSelector = ({
  children,
  fullWidth,
  value,
  label,
  applyChangeOnBlur,
  rounded,
  defaultValue,
  onChange,
  ...props
}: React.PropsWithChildren<PriceRangeSelectorProps>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleTogglePopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <PriceRangeProvider value={value} defaultValue={defaultValue} label={label}>
      <PriceRangeContext.Consumer>
        {({ innerValue, textValue }) => (
          <FormControl fullWidth={fullWidth} sx={{ m: 1 }}>
            {label && [innerValue?.from, innerValue?.to].filter((v) => !!v).length > 0 && (
              <InputLabel size={props.size || 'medium'}>{label}</InputLabel>
            )}
            <ClickAwayListener
              onClickAway={() => {
                setAnchorEl(null);
                if (applyChangeOnBlur) {
                  onChange?.(null, innerValue);
                }
              }}
            >
              <Box>
                <OutlinedInput
                  type="text"
                  readOnly
                  rounded={rounded}
                  onClick={handleTogglePopper}
                  {...(label &&
                    [innerValue?.from, innerValue?.to].filter((v) => !!v).length > 0 && {
                    label,
                  })}
                  inputProps={{
                    style: {
                      cursor: 'pointer',
                    },
                  }}
                  value={textValue}
                  {...props}
                  fullWidth={fullWidth}
                />
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  disablePortal={false}
                >
                  <PriceRangeInputGroup
                    onChange={onChange}
                    value={value}
                    defaultValue={defaultValue}
                    size={props.size}
                    setAnchorEl={setAnchorEl}
                    sx={{ p: 2, width: 300 }}
                  >
                    {children}
                  </PriceRangeInputGroup>
                </Popover>
              </Box>
            </ClickAwayListener>
          </FormControl>
        )}
      </PriceRangeContext.Consumer>
    </PriceRangeProvider>
  );
};

export default {
  View: PriceView,
  Input: PriceInput,
  RangeSelector: PriceRangeSelector,
  RangeInputGroup: PriceRangeInputGroup,
  Consumer: SelectContext.Consumer,
};
