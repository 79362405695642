import { useState, useEffect } from 'react';
import { collection as collectionApi } from 'src/api';
import useClientHttp from 'src/hooks/useClientHttp';
import { IMintableCollection } from 'src/types';
import useErrorHandler from './useErrorHandler';
import useWeb3Application from './useWeb3Application';
import useAddresses from './useAddresses';

const useCollections = () => {
  const { throwError } = useErrorHandler();
  const { account } = useWeb3Application();
  const { NFT_ADDRESS } = useAddresses();
  const [collections, setCollections] = useState<IMintableCollection[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { getClient } = useClientHttp();
  const defaultCollection = {
    collectionName: 'Elacity Genesis',
    erc721Address: NFT_ADDRESS,
    type: 721,
  };

  useEffect(() => {
    async function fetchCollections() {
      try {
        setLoading(true);
        const clientHttp = await getClient();
        const results = await collectionApi.getMintableCollections(clientHttp);
        const cols = (results.data || []).map((c) => c);
        setCollections([defaultCollection, ...cols]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        throwError(e);
      }
    }

    if (account) {
      fetchCollections();
    }
  }, [account]);

  return { collections, loading };
};

export default useCollections;
