import { useCallback } from 'react';
import { FormikErrors } from 'formik';
import { useSnackbar } from 'notistack';
import { TokenID } from '@elacity-js/lib';
import { isAddress } from '@ethersproject/address';
import { ContractReceipt } from '@ethersproject/contracts';
import events from 'src/lib/nfts/events';
import { TransferableNftFactory, BurnableNftFactory } from 'src/lib/nfts/factories';
import { useNotifiyTokenRemovalMutation } from 'src/state/api';
import useScContracts from './contracts/sc.contracts';
import useWeb3Application from './useWeb3Application';
import useModal, { ModalOptionsProps } from './useModal';
import useErrorHandler from './useErrorHandler';

interface Params {
  contractAddress?: string;
}

interface TransferFormData {
  address: string;
  tokenID: TokenID;
}

interface TransferActionOptions extends ModalOptionsProps<TransferFormData> {
  contractAddress?: string;
  onStart?: () => void
  onDone?: () => void
}

interface BurnActionOptions extends ModalOptionsProps<{tokenID: TokenID}> {
  contractAddress?: string;
  onStart?: () => void
  onDone?: () => void
}

export default (params: Params) => {
  const [triggerTokenRemoval] = useNotifiyTokenRemovalMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal, updateProgress } = useModal();
  const { library, account, chainId } = useWeb3Application();
  const { throwError } = useErrorHandler();
  const { loadERC721Contract } = useScContracts();

  const handleAssetTransfer = useCallback(({
    onStart,
    onDone,
    contractAddress,
    ...options
  }: TransferActionOptions) => async () => {
    openModal({
      ...options,
      withProgress: true,
      title: 'Transfer',
      maxWidth: 'xs',
      formValidator: (values: TransferFormData) => {
        const errors: FormikErrors<TransferFormData> = {};

        if (!isAddress(values.address || '')) {
          errors.address = 'Please enter a valid address';
        }

        return Promise.resolve(errors);
      },
      onOk: async (values: TransferFormData) => {
        if (onStart) {
          onStart();
        }

        try {
          const NftFactory = new TransferableNftFactory();
          const NftItem = NftFactory.create(
            {
              type: '721',
              nftAddress: contractAddress || params.contractAddress,
              account,
              chainId: chainId?.toString(),
            },
            {
              library,
              loadContract: loadERC721Contract,
            }
          );

          NftItem.on(events.CONFIRMING_TX, () => updateProgress('Waiting for TX to validate...', 10));
          NftItem.on(events.NFT_TRANSFER_TX_CONFIRMED, (tx: ContractReceipt) => updateProgress(`Tx confirmed (${tx})`, 80));
          NftItem.on(events.NFT_TRANSFER_DONE, () => {
            enqueueSnackbar(`Asset successfully transfered to ${values.address}`, {
              variant: 'success',
              autoHideDuration: 5000,
            });
            updateProgress('Tx done!', 100);
            triggerTokenRemoval({ ...values.tokenID.toJSON(), contractAddress: contractAddress || params.contractAddress });
            if (onDone) {
              onDone();
            }
          });

          // @todo: handle erc1155, for now only 721 is supported
          await NftItem.transfer(values.address, {
            tokenID: values.tokenID,
          });
        } catch (e) {
          throwError(e);
        }
      },
    });
  }, [library, account, chainId]);

  const handleAssetBurn = useCallback(({ onStart, onDone, contractAddress, ...options }: BurnActionOptions) => async () => {
    openModal({
      ...options,
      withProgress: true,
      centerActions: true,
      title: 'Remove Asset',
      maxWidth: 'xs',
      onOk: async (values: {tokenID: TokenID}) => {
        if (onStart) {
          onStart();
        }

        try {
          const NftFactory = new BurnableNftFactory();
          const NftItem = NftFactory.create(
            {
              type: '721',
              nftAddress: contractAddress || params.contractAddress,
              account,
              chainId: chainId?.toString(),
            },
            {
              library,
              loadContract: loadERC721Contract,
            }
          );

          NftItem.on(events.CONFIRMING_TX, () => updateProgress('Waiting for TX to validate...', 10));
          NftItem.on(events.NFT_BURN_TX_CONFIRMED, (tx: ContractReceipt) => updateProgress(`Tx confirmed (${tx})`, 80));
          NftItem.on(events.NFT_BURN_DONE, () => {
            updateProgress('Tx done!', 100);
            triggerTokenRemoval({ tokenID: values.tokenID, contractAddress: contractAddress || params.contractAddress });
            if (onDone) {
              onDone();
            }
          });

          await NftItem.burn({
            tokenID: values.tokenID,
          });
        } catch (e) {
          throwError(e);
        }
      },
    });
  }, [library, account, chainId]);

  return { handleAssetTransfer, handleAssetBurn };
};
