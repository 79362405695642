import * as React from 'react';
import {
  styled, alpha, Theme,
} from '@mui/material/styles';
import {
  Menu as MuiMenu, MenuItem, SxProps, useMediaQuery,
} from '@mui/material';
import { MenuProps } from '@mui/material/Menu';

export const Menu = styled(({ triggerOnHover, sx, ...props }: MenuProps & { triggerOnHover?: boolean }) => (
  <MuiMenu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    sx={{
      ...(sx || {}),
      ...(triggerOnHover && {
        pointerEvents: 'none',
        '& .MuiPopover-paper': {
          pointerEvents: 'auto',
        },
      }),
    }}
    {...props}
  />
))<MenuProps & { triggerOnHover?: boolean }>(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 12,
    marginTop: 3,
    minWidth: 180,

    border: '1px solid rgba(176, 151, 151, 0.3)',
    ...(theme.palette.mode === 'light'
      ? {
        ...theme.glassy(theme.palette.background.paper, 0.8, 3),
        border: '1px solid rgba(176, 151, 151, 0.3)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        color: 'rgb(55, 65, 81)',
      }
      : {
        ...theme.glassy(theme.palette.background.paper, 0.8, 3),
        border: '1px solid rgba(176, 151, 151, 0.1)',
        boxShadow: '0 2px 20px rgba(150, 150, 150, 0.1)',
        color: theme.palette.grey[300],
      }),

    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1.25, 2),
      fontWeight: 500,
      fontSize: '0.92rem',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

export { MenuItem };

interface DropdownContextValue {
  handleClose: () => void;
  wrapClose?: (handler: (e?: React.MouseEvent<HTMLElement>) => void) => (e?: React.MouseEvent<HTMLElement>) => void;
}

export const DropdownContext = React.createContext<DropdownContextValue>({
  handleClose: () => {},
});

interface MouseTargetElement {
  onMouseEnter?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLElement>) => void;
  open?: boolean;
}

interface AnchorProps extends MouseTargetElement {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const EmptyWrapper = ({ children, ...props }: React.PropsWithChildren<MouseTargetElement>) => <>{children}</>;
const HoverableWrapper = ({ children, open, ...props }: React.PropsWithChildren<MouseTargetElement>) => (
  <div aria-owns="mouse-over-popover" aria-haspopup="true" {...props}>
    {children}
  </div>
);

interface MenuDropdownProps<T extends AnchorProps> {
  id: string;
  Anchor: React.ComponentType<T>;
  hover?: boolean;
  sx?: SxProps;
}

const MenuDropdown = <T extends AnchorProps>({
  Anchor,
  children,
  sx,
  hover,
  id,
}: React.PropsWithChildren<MenuDropdownProps<T>>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const wrapClose = (fn: (e: React.MouseEvent<HTMLElement>) => void) => (e: React.MouseEvent<HTMLElement>) => {
    handleClose();
    fn(e);
  };

  const isSmall = useMediaQuery((t: Theme) => t.breakpoints.down('sm'));
  const triggerWithHover = React.useMemo(() => hover && !isSmall, [hover, isSmall]);

  const Wrapper: React.ComponentType<MouseTargetElement> = React.useMemo(() => {
    if (triggerWithHover) {
      return HoverableWrapper;
    }

    return EmptyWrapper;
  }, [triggerWithHover]);

  return (
    <DropdownContext.Provider
      value={{
        handleClose,
        wrapClose,
      }}
    >
      <Wrapper
        open={Boolean(anchorEl)}
        {...{
          ...(triggerWithHover && {
            onMouseEnter: handleOpen,
            onMouseLeave: handleClose,
          }),
        }}
      >
        {/* @ts-ignore */}
        <Anchor
          open={Boolean(anchorEl)}
          {...{
            ...(!triggerWithHover && {
              onClick: handleOpen,
            }),
            ...(!triggerWithHover && {
              onMouseEnter: handleOpen,
            }),
          }}
        />
        <Menu
          id={id}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={sx}
          triggerOnHover={triggerWithHover}
          PaperProps={{
            ...(triggerWithHover && {
              onMouseLeave: handleClose,
            }),
          }}
        >
          {children}
        </Menu>
      </Wrapper>
    </DropdownContext.Provider>
  );
};

export default MenuDropdown;
