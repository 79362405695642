import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useProfile from 'src/hooks/useProfile';
import usePrediction from 'src/hooks/usePrediction';
import { useLazyFetchPredictionQuery } from 'src/state/api';
import reducer, { SourceType, AIResultState } from './reducer';
import useHandlers from './handler';
import { AIResultHandlers } from './types';

interface AIContextType extends AIResultState {
  images: string[];
  setSource: (source: SourceType) => void;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
}

const AIContext = React.createContext<AIContextType & AIResultHandlers>({
  images: [],
  currentIndex: 0,
  setSource: () => {},
  inputRef: null,
});

interface AIProviderProps {}

export const AIProvider = ({ children }: React.PropsWithChildren<AIProviderProps>) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const params = useParams<{ predictionId?: string; index?: string }>();
  const location = useLocation();

  const { hiveLoaded, loadHiveFiles, removeHiveFile, hiveFiles, isLoadingHive, select, prediction, setPrediction } =
    usePrediction();
  const { profile } = useProfile();

  const [fetchPrediction] = useLazyFetchPredictionQuery();

  const [{ source, ...state }, dispatch] = React.useReducer(reducer, {
    isLoading: true,
    error: null,
    source: null,
    currentIndex: 0,
    currentView: null,

    downloading: false,
    upscaling: false,
    removing: false,
  });

  const images = React.useMemo(() => {
    switch (source) {
      case 'hive':
        return (hiveFiles || []).map((h) => h.url);
      case 'prediction':
        return prediction?.output || [];
      default:
        return [];
    }
  }, [source, hiveFiles, prediction?.output]);

  const handlers = useHandlers({
    source,
    images,
    currentView: state.currentView,
    currentIndex: state.currentIndex || 0,
    hiveFiles,
    removeHiveFile: (filepath: string) => removeHiveFile(profile?.did.did, filepath),
    wrapProcess: async (key: keyof AIResultState, fn: () => Promise<void>) => {
      dispatch({ type: 'SET', payload: { [key]: true } });
      await fn();
      dispatch({ type: 'SET', payload: { [key]: false } });
    },
  });

  const handlePrev = React.useCallback(() => {
    const prevIndex = state.currentIndex > 0 ? state.currentIndex - 1 : images.length - 1;
    dispatch({
      type: 'SET',
      payload: {
        currentIndex: prevIndex,
        currentView: images[prevIndex],
      },
    });
    select(images[prevIndex]);
  }, [images, state.currentIndex]);

  const handleNext = React.useCallback(() => {
    const nextIndex = state.currentIndex === images.length - 1 ? 0 : state.currentIndex + 1;
    dispatch({
      type: 'SET',
      payload: {
        currentIndex: nextIndex,
        currentView: images[nextIndex],
      },
    });
    select(images[nextIndex]);
  }, [images, state.currentIndex]);

  const bindArrowKeyboards = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        handlePrev();
      } else if (e.key === 'ArrowRight') {
        handleNext();
      }
    },
    [images, state.currentIndex]
  );

  React.useEffect(() => {
    if (source) {
      switch (source) {
        case 'hive':
          if (hiveLoaded) {
            const index = hiveFiles.findIndex((h) => h.path === location.hash?.substring(1));
            if (index >= 0) {
              select(hiveFiles[index].url);
              dispatch({
                type: 'SET',
                payload: {
                  currentIndex: index,
                  currentView: hiveFiles[index].url,
                  isLoading: false,
                  error: null,
                },
              });
            }
          } else if (!isLoadingHive && profile?.did) {
            loadHiveFiles(profile?.did?.did);
          }
          break;
        case 'prediction':
          if (prediction?.id !== params?.predictionId) {
            // should load prediction in prior
            if (profile && params?.predictionId) {
              fetchPrediction(params?.predictionId)
                .unwrap()
                .then(({ status, data }) => {
                  // @ts-ignore
                  if (status !== 'success') {
                    dispatch({
                      type: 'ERROR',
                      error: new Error(`failed to fetch prediction "${params?.predictionId}"`),
                    });
                  } else {
                    setPrediction(data);
                  }
                })
                .catch(({ status, data }) => {
                  dispatch({
                    type: 'ERROR',
                    error: new Error(`${status}: ${data?.reason || JSON.stringify(data)}`),
                  });
                });
            }
          } else if ((prediction?.output || []).length > 0) {
            const index = parseInt(params?.index, 10);
            select(prediction?.output[index]);
            dispatch({
              type: 'SET',
              payload: {
                currentIndex: index,
                currentView: prediction?.output[index],
                isLoading: false,
                error: null,
              },
            });
          }
          break;
        default:
          dispatch({ type: 'ERROR', error: new Error('source type not supported') });
          break;
      }
    }
  }, [profile?.did, source, location, params, hiveFiles, isLoadingHive, prediction?.output]);

  return (
    <AIContext.Provider
      value={{
        ...state,
        source,
        images,
        setSource: (s: SourceType) => dispatch({ type: 'SET', payload: { source: s } }),
        ...handlers,
        handlePrev,
        handleNext,
        bindArrowKeyboards,
        inputRef,
      }}
    >
      {children}
    </AIContext.Provider>
  );
};

export default AIContext;

export const useAIContext = () => {
  const ctx = React.useContext(AIContext);

  if (!ctx) {
    throw new Error('`useAIContext` can only be used within AIContext.Provider');
  }

  return ctx;
};
