/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TokenID,
  convertIfHiveURL, ipfsLink, ipfsLinkFor,
} from '@elacity-js/lib';
import { VerifiableCredential } from '@elastosfoundation/did-js-sdk';
import {
  ApiResponse, IProfile, IEvent, IAccountOffer, ApiResponseWithTotal, GraphqQLResponse, Account,
} from 'src/types';
import { IEvent as Event } from 'src/types/event';
import {
  baseURL, ifThumbnail, thumbnail,
} from 'src/utils';
import { DIdentity } from 'src/lib/did/types';
import { Prediction } from 'src/types/prediction';
import { VerifiableSocialCredential } from 'src/lib/did/verification';
import { api, eventFragment } from './query.base';
import { privateQuery } from './privateBaseQuery';
import { AccountRequest, FindProfileQuery } from './types';
import { transformActivties } from './transform';

enum Tags {
  Profile = 'Profile',
  Account = 'Account', // eslint-disable-line
  Offer = 'Offer',
  PT = 'ProcessingTime',
}

const calculateTrustLevel = (didData: IProfile['did']) => {
  const { socials, kycOk } = didData || {};
  if (kycOk) {
    return 3;
  }

  return Object.keys(socials || {}).filter((key: VerifiableSocialCredential) => [
    VerifiableSocialCredential.TWITTER,
    VerifiableSocialCredential.DISCORD,
    VerifiableSocialCredential.INSTAGRAM,
    VerifiableSocialCredential.EMAIL,
  ].includes(key)).length >= 1
    ? 2
    : 1;
};

const accountApi = api
  .enhanceEndpoints({
    addTagTypes: [Tags.Account, Tags.Profile, Tags.Offer, Tags.PT],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getProfile: builder.query<IProfile, AccountRequest>({
        query: (body) => ({
          url: '/account/getuseraccountinfo',
          method: 'POST',
          body,
        }),
        transformResponse: async (result: ApiResponse<IProfile & { imageHash?: string; bannerHash?: string }>) => {
          const { imageHash: _imageHash, bannerHash: _bannerHash, did, ...profile } = result.data;

          const imageHash = ifThumbnail(_imageHash, 320);
          const bannerHash = ifThumbnail(_bannerHash, 1920);

          return {
            image: baseURL('/static/elacity/elanaut-icon.png'),
            alias: profile.address?.substr(0, 8) || 'Unnamed',
            twitter: profile.twitter,
            ...profile,
            ...(imageHash &&
              imageHash.match(/^https?/) && {
              image: imageHash,
            }),
            ...(imageHash &&
              !imageHash.match(/^https?/) && {
              image: ipfsLink(`/ipfs/${imageHash}`),
            }),
            ...(bannerHash &&
              bannerHash.match(/^https?/) && {
              banner: bannerHash,
            }),
            ...(bannerHash &&
              !bannerHash.match(/^https?/) && {
              banner: ipfsLink(`/ipfs/${bannerHash}`),
            }),
            ...(did && {
              alias: did.credentials.name,
              did: {
                ...did,
                level: calculateTrustLevel(did),
              },
              ...(did?.credentials.avatar?.base64 && {
                image: ifThumbnail(did?.credentials.avatar?.base64),
              }),
              ...(did?.credentials.description && {
                bio: did?.credentials.description,
              }),
            }),
            settings: {
              ...profile?.settings,
              // set default settings values
              ...(typeof profile?.settings?.autoSaveAI === 'undefined' && {
                autoSaveAI: true,
              }),
            },
          };
        },
        providesTags: (result, error, arg) => [{ type: Tags.Profile, id: arg.address }],
      }),

      findAccounts: builder.query<ApiResponseWithTotal<IProfile[]>, FindProfileQuery>({
        query: (body) => ({
          url: '/account/find',
          method: 'POST',
          body,
        }),
        // @ts-ignore
        providesTags: (result: ApiResponseWithTotal<IProfile[]>, error?: Error | null) => (result && !error
          ? [...result.data.map(({ address }) => ({ type: Tags.Account as const, id: address })), Tags.Account]
          : [{ type: Tags.Account, id: 'LIST' }]),
        transformResponse: (result: ApiResponseWithTotal<IProfile[]>, meta) => {
          if (!meta) {
            return {
              data: [],
              status: 'failed',
            };
          }

          return {
            ...result,
            // add default data for empty ones
            data: (result?.data || [])
              .map(({ imageHash, bannerHash, ...profile }: IProfile & { imageHash?: string; bannerHash?: string }) => ({
                ...profile,
                ...(imageHash && {
                  imageHash: ifThumbnail(imageHash, 320),
                }),
                ...(bannerHash && {
                  bannerHash: ifThumbnail(bannerHash, 1920),
                }),
              }))
              .map(({ imageHash, bannerHash, did, ...profile }: IProfile & { imageHash?: string; bannerHash?: string }) => ({
                ...profile,
                ...(!profile.alias && {
                  alias: profile.address?.substr(0, 8) || 'Unnamed',
                }),
                ...(!profile.image && {
                  image: baseURL('/static/elacity/elanaut-icon.png'),
                }),
                ...(imageHash &&
                  imageHash.match(/^https?/) && {
                  image: imageHash,
                }),
                ...(imageHash &&
                  !imageHash.match(/^https?/) && {
                  image: ipfsLink(`/ipfs/${imageHash}`),
                }),
                ...(bannerHash &&
                  bannerHash.match(/^https?/) && {
                  banner: bannerHash,
                }),
                ...(bannerHash &&
                  !bannerHash.match(/^https?/) && {
                  banner: ipfsLink(`/ipfs/${bannerHash}`),
                }),
                ...(did && {
                  alias: did.credentials.name,
                  did: {
                    ...did,
                    level: calculateTrustLevel(did),
                  },
                  ...(did?.credentials.avatar?.base64 && {
                    image: ifThumbnail(did?.credentials.avatar?.base64, 320),
                  }),
                  ...(did?.credentials.description && {
                    bio: did?.credentials.description,
                  }),
                }),
              })),
          };
        },
      }),

      getFollowings: builder.query<ApiResponse<{ address: string; followers: number; alias?: string }[]>, string>({
        query: (address) => `/follow/getFollowings/${address}`,
      }),

      getFollowers: builder.query<ApiResponse<{ address: string; followers: number; alias?: string }[]>, string>({
        query: (address) => `/follow/getFollowers/${address}`,
      }),

      getActivities: builder.query<IEvent<'Bid' | 'Sold' | 'Offer' | 'Listing' | 'Transfer'>[], string>({
        query: (address) => `/info/getAccountActivity/${address}`,
        transformResponse: transformActivties,
      }),

      // outgoing offers
      getOffersFrom: builder.query<ApiResponse<Omit<IAccountOffer, 'creator'>[]>, string>({
        query: (address) => `/info/getOffersFromAccount/${address}?onlyactive=1`,
        transformResponse: (result: ApiResponse<(Omit<IAccountOffer, 'creator'> & { paymentToken?: string })[]>, meta) => {
          if (!meta) {
            return {
              data: [],
              status: 'failed',
            };
          }

          return {
            ...result,
            data: result?.data.map(({ paymentToken, ...d }) => ({
              ...d,
              ...(paymentToken && {
                payToken: paymentToken,
              }),
              image:
                !d.thumbnailPath || ['-', 'embed', 'no-image', '.'].includes(d.thumbnailPath)
                  ? d.imageURL
                  : `${process.env.REACT_APP_BACKEND_URL}/thumbnails/${d.thumbnailPath}`,
            })),
          };
        },
        // @ts-ignore
        providesTags: (result: ApiResponse<Omit<IAccountOffer, 'creator'>[]>, error?: Error | null) => [
          ...(result?.data || []).map(({ contractAddress, tokenID }) => ({
            type: Tags.Offer as const,
            id: `${contractAddress}-${tokenID}`,
          })),
          { type: Tags.Offer, id: 'OUT_LIST' },
        ],
      }),

      // incomming offers
      getOffersTo: builder.query<ApiResponse<IAccountOffer[]>, string>({
        query: (address) => `/info/getActivityFromOthers/${address}?onlyactive=1`,
        transformResponse: (result: ApiResponse<(IAccountOffer & { paymentToken?: string })[]>, meta) => {
          if (!meta) {
            return {
              data: [],
              status: 'failed',
            };
          }

          return {
            ...result,
            data: result?.data.map(({ paymentToken, ...d }) => ({
              ...d,
              ...(paymentToken && {
                payToken: paymentToken,
              }),
              image:
                !d.thumbnailPath || ['-', 'embed', 'no-image', '.'].includes(d.thumbnailPath)
                  ? d.imageURL
                  : `${process.env.REACT_APP_BACKEND_URL}/thumbnails/${d.thumbnailPath}`,
            })),
          };
        },
        // @ts-ignore
        providesTags: (result: ApiResponse<IAccountOffer[]>, error?: Error | null) => [
          ...(result?.data || []).map(({ contractAddress, tokenID }) => ({
            type: Tags.Offer as const,
            id: `${contractAddress}-${tokenID}`,
          })),
          { type: Tags.Offer, id: 'IN_LIST' },
        ],
      }),

      notifiyOffersListChange: builder.mutation({
        queryFn: async (target: 'IN_LIST' | 'OUT_LIST') => Promise.resolve({ data: { status: 'OK', target } }),
        invalidatesTags: (r, error, id) => [{ type: Tags.Offer, id }],
      }),

      // eslint-disable-next-line max-len, @typescript-eslint/no-explicit-any
      forwardOAuth: builder.query<ApiResponse<{ verifiableCredential: VerifiableCredential }>, any>({
        query: ({ provider, ...body }) => ({
          url: `/oauth/callback/${provider}`,
          method: 'POST',
          body,
        }),
      }),

      fetchProcessingTime: builder.query<ApiResponse<{ processingTime: number }>, string>({
        query: (address) => `/ai/${address}/processingtime`,
        providesTags: (result, error, address) => [{ type: Tags.PT, id: address }],
      }),

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      notifyProcessingTimeChange: builder.mutation<any, string>({
        queryFn: async (address) => Promise.resolve({ data: { status: 'success', data: 'OK' } }),
        invalidatesTags: (result, error, address) => [{ type: Tags.PT, id: address }],
      }),

      fetchAccount: builder.query<Account, string>({
        query: (address: string) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
              query GetAccount($address: String!) {
                account(address: $address) {
                  address
                  email
                  alias
                  avatar
                  did {
                    did
                    trustLevel
                    credentials {
                      name
                      avatar {
                        thumbnail
                      }
                    }
                  }
                }
              }
            `,
            variables: {
              address,
            },
          },
        }),
        transformResponse: async (r: GraphqQLResponse<Account>): Promise<Account> => {
          if (r.data?.account) {
            return {
              ...r.data?.account,
            };
          }

          return Promise.reject(r.errors[0]);
        },
      }),

      fetchAccountActivities: builder.query<Event[], string>({
        query: (address: string) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
              ${eventFragment}
              query FetchActivities($query: ActivityQueryInput) {
                sold: searchTradeEvents(query: $query) {
                  ...eventFields
                  to {
                    ...profileFields
                  }
                }
                bids: searchBidEvents(query: $query) {
                  ...eventFields
                }
                listings: searchListingEvents(query: $query) {
                  ...eventFields
                }
                offers: searchOfferEvents(query: $query) {
                  ...eventFields
                }
                transfers: fetchTransferHistory(query: $query) {
                  _id
                  event
                  token {
                    ...tokenFields
                  }
                  from {
                    ...profileFields
                  }
                  to {
                    ...profileFields
                  }
                  quantity
                  createdAt
                  blockNumber
                }
              }`,
            variables: {
              query: {
                from: address,
              },
            },
          },
        }),
        transformResponse: async (r: GraphqQLResponse<Event[]>): Promise<Event[]> => {
          if (!r.data) {
            return Promise.reject(r.errors[0]);
          }

          return Object.entries(r.data)
            .reduce((result, [_, values]) => [...result, ...values], [])
            .sort(
              (a, b) => (b.createdAt - a.createdAt > 0 ? 1 : -1)
            ).filter(
            // don't return ones that have no tokne field
            // they are probably ones that are not involved in elacity contracts
              (e) => !!e.token
            )
            .map(
              ({ token, createdAt, ...e }) => ({
                ...e,
                token: {
                  ...(token && {
                    ...token,
                    image: token.thumbnailPath
                      ? thumbnail(token.thumbnailPath)
                      : convertIfHiveURL(ipfsLinkFor(token.imageURL)),
                    variant: token.variant || 'standard',
                  }),
                },
                ...(token && {
                  link: `/marketplace/${token.contractAddress}/${TokenID.from(token.hexTokenID).toString()}`,
                  ...(token?.variant === 'drm' && {
                    link: `/cinema/view/${token.contractAddress}/${TokenID.from(token.hexTokenID).toString()}`,
                  }),
                }),
                ...(token && e.event === 'Listing' && {
                // eslint-disable-next-line max-len
                  title: `${e.quantity} unit(s) | ${token.name} | Listed for`,
                }),
                ...(token && e.event === 'Sold' && {
                // eslint-disable-next-line max-len
                  title: `${e.quantity} unit(s) | ${token.name} | Sold at`,
                }),
                ...(token && e.event === 'Offer' && {
                // eslint-disable-next-line max-len
                  title: `${e.quantity} unit(s) | ${token.name} | Offer created at`,
                }),
                ...(token && e.event === 'Bid' && {
                // eslint-disable-next-line max-len
                  title: `1 unit | ${token.name} | Bid placed for`,
                }),
                ...(token && e.event === 'Transfer' && {
                // eslint-disable-next-line max-len
                  title: `${e.quantity} unit(s) | ${token.name} | Transferred`,
                }),
                ...(token && e.event === 'Mint' && {
                // eslint-disable-next-line max-len
                  title: `${e.quantity} unit(s) | ${token.name} | Minted`,
                }),
                ...(token && e.event === 'Burn' && {
                // eslint-disable-next-line max-len
                  title: `${e.quantity} unit(s) | ${token.name} | Burnt`,
                }),
                createdAt: Number(createdAt),
              })
            );
        },
      }),
    }),
  });

const accountPrivateApi = api
  .enhanceEndpoints({
    addTagTypes: [Tags.Account, Tags.Profile, Tags.Offer, Tags.PT],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      updateAccountDetails: builder.mutation({
        queryFn: async ({ ...payload }, bqApi, extraOptions) => {
          const body = new FormData();
          Object.entries(payload).forEach(([key, value]) => {
            if (
              typeof value !== 'undefined' &&
              [
                'alias',
                'email',
                'twitter',
                'telegram',
                'instagram',
                'discord',
                'bio',
                'signature',
                'signatureAddress',
                'imgData',
              ].includes(key)
            ) {
              body.append(key, value as string);
            }
          });

          const { error, data } = await privateQuery(
            {
              url: '/account/accountdetails',
              method: 'POST',
              body,
            },
            bqApi,
            extraOptions
          );

          if (error) {
            return Promise.reject(error);
          }

          return {
            data,
          };
        },
        // @ts-ignore
        invalidatesTags: (result, error, arg) => [{ type: Tags.Profile, id: arg.address }],
      }),

      updateBanner: builder.mutation({
        queryFn: async (imageData, bqApi, extraOptions) => {
          const body = new FormData();
          // @ts-ignore
          body.append('imgData', imageData);

          const { error, data } = await privateQuery(
            {
              url: '/ipfs/uploadBannerImage2Server',
              method: 'POST',
              body,
            },
            bqApi,
            extraOptions
          );

          if (error) {
            return Promise.reject(error);
          }

          return {
            data,
          };
        },
      }),

      followUser: builder.mutation<ApiResponse<string>, { follower: string; status: number }>({
        queryFn: (body, bqApi, extraOptions) => privateQuery(
          {
            url: '/follow/update',
            method: 'POST',
            body,
          },
          bqApi,
          extraOptions
        ) as { data: ApiResponse<string> },
        // @ts-ignore
        invalidatesTags: (result: ApiResponse<string>, error: Error | null, arg) => [
          ...(result && result.status === 'success' && !error ? [{ type: Tags.Account, id: arg.follower }] : []),
        ],
      }),

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateAccount: builder.mutation<ApiResponse<Partial<IProfile> & { address: string }>, any>({
        queryFn: (body, bqApi, extraOptions) => privateQuery(
          {
            method: 'POST',
            url: '/account/update',
            body,
          },
          bqApi,
          extraOptions
        ) as { data: ApiResponse<Partial<IProfile> & { address: string }> },
        invalidatesTags: (result) => [{ type: Tags.Profile, id: result?.data?.address }],
      }),

      linkDID: builder.mutation<ApiResponse<DIdentity>, DIdentity>({
        queryFn: (body, bqApi, extraOptions) => privateQuery(
          {
            method: 'POST',
            url: '/account/did/link',
            body,
          },
          bqApi,
          extraOptions
        ) as { data: ApiResponse<DIdentity> },
        invalidatesTags: (result) => [{ type: Tags.Profile, id: result?.data?.address }],
      }),

      // eslint-disable-next-line max-len, @typescript-eslint/no-explicit-any
      approveSocialCredential: builder.mutation<ApiResponse<{ address: string }>, { userDid: string; credentialName: string }>({
        queryFn: async ({ ...body }, bqApi, extraOptions) => privateQuery(
          {
            url: '/account/did/approve-credential',
            method: 'POST',
            body,
          },
          bqApi,
          extraOptions
        ) as { data: ApiResponse<{ address: string }> },
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        invalidatesTags: (result: ApiResponse<{ address: string }>, error: Error | null) => [
          ...(result && result.status === 'success' && !error ? [{ type: Tags.Account, id: result?.data?.address }] : []),
        ],
      }),

      // eslint-disable-next-line max-len, @typescript-eslint/no-explicit-any
      removeSocialCredential: builder.mutation<ApiResponse<{ address: string }>, { userDid: string; credentialName: string }>({
        queryFn: async ({ ...body }, bqApi, extraOptions) => privateQuery(
          {
            url: '/account/did/remove-credential',
            method: 'POST',
            body,
          },
          bqApi,
          extraOptions
        ) as { data: ApiResponse<{ address: string }> },

        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        invalidatesTags: (result: ApiResponse<{ address: string }>, error: Error | null) => [
          ...(result && result.status === 'success' && !error ? [{ type: Tags.Account, id: result?.data?.address }] : []),
        ],
      }),

      // eslint-disable-next-line max-len, @typescript-eslint/no-explicit-any
      setEmailVisibility: builder.mutation<ApiResponse<{ address: string; value: boolean }>, { userDid: string; value: boolean }>(
        {
          queryFn: async ({ ...body }, bqApi, extraOptions) => privateQuery(
            {
              url: '/account/did/set-email-visibility',
              method: 'POST',
              body,
            },
            bqApi,
            extraOptions
          ) as { data: ApiResponse<{ address: string; value: boolean }> },

          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          invalidatesTags: (result: ApiResponse<{ address: string }>, error: Error | null) => [
            ...(result && result.status === 'success' && !error ? [{ type: Tags.Account, id: result?.data?.address }] : []),
          ],
        }
      ),

      requestEmailVerification: builder.mutation<ApiResponse<boolean>, { userDid: string; email: string }>({
        queryFn: async ({ ...body }, bqApi, extraOptions) => privateQuery(
          {
            url: '/account/did/verify-email',
            method: 'POST',
            body,
          },
          bqApi,
          extraOptions
        ) as { data: ApiResponse<boolean> },
      }),

      updateKycStatus: builder.mutation<ApiResponse<{ kycOK: boolean; address: string }>, { userDid: string }>({
        queryFn: async ({ ...body }, bqApi, extraOptions) => privateQuery(
          {
            url: '/account/did/kyc-status',
            method: 'PUT',
            body,
          },
          bqApi,
          extraOptions
        ) as { data: ApiResponse<{ kycOK: boolean; address: string }> },
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        invalidatesTags: (result: ApiResponse<{ kycOK: boolean; address: string }>, error: Error | null) => [
          ...(result && result.status === 'success' && !error ? [{ type: Tags.Account, id: result?.data?.address }] : []),
        ],
      }),

      fetchPrediction: builder.query<ApiResponse<Prediction>, string>({
        queryFn: async (predictionId, bqApi, extraOptions) => privateQuery(
          {
            url: `/ai/prediction/${predictionId}`,
            method: 'GET',
          },
          bqApi,
          extraOptions
        ) as { data: ApiResponse<Prediction> },
      }),

      fillFreeProcessingTime: builder.mutation<ApiResponse<number>, { signature: string; signatureAddress: string }>({
        queryFn: async ({ ...body }, bqApi, extraOptions) => privateQuery(
          {
            url: '/ai/claimfree',
            method: 'POST',
            body,
          },
          bqApi,
          extraOptions
        ) as { data: ApiResponse<number> },
        invalidatesTags: (result, error) => [{ type: Tags.PT, id: 'LIST' }],
      }),
    }),
  });

export { accountApi, accountPrivateApi };

export const {
  useFindAccountsQuery,
  useGetFollowersQuery,
  useGetFollowingsQuery,
  useGetProfileQuery,
  useGetActivitiesQuery,
  useGetOffersFromQuery,
  useGetOffersToQuery,
  useNotifiyOffersListChangeMutation,
  useForwardOAuthQuery,
  useFetchProcessingTimeQuery,
  useNotifyProcessingTimeChangeMutation,

  // graphql
  useFetchAccountQuery,
  useFetchAccountActivitiesQuery,
} = accountApi;

export const {
  useLazyFetchPredictionQuery,
  useUpdateAccountDetailsMutation,
  useUpdateBannerMutation,
  useFollowUserMutation,
  useUpdateAccountMutation,
  useLinkDIDMutation,
  useApproveSocialCredentialMutation,
  useRemoveSocialCredentialMutation,
  useSetEmailVisibilityMutation,
  useRequestEmailVerificationMutation,
  useUpdateKycStatusMutation,
  useFillFreeProcessingTimeMutation,
} = accountPrivateApi;
