/* eslint-disable @typescript-eslint/no-explicit-any */
const generateClassicEvents = (domain: string, name: string) => ({
  [`${domain.toUpperCase()}_${name.toUpperCase()}_START`]: `${domain.toLowerCase()}_${name.toLowerCase()}_start`,
  [`${domain.toUpperCase()}_${name.toUpperCase()}_WAIT_TX`]: `${domain.toLowerCase()}_${name.toLowerCase()}_wait_tx`,
  [`${domain.toUpperCase()}_${name.toUpperCase()}_TX_CONFIRMED`]: `${domain.toLowerCase()}_${name.toLowerCase()}_tx_confirmed`,
  [`${domain.toUpperCase()}_${name.toUpperCase()}_DONE`]: `${domain.toLowerCase()}_${name.toLowerCase()}_done`,
});

export default {
  // Minting events
  LAST_MINTED_TX_ID: 'Last_minted_TX_id',
  IS_MINTING: 'is_minting',
  UPLOADING_TO_IPFS: 'is_uploading_to_ipfs',
  START_MINTING: 'is_starting_mint_process',
  CREATING_NFT: 'is_creating_nft',
  CONFIRMING_TX: 'is_confirmingTx',
  VALIDATE_FAILED: 'validate_failed',
  PROCESSING_ROYALTY: 'processing_royalty',
  NFT_MINT_FINISHED: 'nft_mint_finished',
  PROCESS_ERRORED: 'process_errored',

  // Sales events
  ...generateClassicEvents('sales', 'list_item'),

  // Cancel sales events
  ...generateClassicEvents('sales', 'cancel_listing'),

  // Updates events
  ...generateClassicEvents('sales', 'update_listing'),

  // buy events
  ...generateClassicEvents('sales', 'buy'),

  // create offer
  ...generateClassicEvents('sales', 'create_offer'),

  // cancel offer
  ...generateClassicEvents('sales', 'cancel_offer'),

  // accept offer
  ...generateClassicEvents('sales', 'accept_offer'),

  // Auction events
  ...generateClassicEvents('auction', 'create'),

  // Cancel auction events
  ...generateClassicEvents('auction', 'cancel'),

  // Update auction events
  ...generateClassicEvents('auction', 'update'),

  // Placing a bid
  ...generateClassicEvents('auction', 'place_bid'),

  // auction result
  ...generateClassicEvents('auction', 'result'),

  // withdraw bid
  ...generateClassicEvents('auction', 'withdraw_bid'),

  // generic
  ...generateClassicEvents('nft', 'transfer'),
  ...generateClassicEvents('nft', 'burn'),
  ...generateClassicEvents('approval', 'approve'),
} as any;
