/* eslint-disable max-len */
import { AssetItemInterface } from 'src/components/profile/Vault/AssetItem';
import { ipfsLinkFor } from '@elacity-js/lib';
import { api } from './query.base';
import { Account, GraphqQLResponse } from '../../types';

enum Tags {
  Statistic = 'Statistic',
}

const statisticApi = api
  .enhanceEndpoints({
    addTagTypes: [Tags.Statistic],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      fetchStatistic: builder.query<AssetItemInterface[], { address: string }>({
        query: ({ address }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            query FetchStatistic($address: String!) {
              fetchStatistic(address: $address) {
                items {
                  id
                  address
                  tokenId
                  name
                  thumbnail
                  views
                  sold
                  opType
                  cost
                  earnt
                  totalSupply
                  resell {
                    totalResell
                    totalVendors
                    totalRevenue
                    percentage
                  }
                  price {
                    amount
                    payToken
                  }
                  royaltyToken
                  share
                  distribution
                  labelType
                  royalties
                  unpublished
                  minted
                }
              }
            }`,
            variables: {
              address,
            },
          },
        }),
        transformResponse: async (r: GraphqQLResponse<{ items: AssetItemInterface[] }>): Promise<AssetItemInterface[]> => {
          if (!r.data?.fetchStatistic?.items) {
            return [];
          }
          return (r.data?.fetchStatistic?.items || []).map((stats: AssetItemInterface) => ({
            ...stats,
            thumbnail: ipfsLinkFor(stats.thumbnail),
          }));
        },
      }),
    }),
  });

export { statisticApi };

export const {
  useFetchStatisticQuery,
} = statisticApi;
