import clientHttp from './client';

export interface PredictionResponse<Output> {
  status?: 'failed' | 'succeeded';
  error?: string;
  output?: Output;
  id: string;
}

export const text2imagePredict = async (data: FormData, client: typeof clientHttp): Promise<PredictionResponse<string[]>> => {
  const response = await client.call<PredictionResponse<string[]>>({
    noRetry: true,
    method: 'post',
    url: '/ai/text2image/prediction',
    data,
  });

  // @ts-ignore
  if (response.error && response.error?.response.data) {
    // @ts-ignore
    return response.error.response.data;
  }

  return response.data;
};

export const upscalePredict = async (
  source: string,
  client: typeof clientHttp
): Promise<PredictionResponse<string>> => {
  const response = await client.call<PredictionResponse<string>>({
    noRetry: true,
    method: 'post',
    url: '/ai/upscale',
    data: {
      image: source,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // @ts-ignore
  if (response.error && response.error?.response.data) {
    // @ts-ignore
    return response.error.response.data;
  }

  return response.data;
};

export const promptParrot = async (
  prompt: string,
  client: typeof clientHttp
): Promise<PredictionResponse<string[]>> => {
  const response = await client.call<PredictionResponse<string[]>>({
    noRetry: true,
    method: 'post',
    url: '/ai/prompt-parrot',
    data: {
      prompt,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // @ts-ignore
  if (response.error && response.error?.response.data) {
    // @ts-ignore
    return response.error.response.data;
  }

  return response.data;
};

export const randomPrompt = async (
  client: typeof clientHttp
): Promise<PredictionResponse<string>> => {
  const response = await client.call<PredictionResponse<string>>({
    noRetry: true,
    method: 'post',
    url: '/ai/prompt-rand',
  });

  // @ts-ignore
  if (response.error && response.error?.response.data) {
    // @ts-ignore
    return response.error.response.data;
  }

  return response.data;
};
