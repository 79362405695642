/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-classes-per-file */
import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers';
import NftItem from '.';
import type {
  IMintableNftCreateParams, IMuseumNftCreateParams,
  IAuctionNftCreateParams, ITransferableNftCreateParams,
  IBurnableNftCreateParams, IMintableFactoryHelpersParams,
  IListableFactoryHelpersParams, IAuctionFactoryHelpersParams,
  ITransferableFactoryHelpersParams,
} from './types';
import SalesMixin from './mixins/salesMixin';
import AuctionMixin from './mixins/auctionMixin';
import TransferableMixin from './mixins/transferableMixin';
import BurnableMixin from './mixins/burnableMixin';
import ApprovableMixin from './mixins/approvableMixin';

type createParams = Record<string, any>;
type helpersParams = Record<string, any>;

class ExecutableContract<P extends unknown> {
  protected provider: P;

  protected address: string;

  protected account: string;

  constructor(params: Record<string, any>) {
    this.address = params.address || params.contractAddress;
  }

  public setProvider(p: P) {
    this.provider = p;
  }

  public setAccount(account: string) {
    this.account = account;
  }
}

abstract class GenericFacory<T extends unknown> {
  abstract create(params: createParams, helpers?: helpersParams): T;
}

abstract class NftFactory extends GenericFacory<NftItem> {}
abstract class ContractProxied<P extends unknown> extends GenericFacory<ExecutableContract<P>> {}

// All resulted classes
export class MintableNft extends NftItem {}
export class MuseumNft extends SalesMixin<typeof NftItem>(NftItem) {}
export class AuctionNft extends AuctionMixin<typeof NftItem>(NftItem) {}
export class TraneferableNft extends TransferableMixin<typeof NftItem>(NftItem) {}
export class BurnableNft extends BurnableMixin<typeof NftItem>(NftItem) {}
export class ApprovableNft extends ApprovableMixin<typeof ExecutableContract<JsonRpcSigner>>(ExecutableContract<JsonRpcSigner>) {}

// All classes factory
export class MintableNftFactory implements NftFactory {
  public create(params: IMintableNftCreateParams, helpers: IMintableFactoryHelpersParams): MintableNft {
    const finalNftItem = new MintableNft({
      ...params,
      ...(helpers.salesContract && { salesContract: helpers.salesContract }),
      ...(helpers.auctionContract && { auctionContract: helpers.auctionContract }),
      ...(helpers.private721Factory && { private721Factory: helpers.private721Factory }),
    });

    finalNftItem.setContractLoader(helpers.loadContract);
    if (helpers.clientHttp) {
      finalNftItem.setClientHttp(helpers.clientHttp);
    }
    if (helpers.WalletUtils) {
      finalNftItem.setWalletUtils(helpers.WalletUtils);
    }
    finalNftItem.setProvider(helpers.library);
    if (helpers.registerRoyalty) {
      finalNftItem.setRoyaltyRegisterer(helpers.registerRoyalty);
    }

    return finalNftItem;
  }
}

export class MuseumNftFactory implements NftFactory {
  public create(params: IMuseumNftCreateParams, helpers: IListableFactoryHelpersParams): MuseumNft {
    const finalNftItem = new MuseumNft(params as any);
    finalNftItem.setContractLoader(helpers.loadContract);
    finalNftItem.setProvider(helpers.library);

    return finalNftItem;
  }
}

export class AuctionNftFactory implements NftFactory {
  public create(params: IAuctionNftCreateParams, helpers: IAuctionFactoryHelpersParams): AuctionNft {
    const finalNftItem = new AuctionNft({
      ...params,
    } as any);
    finalNftItem.setContractLoader(helpers.loadContract);
    finalNftItem.setProvider(helpers.library);

    return finalNftItem;
  }
}

export class TransferableNftFactory implements NftFactory {
  public create(params: ITransferableNftCreateParams, helpers: ITransferableFactoryHelpersParams): TraneferableNft {
    const finalNftItem = new TraneferableNft({
      ...params,
    } as any);
    finalNftItem.setContractLoader(helpers.loadContract);
    finalNftItem.setProvider(helpers.library);

    return finalNftItem;
  }
}

export class BurnableNftFactory implements NftFactory {
  public create(params: IBurnableNftCreateParams, helpers: ITransferableFactoryHelpersParams): BurnableNft {
    const finalNftItem = new BurnableNft({
      ...params,
    } as any);
    finalNftItem.setContractLoader(helpers.loadContract);
    finalNftItem.setProvider(helpers.library);

    return finalNftItem;
  }
}

export class ApprovableFactory implements ContractProxied<JsonRpcSigner> {
  public create(params: createParams, helpers?: helpersParams): ApprovableNft {
    const proxy = new ApprovableNft({ ...params });

    if (helpers.provider) {
      proxy.setProvider(helpers.provider);
    }

    if (helpers.account) {
      proxy.setAccount(helpers.account);
    }

    return proxy;
  }
}
