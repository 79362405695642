import React from 'react';
import MuiLinkBase, { LinkProps } from '@mui/material/Link';
import {
  Link as RouterLinkBase, NavLink as NavLinkBase, LinkProps as RouterLinkProps, NavLinkProps,
} from 'react-router-dom';
import { baseURL } from 'src/utils';

export const RouterLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(({ to, ...props }, ref) => (
  <RouterLinkBase ref={ref} {...props} to={baseURL(to as string)} />
));

export const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(({ to, ...props }, ref) => (
  <NavLinkBase ref={ref} {...props} to={(to as string)?.startsWith('http') ? to : baseURL(to as string)} />
));

export const Link = ({ href, ...props }: LinkProps) => (
  <MuiLinkBase {...props} href={href?.startsWith('http') ? href : baseURL(href)} />
);
