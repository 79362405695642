import { SliderProps } from '@mui/material/Slider';

/* eslint-disable camelcase */
export interface StableDiffusionModel {
  prompt: string;
  width: number;
  height: number;
  guidance_scale: number;
  num_outputs: number;
  num_inference_steps: number;
  seed?: string;
  init_image?: File;
  image?: File;

  submit?: null;
}

export interface Text2ImageField {
  type: 'text' | 'image' | 'slider' | 'number';
  order?: number;
  vaildFor: number[];
}

export interface SliderFieldInput extends Text2ImageField {
  key: string;
  label: string;
  description: string;
  defaultValue: number;
  step?: number;
  min: number;
  max: number;
  marks?: SliderProps['marks'];
}

export interface TextFieldInput extends Text2ImageField {
  key: string;
  label: string;
  description: string;
  placeholder?: string;
  min?: number;
  max?: number;
}

export interface BuyInputs {
  processingTime: number;
  amount?: number;
  payToken?: string;
  discount?: number;
}

export enum Capabilities {
  HIVE_REMOVE = 'hive:remove'
}

export type ModelType = 'text2image' | 'unknown'

export interface ModelSelection {
  modelType: ModelType;
  modelName: string;
}
