/* eslint-disable react/destructuring-assignment */
import React, {
  useState, useEffect, useRef,
} from 'react';
import { Outlet } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import ScrollToTop from 'src/components/section/ScrollToTopButton';
import { MHidden } from '../components/@material-extend';
import DashboardNavbar from '../components/minimal/DashboardNavbar';
import DashboardSidebar from '../components/minimal/DashboardSidebar';
import BottomNavigation from '../components/minimal/BottomNavigation';
import Footer from '../components/minimal/Footer';
import useAppSettings from '../hooks/useAppSettings';
import sidebarConfig from '../components/minimal/SidebarConfig';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Background = styled('div')(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  zIndex: -2,
  opacity: theme.palette.mode === 'light' ? 0.75 : 0.8,
  background:
    theme.palette.mode === 'light'
      ? `radial-gradient(circle at right bottom, ${theme.palette.vivid.main} -360%, ${theme.palette.background.default} 40%)`
      : `radial-gradient(circle at right bottom, ${theme.palette.vivid.main} -460%, ${theme.palette.background.default} 40%)`,
}));

interface LayoutProps {
  noPadding?: boolean;
  withBottomNav?: boolean;
}

const MainLayout = styled('div', {
  name: 'MainLayout',
  slot: 'Root',
  shouldForwardProp: (prop: string) => !['noPadding', 'withBottomNav', 'sx'].includes(prop),
  overridesResolver: (props, styles) => [
    styles.root,
    props.noPadding && styles.noPadding,
    props.withBottomNav && styles.withBottomNav,
  ],
})<LayoutProps>(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  '& > *:first-of-type': {
    // ensure to fill all the available height, minus the header
    minHeight: 'calc(100vh - 160px)',
    [theme.breakpoints.between('lg', 'xl')]: {
      minHeight: 'calc(100vh - 160px)',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      minHeight: 'calc(100vh - 230px)',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh - 230px)',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 250px)',
    },
  },
}));

export default function DashboardLayout(props: LayoutProps) {
  const { values, setValues } = useAppSettings();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [openSidebar, setSidebarOpen] = useState<boolean>(false);
  const [minimizedSidebar, minimizeSidebar] = useState<boolean>(Boolean(smDown ? false : values.sidebarMinimized));
  const navRef = useRef<boolean>(false);

  const toggleMinimize = () => minimizeSidebar((prev: boolean) => (smDown ? false : !prev));

  useEffect(() => {
    setValues({ sidebarMinimized: minimizedSidebar });
  }, [minimizedSidebar]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <RootStyle>
      <Background />
      <DashboardNavbar onOpenSidebar={() => setSidebarOpen(true)} />
      <MHidden width="lgUp">
        <DashboardSidebar
          isOpenSidebar={openSidebar}
          onCloseSidebar={() => setSidebarOpen(false)}
          minimized={false}
          toggleMinimize={toggleMinimize}
        />
      </MHidden>

      <MainLayout {...props} noPadding={props.noPadding || smDown} withBottomNav={Boolean(navRef.current)}>
        <Outlet />
        <Footer sx={{ margin: '0px auto 8px' }} />
        <MHidden width="smUp" alsoIf={!/(mobile|android|iphone|operamobile|ipad)/gi.test(navigator.userAgent)}>
          <BottomNavigation
            ref={navRef}
            menu={sidebarConfig.map((conf, i) => ({
              ...conf,
              ...(i === 0 && {
                children: [
                  // {
                  //   title: 'Desktop',
                  //   path: '/',
                  //   menuItemSx: { '& .MuiListItemText-root': { fontWeight: '500' } },
                  // },
                  ...conf.children,
                ],
              }),
            }))}
          />
        </MHidden>
      </MainLayout>
      <ScrollToTop />
    </RootStyle>
  );
}
