import CopyToClipboard from 'react-copy-to-clipboard';
import React from 'react';
import {
  Box as MuiBox, IconButton, SxProps,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

const CopyCapsule = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

interface CopyCapsuleProps {
  text: string;
  sx?: SxProps;
}

export default ({ text, children, sx }: React.PropsWithChildren<CopyCapsuleProps>) => {
  const [copied, setCopied] = React.useState<boolean>(false);
  const handleCopy = React.useCallback(() => {
    if (copied) {
      return;
    }

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  }, []);

  return (
    <CopyCapsule sx={sx}>
      {children}
      <CopyToClipboard text={text} onCopy={handleCopy}>
        <IconButton
          sx={{
            ml: 1,
            p: 0,
            '& .MuiSvgIcon-root': {
              fontSize: 18,
              ...(copied && {
                color: (t: Theme) => t.palette.primary.main,
              }),
            },
          }}
          disabled={copied}
        >
          {copied ? <CheckIcon /> : <ContentCopyIcon />}
        </IconButton>
      </CopyToClipboard>
    </CopyCapsule>
  );
};
