export enum BreakpointWidth {
  'mdDown',
  'lgDown',
  'xlDown',
  'xsUp',
  'smUp',
  'mdUp',
  'lgUp',
  'xlUp',
  'xsDown',
  'smDown',
}
