/* eslint-disable max-len */
import React from 'react';
import { baseURL } from '@elacity-js/lib';
import {
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography as MuiTypography,
  Link as MuiLink,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Scrollbar from 'src/components/@ui/Scrollbar';

const Link = styled(MuiLink)({
  textDecoration: 'none',
  fontWeight: 500,
});

export interface CinemaAgreementValue {
  agreed?: boolean;
  acceptAgreement: () => Promise<void>;
  promptAgreement: () => Promise<boolean>;
}

export const CinemaAgreementContext = React.createContext<CinemaAgreementValue>({
  agreed: false,
  acceptAgreement: () => Promise.resolve(),
  promptAgreement: () => Promise.resolve(false),
});

// --- Agreement modal
const Typography = styled(MuiTypography)({
  '&.MuiTypography-h6': {
    paddingTop: 16,
  },
});

const List = styled('ul')(({ theme }) => ({
  '& li': {
    listStyle: 'lower-latin outside',
    margin: theme.spacing(0.15, 0, 0.85),
  },
}));

interface AgreementModalProps {
  open?: boolean;
  onClose?: () => void;
  onConfirm?: () => Promise<void>;
}

const AgreementModal: React.FC<AgreementModalProps> = ({ open, onClose, onConfirm }: AgreementModalProps) => {
  const [read, setRead] = React.useState<boolean>(false);
  const theme = useTheme();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={Boolean(open)}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        <img
          alt="elacity animation"
          src={baseURL(`/static/elacity/Elacity${theme.palette.mode === 'light' ? 'Black' : 'White'}_01.gif`)}
          style={{ height: 270, marginBottom: -50 }}
          crossOrigin="anonymous"
        />
      </DialogTitle>
      <DialogContent>
        <Scrollbar>
          <Typography variant="body2" gutterBottom paragraph>
            By using our platform, you agree to our
            {' '}
            <Link href="https://docs.ela.city/terms-of-service" target="_blank">
              Terms of Service
            </Link>
            ,
            <Link href="https://docs.ela.city/privacy-policy" target="_blank">Privacy Policy</Link>
            {' '}
            and
            {' '}
            <Link href="https://docs.ela.city/community-guidelines" target="_blank">
              Community Guidelines
            </Link>
            . Please review them to understand your rights and responsibilities.
          </Typography>

          <Typography variant="body2" gutterBottom paragraph>
            In accessing and/or using Elacity, you acknowledge and agree that:
          </Typography>

          <Typography variant="body2" gutterBottom paragraph>
            <List>
              <li>
                Elacity is provided on an “as-is” and “as available” basis, and Elacity LLC (“Operator”) and its affiliates (save to the extent prohibited by applicable laws) expressly disclaim any and all representations, warranties, and/or conditions of any kind in respect thereof, whether express, implied, or statutory, including all warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement.
              </li>

              <li>
                Operator and its affiliates have not made and make no representation, warranty, and/or condition of any kind that Elacity will meet your requirements, or will be available on an uninterrupted, timely, secure, or error-free basis, or will be accurate, reliable, free of viruses or other harmful code, complete, legal, or safe.
              </li>

              <li>
                You shall have no claim against Operator and/or its affiliates in respect of any loss suffered by you in relation to or arising from your access and/or use of Elacity.
              </li>
            </List>
          </Typography>
        </Scrollbar>
      </DialogContent>
      <DialogActions sx={{ my: 3, mx: 2, justifyContent: 'center' }}>
        <Box>
          <FormControlLabel
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '0.8rem',
              },
            }}
            control={(
              <Checkbox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setRead(e.target.checked);
                }}
              />
            )}
            label="I’ve read, understood and agree to Elacity’s service agreement"
          />
        </Box>
        <Button size="large" type="submit" variant="contained" onClick={onConfirm} disabled={!read}>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
// --- /Agreement modal

interface CinemaAgreementProviderProps {}

declare type PromiseResolver<T> = (value: T) => void;

export const CinemaAgreementProvider: React.FC<React.PropsWithChildren<CinemaAgreementProviderProps>> = ({
  children,
}: React.PropsWithChildren<CinemaAgreementProviderProps>) => {
  const acceptResolver = React.useRef<PromiseResolver<boolean>>(null);

  const [agreed, setAgreed] = React.useState<boolean>(
    // take previous value from local storage (browser-based value)
    // @todo: use database to store this value according to account (if connected)
    // if not connected, we will not really ned to make the prompt
    localStorage.getItem('__elacity_cinema_agree') === 'yes'
  );
  const [open, openModal] = React.useState<boolean>(false);
  const acceptAgreement = async () => {
    acceptResolver.current?.call(null, true);

    setAgreed(true);
    setTimeout(() => {
      openModal(false);
    }, 500);
  };

  const promptAgreement = () => {
    openModal(true);

    return new Promise<boolean>((resolve) => {
      acceptResolver.current = resolve;
    });
  };

  React.useEffect(() => {
    if (agreed) {
      localStorage.setItem('__elacity_cinema_agree', 'yes');
    }
  }, [agreed]);

  React.useEffect(() => {}, []);

  return (
    <CinemaAgreementContext.Provider
      value={{
        agreed,
        acceptAgreement,
        promptAgreement,
      }}
    >
      {children}
      <AgreementModal open={open} onClose={() => openModal(false)} onConfirm={acceptAgreement} />
    </CinemaAgreementContext.Provider>
  );
};

// high order component
export const withCinemaAgreement =
  <T, >(Component: React.ComponentType<T>) => (props: T) => {
    const { agreed, promptAgreement } = React.useContext(CinemaAgreementContext);
    React.useEffect(() => {
      // on load, check whether the user has already agreed with the NFTs Agreement
      // if so, we can just skip prompt
      // otherwise, we should show modal to allow user to confirm it
      if (!agreed) {
        promptAgreement().catch((e: Error) => console.error('Agreement Error', e));
      }
    }, []);

    return <Component {...props} />;
  };
