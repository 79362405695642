import React from 'react';
import { ButtonBase } from '@mui/material';
import { RouterLink, Link } from 'src/components/Link';
import MenuDropdown, { MenuItem, DropdownContext } from 'src/components/@ui/MenuDropdown';
import { links } from 'src/config';
import { useConnector } from 'src/lib/web3/components/ConnectorSelect';
import { useProfile, useAddresses } from 'src/hooks';

export default () => {
  const { promptConnector, disconnect } = useConnector();
  const { BELLA_COLLECTION_ADDRESS } = useAddresses();
  const { profile } = useProfile();
  return (
    <MenuDropdown
      id="main-entry-dropdown"
      Anchor={({ onClick, onMouseEnter, onMouseLeave }) => (
        <ButtonBase
          {...{
            onClick,
            component: RouterLink,
            to: `marketplace/collections/${BELLA_COLLECTION_ADDRESS}`,
            onMouseEnter,
            onMouseLeave,
          }} disableRipple
        >
          <img crossOrigin="anonymous" style={{ width: 'auto', height: 48 }} src="/favicon-64-old.png" alt="Elacity" />
        </ButtonBase>
      )}
      sx={{
        '&#main-entry-dropdown .MuiPaper-root': {
          mt: 0.5,
        },
      }}
      hover
    >
      <DropdownContext.Consumer>
        {({ wrapClose, handleClose }) => (
          <>
            <MenuItem component={Link} href={links.documentation} target="_blank" rel="noreferrer" onClick={handleClose}>
              About
            </MenuItem>
            <MenuItem component={Link} href={links.whitepaper} target="_blank" rel="noreferrer" onClick={handleClose}>
              Whitepaper
            </MenuItem>
            {/* <MenuItem disabled>DApp Store</MenuItem> */}
            {/* profile?.address ? (
              <MenuItem onClick={wrapClose(disconnect)}>
                Log Out
                {` ${profile?.did?.credentials?.name || profile?.alias || 'Elanaut'}`}
              </MenuItem>
            ) : (
              <MenuItem onClick={wrapClose(promptConnector)}>Log In</MenuItem>
            ) */}
          </>
        )}
      </DropdownContext.Consumer>
    </MenuDropdown>
  );
};
