import { SignatureRequest, SignatureResponse } from './types';

export const signLicenseRequest = async <T extends unknown>({
  from,
  provider: lib,
  chainId,
  verifyingContract,
  ...payload
}: SignatureRequest<T>): Promise<SignatureResponse> => {
  const typedMessage = {
    domain: {
      name: 'AuthorityGateway',
      version: '1.0',
      chainId,
      verifyingContract,
    },

    ...payload,

    primaryType: 'LicenseRequest',
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' },
      ],
      IPEntity: [
        { name: 'contentId', type: 'bytes16' },
        { name: 'ledger', type: 'address' },
        { name: 'tokenId', type: 'uint256' },
      ],
      LicenseRequest: [
        { name: 'entitlement', type: 'string' },
        { name: 'entity', type: 'IPEntity' },
      ],
      // SignedLicenseRequest: [
      //   { name: 'request', type: 'LicenseRequest' },
      //   { name: 'signature', type: 'bytes' },
      // ],
    },
  };

  // @ts-ignore
  const provider = lib?.provider;

  // @ts-ignore
  if (!provider || !provider?.request) {
    return Promise.reject(new Error('You need a connected wallet to pursue'));
  }

  // @ts-ignore
  const signature = await provider.request({
    method: 'eth_signTypedData_v4',
    params: [from, JSON.stringify(typedMessage)],
  });

  return { signature, signer: from };
};
