/* eslint-disable camelcase */
import { TokenID } from '@elacity-js/lib';
import { BigNumberish } from '@ethersproject/bignumber';
import { FileType } from 'src/components/MediaViewer';
import { Account } from './account';
import { Comment } from './comment';
import { NFTArtBase } from './art';

export interface ISCCChain {
  iscc: string;
  iscc_raw: string;
  title: string;
  title_trimmed: string;
  tophash: string;
  gmt: string;
  bits: string[];
}

export enum GrantType {
  // these grant are relative to Token Type in Operative contracts
  PLAY = 1,
  DISTRIBUTE = 3,
}

export interface AccessLevel {
  haveAccess: boolean;
  entitlement: GrantType;
}

export interface TokenInfo {
  name?: string;
  kid?: string;
  description?: string;
  image?: string;
  mediaURL?: string;
}

export interface MetadataAttribute {
  // eslint-disable-next-line camelcase
  trait_type: string;
  value: string;
}

export type MetadataProperty = string | number;

export interface MediaTokenMetadata extends TokenInfo {
  media: {
    uri: string;
    contentType: string;
    size?: number;
    object?: string;
    protectionType?: string[];
  };
  type?: FileType;
  attributes?: MetadataAttribute[];
  properties?: {
    chainId: number;
    ledger: string;
    authority: string;
    publisher?: Account;
    contract?: string;
    labelType?: string;
    distribution?: string;
  };
}

export interface NFTToken {
  tokenURI: string;
  tokenId: BigNumberish | TokenID;
}

export interface IComment {
  count?: number;
  values?: Comment[];
}
interface AdditionalData {
  createdAt?: number;
  views?: number;
  comments?: number | IComment;
}

export interface OperativeMetadataListing {
  price: number;
  quantity: number;
  payToken?: string;
  seller?: string;
}

export interface OperativeMetadata {
  address: string;
  opType: number;
  access?: {
    totalSupply?: number;
    holdings?: number;
    listings?: OperativeMetadataListing[];
  };
}

export interface SellableAsset {
  paymentToken?: string;
  price?: BigNumberish | number | string;
}

export interface MediaTokenAsset extends NFTToken, TokenInfo, AdditionalData, SellableAsset {
  // additional data for ease on UI retrieval
  address?: string;

  contractAddress?: string;
  hexTokenID?: string;

  metadata?: MediaTokenMetadata;

  operative?: OperativeInformation;
}

export interface OperativeTokenInfo {
  tokenId: number;
  metadataURI: string;
}

export interface OperativeInformation {
  address: string;
  contentId: string;
  owner: string;
  opType: number;
  metadataURI: string;
  access: OperativeTokenInfo & OperativeMetadata['access'];
  resellerCut: number;
  royalties: OperativeTokenInfo & {
    distributions: Record<string, number>;
  };
}

export interface MediaViewState extends TokenInfo, AdditionalData {
  loading?: boolean;
  loaded?: boolean;
  tokenId?: TokenID;
  tokenURI?: string;

  // additional informations
  metadata?: MediaTokenMetadata;
  isProtected?: boolean;
  operative?: OperativeInformation;
  access?: AccessLevel[];
}

export interface NFTMediaTokenAsset extends Omit<NFTArtBase, 'metadata'> {
  metadata: MediaTokenMetadata;
}

export interface AssetLogsItem {
  input: {
    contractAddress: string;
    tokenId: number | string | TokenID;
  }
}
