import React from 'react';
import {
  EthereumLogoPurple as EthereumLogo, BinanceLogo, EvmosLogo, PolygonLogo,
} from 'src/assets/others';
import { ElastosLogoMark, ElastosNewLogoMark } from 'src/assets/elacity';

export const EmptyLogo = () => null;

export const LogoMap = {
  ELA: ElastosNewLogoMark,
  ETH: EthereumLogo,
  BNB: BinanceLogo,
  MATIC: PolygonLogo,
  EVMOS: EvmosLogo,
};

export const LogoByChain = {
  0: ElastosLogoMark,
  20: ElastosLogoMark,
  21: ElastosLogoMark,
  1: EthereumLogo,
  2: EthereumLogo,
  3: EthereumLogo,
  5: EthereumLogo,
  56: BinanceLogo,
  80001: PolygonLogo,
  25: EvmosLogo,
};

export default ({ chain }: {chain: number}) => {
  const LogoComponent = React.useMemo(() => LogoByChain[chain || 0] || EmptyLogo, [chain]);

  return (
    <LogoComponent />
  );
};
