export const CHAIN_ID = {
  MAINNET: '20',
  TESTNET: '21',
};

export const FEES = {
  MAINNET: process.env.REACT_APP_FEE_MAINNET || '0.00001',
  TESTNET: process.env.REACT_APP_FEE_TESTNET || '0.00001',
};

export const ADDRESS = {
  MAINNET: process.env.REACT_APP_NFT_ADDRESS_MAINNET || '0xdCBEE2825F5a6244E4aD6ceA243D8Eb5d172cF8B',
  TESTNET: process.env.REACT_APP_NFT_ADDRESS_TESTNET || '0xdCBEE2825F5a6244E4aD6ceA243D8Eb5d172cF8B',
  ZERO: '0x0000000000000000000000000000000000000000',
  PAYTOKEN: process.env.REACT_APP_PAYTOKEN || '0x0000000000000000000000000000000000000000',
};

export const ABIS = {
  SINGLE_NFT_ABI: [
    {
      constant: true,
      inputs: [],
      name: 'symbol',
      outputs: [
        {
          name: '',
          type: 'string',
        },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_tokenId',
          type: 'uint256',
        },
      ],
      name: 'burn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_to', type: 'address' },
        { internalType: 'string', name: '_tokenUri', type: 'string' },
      ],
      name: 'mint',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'setApprovalForAll',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
      ],
      name: 'isApprovedForAll',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true,
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'platformFee',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true,
    },
  ],

  MULTI_NFT_ABI: [
    {
      constant: true,
      inputs: [],
      name: 'symbol',
      outputs: [
        {
          name: '',
          type: 'string',
        },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_to', type: 'address' },
        { internalType: 'uint256', name: '_supply', type: 'uint256' },
        { internalType: 'string', name: '_uri', type: 'string' },
      ],
      name: 'mint',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '_to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '_id',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '_data',
          type: 'bytes',
        },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'platformFee',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true,
    },
  ],
};

export const getPayTokenByChain = (chainId: number) => (chainId === 21
  // ECITY
  ? '0x517E9e5d46C1EA8aB6f78677d6114Ef47F71f6c4'
  // wELA
  : '0x517E9e5d46C1EA8aB6f78677d6114Ef47F71f6c4');
