import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Provider as ReduxProvider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import { DIDProvider } from 'src/lib/did/context';
import MediaPlayerProvider from 'src/components/Cinema/Media/Player/CoreContext';
import { MobileCommentProvider } from 'src/contexts/MobileCommentContext';
import { MobileExploreProvider } from 'src/contexts/MobileExploreContext';
import ErrorPage from '../views/Error';
import { Web3Provider } from '../lib/web3';
import { Web3ApplicationProvider } from '../contexts/Web3ApplicationContext';
import { NetworkProvisioner, NETWORKS as supportedNetworks } from '../lib/web3/network';
import { AppSettingsProvider } from '../contexts/AppSettingsContext';
import { ModalProvider } from '../contexts/ModalContext';
import { FullscreenProvider } from '../contexts/FullscreenContext';
import { GalleryProvider } from '../contexts/GalleryContext';
import { ApiProvider as ProfileProvider } from '../contexts/ApiContext';
import { NftApprovalProvider } from '../contexts/ContractApprovalContext';
import { UserAgreementProvider } from '../contexts/agreement/UserAgreementContext';
import { CinemaAgreementProvider } from '../contexts/agreement/CinemaAgreementContext';
import { FlintServiceAgreementProvider } from '../contexts/agreement/FlintServiceAgreementContext';
import { ConnectorProvider } from '../lib/web3/components/ConnectorSelect';
import { AppBarUIProvider } from '../contexts/AppBarUiContext';
import { PredictionProvider } from '../contexts/PredictionContext';
import store from '../state/store';
import { ThemeProvider } from '../themes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AppProviderProps {}

const MemoizedProvider: FC<PropsWithChildren<AppProviderProps>> = React.memo(({ children }) => (
  <BrowserRouter>
    <DIDProvider>
      <ReduxProvider store={store}>
        <SnackbarProvider
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          autoHideDuration={5000}
          classes={{
            containerRoot: 'Uikit-SnackbarContainer',
          }}
        >
          <Web3Provider>
            <Web3ApplicationProvider>
              <NetworkProvisioner
                networks={Object.values(supportedNetworks)}
                preferedNetwork={Number(process.env.REACT_APP_NETWORK === 'main' ? 20 : 21)}
              >
                <ConnectorProvider>
                  <ProfileProvider>
                    <UserAgreementProvider>
                      <FlintServiceAgreementProvider>
                        <CinemaAgreementProvider>
                          <NftApprovalProvider checkOnLoad>
                            <ModalProvider>
                              <MediaPlayerProvider remote websocketURL="wss://cdn.ela.city/ws">
                                <GalleryProvider>
                                  <FullscreenProvider>
                                    <AppBarUIProvider>
                                      <PredictionProvider>
                                        <MobileCommentProvider>
                                          <MobileExploreProvider>
                                            {children}
                                          </MobileExploreProvider>
                                        </MobileCommentProvider>
                                      </PredictionProvider>
                                    </AppBarUIProvider>
                                  </FullscreenProvider>
                                </GalleryProvider>
                              </MediaPlayerProvider>
                            </ModalProvider>
                          </NftApprovalProvider>
                        </CinemaAgreementProvider>
                      </FlintServiceAgreementProvider>
                    </UserAgreementProvider>
                  </ProfileProvider>
                </ConnectorProvider>
              </NetworkProvisioner>
            </Web3ApplicationProvider>
          </Web3Provider>
        </SnackbarProvider>
      </ReduxProvider>
    </DIDProvider>
  </BrowserRouter>
));

const AppProvider: FC<PropsWithChildren<AppProviderProps>> = ({ children }: PropsWithChildren<AppProviderProps>) => (
  <HelmetProvider>
    <AppSettingsProvider appName="" logo={undefined} storage={undefined}>
      <ThemeProvider>
        <MemoizedProvider>{children}</MemoizedProvider>
      </ThemeProvider>
    </AppSettingsProvider>
  </HelmetProvider>
);

const ErrFallback: FC = () => <ErrorPage message="An unknown error occured, please reload" />;

export default Sentry.withErrorBoundary(AppProvider, {
  fallback: <ErrFallback />,
  onError: (e: Error) => console.error(e),
});
