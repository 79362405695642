/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useNavigate from 'src/hooks/useNavigate';
import usePrediction from 'src/hooks/usePrediction';
import useClientHttp from 'src/hooks/useClientHttp';
import HiveHandlers from './hive.handler';
import PredictionHandlers from './prediction.handler';
import type { SourceType } from './reducer';
import type {
  AIResultHandlers, BuilderParams, HandlerOption,
} from './types';

class HandlerBuilder {
  constructor(protected source: SourceType, protected params: BuilderParams) {}

  getHandlers(): AIResultHandlers {
    switch (this.source) {
      case 'hive': return new HiveHandlers(this.params);
      case 'prediction': return new PredictionHandlers(this.params);
      default: return {};
    }
  }

  handleAll(): AIResultHandlers {
    const hs = this.getHandlers();

    return {
      onCarouselMove: hs.onCarouselMove?.bind(hs),
      onReturn: hs.onReturn?.bind(hs),
      onMint: hs.onMint?.bind(hs),
      onUpscale: hs.onUpscale?.bind(hs),
      handleUpscale: hs.handleUpscale?.bind(hs),
      onDownload: hs.onDownload?.bind(hs),
      handleDownload: hs.handleDownload?.bind(hs),
      handleRemove: hs.handleRemove?.bind(hs),
    } as AIResultHandlers;
  }
}

export default ({ source, images, currentView, currentIndex, ...others }: HandlerOption) => {
  const navigate = useNavigate();
  const { getClient } = useClientHttp();
  const { predictionId } = useParams();
  const { prediction } = usePrediction();
  const handlers = useMemo(() => {
    const builder = new HandlerBuilder(source, {
      images,
      currentView,
      currentIndex,
      navigate,
      predictionId,
      prediction,
      getClient,
      ...others,
    });
    return builder.handleAll();
  }, [source, images, currentView]);

  return {
    ...handlers,
  };
};
