import { AddressZero } from '@ethersproject/constants';
import { BigNumberish } from '@ethersproject/bignumber';
import { formatEther, formatUnits } from '@ethersproject/units';
import erc20Currencies from 'src/constants/currencies';

export const ethToNumber = (value: BigNumberish, decimal = 18) => {
  if (!value) {
    return 0;
  }

  if (decimal === 18) {
    return parseFloat(formatEther(value?.toString()));
  }

  return parseFloat(formatUnits(value?.toString(), decimal));
};

export const ethToNumberCurr = (value: BigNumberish, currency = AddressZero) => {
  // find the currency
  const decimals = erc20Currencies.find(
    (c) => c.address.toLowerCase() === currency?.toLowerCase()
  )?.decimals || 18;

  return ethToNumber(value, decimals);
};
