/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { Web3Provider } from '@ethersproject/providers';
import { BigNumberish } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import {
  Typography,
  Popover,
  Box,
  Grid,
  Link,
  Stack,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
} from '@mui/material';
import type { GridProps } from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Price, { TokenLogo } from 'src/components/@ui/Price';
import { usePayment, useWeb3Application } from 'src/hooks';
import { nFormat } from 'src/utils';
import { NetworkSelector, useNetworkProvisioner } from 'src/lib/web3/network';
import { BuyInputs } from './type';

const ProcessingTimeModalForm: FC = () => {
  const { account, payment, chainId, library } = useWeb3Application();
  const { switchNetwork } = useNetworkProvisioner();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [balance, setBalance] = React.useState<number>(0);
  const { values, setFieldValue } = useFormikContext<BuyInputs>();
  const gridItemProps: GridProps = { item: true, lg: 12, style: { width: '100%', textAlign: 'center' } };

  const { supportedArr: currencies } = usePayment();
  React.useEffect(() => {
    if (!values.payToken || values.payToken?.length === 0) {
      const usdc = currencies.filter((tk) => tk.symbol === 'USDC' || tk.symbol === 'BUSD').pop();
      setFieldValue('payToken', usdc?.address);
    }
  }, [chainId]);

  React.useEffect(() => {
    if (values.payToken && account) {
      payment
        ?.use(values.payToken)
        .balanceOf(account)
        .then((b: BigNumberish) => {
          const decimals = currencies.find((c) => c.address?.toLowerCase() === values.payToken?.toLowerCase())?.decimals;
          setBalance(parseFloat(formatUnits(b.toString(), decimals)));
        })
        .catch(console.error);
    }
  }, [values.payToken, account]);

  React.useEffect(() => {
    // 5 USD per 1000 second
    setFieldValue('amount', values?.processingTime * (5 / 1000));
  }, [values.processingTime]);

  const handleSelectChainId = React.useCallback(
    (newChainId: number) => async () => {
      if (chainId !== newChainId) {
        console.log('Switching to chainId %d...', newChainId);
        await switchNetwork(newChainId, library as Web3Provider);
      }
    },
    [chainId, library]
  );

  return (
    <Grid container spacing={{ lg: 1, md: 1, xs: 1 }} sx={{ mt: 1 }}>
      <Grid item lg={12} xs={12}>
        <Typography sx={{ width: '100%', textAlign: 'center' }} variant="h5">
          Buy Processing Time
        </Typography>
      </Grid>
      <Grid item lg={12} xs={12}>
        <NetworkSelector
          chainId={chainId}
          handleChange={handleSelectChainId}
          sxBuilder={(c, s) => ({
            ...(c !== s && {
              '& svg': {
                filter: 'grayscale(0.7) opacity(0.7)',
              },
            }),
          })}
        />
      </Grid>
      <Grid item lg={12} xs={12} sx={{ mt: 1 }}>
        <Typography sx={{ fontSize: 13.5, width: '100%', textAlign: 'center' }}>
          🐘
          {' '}
          <Link
            href="https://ela.city/marketplace/collections/0xef5f768618139d0f5fa3bcbbbcaaf09fe9d7a07d"
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            Bella
          </Link>
          {' '}
          NFT holders receive a 28% discount!
        </Typography>
      </Grid>
      <Grid {...gridItemProps} sx={{ pt: 0 }}>
        <FormControl>
          <RadioGroup
            value={values.processingTime}
            name="processing-time"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('processingTime', e.target.value);
            }}
          >
            <FormControlLabel value={1000} control={<Radio />} label="Buy 1000 seconds of time" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Typography sx={{ fontSize: 13.5, width: '100%', textAlign: 'center' }}>
          My balance:&nbsp;
          {nFormat(balance, 4)}
          {' '}
          {currencies.find((c) => c.address === values.payToken)?.symbol || ''}
        </Typography>
      </Grid>
      <Grid {...gridItemProps}>
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '& svg': {
                cursor: 'pointer',
              },
            }}
            onClick={(e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}
          >
            {!anchorEl ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            <Price.View
              value={values.amount * ((100 - values.discount) / 100)}
              size={36}
              precision={4}
              currency={values?.payToken}
            />
          </Box>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <List>
              {currencies
                .filter((c) => c.symbol?.match(/usd/gi))
                .map((c) => (
                  <ListItem key={c.address} disablePadding>
                    <ListItemButton
                      sx={{ minWidth: 60, justifyContent: 'center' }}
                      onClick={() => {
                        setFieldValue('payToken', c.address.toLowerCase());
                        setAnchorEl(null);
                      }}
                    >
                      <TokenLogo size={20} currency={c.address} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </Popover>
          {values.discount > 0 && (
            <Typography sx={{ fontWeight: 500, color: 'success.main' }}>
              {values.discount}
              % off!
            </Typography>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ProcessingTimeModalForm;
