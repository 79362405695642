/* eslint-disable camelcase */
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Container, Box } from '@mui/material';
import {
  useTheme, alpha, styled,
} from '@mui/material/styles';
import { imageToBase64 } from 'src/utils';
import usePrediction from 'src/hooks/usePrediction';
import { useClientHttp, useProfile } from 'src/hooks';
import { useNotifyProcessingTimeChangeMutation } from 'src/state/api';
import { aiApi } from 'src/api';
import { Prediction } from 'src/types/prediction';
import DrawerUI from '../@ui/DrawerUI';
import { AIProvider } from './context/AiResultContext';
import { DrawerContent } from './Drawer';
import { StableDiffusionModel, ModelSelection } from './type';

const InnerContainer = styled(Box)(({ theme }) => ({}));

export default () => {
  const [notifyPTChange] = useNotifyProcessingTimeChangeMutation();
  const { profile } = useProfile();
  const theme = useTheme();
  const { getClient } = useClientHttp();
  const { setPrediction, prediction, drawerOpen, toggleDrawer, hiveUploadOutput } = usePrediction();

  const initialValues: StableDiffusionModel & ModelSelection = {
    // selection
    modelType: 'text2image',
    modelName: 'stability-ai/sdxl@2b017d9b67edd2ee1401238df49d75da53c523f36e363881e057f5dc3ed3c5b2',

    // model inputs
    prompt: '',
    width: 1024,
    height: 1024,
    guidance_scale: 7.5,
    num_outputs: 1,
    num_inference_steps: 50,
    ...(prediction?.input || {}),
  };

  return (
    <Formik
      initialValues={{ ...initialValues, submit: null }}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        prompt: Yup.string().required('Please enter a text'),
      })}
      onSubmit={async ({ init_image, image, ...values }, { setSubmitting, setErrors }) => {
        const client = await getClient();
        setPrediction(null);
        try {
          const formData = new FormData();
          Object.entries(values).forEach(([k, v]) => (v ? formData.append(k, v) : null));
          if (init_image) {
            const base64: string | ArrayBuffer = await imageToBase64(init_image);
            formData.append('init_image', base64?.toString());
          }
          if (image) {
            const base64: string | ArrayBuffer = await imageToBase64(image);
            formData.append('image', base64?.toString());
          }
          const response = await aiApi.text2imagePredict(formData, client);
          if (response?.status === 'succeeded') {
            setPrediction(response as Prediction);
            hiveUploadOutput(response.output || []);
          } else if (response?.status === 'failed') {
            setErrors({
              submit: response.error || 'Unable to predict with this prompt, please use another input',
            });
          }
        } catch (err) {
          setErrors({ submit: err.error ? JSON.stringify(err.error) : err.message });
        } finally {
          setSubmitting(false);
          notifyPTChange(profile?.address);
        }
      }}
    >
      <AIProvider>
        <Container>
          <DrawerUI
            open={drawerOpen}
            anchor="right"
            variant="persistent"
            mbCorrection={0}
            hideBackdrop
            innerBackground={alpha(theme.palette.background.default, 0.7)}
            PaperProps={{
              sx: {
                zIndex: theme.zIndex.appBar - 1,
                '& > div': {
                  height: '100vh',
                },
              },
            }}
          >
            <DrawerContent handleClose={() => toggleDrawer(false)} />
          </DrawerUI>
          <InnerContainer>
            <Outlet />
          </InnerContainer>
        </Container>
      </AIProvider>
    </Formik>
  );
};
