import React from 'react';
import useWeb3Application from './useWeb3Application';
import { getPayTokenByChain } from '../lib/nfts/constants';

const useAddresses = () => {
  const { chainId, ready } = useWeb3Application();

  const ecosystem = {
    20: {
      NFT_ADDRESS: process?.env?.REACT_APP_NFT_ADDRESS_MAINNET,
      MARKETPLACE_ADDRESS: process?.env?.REACT_APP_MARKETPLACE_ADDRESS_MAINNET,
      MARKETPLACE_AGGREGATOR: process?.env?.REACT_APP_MARKETPLACE_AGGREGATOR_MAINNET,
      AUCTION_ADDRESS: process?.env?.REACT_APP_AUCTION_ADDRESS_MAINNET,
      FACTORY_ADDRESS: process?.env?.REACT_APP_FACTORY_ADDRESS_MAINNET,
      PRIVATE_FACTORY_ADDRESS: process?.env?.REACT_APP_PRIVATE_FACTORY_ADDRESS_MAINNET,
      ART_FACTORY_ADDRESS: process?.env?.REACT_APP_ART_FACTORY_ADDRESS_MAINNET,
      PRIVATE_ART_FACTORY_ADDRESS: process?.env?.REACT_APP_PRIVATE_ART_FACTORY_ADDRESS_MAINNET,
      BELLA_COLLECTION_ADDRESS: process?.env?.REACT_APP_BELLA_COLLECTION_ADDRESS_MAINNET,
    },
    21: {
      NFT_ADDRESS: process?.env?.REACT_APP_NFT_ADDRESS_TESTNET,
      MARKETPLACE_ADDRESS: process?.env?.REACT_APP_MARKETPLACE_ADDRESS_TESTNET,
      MARKETPLACE_AGGREGATOR: process?.env?.REACT_APP_MARKETPLACE_AGGREGATOR_TESTNET,
      AUCTION_ADDRESS: process?.env?.REACT_APP_AUCTION_ADDRESS_TESTNET,
      FACTORY_ADDRESS: process?.env?.REACT_APP_FACTORY_ADDRESS_TESTNET,
      PRIVATE_FACTORY_ADDRESS: process?.env?.REACT_APP_PRIVATE_FACTORY_ADDRESS_TESTNET,
      ART_FACTORY_ADDRESS: process?.env?.REACT_APP_ART_FACTORY_ADDRESS_TESTNET,
      PRIVATE_ART_FACTORY_ADDRESS: process?.env?.REACT_APP_PRIVATE_ART_FACTORY_ADDRESS_TESTNET,
      BELLA_COLLECTION_ADDRESS: process?.env?.REACT_APP_BELLA_COLLECTION_ADDRESS_TESTNET,
    },
  };

  return React.useMemo(
    () => ((ready && chainId)
      ? {
        PAYTOKEN: getPayTokenByChain(chainId),
        ...(ecosystem[chainId] || {}),
      }
      : {}),
    [chainId, ready]
  );
};

export default useAddresses;
