/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Box, FormControl, InputLabel, OutlinedInput, FormHelperText,
} from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import DateInput from 'src/components/form/DateInput';

export interface GeneratedFieldParams {
  name: string;
  isSubmitting: boolean;
  errors: any;
  values: any;
  setFieldValue: any;
  label: string;
  endWith?: any;
  isDate?: boolean;
  startingTime?: number;
  autoFocus?: boolean;
}

export const generateField = (
  { name, isSubmitting, errors, values, setFieldValue, label, endWith, isDate, autoFocus }: GeneratedFieldParams,
  boxProps: BoxProps,
  readonly = false
) => (
  <Box {...boxProps}>
    <FormControl fullWidth sx={{ m: 0 }} variant="outlined" disabled={isSubmitting} error={Boolean(errors[name])}>
      {isDate ? (
        <DateInput
          format="yyyy/MM/dd HH:mm:ss"
          type="mobile-datetime"
          label={label}
          value={typeof values[name] === 'string' ? parseInt(values[name], 10) : values[name]}
          onChange={(newValue) => {
            setFieldValue(name, newValue.getTime());
          }}
          disabled={Boolean(readonly)}
        />
      ) : (
        <>
          <InputLabel>{label}</InputLabel>
          <OutlinedInput
            type="text"
            label={label}
            endAdornment={endWith || null}
            disabled={Boolean(readonly)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(name, e.target.value)}
            autoFocus={Boolean(autoFocus)}
            {...(!values[name] || values[name] === '0'
              ? {
                placeholder: label,
                value: '',
              }
              : {
                value: values[name] || '',
              })}
          />
          {Boolean(errors[name]) && <FormHelperText error>{errors[name]}</FormHelperText>}
        </>
      )}
    </FormControl>
  </Box>
);

export const generateFields = (
  inputs: {
    params: GeneratedFieldParams;
    boxProps?: BoxProps;
    readonly?: boolean;
  }[]
) => inputs.map(({ params, boxProps, readonly }, i) => generateField(params, { ...boxProps, key: `$.index-${i + 1}` }, readonly));
