import { signingMethods } from '@walletconnect/utils';
import config from 'src/config';
import injectedConnector from './metamask';
import walletconnectConnector from './walletconnect';
import essentialsConnector from './essentials.did';

export * from './types';
export * from './metamask';
export * from './walletconnect';
export * from './essentials.did';

const { POLLING_INTERVAL } = config.web3;
export { POLLING_INTERVAL };

signingMethods.push('wallet_addEthereumChain', 'wallet_watchAsset', 'wallet_switchEthereumChain');

export default [
  essentialsConnector,
  injectedConnector,
  walletconnectConnector,
];

export {
  essentialsConnector, injectedConnector, walletconnectConnector,
};
