import React, { Suspense } from 'react';
import { ContractTransaction } from '@ethersproject/contracts';
import useContract from 'src/hooks/contracts/sc.contracts';
import useAddresses from 'src/hooks/useAddresses';
import useWeb3Application from 'src/hooks/useWeb3Application';
import { marketplaceSupportedChainIds } from 'src/lib/web3/network';

export interface ApprovalState {
  approved: boolean;
  approving?: boolean;
  checking?: boolean;
}

export interface ApprovalFns {
  isApproved: (address: string) => Promise<boolean>;
  approve: (address: string) => Promise<ContractTransaction>;
}

export interface ContractApprovalContextValue {
  status: Record<string, Record<string, ApprovalState>>;
  contracts: Record<string, ApprovalFns>;
  loadFor?: (contractAddr: string, approveAddr: string) => Promise<void>;
  addAddressForApproval: (contractAddr: string) => void;
}

interface ContractApprovalProviderProps {
  /**
   * List of contract addresses that may need to be approved against `addresses`
   */
  contractAddresses: string[];

  /**
   * list of addresses
   */
  addresses?: string[];

  /**
   * deletermine wether approval checking should or not process during loading
   */
  checkOnLoad?: boolean;
}

const ContractApprovalContext = React.createContext<ContractApprovalContextValue>({
  status: {},
  contracts: {},
  addAddressForApproval: () => {},
});

export const ContractApprovalProvider: React.FC<React.PropsWithChildren<ContractApprovalProviderProps>> = ({
  children,
  checkOnLoad,
  contractAddresses,
  addresses,
}: React.PropsWithChildren<ContractApprovalProviderProps>) => {
  const { active, chainId, account } = useWeb3Application();

  // /!\ Attention: for now we only support erc721 contract type to approve against `contractAddresses`
  const { loadERC721Contract } = useContract();

  const [addressesState, setStateAddresses] = React.useState<string[]>(addresses);

  // handle statuses
  const innerStatusHandler = {
    get: (target: Record<string, ApprovalState>, p: string) => {
      if (typeof p !== 'string') {
        return null;
      }

      if (!target || !target[p.toLowerCase()]) {
        return { approved: false };
      }

      return target[p.toLowerCase()];
    },
  };

  const statusHandler = {
    get(target: Record<string, Record<string, ApprovalState>>, property: string) {
      if (typeof property !== 'string') {
        return null;
      }

      if (!property || !target || !target[property.toLowerCase()]) {
        return new Proxy<Record<string, ApprovalState>>({}, innerStatusHandler);
      }

      return target[property.toLowerCase()];
    },
  };

  const [status, setStatus] = React.useState<Record<string, Record<string, ApprovalState>>>(
    new Proxy(
      contractAddresses.reduce(
        (c: Record<string, Record<string, ApprovalState>>, addr: string) => ({
          ...c,
          [addr]: new Proxy<Record<string, ApprovalState>>({}, innerStatusHandler),
        }),
        {}
      ),
      statusHandler
    )
  );

  const changeStatusFor = (contractAddr: string, apAddr: string, key: keyof ApprovalState, value: boolean) => setStatus(
    (prev) => new Proxy(
      {
        ...prev,
        [contractAddr]: {
          ...(prev[contractAddr] || new Proxy<Record<string, ApprovalState>>({}, innerStatusHandler)),
          [apAddr]: {
            // eslint-disable-next-line max-len
            ...(((prev[contractAddr] || new Proxy<Record<string, ApprovalState>>({}, innerStatusHandler))[apAddr] || {
              approved: false,
              approving: false,
              checking: false,
            }) as ApprovalState),
            [key]: value,
          },
        },
      },
      statusHandler
    )
  );

  // handle contracts
  const nullApprovals: ApprovalFns = {
    isApproved: () => Promise.resolve(false),
    approve: (NFTAddress: string) => Promise.reject(new Error(`initialization error, addr=${NFTAddress}`)),
  };

  const contractsHandler = {
    get(target: Record<string, ApprovalFns>, property: string) {
      if (typeof property !== 'string') {
        return null;
      }

      if (!target || !target[property.toLowerCase()]) {
        return nullApprovals;
      }

      return target[property.toLowerCase()];
    },
  };

  const contracts = React.useMemo<Record<string, ApprovalFns>>(
    () => new Proxy(
      contractAddresses.reduce(
        (c: Record<string, ApprovalFns>, addr: string) => ({
          ...c,
          [addr]: {
            isApproved: async (NFTAddress: string): Promise<boolean> => {
              changeStatusFor(addr, NFTAddress, 'checking', true);
              if (!account) {
                return Promise.resolve(false);
              }

              const contract = loadERC721Contract(NFTAddress);
              const approved = await contract.isApprovedForAll(account, addr);

              changeStatusFor(addr, NFTAddress, 'approved', Boolean(approved));
              changeStatusFor(addr, NFTAddress, 'checking', false);

              return Boolean(approved);
            },
            approve: async (NFTAddress: string): Promise<ContractTransaction> => {
              changeStatusFor(addr, NFTAddress, 'approving', true);
              try {
                const contract = loadERC721Contract(NFTAddress);
                const tx = await contract.setApprovalForAll(addr, true);

                changeStatusFor(addr, NFTAddress, 'approved', true);
                changeStatusFor(addr, NFTAddress, 'approving', false);

                return tx;
              } catch (e) {
                changeStatusFor(addr, NFTAddress, 'approving', false);
                return Promise.reject(e);
              }
            },
          },
        }),
        {}
      ),
      contractsHandler
    ),
    [chainId, account]
  );

  const setNewAddresseList = React.useCallback(
    (address: string) => {
      if (!active || !account) {
        // prevent state change when not initialized or not connected
        return;
      }

      if (address && !addressesState.includes(address) && !addressesState.includes(address?.toLowerCase())) {
        setStateAddresses((prev) => [...prev, address.toLowerCase()]);
      }
    },
    [addressesState, active, account]
  );

  React.useEffect(() => {
    if (checkOnLoad && active && account && marketplaceSupportedChainIds.includes(chainId)) {
      contractAddresses.forEach((addr: string) => {
        (addressesState || []).forEach((apAddr: string) => {
          // avoid double trigger
          if ((contracts || {})[addr] && (status || {})[addr] && (!status[addr][apAddr] || !status[addr][apAddr].checking)) {
            contracts[addr].isApproved(apAddr).catch((e: Error) => {
              console.error('failed to check approval state', e, { addr, apAddr });
              changeStatusFor(addr, apAddr, 'checking', false);
            });
          }
        });
      });
    }
  }, [active, account, addressesState.length]);

  return (
    <ContractApprovalContext.Provider
      value={{
        status,
        contracts,
        addAddressForApproval: (address: string) => setNewAddresseList(address),
      }}
    >
      {children}
    </ContractApprovalContext.Provider>
  );
};

declare type NftContractApprovalPros = Omit<ContractApprovalProviderProps, 'addresses' | 'contractAddresses'>;

export const NftApprovalProvider = ({ children, ...props }: React.PropsWithChildren<NftContractApprovalPros>) => {
  const { active, account } = useWeb3Application();
  const { NFT_ADDRESS, MARKETPLACE_ADDRESS, AUCTION_ADDRESS } = useAddresses();

  return (
    <Suspense fallback={() => 'loading...'}>
      <ContractApprovalProvider
        contractAddresses={active && account ? [MARKETPLACE_ADDRESS?.toLowerCase(), AUCTION_ADDRESS?.toLowerCase()] : []}
        addresses={active && account ? [NFT_ADDRESS?.toLowerCase()] : []}
        {...props}
      >
        {children}
      </ContractApprovalProvider>
    </Suspense>
  );
};

export default ContractApprovalContext;
