import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';

export const useResponsivenessWith = (sz: number) => {
  const isDownSm = useMediaQuery((t: Theme) => t.breakpoints.down('sm'));
  const isDownMd = useMediaQuery((t: Theme) => t.breakpoints.down('md'));
  const isDownLg = useMediaQuery((t: Theme) => t.breakpoints.down('lg'));
  if (isDownSm) {
    return sz * 0.8;
  }

  if (isDownMd) {
    return sz * 0.9;
  }

  if (isDownLg) {
    return sz * 0.95;
  }

  return sz;
};
