import React from 'react';
import { Outlet } from 'react-router-dom';
import { useProfile } from 'src/hooks';
import ErrorPage from 'src/views/Error';

export default () => {
  const { isAdmin } = useProfile();

  if (isAdmin) {
    return (<Outlet />);
  }

  return (
    <ErrorPage
      inset
      code="403"
      message="Access restricted, only admin allowed"
    />
  );
};
