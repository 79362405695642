import { useLayoutEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { baseURL } from '@elacity-js/lib';
import { useErrorHandler } from 'src/hooks';
import { NETWORKS as availableNetworks } from './constants';
import { NetworkProvisionerHelpers } from './types';

interface NetworkRestrictionParams {
  switchNetwork: NetworkProvisionerHelpers['switchNetwork'];
  preferedNetwork: number;
  connecting?: boolean;
}

/**
 * This hook basically prevents browsing the platform
 * to use a supported network
 * Eg:
 * - /ai/* can support all network
 * - /shops, /map doesn't need any network connection
 * - all marketplace and profile links only support ESC
 */
export default ({ switchNetwork, preferedNetwork, connecting }: NetworkRestrictionParams) => {
  const { handlerError } = useErrorHandler();
  const { chainId, library } = useWeb3React();
  const location = useLocation();

  useLayoutEffect(() => {
    if (chainId && !connecting) {
      // only prcess when user is connected
      // when not conect, ProfileGuard should take care
      if (![baseURL('/marketplace/shops'), baseURL('/home/map')].includes(location.pathname)) {
        // console.log('process restriction rules', location.pathname);

        if (location.pathname?.match(/\/ai\/*/i)) {
          // console.log('rule: [/ai/*] all networks supported');
        } else if (chainId !== preferedNetwork) {
          switchNetwork(preferedNetwork, library).catch(handlerError);
        }
      }
    }
  }, [location.pathname, chainId, connecting]);
};

/**
 * This helper is aimed to detect whether a chain is supported
 * according to browser location
 *
 * @returns
 */
export const useNetworkSupportCheck = () => {
  const location = useLocation();

  const checkNetworkSupport = useCallback((chainId: number): boolean => {
    const networkChains = Object.keys(availableNetworks).map((n) => Number(n));
    if (!networkChains.includes(chainId)) {
      return false;
    }

    // all chains supported on AI District
    if (location.pathname.match(/\/ai/i)) {
      return true;
    }

    // for the rest, only ESC supported
    return [20, 21].includes(chainId);
  }, [location.pathname]);

  return { checkNetworkSupport };
};
