/* eslint-disable max-len */
export default {
  translation: {
    'Welcome to React': 'Welcome to React and react-i18next',
    storageType: {
      ipfs: 'The InterPlanetary File System (IPFS) is a protocol, hypermedia and file sharing peer-to-peer network for storing and sharing data in a distributed file system. Data is public and interoperable.',
      hive: 'HIVE is a private IPFS network on Elacity shielded by a SmartWeb Data Vault. Any asset minted onto HIVE will not be able to be seen on another marketplace. This offers scarcity of availability and the guarantee of creators re-sale royalty fees through Elacity.',
    },
    cinema: {
      saleType: {
        Free: 'Free',
        'Buy once, play always': 'Buy Now',
        'Buy once, play always, resell': 'Buy Now and Resell',
        'Pay Per View (PPV)': 'Pay Per View (PPV)',
        Rental: 'Rental',
      },
    },
  },
};
