/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { LinkProps } from 'react-router-dom';
import {
  ipfsLink, toIpfsGateway, TokenID,
} from '@elacity-js/lib';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { styled, alpha } from '@mui/material/styles';
import {
  Box,
  Input,
  Slide,
  InputAdornment,
  ClickAwayListener,
  IconButton,
  Popper,
  Paper,
  List,
  ListItem as MuiListItem,
  ListSubheader as MuiListSubheader,
  Typography,
  Theme,
  TextField,
} from '@mui/material';
import { PopperProps } from '@mui/material/Popper';
import { ListItemProps } from '@mui/material/ListItem';
import { Appeal } from 'src/components/@material-extend';
import { Spinner } from 'src/components/@ui/Spinner';
import Avatar from 'src/components/@ui/Avatar';
import { BeautifulInput as SearchInput } from 'src/components/@ui/Input';
import Scrollbar from 'src/components/@ui/Scrollbar';
import { RouterLink as Link } from 'src/components/Link';
import { useSearchNamesQuery } from 'src/state/api';
import { displayAddress } from 'src/lib/web3';
import c from 'src/config';

const ListItem = styled(MuiListItem)<ListItemProps & LinkProps & { component: React.ComponentType }>(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const ListSubheader = styled(MuiListSubheader)(({ theme }) => ({
  marginTop: 8,
  // produce glass effect on groupd header
  background: alpha(theme.palette.background.default, 0.85),
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)',
}));

// @todo: find the appropriate type for WithTheme usage
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 999,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: c.layout.APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.shadows[4],
  backdropFilter: 'blur(10px)',
  WebkitBackdropFilter: 'blur(10px)',
  backgroundColor: `${alpha(theme.palette.background.default, 0.8)}`,
  [theme.breakpoints.up('md')]: {
    height: c.layout.APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

interface ResultLineProps {
  label: string;
  imageHash?: string;
  // subtitle?: string;
  address?: string;
  id?: string | number;
  src?: string;
}

const ResultLine: React.FC<ResultLineProps> = ({ imageHash, label, address, src, id }: ResultLineProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Box sx={{ mr: 1 }}>
      <Avatar
        src={src || ipfsLink(`/ipfs/${imageHash}`)} sx={{ width: 36, height: 36 }}
        imgProps={{ crossOrigin: 'anonymous' }}
      />
    </Box>
    <Box>
      <Typography>{label}</Typography>
      <Typography variant="body2" fontFamily="monospace" fontSize="0.7rem" noWrap>
        {displayAddress(address, 12, 4)}
        {id && ` . #${displayAddress(id.toString(), 10, 4)}`}
      </Typography>
    </Box>
  </Box>
);

const useDebounce = (value: string, delay: number): string => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

interface SearchResultProps extends Omit<PopperProps, 'open'> {
  searchTerm: string;
  hasWindow?: boolean;
  onClose?: () => void;
  fullScreen?: boolean;
}

const SearchResultPopover = ({ searchTerm, anchorEl, hasWindow, onClose, fullScreen, ...props }: SearchResultProps) => {
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    if (searchTerm.length >= 3 && Boolean(anchorEl) && hasWindow) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [searchTerm, anchorEl, hasWindow]);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const { data: result, isFetching } = useSearchNamesQuery(searchTerm, {
    skip: !open,
  });

  return (
    <Popper
      style={{ borderRadius: 12 }}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      modifiers={[
        {
          // This part is aimed to set the popover
          // to cover full width (if `fullScreen=true`)
          name: 'applyFullWidth',
          phase: 'beforeWrite',
          enabled: Boolean(fullScreen),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          fn: ({ state }: any) => {
            state.styles.popper.width = '100%';
          },
        },
        {
          name: 'applyWidth',
          phase: 'beforeWrite',
          enabled: !fullScreen,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          fn: ({ state }: any) => {
            const anchorWidth = (anchorEl as HTMLElement)?.clientWidth || 0;
            state.styles.popper.width = anchorWidth > 0 ? `${anchorWidth}px` : 'calc(100vw / 3)';
            state.styles.popper['margin-top'] = '10px';
          },
        },
      ]}
      {...props}
    >
      <Paper
        sx={{
          mt: 0,
          borderRadius: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 1,
          },
          height: {
            xs: 'calc(100vh - 56px - 64px)',
            sm: 'calc(100vh - 64px)',
            md: 'calc(100vh - 56px)',
            lg: 520,
          },
          bgcolor: (t: Theme) => `${alpha(t.palette.background.default, 0.7)}`,
          backdropFilter: 'blur(6px)',
          WebkitBackdropFilter: 'blur(6px)',
        }}
      >
        {isFetching ? (
          <Spinner.Dots sx={{ py: { xs: 4, md: 6, lg: 8 } }} />
        ) : (
          <Scrollbar
            sx={{
              maxHeight: '100vh',
              '& .simplebar-content': {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              },
              overflowX: 'hidden',
            }}
          >
            <List
              sx={{
                position: 'relative',
                overflow: 'auto',
                height: {
                  xs: 'calc(100vh - 56px - 64px)',
                  sm: 'calc(100vh - 64px)',
                  md: 'calc(100vh - 56px)',
                  lg: 520,
                },
                padding: 0,
                '& ul': { padding: 0 },
              }}
              subheader={<li />}
            >
              {(result?.data.accounts || []).length === 0 &&
                (result?.data.tokens || []).length === 0 &&
                (result?.data.collections || []).length === 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: {
                      xs: '80vh',
                      sm: '80vh',
                      md: '80vh',
                      lg: 520,
                    },
                  }}
                >
                  <Appeal title="No result" subtitle="Nothing matches your search" />
                </Box>
              )}
              {(result?.data.accounts || []).length > 0 && (
                <li key="section-accounts">
                  <ul>
                    <ListSubheader>Shops</ListSubheader>
                    {(result?.data.accounts || []).map((item) => (
                      <ListItem
                        component={Link}
                        to={`/marketplace/shops/${item.address}`}
                        key={`item-account-${item._id}`}
                        onClick={handleClose}
                      >
                        <ResultLine
                          label={item.alias}
                          address={item.address}
                          imageHash={item.imageHash || 'QmZzbP9GQwWsziMzbAzNLLVGS8Wx6L8QW7Vort82CUmS4a'}
                        />
                      </ListItem>
                    ))}
                  </ul>
                </li>
              )}
              {(result?.data.collections || []).length > 0 && (
                <li key="section-collections">
                  <ul>
                    <ListSubheader>Collections</ListSubheader>
                    {(result?.data.collections || []).map((item) => (
                      <ListItem
                        component={Link}
                        to={`/marketplace/collections/${item.erc721Address}`}
                        key={`item-collection-${item._id}`}
                        onClick={handleClose}
                      >
                        <ResultLine
                          label={item.collectionName}
                          address={item.erc721Address}
                          imageHash={item.logoImageHash || 'QmXikKAMxSfQBpEqF6a33YibMJpo4mZJod1WSGhSpa18CJ'}
                        />
                      </ListItem>
                    ))}
                  </ul>
                </li>
              )}
              {(result?.data.tokens || []).length > 0 && (
                <li key="section-tokens">
                  <ul>
                    <ListSubheader>NFTs</ListSubheader>
                    {(result?.data.tokens || []).map(({ tokenID, hexTokenID, ...item }) => (
                      <ListItem
                        component={Link}
                        to={`/${item.variant === 'drm'
                          ? 'cinema/view' : 'marketplace'}/${item.contractAddress}/${TokenID.fromObject({
                          tokenID,
                          hexTokenID,
                        }).toString()}`}
                        key={`item-token-${item._id}`}
                        onClick={handleClose}
                      >
                        <ResultLine
                          label={item.name}
                          address={item.contractAddress}
                          src={toIpfsGateway(item.imageURL)}
                          id={TokenID.fromObject({ tokenID, hexTokenID }).toString()}
                        />
                      </ListItem>
                    ))}
                  </ul>
                </li>
              )}
            </List>
          </Scrollbar>
        )}
      </Paper>
    </Popper>
  );
};

const SearchbarWrapper = styled('div')(({ theme }) => ({
  '& .UiPopper-Wrapper [role="tooltip"]': {
    zIndex: 999,
    ...theme.glassy(theme.palette.background.default, 0.82, 4),
  },
}));

export default () => {
  const [isOpen, setOpen] = useState(false);
  const anchorEl = React.useRef<HTMLDivElement>();
  const containerEl = React.useRef<HTMLDivElement>();
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchTerm('');
  };

  return (
    <SearchbarWrapper>
      <div className="UiPopper-Wrapper" ref={containerEl} />
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          {!isOpen && (
            <IconButton onClick={handleOpen}>
              <Icon icon={searchFill} width={20} height={20} />
            </IconButton>
          )}

          <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit ref={anchorEl}>
            <SearchbarStyle>
              <Input
                autoFocus
                fullWidth
                disableUnderline
                placeholder="Search Elacity…"
                startAdornment={(
                  <InputAdornment position="start">
                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                )}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton onClick={handleClose}>
                      <Box component={Icon} icon={closeFill} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </IconButton>
                  </InputAdornment>
                )}
                sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
            </SearchbarStyle>
          </Slide>
          <SearchResultPopover
            anchorEl={anchorEl.current}
            searchTerm={debouncedSearchTerm}
            hasWindow={isOpen}
            container={containerEl.current}
            onClose={handleClose}
            fullScreen
          />
        </div>
      </ClickAwayListener>
    </SearchbarWrapper>
  );
};

export const SearchbarVisible = () => {
  const anchorEl = React.useRef<HTMLDivElement>();
  const containerEl = React.useRef<HTMLDivElement>();
  const [isOpen, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  React.useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      if (!isOpen) {
        setOpen(true);
      }
    } else {
      setOpen(false);
    }
  }, [debouncedSearchTerm]);

  const handleClose = () => {
    setOpen(false);
    setSearchTerm('');
  };

  return (
    <Box sx={{ width: 'calc(100vw / 3)', maxWidth: 480, ml: 1 }}>
      <SearchbarWrapper>
        <div className="UiPopper-Wrapper" ref={containerEl} />
        <SearchInput
          placeholder="Search Elacity"
          size="small"
          fullWidth
          ref={anchorEl}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          InputProps={{
            autoComplete: 'off',
            startAdornment: (
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
            ...(searchTerm.length > 0 && {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClose} color="default">
                    <Box component={Icon} icon={closeFill} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </IconButton>
                </InputAdornment>
              ),
            }),
          }}
        />
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <SearchResultPopover
              anchorEl={anchorEl.current}
              searchTerm={debouncedSearchTerm}
              hasWindow={isOpen}
              container={containerEl.current}
              onClose={handleClose}
            />
          </div>
        </ClickAwayListener>
      </SearchbarWrapper>
    </Box>
  );
};

export { SearchInput };
