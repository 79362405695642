import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ICollection, ApiResponse } from 'src/types';
import { collectionApi } from 'src/state/api/collection';

type NetworkState = {
  collections: Record<string, ICollection>;
  isLoading: boolean;
}

const initialState: NetworkState = {
  collections: {},
  isLoading: true,
};

const slice = createSlice({
  name: 'network',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addMatcher(
      collectionApi.endpoints.fetchCollections.matchFulfilled,
      (state, { payload }: PayloadAction<ApiResponse<ICollection[]>>) => {
        state.collections = payload.data.reduce(
          (res: Record<string, ICollection>, col: ICollection) => ({
            ...res,
            [col.address]: col,
          }), {}
        );
      }
    );
  },
});

export const { reducer } = slice;
