import React from 'react';
import { Box, useTheme } from '@mui/material';
import FormConfig from './FormConfig';
import ProcessingTimeView from '../ProcessingTimeView';

const DrawerConfig = () => {
  const theme = useTheme();

  return (
    <Box sx={{ padding: theme.spacing(0, 1) }}>
      <ProcessingTimeView />
      <Box>
        <FormConfig />
      </Box>
    </Box>
  );
};

export default DrawerConfig;
