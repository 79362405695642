import OAuthAdapter from './oauth.adapter';

interface TwitterOAuthOptions {
  clientId: string;
  codeChallengeMethod?: string;
}

export default class TwitterOAuthAdapter extends OAuthAdapter {
  constructor({ clientId, codeChallengeMethod }: TwitterOAuthOptions) {
    super('twitter', {
      authorizeURLBuilder: {
        // Twitter OAuth2.0: With PKCE https://developer.twitter.com/en/docs/authentication/oauth-2-0/authorization-code
        baseURL: 'https://twitter.com',
        path: '/i/oauth2/authorize',
        query: {
          client_id: clientId,
          redirect_uri: `${window.location.origin}/callback/twitter`,
          // We basically need to retrieve user information,
          // required scopes are defined here
          // https://developer.twitter.com/en/docs/twitter-api/users/lookup/api-reference/get-users-me
          scope: 'tweet.read users.read offline.access',
          response_type: 'code',
          state: 'code',
        },
      },
      pkce: {
        codeChallengeMethod: codeChallengeMethod || 'plain',
      },
    });

    // console.log('oauth.twitter', [this.codeVerifier]);
  }
}
