import { useCallback } from 'react';
import { verifyMessage } from '@ethersproject/wallet';
import type { SignatureResult, Message } from '@elacity-js/lib';
import { defaultSignatureMessage } from 'src/constants';
import { useLazyGetNonceQuery } from 'src/state/api';
import useWeb3Application from './useWeb3Application';

interface UseSignatureOptions {
  onError?: (err: Error) => void
}

export default ({ onError }: UseSignatureOptions) => {
  const { account, signMessage } = useWeb3Application();
  const [getNonce] = useLazyGetNonceQuery();

  return useCallback(
    async (message: Message = defaultSignatureMessage): Promise<SignatureResult> => {
      if (!account) {
        return Promise.reject(new Error('no wallet connected'));
      }

      // get nonce
      const { data: nonce } = await getNonce(account);
      if (typeof nonce !== 'number') {
        onError?.(new Error('failed to get nonce'));
        return {};
      }

      const m = typeof message === 'function'
        ? message(nonce)
        : message;

      const signature = await signMessage(String(m));
      const address = verifyMessage(m, signature);

      return {
        address,
        signature,
      };
    }, [account]
  );
};
