/* eslint-disable max-len */

interface ExtraRpc {
  [chainId: number]: {
    rpcs: string[];
  };
}

const rpcs: ExtraRpc = {
  1: {
    rpcs: [
      'https://eth.public-rpc.com',
    ],
  },
  2: {
    rpcs: [
      'https://node.eggs.cool',
      'https://node.expanse.tech',
    ],
  },
  80001: {
    rpcs: [
      'https://polygon-mumbai.g.alchemy.com/v2/demo',
      'https://rpc.ankr.com/polygon_mumbai',
      'https://rpc-mumbai.maticvigil.com',
      'https://polygontestapi.terminet.io/rpc',
      'https://polygon-testnet.public.blastapi.io',
    ],
  },
  4: {
    rpcs: [
      'https://rpc.ankr.com/eth_rinkeby',
      'https://rinkeby.infura.io/3/9aa3d95b3bc440fa88ea12eaa4456161',
    ],
  },
  5: {
    rpcs: [
      'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
      'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    ],
  },
  3: {
    rpcs: [
      'https://rpc.ankr.com/eth_ropsten',
      'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    ],
  },
  4002: {
    rpcs: [
      'https://rpc.ankr.com/fantom_testnet',
      'https://rpc.testnet.fantom.network/',
      'https://fantom-testnet.public.blastapi.io',
    ],
  },
  43113: {
    rpcs: [
      'https://rpc.ankr.com/avalanche_fuji',
      'https://rpc.ankr.com/avalanche_fuji-c',
      'https://api.avax-test.network/ext/bc/C/rpc',
      'https://avalanchetestapi.terminet.io/ext/bc/C/rpc',
      'https://ava-testnet.public.blastapi.io/ext/bc/C/rpc',
    ],
  },
  11155111: {
    rpcs: [
      'https://nunki.htznr.fault.dev/rpc',
    ],
  },
  56: {
    rpcs: [
      'https://bscrpc.com',
    ],
  },
  97: {
    rpcs: [
      'https://bsctestapi.terminet.io/rpc',
      'https://bsc-testnet.public.blastapi.io',
    ],
  },
  900000: {
    rpcs: [
      'https://api.posichain.org',
      'https://api.s0.posichain.org',
    ],
  },
  43114: {
    rpcs: [
      'https://api.avax.network/ext/bc/C/rpc',
      'https://rpc.ankr.com/avalanche',
      'https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc',
      'https://avalancheapi.terminet.io/ext/bc/C/rpc',
      'https://1rpc.io/avax/c',
      'https://avalanche-evm.publicnode.com',
    ],
  },
  250: {
    rpcs: [
      'https://fantom-mainnet.gateway.pokt.network/v1/lb/62759259ea1b320039c9e7ac',
      'https://rpc.ftm.tools/',
      'https://rpc.ankr.com/fantom',
      'https://rpc.fantom.network',
      'https://rpc2.fantom.network',
      'https://rpc3.fantom.network',
      'https://rpcapi.fantom.network',
      'https://fantom-mainnet.public.blastapi.io',
    ],
  },
  137: {
    rpcs: [
      'https://polygon-rpc.com',
    ],
  },
  25: {
    rpcs: [
      'https://cronos-rpc.heavenswail.one/',
      'https://evm.cronos.org',
      'https://cronosrpc-1.xstaking.sg/',
      'https://cronos-rpc.elk.finance/',
    ],
  },
  338: {
    rpcs: [
      'https://evm-t3.cronos.org/',
    ],
  },
  42161: {
    rpcs: [
      'https://arb-mainnet.g.alchemy.com/v2/demo',
      'https://arb1.arbitrum.io/rpc',
      'https://rpc.ankr.com/arbitrum',
      'https://1rpc.io/arb',
    ],
  },
  421613: {
    rpcs: [
      'https://arb-goerli.g.alchemy.com/v2/demo',
    ],
  },
  42170: {
    rpcs: [
      'https://nova.arbitrum.io/rpc',
    ],
  },
  8217: {
    rpcs: [
      'https://public-node-api.klaytnapi.com/v1/cypress',
      'https://klaytn01.fandom.finance',
      'https://klaytn02.fandom.finance',
      'https://klaytn03.fandom.finance',
      'https://klaytn04.fandom.finance',
      'https://klaytn05.fandom.finance',
      'https://cypress.fandom.finance/archive',
    ],
  },
  1666600000: {
    rpcs: [
      'https://harmony-0-rpc.gateway.pokt.network',
      'https://api.harmony.one',
      'https://a.api.s0.t.hmny.io',
      'https://api.s0.t.hmny.io',
      'https://rpc.ankr.com/harmony',
      'https://harmony-mainnet.chainstacklabs.com',
    ],
  },
  1313161554: {
    rpcs: [
      'https://mainnet.aurora.dev',
    ],
  },
  4181: {
    rpcs: [
      'https://rpc1.phi.network',
    ],
  },
  128: {
    rpcs: [
      'https://http-mainnet-node.huobichain.com',
      'https://http-mainnet.hecochain.com',
      'https://pub001.hg.network/rpc',
      'https://hecoapi.terminet.io/rpc',
    ],
  },
  256: {
    rpcs: [
      'https://hecotestapi.terminet.io/rpc',
    ],
  },
  42220: {
    rpcs: [
      'https://forno.celo.org',
      'https://rpc.ankr.com/celo',
    ],
  },
  10: {
    rpcs: [
      'https://opt-mainnet.g.alchemy.com/v2/demo',
      'https://mainnet.optimism.io/',
      'https://optimism-mainnet.public.blastapi.io',
      'https://rpc.ankr.com/optimism',
      'https://1rpc.io/op',
    ],
  },
  420: {
    rpcs: [
      'https://opt-goerli.g.alchemy.com/v2/demo',
    ],
  },
  1088: {
    rpcs: [
      'https://andromeda.metis.io/?owner=1088',
    ],
  },
  1246: {
    rpcs: [
      'https://rpc-cnx.omplatform.com',
    ],
  },
  100: {
    rpcs: [
      'https://rpc.gnosischain.com',
      'https://xdai-rpc.gateway.pokt.network',
      'https://xdai-archive.blockscout.com',
      'https://gnosis-mainnet.public.blastapi.io',
      'https://rpc.ankr.com/gnosis',
      'https://rpc.ap-southeast-1.gateway.fm/v1/gnosis/non-archival/mainnet',
    ],
  },
  10200: {
    rpcs: [
      'https://rpc.chiadochain.net',
      'https://rpc.eu-central-2.gateway.fm/v3/gnosis/archival/chiado',
    ],
  },
  1231: {
    rpcs: [
      'https://ultron-rpc.net',
    ],
  },
  1285: {
    rpcs: [
      'https://moonriver.api.onfinality.io/rpc?apikey=673e1fae-c9c9-4c7f-a3d5-2121e8274366',
      'https://moonriver.api.onfinality.io/public',
      'https://moonriver.public.blastapi.io',
    ],
  },
  361: {
    rpcs: [
      'https://eth-rpc-api.thetatoken.org/rpc',
    ],
  },
  42262: {
    rpcs: [
      'https://emerald.oasis.dev/',
    ],
  },
  40: {
    rpcs: [
      'https://mainnet.telos.net/evm',
      'https://rpc1.eu.telos.net/evm',
      'https://rpc1.us.telos.net/evm',
      'https://rpc2.us.telos.net/evm',
      'https://api.kainosbp.com/evm',
      'https://rpc2.eu.telos.net/evm',
      'https://evm.teloskorea.com/evm',
      'https://rpc2.teloskorea.com/evm',
      'https://rpc01.us.telosunlimited.io/evm',
      'https://rpc02.us.telosunlimited.io/evm',
      'https://rpc.telosarabia.net/evm',
    ],
  },
  32659: {
    rpcs: [
      'https://mainnet.anyswap.exchange',
      'https://mainway.freemoon.xyz/gate',
      'https://fsn.dev/api',
      'https://mainnet.fusionnetwork.io',
    ],
  },
  1284: {
    rpcs: [
      'https://rpc.api.moonbeam.network',
      'https://moonbeam.public.blastapi.io',
      'https://rpc.ankr.com/moonbeam',
      'https://1rpc.io/glmr',
    ],
  },
  30: {
    rpcs: [
      'https://public-node.rsk.co',
    ],
  },
  4689: {
    rpcs: [
      'https://iotex-mainnet.gateway.pokt.network/v1/lb/6176f902e19001003499f492',
      'https://rpc.ankr.com/iotex',
      'https://babel-api.mainnet.iotex.io',
      'https://babel-api.mainnet.iotex.one',
      'https://pokt-api.iotex.io',
    ],
  },
  66: {
    rpcs: [
      'https://exchainrpc.okex.org',
    ],
  },
  288: {
    rpcs: [
      'https://mainnet.boba.network/',
      'https://boba-mainnet.gateway.pokt.network/v1/lb/623ad21b20354900396fed7f',
      'https://lightning-replica.boba.network/',
    ],
  },
  321: {
    rpcs: [
      'https://rpc-mainnet.kcc.network',
      'https://kcc.mytokenpocket.vip',
      'https://public-rpc.blockpi.io/http/kcc',
      'https://kcc.getblock.io/mainnet/?api_key=cd77b9bd-ce1c-4a91-89bb-ff2e2c1206c5',
    ],
  },
  888: {
    rpcs: [
      'https://gwan-ssl.wandevs.org:56891',
    ],
  },
  106: {
    rpcs: [
      'https://evmexplorer.velas.com/rpc',
      'https://velas-mainnet.rpcfast.com?api_key=S3X5aFCCW9MobqVatVZX93fMtWCzff0MfRj9pvjGKSiX5Nas7hz33HwwlrT5tXRM',
    ],
  },
  10000: {
    rpcs: [
      'https://smartbch.fountainhead.cash/mainnet',
      'https://smartbch.greyh.at',
      'https://global.uat.cash',
      'https://rpc.uatvo.com',
    ],
  },
  19: {
    rpcs: [
      'https://songbird.towolabs.com/rpc',
    ],
  },
  122: {
    rpcs: [
      'https://fuse-rpc.gateway.pokt.network/',
      'https://rpc.fuse.io',
      'https://fuse-mainnet.chainstacklabs.com',
    ],
  },
  336: {
    rpcs: [
      'https://rpc.shiden.astar.network:8545/',
      'https://shiden.public.blastapi.io',
    ],
  },
  592: {
    rpcs: [
      'https://astar-mainnet.g.alchemy.com/v2/demo',
      'https://rpc.astar.network:8545',
      'https://astar.public.blastapi.io',
      'https://evm.astar.network/',
      'https://1rpc.io/astr',
    ],
  },
  71394: {
    rpcs: [
      'https://mainnet.godwoken.io/rpc/eth-wallet',
    ],
  },
  0: {
    rpcs: [
      'https://rpc.kardiachain.io/',
    ],
  },
  52: {
    rpcs: [
      'https://rpc.coinex.net/',
      'https://rpc1.coinex.net/',
      'https://rpc2.coinex.net/',
      'https://rpc3.coinex.net/',
      'https://rpc4.coinex.net/',
    ],
  },
  820: {
    rpcs: [
      'https://rpc.callisto.network',
      'https://clo-geth.0xinfra.com/',
    ],
  },
  108: {
    rpcs: [
      'https://mainnet-rpc.thundercore.com',
    ],
  },
  20: {
    rpcs: [
      'https://api.ela.city/esc',
      // 'https://api2.elastos.io/esc',
      // 'https://api2.elastos.net/esc',
    ],
  },
  21: {
    rpcs: [
      'https://api2-testnet.elastos.net/eth',
      'https://api2-testnet.elastos.io/esc',
      'https://api-testnet.elastos.io/esc',
    ],
  },
  82: {
    rpcs: [
      'https://rpc.meter.io',
    ],
  },
  5551: {
    rpcs: [
      'https://l2.nahmii.io/',
    ],
  },
  88: {
    rpcs: [
      'https://rpc.tomochain.com',
    ],
  },
  246: {
    rpcs: [
      'https://rpc.energyweb.org',
    ],
  },
  57: {
    rpcs: [
      'https://rpc.syscoin.org',
      'https://rpc.ankr.com/syscoin',
    ],
  },
  8: {
    rpcs: [
      'https://rpc.octano.dev',
    ],
  },
  5050: {
    rpcs: [
      'https://rpc.liquidchain.net/',
      'https://rpc.xlcscan.com/',
    ],
  },
  333999: {
    rpcs: [
      'https://rpc.polis.tech',
    ],
  },
  55: {
    rpcs: [
      'https://rpc-1.zyx.network/',
      'https://rpc-2.zyx.network/',
      'https://rpc-3.zyx.network/',
      'https://rpc-5.zyx.network/',
    ],
  },
  269: {
    rpcs: [
      'https://hpbnode.com',
    ],
  },
  60: {
    rpcs: [
      'https://rpc.gochain.io',
    ],
  },
  11297108109: {
    rpcs: [
      'https://palm-mainnet.infura.io/v3/3a961d6501e54add9a41aa53f15de99b',
      'https://palm-mainnet.public.blastapi.io',
    ],
  },
  7: {
    rpcs: [
      'https://rpc.dome.cloud',
    ],
  },
  11: {
    rpcs: [
      'https://api.metadium.com/dev',
    ],
  },
  14: {
    rpcs: [],
  },
  15: {
    rpcs: [
      'https://prenet.diode.io:8443/',
    ],
  },
  17: {
    rpcs: [
      'https://rpc.thaifi.com',
    ],
  },
  24: {
    rpcs: [
      'https://node-mainnet.dithereum.io  ',
    ],
  },
  27: {
    rpcs: [
      'https://rpc.shibachain.net',
    ],
  },
  29: {
    rpcs: [
      'https://rpc.genesisl1.org',
    ],
  },
  33: {
    rpcs: [],
  },
  35: {
    rpcs: [
      'https://rpc.tbwg.io',
    ],
  },
  38: {
    rpcs: [],
  },
  44: {
    rpcs: [],
  },
  50: {
    rpcs: [
      'https://xdcrpc.com',
      'https://erpc.xinfin.network',
      'https://rpc.xinfin.network',
      'https://rpc1.xinfin.network',
    ],
  },
  58: {
    rpcs: [
      'https://dappnode1.ont.io:10339',
      'https://dappnode2.ont.io:10339',
      'https://dappnode3.ont.io:10339',
      'https://dappnode4.ont.io:10339',
    ],
  },
  59: {
    rpcs: [
      'https://api.eosargentina.io',
      'https://api.metahub.cash',
    ],
  },
  61: {
    rpcs: [
      'https://blockscout.com/etc/mainnet/api/eth-rpc',
      'https://www.ethercluster.com/etc',
      'https://etc.etcdesktop.com',
      'https://etc.mytokenpocket.vip',
      'https://besu-de.etc-network.info',
      'https://geth-de.etc-network.info',
      'https://besu-at.etc-network.info',
      'https://geth-at.etc-network.info',
      'https://rpc.etcplanets.com',
    ],
  },
  2020: {
    rpcs: [
      'https://api.roninchain.com/rpc',
    ],
  },
  64: {
    rpcs: [],
  },
  68: {
    rpcs: [],
  },
  74: {
    rpcs: [
      'https://idchain.one/rpc/',
    ],
  },
  76: {
    rpcs: [],
  },
  77: {
    rpcs: [
      'https://sokol.poa.network',
    ],
  },
  78: {
    rpcs: [
      'https://ethnode.primusmoney.com/mainnet',
    ],
  },
  80: {
    rpcs: [
      'website:https://genechain.io/en/index.html',
    ],
  },
  86: {
    rpcs: [
      'https://evm.gatenode.cc',
    ],
  },
  87: {
    rpcs: [
      'https://rpc.novanetwork.io:9070',
      'https://dev.rpc.novanetwork.io/',
      'http://dataseed-0.rpc.novanetwork.io:8545/',
      'http://dataseed-1.rpc.novanetwork.io:8545/',
      'http://dataseed-2.rpc.novanetwork.io:8545/',
      'http://dataseed-3.rpc.novanetwork.io:8545/',
      'http://dataseed-4.rpc.novanetwork.io:8545/',
      'http://dataseed-5.rpc.novanetwork.io:8545/',
      'http://dataseed-6.rpc.novanetwork.io:8545/',
      'http://dataseed-f.rpc.novanetwork.io:8545/',
    ],
  },
  90: {
    rpcs: [
      'https://s0.garizon.net/rpc',
    ],
  },
  91: {
    rpcs: [
      'https://s1.garizon.net/rpc',
    ],
  },
  92: {
    rpcs: [
      'https://s2.garizon.net/rpc',
    ],
  },
  93: {
    rpcs: [
      'https://s3.garizon.net/rpc',
    ],
  },
  96: {
    rpcs: [
      'https://rpc.nextsmartchain.com',
    ],
  },
  99: {
    rpcs: [
      'https://core.poanetwork.dev',
    ],
  },
  101: {
    rpcs: [],
  },
  111: {
    rpcs: [
      'https://rpc.etherlite.org',
    ],
  },
  123: {
    rpcs: [
      'https://rpc.fusespark.io',
    ],
  },
  124: {
    rpcs: [],
  },
  126: {
    rpcs: [
      'https://rpc.mainnet.oychain.io',
      'https://rpc.oychain.io',
    ],
  },
  127: {
    rpcs: [],
  },
  142: {
    rpcs: [
      'https://rpc.prodax.io',
    ],
  },
  163: {
    rpcs: [
      'https://node.mainnet.lightstreams.io',
    ],
  },
  186: {
    rpcs: [
      'https://rpc.seelen.pro/',
      'https://rpc.seele-n.com/',
      'https://rpc.dsnleel.link/',
    ],
  },
  188: {
    rpcs: [
      'https://mainnet.bmcchain.com/',
    ],
  },
  199: {
    rpcs: [
      'https://rpc.bittorrentchain.io/',
    ],
  },
  200: {
    rpcs: [
      'https://arbitrum.xdaichain.com',
    ],
  },
  70: {
    rpcs: [
      'https://http-mainnet.hoosmartchain.com',
    ],
  },
  211: {
    rpcs: [],
  },
  222: {
    rpcs: [
      'https://blockchain-api-mainnet.permission.io/rpc',
    ],
  },
  258: {
    rpcs: [],
  },
  262: {
    rpcs: [
      'https://sur.nilin.org',
    ],
  },
  333: {
    rpcs: [],
  },
  369: {
    rpcs: [],
  },
  385: {
    rpcs: [],
  },
  416: {
    rpcs: [
      'https://rpc.sx.technology',
    ],
  },
  499: {
    rpcs: [],
  },
  512: {
    rpcs: [
      'https://rpc.acuteangle.com',
    ],
  },
  555: {
    rpcs: [
      'https://rpc.velaverse.io',
    ],
  },
  558: {
    rpcs: [
      'https://rpc.tao.network',
    ],
  },
  686: {
    rpcs: [
      'https://eth-rpc-karura.aca-api.network',
      'https://rpc.evm.karura.network',
    ],
  },
  707: {
    rpcs: [],
  },
  777: {
    rpcs: [
      'https://node.cheapeth.org/rpc',
    ],
  },
  787: {
    rpcs: [
      'https://eth-rpc-acala.aca-api.network',
      'https://rpc.evm.acala.network',
    ],
  },
  803: {
    rpcs: [],
  },
  880: {
    rpcs: [
      'https://mainnet.ambroschain.com',
    ],
  },
  977: {
    rpcs: [],
  },
  998: {
    rpcs: [
      'https://rpc.luckynetwork.org',
    ],
  },
  1010: {
    rpcs: [
      'https://meta.evrice.com',
    ],
  },
  1012: {
    rpcs: [
      'https://global.rpc.mainnet.newtonproject.org',
    ],
  },
  1022: {
    rpcs: [],
  },
  1024: {
    rpcs: [
      'https://rpc-ivy.clover.finance',
      'https://rpc-ivy-2.clover.finance',
      'https://rpc-ivy-3.clover.finance',
    ],
  },
  1030: {
    rpcs: [
      'https://evm.confluxrpc.com',
      'https://conflux-espace-public.unifra.io',
    ],
  },
  1139: {
    rpcs: [
      'https://mathchain.maiziqianbao.net/rpc',
    ],
  },
  1197: {
    rpcs: [],
  },
  1202: {
    rpcs: [
      'https://rpc.cadaut.com',
    ],
  },
  1213: {
    rpcs: [
      'https://dataseed.popcateum.org',
    ],
  },
  1214: {
    rpcs: [],
  },
  1280: {
    rpcs: [
      'https://nodes.halo.land',
    ],
  },
  1287: {
    rpcs: [
      'https://rpc.testnet.moonbeam.network',
      'https://moonbase-alpha.public.blastapi.io',
    ],
  },
  1288: {
    rpcs: [],
  },
  1618: {
    rpcs: [
      'https://send.catechain.com',
    ],
  },
  1620: {
    rpcs: [],
  },
  1657: {
    rpcs: [
      'https://dataseed1.btachain.com/',
    ],
  },
  1987: {
    rpcs: [
      'https://lb.rpc.egem.io',
    ],
  },
  2000: {
    rpcs: [
      'https://rpc.dogechain.dog',
      'https://rpc-us.dogechain.dog',
      'https://rpc-sg.dogechain.dog',
      'https://rpc.dogechain.dog',
      'https://rpc01-sg.dogechain.dog',
      'https://rpc02-sg.dogechain.dog',
      'https://rpc03-sg.dogechain.dog',
      'https://dogechain.ankr.com',
      'https://dogechain-sj.ankr.com',
    ],
  },
  2021: {
    rpcs: [
      'https://mainnet2.edgewa.re/evm',
      'https://mainnet3.edgewa.re/evm',
    ],
  },
  2025: {
    rpcs: [
      'https://mainnet.rangersprotocol.com/api/jsonrpc',
    ],
  },
  2077: {
    rpcs: [
      'http://rpc.qkacoin.org:8548',
    ],
  },
  2100: {
    rpcs: [
      'https://api.ecoball.org/ecoball/',
    ],
  },
  2213: {
    rpcs: [
      'https://seed4.evanesco.org:8546',
    ],
  },
  2222: {
    rpcs: [
      'https://evm.kava.io',
    ],
  },
  2612: {
    rpcs: [
      'https://api.ezchain.com/ext/bc/C/rpc',
    ],
  },
  3690: {
    rpcs: [
      'https://rpc1.bittexscan.info',
    ],
  },
  5197: {
    rpcs: [
      'https://mainnet.eraswap.network',
    ],
  },
  5315: {
    rpcs: [],
  },
  5869: {
    rpcs: [
      'https://proxy.wegochain.io',
    ],
  },
  6626: {
    rpcs: [
      'https://http-mainnet.chain.pixie.xyz',
    ],
  },
  7341: {
    rpcs: [
      'https://rpc.shyft.network/',
    ],
  },
  8000: {
    rpcs: [
      'https://dataseed.testnet.teleport.network',
    ],
  },
  8723: {
    rpcs: [
      'https://mainnet-web3.wolot.io',
    ],
  },
  8995: {
    rpcs: [
      'https://core.bloxberg.org',
    ],
  },
  9001: {
    rpcs: [
      'https://eth.bd.evmos.org:8545/',
      'https://evmos-mainnet.gateway.pokt.network/v1/lb/627586ddea1b320039c95205',
      'https://evmos-json-rpc.stakely.io',
      'https://jsonrpc-evmos-ia.cosmosia.notional.ventures',
      'https://json-rpc.evmos.blockhunters.org',
      'https://evmos-json-rpc.agoranodes.com',
      'https://evmos-mainnet.public.blastapi.io',
      'https://evmos-evm.publicnode.com',
      'https://jsonrpc-evmos.goldenratiostaking.net',
    ],
  },
  836542336838601: {
    rpcs: [
      'https://mainnet-api.skalenodes.com/v1/fit-betelgeuse',
    ],
  },
  9100: {
    rpcs: [
      'rpcWorking:false',
    ],
  },
  10101: {
    rpcs: [
      'https://eu.mainnet.xixoio.com',
    ],
  },
  11111: {
    rpcs: [
      'https://api.trywagmi.xyz/rpc',
    ],
  },
  12052: {
    rpcs: [
      'https://zerorpc.singularity.gold',
    ],
  },
  13381: {
    rpcs: [
      'https://rpc.phoenixplorer.com/',
    ],
  },
  16000: {
    rpcs: [],
  },
  19845: {
    rpcs: [
      'https://seed.btcix.org/rpc',
    ],
  },
  21816: {
    rpcs: [
      'https://seed.omlira.com',
    ],
  },
  24484: {
    rpcs: [],
  },
  24734: {
    rpcs: [
      'https://node1.mintme.com',
    ],
  },
  31102: {
    rpcs: [
      'rpcWorking:false',
    ],
  },
  32520: {
    rpcs: [
      'https://rpc.icecreamswap.com',
      'https://rpc-bitgert-vefi.com',
    ],
  },
  39797: {
    rpcs: [
      'https://nodeapi.energi.network',
      'https://explorer.energi.network/api/eth-rpc',
    ],
  },
  42069: {
    rpcs: [
      'rpcWorking:false',
    ],
  },
  43110: {
    rpcs: [
      'rpcWorking:false',
    ],
  },
  45000: {
    rpcs: [
      'https://rpc.autobahn.network',
    ],
  },
  47805: {
    rpcs: [
      'https://rpc.rei.network',
    ],
  },
  55555: {
    rpcs: [
      'https://rei-rpc.moonrhythm.io',
    ],
  },
  63000: {
    rpcs: [
      'https://rpc.ecredits.com',
    ],
  },
  70000: {
    rpcs: [],
  },
  70001: {
    rpcs: [
      'https://proxy1.thinkiumrpc.net/',
    ],
  },
  70002: {
    rpcs: [
      'https://proxy2.thinkiumrpc.net/',
    ],
  },
  70103: {
    rpcs: [
      'https://proxy103.thinkiumrpc.net/',
    ],
  },
  99999: {
    rpcs: [
      'https://rpc.uschain.network',
    ],
  },
  100000: {
    rpcs: [],
  },
  100001: {
    rpcs: [
      'http://eth-jrpc.mainnet.quarkchain.io:39000',
    ],
  },
  100002: {
    rpcs: [
      'http://eth-jrpc.mainnet.quarkchain.io:39001',
    ],
  },
  100003: {
    rpcs: [
      'http://eth-jrpc.mainnet.quarkchain.io:39002',
    ],
  },
  100004: {
    rpcs: [
      'http://eth-jrpc.mainnet.quarkchain.io:39003',
    ],
  },
  100005: {
    rpcs: [
      'http://eth-jrpc.mainnet.quarkchain.io:39004',
    ],
  },
  100006: {
    rpcs: [
      'http://eth-jrpc.mainnet.quarkchain.io:39005',
    ],
  },
  100007: {
    rpcs: [
      'http://eth-jrpc.mainnet.quarkchain.io:39006',
    ],
  },
  100008: {
    rpcs: [
      'http://eth-jrpc.mainnet.quarkchain.io:39007',
    ],
  },
  108801: {
    rpcs: [
      'rpcWorking:false',
    ],
  },
  110000: {
    rpcs: [
      'rpcWorking:false',
    ],
  },
  110001: {
    rpcs: [
      'http://eth-jrpc.devnet.quarkchain.io:39900',
    ],
  },
  110002: {
    rpcs: [
      'http://eth-jrpc.devnet.quarkchain.io:39901',
    ],
  },
  110003: {
    rpcs: [
      'http://eth-jrpc.devnet.quarkchain.io:39902',
    ],
  },
  110004: {
    rpcs: [
      'http://eth-jrpc.devnet.quarkchain.io:39903',
    ],
  },
  110005: {
    rpcs: [
      'http://eth-jrpc.devnet.quarkchain.io:39904',
    ],
  },
  110006: {
    rpcs: [
      'http://eth-jrpc.devnet.quarkchain.io:39905',
    ],
  },
  110007: {
    rpcs: [
      'http://eth-jrpc.devnet.quarkchain.io:39906',
    ],
  },
  110008: {
    rpcs: [
      'http://eth-jrpc.devnet.quarkchain.io:39907',
    ],
  },
  200625: {
    rpcs: [
      'https://boot2.akroma.org/',
    ],
  },
  201018: {
    rpcs: [
      'https://openapi.alaya.network/rpc',
    ],
  },
  210425: {
    rpcs: [],
  },
  246529: {
    rpcs: [
      'https://rpc.sigma1.artis.network',
    ],
  },
  281121: {
    rpcs: [
      'rpcWorking:false',
    ],
  },
  888888: {
    rpcs: [
      'https://infragrid.v.network/ethereum/compatible',
    ],
  },
  955305: {
    rpcs: [
      'https://host-76-74-28-226.contentfabric.io/eth/',
    ],
  },
  1313114: {
    rpcs: [
      'https://rpc.ethoprotocol.com',
    ],
  },
  1313500: {
    rpcs: [
      'https://rpc.xerom.org',
    ],
  },
  7762959: {
    rpcs: [],
  },
  13371337: {
    rpcs: [],
  },
  18289463: {
    rpcs: [],
  },
  20181205: {
    rpcs: [
      'https://hz.rpc.qkiscan.cn',
    ],
  },
  35855456: {
    rpcs: [
      'https://node.joys.digital',
    ],
  },
  61717561: {
    rpcs: [
      'https://c.onical.org',
    ],
  },
  192837465: {
    rpcs: [
      'https://mainnet.gather.network',
    ],
  },
  245022926: {
    rpcs: [
      'https://proxy.devnet.neonlabs.org/solana',
    ],
  },
  245022934: {
    rpcs: [
      'rpcWorking:false',
    ],
  },
  311752642: {
    rpcs: [
      'https://mainnet-rpc.oneledger.network',
    ],
  },
  356256156: {
    rpcs: [
      'https://testnet.gather.network',
    ],
  },
  486217935: {
    rpcs: [
      'https://devnet.gather.network',
    ],
  },
  1122334455: {
    rpcs: [],
  },
  1313161556: {
    rpcs: [
      'http://localhost:8545',
    ],
  },
  53935: {
    rpcs: [
      'https://avax-dfk.gateway.pokt.network/v1/lb/6244818c00b9f0003ad1b619/ext/bc/q2aTwKuyzgs8pynF7UXBZCU7DejbZbZ6EUyHr3JQzYgwNPUPi/rpc',
    ],
  },
  1666600001: {
    rpcs: [
      'https://s1.api.harmony.one',
    ],
  },
  1666600002: {
    rpcs: [
      'https://s2.api.harmony.one',
    ],
  },
  197710212030: {
    rpcs: [
      'https://rpc.ntity.io',
    ],
  },
  6022140761023: {
    rpcs: [
      'https://molereum.jdubedition.com',
    ],
  },
  79: {
    rpcs: [
      'https://dataserver-us-1.zenithchain.co/',
      'https://dataserver-asia-3.zenithchain.co/',
      'https://dataserver-asia-4.zenithchain.co/',
      'https://dataserver-asia-2.zenithchain.co/',
    ],
  },
  1506: {
    rpcs: [
      'https://mainnet.sherpax.io/rpc',
    ],
  },
  512512: {
    rpcs: [
      'https://galaxy.block.caduceus.foundation',
    ],
  },
  256256: {
    rpcs: [
      'https://mainnet.block.caduceus.foundation',
    ],
  },
  7777: {
    rpcs: [
      'https://testnet1.rotw.games',
      'https://testnet2.rotw.games',
      'https://testnet3.rotw.games',
      'https://testnet4.rotw.games',
      'https://testnet5.rotw.games',
    ],
  },
  103090: {
    rpcs: [
      'https://evm.cryptocurrencydevs.org',
      'https://rpc.crystaleum.org',
    ],
  },
  420666: {
    rpcs: [
      'https://testnet.kekchain.com',
    ],
  },
  1515: {
    rpcs: [
      'https://beagle.chat/eth',
    ],
  },
  10067275: {
    rpcs: [
      'https://testnet.plian.io/child_test',
    ],
  },
  16658437: {
    rpcs: [
      'https://testnet.plian.io/testnet',
    ],
  },
  2099156: {
    rpcs: [
      'https://mainnet.plian.io/pchain',
    ],
  },
  8007736: {
    rpcs: [
      'https://mainnet.plian.io/child_0',
    ],
  },
  970: {
    rpcs: [
      'https://rpc.mainnet.computecoin.com',
    ],
  },
  971: {
    rpcs: [
      'https://beta-rpc.mainnet.computecoin.com',
    ],
  },
  10086: {
    rpcs: [
      'http://geth.free.idcfengye.com',
    ],
  },
  5177: {
    rpcs: [
      'https://mainnet-rpc.tlxscan.com',
    ],
  },
  10248: {
    rpcs: [
      'https://node.0xtchain.com',
    ],
  },
};

// eslint-disable-next-line no-underscore-dangle
const rpcArray = Object.entries(rpcs).filter(
  ([chainId, rpcData]) => Number(chainId) > 0 && rpcData?.rpcs?.length > 0
).map(
  ([chainId, rpcData]) => {
    const rpcPerChain = rpcData?.rpcs.filter(
      (url: string) => ![
        // RPC to exclude due to unstability
      ].includes(url)
    );

    switch (Number(chainId)) {
      // enforce to use some RPC for some chain
      case 128: return {
        chainId: 128,
        rpc: 'https://http-mainnet.hecochain.com',
      };
      default: return {
        chainId: Number(chainId),
        rpc: String(rpcPerChain[Math.round(Math.random() * (rpcPerChain.length - 1))]).trim(),
      };
    }
  }
);

export const rpcMap = Object.fromEntries(
  rpcArray.map(
    ({ chainId, rpc }) => [chainId, rpc]
  )
);

export const getChainIds = () => rpcArray.map(
  ({ chainId }) => chainId
);
