/* eslint-disable max-len */
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Price from 'src/components/@ui/Price';

interface FeesDisplatchProps {
  payToken: string;
  platformFee: number;
  royalty: number;
  royaltyValue: number;
  earningValue: number;
}

export default ({ payToken, platformFee, royalty, royaltyValue, earningValue }: FeesDisplatchProps) => (
  <TableContainer component={Box}>
    <Table sx={{ minWidth: '100%' }} size="small">
      <TableBody>
        <TableRow>
          <TableCell>Seller</TableCell>
          <TableCell align="right">
            <Price.View value={earningValue} size={14} currency={payToken} precision={6} hideUSD />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Royalties (
            {royalty}
            %)
            <Tooltip title="Royalties represent the payment percentage set and made to the original creator of a digital asset.">
              <IconButton disableRipple size="small" sx={{ mt: -1, bgcolor: 'transparent' }}>
                <HelpOutlineIcon sx={{ width: '0.9rem', height: '0.9rem' }} />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell align="right">
            <Price.View value={royaltyValue} size={14} currency={payToken} precision={6} hideUSD />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Platform Fee (2%)
            <Tooltip title="Elacity tax helps keeps our transportation systems and city growing. In Web2, business models evolve around collecting and selling data to advertisers. In Web3, you own all the value you create and protocols which offer exchange adopt business models with small service charge fees.">
              <IconButton disableRipple size="small" sx={{ mt: -1, bgcolor: 'transparent' }}>
                <HelpOutlineIcon sx={{ width: '0.9rem', height: '0.9rem' }} />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell align="right">
            <Price.View value={platformFee} size={14} currency={payToken} precision={6} hideUSD />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);
