/* eslint-disable no-case-declarations */
import { NetworkProvisionerState } from './types';

declare type SetAction = {
  type: 'SET';
  payload: Partial<NetworkProvisionerState>;
}

declare type SelectNetworkAction = {
  type: 'SELECT';
  payload: number;
}

declare type Action = SetAction | SelectNetworkAction;

export default (state: NetworkProvisionerState, action: Action): NetworkProvisionerState => {
  // console.log('network.reducer', { state, action });
  switch (action.type) {
    case 'SELECT':
      return { ...state, selectedChainId: action.payload };
    default:
      const additionalPayload: Partial<NetworkProvisionerState> = {};
      if (action.payload.connecting) {
        if (!action.payload?.message) {
          additionalPayload.message = 'Connecting wallet...';
        }
      } else if (action.payload.connected) {
        additionalPayload.message = 'Connected successfuly!';
      }
      return { ...state, ...(action.payload || {}), ...additionalPayload };
  }
};
