import { ApiResponse, TokenID } from 'src/types';
import { api } from './query.base';

interface SearchResult {
  accounts?: {
    _id: string;
    address: string;
    imageHash?: string;
    alias: string;
  }[];
  bundles?: unknown[];
  collections?: {
    _id: string;
    collectionName: string;
    erc721Address: string;
    logoImageHash?: string;
  }[];
  tokens?: {
    _id: string;
    contractAddress: string;
    imageURL: string;
    name: string;
    thumnailPath?: string;
    tokenURI: string;
    tokenID?: TokenID;
    hexTokenID: string;
    variant: string;
  }[];
}

const searchApi = api
  .injectEndpoints({
    endpoints: (builder) => ({
      searchNames: builder.query<ApiResponse<SearchResult>, string>({
        query: (name: string) => ({
          url: '/info/searchNames',
          method: 'post',
          body: { name },
        }),
      }),
    }),
  });

export { searchApi };

export const {
  useSearchNamesQuery,
} = searchApi;
