import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DIdentity } from 'src/lib/did/types';
import { JsonLocalStorage } from 'src/lib/storage';
import type { IUser } from '../../types';
import type { RootState } from '../store';
import { useSelector, useDispatch } from '../store';
import type { AuthResponse } from '../api';
import { authApi } from '../api/auth';
import { accountApi, accountPrivateApi } from '../api/account';
import type { Metadata, UserState } from './types';

export const KEY = 'accounts___data';
export const JWT_VAULT = '__ela_user_vault';

export interface AddEncryptionKeyPayload {
  account: string;
  data: string;
}

const afterLogin = (state: UserState, { payload, meta }: PayloadAction<AuthResponse, string, Metadata<{address?: string}>>) => {
  console.log('signed successfully...', { payload, meta });
  state.token = payload.token;
  state.loaded = true;
  state.isRequestingSigning = false;
  if (meta.arg?.originalArgs?.address) {
    state.address = meta.arg.originalArgs.address;
  }
  if (payload.isAdmin) {
    state.isAdmin = true;
  }
};

const slice = createSlice({
  name: 'user',
  initialState: {
    data: null,
    token: null,
    expiresIn: null,
    createdAt: null,
    loaded: false,
    isRequestingSigning: false,
    address: '',
    followings: [],
    dids: {},
    vault: {},
  } as UserState,
  reducers: {
    setEncryptionKey: (state, action: PayloadAction<AddEncryptionKeyPayload>) => {
      const { account, data } = action.payload;
      state[account] = {
        ...state[account],
        encryptionKey: data,
      };

      localStorage.setItem(KEY, JSON.stringify(state));
    },
    setUserToken: (state: UserState, action: PayloadAction<{
      account: string;
      token: string;
      expiresIn?: number;
      createdAt?: number;
      isAdmin?: boolean
    }>) => {
      state.address = action.payload.account;
      state.token = action.payload.token;

      if (action.payload.expiresIn) {
        state.expiresIn = action.payload.expiresIn;
      }

      if (action.payload.createdAt) {
        state.createdAt = action.payload.createdAt;
      }

      if (action.payload.isAdmin) {
        state.isAdmin = true;
      }
    },

    // avoid multiple signing request
    setUserRequestSigning: (state: UserState, action: PayloadAction<{ isRequestingSigning: boolean }>) => {
      state.isRequestingSigning = action.payload.isRequestingSigning;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.signin.matchFulfilled, afterLogin
    );

    builder.addMatcher(
      accountApi.endpoints.getFollowings.matchFulfilled,
      (state, { payload }) => {
        state.followings = payload.status === 'success' ? payload.data : [];
      }
    );

    builder.addMatcher(
      accountPrivateApi.endpoints.linkDID.matchFulfilled,
      (state, { payload }) => {
        state.dids = {
          ...state.dids,
          [payload.data.address?.toLowerCase()]: payload.data,
        };
      }
    );

    builder.addMatcher(
      accountApi.endpoints.getProfile.matchFulfilled,
      (state, { payload }) => {
        state.dids = {
          ...state.dids,
          [payload.address?.toLowerCase()]: payload.did,
        };
      }
    );

    builder.addMatcher(
      accountApi.endpoints.findAccounts.matchFulfilled,
      (state, { payload }) => {
        payload.data.forEach(({ address, did }) => {
          state.dids = {
            ...state.dids,
            [address.toLowerCase()]: did,
          };
        });
      }
    );

    builder.addMatcher(
      accountPrivateApi.endpoints.followUser.matchFulfilled,
      (state, { payload, meta }) => {
        // check if it was success in prior

        // check what status has been used for the request:
        // 0: unfollow
        // 1: follow
        if (payload.status === 'success') {
          // get the index of targeted account
          const index = state.followings.map(
            ({ address }) => address
          ).indexOf(meta.arg.originalArgs.follower);

          if (index >= 0) {
            if (meta.arg.originalArgs.status === 0) {
              // remove from following list
              state.followings.splice(index, 1);
            }
          } else if (meta.arg.originalArgs.status === 1) {
            // add to the followings list
            state.followings = [
              ...state.followings,
              {
                address: meta.arg.originalArgs.follower,
                followers: 1,
              },
            ];
          }
        }
      }
    );
  },
});

export const { reducer } = slice;
export const { actions: { setEncryptionKey, setUserToken, setUserRequestSigning } } = slice;

export const selectCurrentUser = (state: RootState) => state.user.data;
export const useDIDAccounts = () => useSelector((state: RootState) => state.user.dids);
export const useDIDAccount = (address: string) => useSelector((state: RootState) => state.user.dids[address]);
export const useCurrentUser = () => {
  const selected = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  return {
    ...selected,
    setUserToken: (...payload: Parameters<typeof setUserToken>) => dispatch(setUserToken(...payload)),
    setUserRequestSigning: (
      ...payload: Parameters<typeof setUserRequestSigning>
    ) => dispatch(setUserRequestSigning(...payload)),
  };
};

export const getVaultToken = (address: string): string => {
  const s = new JsonLocalStorage<Record<string, AuthResponse>>(JWT_VAULT);

  return s.get(address)?.token;
};
