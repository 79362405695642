/* eslint-disable class-methods-use-this */
import { ContractTransaction, ContractReceipt } from '@ethersproject/contracts';
import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import {
  ITxArgumentBuilder,
  ITxCallable,
  ITxExecutable,
  ITxOptions,
  ITxResult,
  IUXHandler,
  ITransactionContext,
} from './types';

export class TxExecutable implements ITxExecutable {
  protected static UXHandler: IUXHandler = {};

  constructor(private description: string) {}

  /**
   * Estimate the gas limit adapted to the platform and the action to call
   *
   * @param value
   * @param minimal
   * @returns
   */
  protected calculateGasMargin(value: BigNumberish, minimal?: number): BigNumberish {
    return (value === 0 ? BigNumber.from(minimal || 21000) : BigNumber.from(value))
      .mul(BigNumber.from(10000).add(BigNumber.from(1000)))
      .div(BigNumber.from(10000));
  }

  /**
   * Register different handler for UX purpose in a general context
   *
   * @param handler
   */
  public static registerUXHandler(handler: IUXHandler) {
    TxExecutable.UXHandler = handler;
  }

  /**
   * Executes unified transaction with the provided callable and arguments.
   *
   * @param callable
   * @param argumentBuilder
   * @param options
   * @returns
   */
  public static async invoke(
    description: string,
    callable: ITxCallable,
    argumentBuilder: ITxArgumentBuilder,
    options?: ITxOptions
  ): Promise<ITxResult> {
    const executor = new TxExecutable(description);

    return executor.execute(callable, argumentBuilder, options);
  }

  /**
   * Executes unified transaction with the provided callable and arguments.
   *
   * @param callable
   * @param argumentBuilder
   * @param options
   * @returns
   */
  public async execute(
    callable: ITxCallable,
    argumentBuilder: ITxArgumentBuilder,
    _options?: ITxOptions
  ): Promise<ITxResult> {
    const ctx: ITransactionContext = {};
    const contract = typeof callable.callee === 'function' ? callable.callee() : callable.callee;

    const args = [...argumentBuilder.args()];
    const { estimateGas, onWait, onSuccess, onError, ...options } = { ...TxExecutable.UXHandler, ..._options || {} };

    if (estimateGas) {
      options.gasPrice = await contract.provider.getGasPrice();

      try {
        options.gasLimit = await contract.estimateGas[callable.method](...args, options);
        // deactivated margin calculation and only rely on gas estimate from contract call
        // options.gasLimit = this.calculateGasMargin(gasEstimate);
      } catch (e) {
        if (process.env.NODE_ENV !== 'test') {
          console.warn('Failed to estimate gas, will use arbitrary value', e);
        }

        if (options.value) {
          options.gasLimit = this.calculateGasMargin(0, 2100000);
        }
      }
    }

    // Add options to args if there are relevant settings
    if (Object.keys(options).length > 0) {
      args.push(options);
    }

    try {
      (onWait || (process.env.NODE_ENV !== 'test' ? console.log : null))?.(ctx, this.description, args);
      const tx:ContractTransaction = await contract[callable.method](...args);

      // @todo: enhance test suite cases in regard of this line
      // the jest mocking seems behaving in a very weird manner
      // sometimes, tx is `undefined`
      const receipt: ContractReceipt = await tx.wait();
      onSuccess?.(ctx, receipt);

      return {
        transaction: tx,
        receipt,
      };
    } catch (e) {
      onError?.(ctx, e);
      return Promise.reject(e);
    }
  }
}
