import OAuthAdapter from './oauth.adapter';

interface DiscordOAuthOptions {
  clientId: string;
}

export default class DiscordOAuthAdapter extends OAuthAdapter {
  constructor({ clientId }: DiscordOAuthOptions) {
    super('discord', {
      authorizeURLBuilder: {
        baseURL: 'https://discord.com',
        path: '/api/oauth2/authorize',
        query: {
          client_id: clientId,
          redirect_uri: `${window.location.origin}/callback/discord`,
          scope: 'identify',
          response_type: 'code',
          state: 'code',
        },
      },
    });

    this.options.authorizeURLBuilder.query.state = this.genegateCodeVerifier();
  }
}
