/* eslint-disable max-len */
import React from 'react';
import { FormikErrors } from 'formik';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import { TokenID } from '@elacity-js/lib';
import { parseUnits, formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import type {
  INFTArt as IRawNFTArt, NFTArtBase, ListingNftFormFields, IListing,
} from 'src/types';
import type {
  IOwnedSellableItem, IAcceptOfferWithPrice, ICreateOfferParams, IOptionalActions,
} from 'src/lib/nfts/types';
import { MuseumNftFactory } from 'src/lib/nfts/factories';
import events from 'src/lib/nfts/events';
import { ADDRESS, ABIS } from 'src/lib/nfts/constants';
import {
  useNotifiyTokenRemovalMutation, useNotifiyNewTokenMutation, useNotifiyTokenUpdateMutation,
} from 'src/state/api/token';
import { useNotifiyOffersListChangeMutation } from 'src/state/api/account';
import useScContracts from './contracts/sc.contracts';
import { useApprovalContextFor } from './useContractApproval';
import useWeb3Application from './useWeb3Application';
import usePayment from './usePayment';
import useAddresses from './useAddresses';
import useModal from './useModal';
import useApproval from './useApproval';
import { RemoveButton } from '../components/marketplace/museum/EditListingModalForm';

interface UseListingProps {
  nftAddress: string;
}

type INFTArt = Omit<IRawNFTArt, 'tokenID'> & { tokenID: TokenID };

export default ({ nftAddress }: UseListingProps) => {
  const { account, library, payment } = useWeb3Application();
  const { supportedMap: currencies, native } = usePayment();
  const { loadContract } = useScContracts();
  const { enqueueSnackbar } = useSnackbar();
  const { MARKETPLACE_ADDRESS } = useAddresses();
  const [triggerTokenRemoval] = useNotifiyTokenRemovalMutation();
  const [triggerTokenUpdate] = useNotifiyTokenUpdateMutation();
  const [triggerTokenAdd] = useNotifiyNewTokenMutation();
  const [triggerOffersChange] = useNotifiyOffersListChangeMutation();
  const { ensureApproval } = useApproval();
  const { updateProgress, openModal } = useModal();
  const isItemOnMarketplace = (item: INFTArt): boolean => typeof item.enteredMarketplace !== 'undefined' && item.enteredMarketplace !== null;
  const ctx = useApprovalContextFor(MARKETPLACE_ADDRESS, nftAddress);

  const getMuseumNftObject = React.useCallback(
    (_nftAddress?: string) => {
      const NftFactory = new MuseumNftFactory();
      return NftFactory.create(
        {
          nftAddress: _nftAddress || nftAddress,
          type: '721',
          salesContract: MARKETPLACE_ADDRESS,
        },
        {
          loadContract,
          library,
        }
      );
    },
    [nftAddress, MARKETPLACE_ADDRESS, library]
  );

  // @todo [tkid]
  const isItemOnMarketplaceOnSc = async (tokenID: TokenID, owner: string): Promise<boolean> => {
    const Nft = getMuseumNftObject();

    const listings = await Nft.getListings(tokenID, owner);

    return !!listings && !!listings?.startingTime?.toNumber();
  };

  const ensureMarketplaceTransferAndSpending = React.useCallback(
    async (amount: number, payToken: string, checkAllowance = true, _nftAddress = nftAddress, wrap = false) => {
      // 1. Ensure marketplace is approved to spend your nfts
      if (!ctx.status?.approved) {
        // Check if marketplace was already approved in another context scope
        const isApproveOnContext = ctx.isApprovedInContext(MARKETPLACE_ADDRESS, _nftAddress.toLowerCase())?.approved;
        if (!isApproveOnContext) {
          updateProgress('Approving Marketplace to interact with your NFTs', 5);
          await ensureApproval(
            await (new Contract(_nftAddress, ABIS.SINGLE_NFT_ABI, library)).isApprovedForAll(account, MARKETPLACE_ADDRESS),
            async () => ctx.contract.approve(_nftAddress),
            (err: Error) => {
              throw new Error(`Marketplace Approval failed, ${err.message}`);
            },
            () => {}
          );
        }
      }

      // 2. token handling
      if (payToken !== ADDRESS.ZERO) {
        const pk = currencies[payToken];
        const pc = payment?.useWrapped(payToken);
        if (!wrap && amount > 0) {
          updateProgress('Checking your balance', 10);
          const balance = await pc?.balanceOf(account);

          if (BigNumber.from(balance).lt(parseUnits(amount.toString(), pk?.decimals || 18))) {
            throw new Error(
              `Balance not enough. You only own ${formatUnits(balance.toString(), pk?.decimals || 18)} ${pk?.symbol}`
            );
          }
        } else if (wrap) {
          // swap token
          updateProgress('Swaping your token to fullfil the operation...', 10);
          await pc?.deposit(amount);
        }

        if (checkAllowance) {
          updateProgress(`Allowing contract to spend your ${pk?.symbol} token`, 15);
          await pc?.ensureAllowance(account, MARKETPLACE_ADDRESS, amount);
        }
      }
    },
    [library, account, payment, ctx.status?.approved, nftAddress]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSaleBtnClick = async (item: INFTArt, next: any): Promise<void> => {
    // Proxy the call for now to avoid changing too much code in consumers
    // @todo handle implementation in more proper way
    await next?.call(null, item);

    // await ensureApproval(
    //   // Current state of approval (boolean)
    //   ctx.status?.approved,

    //   // Function for approving the NFT if not approved yet
    //   async () => ctx.contract.approve(NFT_ADDRESS),

    //   // Executes when approval failed
    //   () => {},

    //   // Executes when approval is successful
    //   () => {
    //     next?.call(null, item);
    //   }
    // );
  };

  const processListingTask = async (values, optionalActions?: IOptionalActions) => {
    if (optionalActions?.onStart) {
      await optionalActions.onStart();
    }

    const { payToken } = values;

    // 1. Run approvals for marketplace
    // approval for token is not needed so we pass 0 and false flag to avoid tokens checkings
    await ensureMarketplaceTransferAndSpending(parseFloat('0'), payToken, false);

    const Nft = getMuseumNftObject();

    Nft.on(events.SALES_LIST_ITEM_START, () => {
      updateProgress('Processing listing...', 10);
    });
    Nft.on(events.SALES_LIST_ITEM_WAIT_TX, () => updateProgress('Waiting for TX to validate', 50));
    Nft.on(events.SALES_LIST_ITEM_TX_CONFIRMED, (tx) => updateProgress(`'Tx confirmed (${tx})`, 90));
    Nft.on(events.SALES_LIST_ITEM_DONE, () => {
      updateProgress('Item listed successfully', 100);
      triggerTokenAdd();
    });
    Nft.on(events.PROCESS_ERRORED, (e) => {
      enqueueSnackbar(`There was an error: ${e.message}`, {
        variant: 'error',
      });
    });

    await Nft.listItem({
      ...values,
    });
    Nft.flush();

    if (optionalActions?.onDone) {
      await optionalActions.onDone();
    }
  };

  const processUpdateListingTask = async ({ optionalActions, ...values }) => {
    if (optionalActions?.onStart) {
      await optionalActions.onStart();
    }

    const { payToken } = values;

    // 1. Run approvals for marketplace
    // approval for token is not needed so we pass 0 and false flag to avoid tokens checkings
    await ensureMarketplaceTransferAndSpending(parseFloat('0'), payToken, false);

    const Nft = getMuseumNftObject();

    Nft.on(events.SALES_UPDATE_LISTING_START, () => {
      updateProgress('Processing update listing...', 10);
    });
    Nft.on(events.SALES_UPDATE_LISTING_WAIT_TX, () => updateProgress('Waiting for TX to validate', 50));
    Nft.on(events.SALES_UPDATE_LISTING_TX_CONFIRMED, (tx) => updateProgress(`'Tx confirmed (${tx})`, 90));
    Nft.on(events.SALES_UPDATE_LISTING_DONE, () => {
      updateProgress('Listing updated successfully', 100);
      triggerTokenUpdate({ ...values.tokenId.toJSON(), contractAddress: nftAddress });
    });
    Nft.on(events.PROCESS_ERRORED, (e) => {
      enqueueSnackbar(`There was an error: ${e.message}`, {
        variant: 'error',
      });
    });

    await Nft.updateListing({
      pricePerItem: (values.pricePerItem || 0).toString(),
      tokenId: values.tokenId || 0,
      payToken,
    });
    Nft.flush();

    if (optionalActions?.onDone) {
      await optionalActions.onDone();
    }

    enqueueSnackbar('Sale successfully updated', {
      variant: 'success',
    });
  };

  const handleListItemModal =
    (
      item: Omit<NFTArtBase, 'tokenID'> & { tokenID: TokenID },
      Component: React.ComponentType,
      optionalActions?: IOptionalActions
    ) => (): void => {
      openModal<ListingNftFormFields>({
        withProgress: true,
        maxWidth: 'xs',
        formData: {
          nftAddress,
          tokenID: item.tokenID,
          tokenURI: item.tokenURI,
          pricePerItem: '',
          name: item.name,
          quantity: 1,
          startingTime: new Date().getTime(),
          // payToken: payment.tokenAddress,
        },
        Component,
        okLabel: 'List',
        onOk: async ({ tokenID, ...values }) => {
          await processListingTask({ tokenId: tokenID, ...values }, optionalActions);
        },
      });
    };

  const handleCancelListing = async (tokenId: TokenID, optionalActions?: IOptionalActions) => {
    if (optionalActions?.onStart) {
      await optionalActions.onStart();
    }

    const Nft = getMuseumNftObject();

    Nft.on(events.SALES_CANCEL_LISTING_START, () => updateProgress('Processing listing cancellation ...', 10));
    Nft.on(events.SALES_CANCEL_LISTING_WAIT_TX, () => updateProgress('Waiting for TX to validate', 50));
    Nft.on(events.SALES_CANCEL_LISTING_TX_CONFIRMED, (tx) => updateProgress(`Tx confirmed (${tx})`, 90));
    Nft.on(events.SALES_CANCEL_LISTING_DONE, ({ contractAddress }) => {
      updateProgress('Listing cancelled successfully', 100);
      // @todo [tkid]
      triggerTokenRemoval({ tokenID: tokenId, contractAddress });
    });
    Nft.on(events.PROCESS_ERRORED, (e) => {
      enqueueSnackbar(`There was an error: ${e.message}`, {
        variant: 'error',
      });
      if (optionalActions?.onError) {
        optionalActions.onError();
      }
    });

    await Nft.cancelListing(tokenId);
    Nft.flush();

    if (optionalActions?.onDone) {
      await optionalActions.onDone();
    }

    enqueueSnackbar('Sale successfully cancelled', {
      variant: 'success',
    });
  };

  const handleEditListingModal =
    (item: INFTArt & { listings?: IListing[] }, Component: React.ComponentType, optionalActions?: IOptionalActions) => (): void => {
      openModal<ListingNftFormFields>({
        withProgress: true,
        maxWidth: 'xs',
        formData: {
          nftAddress,
          tokenID: item.tokenID,
          tokenURI: item.tokenURI,
          payToken: item.paymentToken,
          pricePerItem: (item?.price || 0).toString(),
          name: item.name,
          quantity: 1,
          startingTime:
            item?.listings && item?.listings.length > 0 ? new Date(item.listings[0].startTime).getTime() : new Date().getTime(),
        },
        Component,
        okLabel: 'Save',
        onOk: async (values) => {
          await processUpdateListingTask({ ...values, tokenId: item.tokenID, optionalActions });
        },
        additionalActions: (
          <>
            <RemoveButton
              onClick={async () => {
                await handleCancelListing(item.tokenID, {});
              }}
            />
            <Box sx={{ flex: 1 }} />
          </>
        ),
      });
    };

  const handleBuyItem = React.useCallback(
    async (
      { payToken: salePayToken, pricePerItem, tokenId, ownerAddress }: IOwnedSellableItem,
      Component: React.ComponentType,
      formData: ListingNftFormFields,
      optionalActions?: IOptionalActions
    ) => {
      openModal<ListingNftFormFields>({
        Component,
        withProgress: true,
        maxWidth: 'xs',
        okLabel: 'Buy',
        formData,
        onOk: async (values: ListingNftFormFields) => {
          const { payToken } = values;
          if (optionalActions?.onStart) {
            await optionalActions.onStart();
          }

          // 1. Ensure
          await ensureMarketplaceTransferAndSpending(
            parseFloat(pricePerItem || '0'),
            payToken,
            true,
            values.nftAddress,
            values.swap
          );

          // 3. Buy the item
          const Nft = getMuseumNftObject();
          Nft.on(events.SALES_BUY_START, () => updateProgress('Buying item...', 10));
          Nft.on(events.SALES_BUY_WAIT_TX, () => updateProgress('Waiting for TX to validate', 50));
          Nft.on(events.SALES_BUY_TX_CONFIRMED, () => updateProgress('Tx confirmed', 90));
          Nft.on(events.SALES_BUY_DONE, ({ contractAddress }) => {
            updateProgress('Item bought successfully', 100);
            triggerTokenRemoval({ tokenID: tokenId, contractAddress });
          });
          Nft.on(events.PROCESS_ERRORED, (e) => {
            enqueueSnackbar(`There was an error: ${e.message}`, {
              variant: 'error',
            });
          });

          if (salePayToken !== payToken) {
            // trigger allowance transaction if native is used
            const pc = payment?.useWrapped(native.swapSupports.filter((c) => c.rate === 1).shift()?.address);

            updateProgress('Allowing contract to spend your token', 20);
            await pc?.ensureAllowance(account, MARKETPLACE_ADDRESS, parseFloat(pricePerItem));
          }

          await Nft.buyItem({
            ownerAddress,
            payToken,
            tokenId,
            pricePerItem: parseFloat(values.pricePerItem),
          });
          Nft.flush();

          if (optionalActions?.onDone) {
            await optionalActions.onDone();
          }

          enqueueSnackbar('Item successfully bought', {
            variant: 'success',
          });
        },
      });
    },
    [library, payment, account, ctx.status?.approved]
  );

  const handleCreateOffer = React.useCallback(
    async (
      Component: React.ComponentType,
      formData: ICreateOfferParams & { name: string; tokenURI?: string },
      optionalActions?: IOptionalActions
    ) => {
      openModal<ICreateOfferParams>({
        Component,
        withProgress: true,
        maxWidth: 'xs',
        okLabel: 'Create',
        // title: `Make an offer for "${formData.name}"`,
        formData,
        formValidator: (values: ICreateOfferParams) => {
          const errors: FormikErrors<ICreateOfferParams> = {};

          if (!values.pricePerItem || parseFloat(values.pricePerItem) === 0) {
            errors.pricePerItem = 'Price should be greater than 0';
          }

          return Promise.resolve(errors);
        },
        onOk: async (values: ICreateOfferParams) => {
          if (optionalActions?.onStart) {
            await optionalActions.onStart();
          }

          const { pricePerItem, tokenId, quantity, deadline, payToken, swap } = values;

          // 1. Ensure
          await ensureMarketplaceTransferAndSpending(parseFloat(pricePerItem || '0'), payToken, true, formData.nftAddress, swap);

          // 3. Buy the item
          const Nft = getMuseumNftObject();
          Nft.on(events.SALES_CREATE_OFFER_START, () => updateProgress('Creating offer...', 10));
          Nft.on(events.SALES_CREATE_OFFER_WAIT_TX, () => updateProgress('Waiting for TX to validate', 50));
          Nft.on(events.SALES_CREATE_OFFER_TX_CONFIRMED, () => updateProgress('Tx confirmed', 90));
          Nft.on(events.SALES_CREATE_OFFER_DONE, () => {
            updateProgress('Offer created successfully', 100);
            triggerOffersChange('OUT_LIST');
          });
          Nft.on(events.PROCESS_ERRORED, (e) => {
            enqueueSnackbar(`There was an error: ${e.message}`, {
              variant: 'error',
            });
          });

          // eslint-disable-next-line no-underscore-dangle
          const _deadlineInSeconds = Math.floor(parseInt(deadline || '0', 10) / 1000).toString();

          await Nft.createOffer({
            nftAddress: values.nftAddress || nftAddress,
            payToken,
            tokenId,
            quantity,
            pricePerItem,
            deadline: _deadlineInSeconds,
          });
          Nft.flush();

          if (payToken === ADDRESS.ZERO) {
            // trigger allowance transaction if native is used
            const pc = payment?.useWrapped(native.swapSupports.filter((c) => c.rate === 1).shift()?.address);

            await pc?.ensureAllowance(account, MARKETPLACE_ADDRESS, parseFloat(pricePerItem) * quantity);
          }

          if (optionalActions?.onDone) {
            await optionalActions.onDone();
          }

          enqueueSnackbar('Offer successfully created', { variant: 'success' });
        },
      });
    },
    [library, payment?.contract, account, ctx.status?.approved]
  );

  const handleAcceptOffer = React.useCallback(
    async <T extends IAcceptOfferWithPrice>(Component: React.ComponentType, formData: T, optionalActions?: IOptionalActions) => {
      const { tokenId, creator } = formData;

      openModal<T>({
        Component,
        withProgress: true,
        // title: 'Accept Offer',
        maxWidth: 'xs',
        formData,
        okLabel: 'Accept',
        onOk: async () => {
          if (optionalActions?.onStart) {
            await optionalActions.onStart();
          }

          updateProgress('Initializing...', 0);
          // 1. Ensure
          await ensureMarketplaceTransferAndSpending(0, formData.payToken, false, formData.nftAddress);

          // 2. Accept the new offer
          const Nft = getMuseumNftObject(formData.nftAddress);
          Nft.on(events.SALES_ACCEPT_OFFER_START, () => updateProgress('Accepting offer...', 10));
          Nft.on(events.SALES_ACCEPT_OFFER_WAIT_TX, () => updateProgress('Waiting for TX to validate', 50));
          Nft.on(events.SALES_ACCEPT_OFFER_TX_CONFIRMED, () => updateProgress('Tx confirmed', 90));
          Nft.on(events.SALES_ACCEPT_OFFER_DONE, ({ contractAddress }) => {
            triggerTokenRemoval({ ...tokenId.toJSON(), contractAddress });
            triggerOffersChange('IN_LIST');
          });
          Nft.on(events.PROCESS_ERRORED, (e) => {
            enqueueSnackbar(`There was an error: ${e.message}`, {
              variant: 'error',
            });
          });

          await Nft.acceptOffer({
            tokenId,
            creator,
          });
          Nft.flush();

          if (optionalActions?.onDone) {
            await optionalActions.onDone();
          }

          enqueueSnackbar('Offer successfully accepted', { variant: 'success' });
        },
      });
    },
    [library, ctx.status?.approved, account]
  );

  const handleCancelOffer = React.useCallback(
    async <T extends IAcceptOfferWithPrice>(Component: React.ComponentType, formData: T, optionalActions?: IOptionalActions) => {
      const { pricePerItem, payToken, tokenId } = formData;

      openModal<T>({
        Component,
        withProgress: true,
        maxWidth: 'xs',
        formData,
        okLabel: 'Withdraw',
        title: 'Withdraw My Offer',
        onOk: async () => {
          if (optionalActions?.onStart) {
            await optionalActions.onStart();
          }

          updateProgress('Initializing...', 0);
          // 1. Ensure
          await ensureMarketplaceTransferAndSpending(parseFloat(pricePerItem || '0'), payToken, false, formData.nftAddress);

          // 2. Accept the new offer
          const Nft = getMuseumNftObject(formData.nftAddress);
          Nft.on(events.SALES_CANCEL_OFFER_START, () => updateProgress('Cancelling offer...', 10));
          Nft.on(events.SALES_CANCEL_OFFER_WAIT_TX, () => updateProgress('Waiting for TX to validate', 50));
          Nft.on(events.SALES_CANCEL_OFFER_TX_CONFIRMED, () => updateProgress('Tx confirmed', 90));
          Nft.on(events.SALES_CANCEL_OFFER_DONE, () => {
            updateProgress('Offer cancelled', 100);
            triggerOffersChange('OUT_LIST');
          });
          Nft.on(events.PROCESS_ERRORED, (e) => {
            enqueueSnackbar(`There was an error: ${e.message}`, {
              variant: 'error',
            });
          });

          await Nft.cancelOffer({ tokenId });
          Nft.flush();

          if (optionalActions?.onDone) {
            await optionalActions.onDone();
          }

          enqueueSnackbar('Offer successfully withdrawn', { variant: 'success' });
        },
      });
    },
    [library, ctx.status?.approved, account]
  );

  return {
    marketplaceContractApproved: ctx.status?.approved,
    onApprovingListingAdr: ctx.status?.approving,
    isCheckingListingApprovals: ctx.status?.checking,
    checkMarketplaceNftApproval: ctx.contract?.isApproved,
    handleSaleBtnClick,
    processListingTask,
    processUpdateListingTask,
    handleListItemModal,
    handleEditListingModal,
    handleCancelListing,
    isItemOnMarketplace,
    isItemOnMarketplaceOnSc,
    handleBuyItem,
    handleAcceptOffer,
    handleCreateOffer,
    handleCancelOffer,
  };
};
