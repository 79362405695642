import type { Currency } from '@elacity-js/lib';
import { ADDRESS } from 'src/lib/nfts/constants';

const erc20Currencies = [
  {
    symbol: 'wELA',
    address: '0x517e9e5d46c1ea8ab6f78677d6114ef47f71f6c4'.toLowerCase(),
    decimals: 18,
    chainId: 20,
    isWrapped: true,
    preferred: true,
    logo: 'ELA',
    swapSupports: [
      { address: ADDRESS.ZERO, rate: 1 },
    ],
  },
  {
    symbol: 'wELA',
    address: '0x517e9e5d46c1ea8ab6f78677d6114ef47f71f6c4'.toLowerCase(),
    decimals: 18,
    chainId: 21,
    isWrapped: true,
    preferred: true,
    logo: 'ELA',
    swapSupports: [
      { address: ADDRESS.ZERO, rate: 1 },
    ],
  },
  {
    symbol: 'TOK',
    address: '0x181fBcD5349E47Db69Dd686A28eD450375Dfa815'.toLowerCase(),
    decimals: 18,
    chainId: 21,
    isWrapped: false,
  },
  {
    symbol: 'USDT',
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7'.toLowerCase(),
    decimals: 6,
    chainId: 1,
    isWrapped: false,
  },
  {
    symbol: 'USDT',
    address: '0xb1f79Eb4fb3BD4DD516033FA9ab3037874A905E4'.toLowerCase(),
    decimals: 6,
    chainId: 5,
    isWrapped: false,
  },
  {
    // proxy: 0x175F5f4990D0Ee1B43Fa250357C5AFE7a9138908
    // FiatToken implementation: 0x5D7C79B09934A769aA57999C7C7F365d5eD27C5e
    symbol: 'USDC',
    address: '0x175F5f4990D0Ee1B43Fa250357C5AFE7a9138908'.toLowerCase(),
    decimals: 6,
    chainId: 21,
    isWrapped: false,
  },
  {
    symbol: 'USDC',
    address: '0xA06be0F5950781cE28D965E5EFc6996e88a8C141'.toLowerCase(),
    decimals: 6,
    chainId: 20,
    isWrapped: false,
  },
  {
    symbol: 'USDC',
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'.toLowerCase(),
    decimals: 6,
    chainId: 1,
    isWrapped: false,
  },
  {
    symbol: 'USDC',
    address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d'.toLowerCase(),
    decimals: 18,
    chainId: 56,
    isWrapped: false,
  },
  {
    symbol: 'USDC',
    address: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F'.toLowerCase(),
    decimals: 6,
    chainId: 5,
    isWrapped: false,
  },
  {
    symbol: 'BUSD',
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56'.toLowerCase(),
    decimals: 18,
    chainId: 56,
    isWrapped: false,
  },
  {
    symbol: 'GOLD',
    address: '0xaA9691BcE68ee83De7B518DfCBBfb62C04B1C0BA'.toLowerCase(),
    decimals: 18,
    chainId: 20,
    isWrapped: false,
  },
  {
    symbol: 'GLIDE',
    address: '0xd39eC832FF1CaaFAb2729c76dDeac967ABcA8F27'.toLowerCase(),
    decimals: 18,
    chainId: 20,
    isWrapped: false,
  },
  {
    symbol: 'ELK',
    address: '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C'.toLowerCase(),
    decimals: 18,
    chainId: 20,
    isWrapped: false,
  },
  {
    symbol: 'FilDA',
    address: '0x00E71352c91Ff5B820ab4dF08bb47653Db4e32C0'.toLowerCase(),
    decimals: 18,
    chainId: 20,
    isWrapped: false,
  },
  {
    symbol: 'BUNNY',
    address: '0x75740FC7058DA148752ef8a9AdFb73966DEb42a8'.toLowerCase(),
    decimals: 18,
    chainId: 20,
    isWrapped: false,
  },
  {
    symbol: 'CREDA',
    address: '0xc136E6B376a9946B156db1ED3A34b08AFdAeD76d'.toLowerCase(),
    decimals: 18,
    chainId: 20,
    isWrapped: false,
  },
  {
    symbol: 'USDC',
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'.toLowerCase(),
    decimals: 6,
    chainId: 137,
    isWrapped: false,
  },
  {
    symbol: 'USDT',
    address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f'.toLowerCase(),
    decimals: 6,
    chainId: 137,
    isWrapped: false,
  },
  {
    symbol: 'USDC',
    address: '0x15C3Eb3B621d1Bff62CbA1c9536B7c1AE9149b57'.toLowerCase(),
    decimals: 6,
    chainId: 9001,
    isWrapped: false,
  },
  {
    symbol: 'WINGS',
    address: '0x4ba6ba29e6164bdf8baebe52c169eca24ef04228'.toLowerCase(),
    decimals: 18,
    chainId: 21,
    isWrapped: false,
  },
  {
    symbol: 'WINGS',
    address: '0x1814dDE844554e649EE295C767C7A652845A1c0A'.toLowerCase(),
    decimals: 18,
    chainId: 20,
    isWrapped: false,
  },
] as Currency[];
export default erc20Currencies;

export const nativeCurrencies: Record<number, Currency> = {
  1: {
    symbol: 'ETH',
    address: ADDRESS.ZERO,
    decimals: 18,
    chainId: 1,
    logo: 'ETH',
    swapSupports: [],
  },
  5: {
    symbol: 'ETH',
    address: ADDRESS.ZERO,
    decimals: 18,
    chainId: 5,
    logo: 'ETH',
    swapSupports: [],
  },
  21: {
    symbol: 'ELA',
    address: ADDRESS.ZERO,
    decimals: 18,
    chainId: 21,
    logo: 'ELA',
    swapSupports: [
      { address: '0x517e9e5d46c1ea8ab6f78677d6114ef47f71f6c4'.toLowerCase(), rate: 1 },
    ],
  },
  20: {
    symbol: 'ELA',
    address: ADDRESS.ZERO,
    decimals: 18,
    chainId: 20,
    logo: 'ELA',
    swapSupports: [
      { address: '0x517e9e5d46c1ea8ab6f78677d6114ef47f71f6c4'.toLowerCase(), rate: 1 },
    ],
  },
  56: {
    symbol: 'BNB',
    address: ADDRESS.ZERO,
    decimals: 18,
    chainId: 56,
    logo: 'BNB',
    swapSupports: [],
  },
  137: {
    symbol: 'MATIC',
    address: ADDRESS.ZERO,
    decimals: 18,
    chainId: 137,
    logo: 'MATIC',
    swapSupports: [],
  },
  9001: {
    symbol: 'EVMOS',
    address: ADDRESS.ZERO,
    decimals: 18,
    chainId: 9001,
    logo: 'EVMOS',
    swapSupports: [],
  },
};

export const currenciesOn = (chainId: number) => [
  ...erc20Currencies.filter((c) => c.chainId === chainId),
  nativeCurrencies[chainId],
];

export const selectCurrency = (chainId: number, address: string): Currency => {
  const selected = currenciesOn(chainId).filter(
    (c) => c.address?.toLowerCase() === (address || nativeCurrencies[chainId]?.address)?.toLowerCase()
  ).shift();

  if (!selected) {
    // the default one: ftm, wftm, or else that are not mapped
    return nativeCurrencies[chainId];
  }

  return selected;
};

interface Token {
  symbol: string;
  address: string;
  name: string;
  decimals: number;
  chainId: number;
}

const buildToken = (chainId: number, address: string, decimals: number, symbol: string, name: string): Token => ({
  chainId, address, decimals, symbol, name,
});

export const GLIDE: { [chainId: number]: Token } = {
  20: buildToken(20, '0xd39eC832FF1CaaFAb2729c76dDeac967ABcA8F27', 18, 'GLIDE', 'Glide'),
  21: buildToken(21, '0x181fBcD5349E47Db69Dd686A28eD450375Dfa815', 18, 'TOK', 'Non Mapped Token'),
};

export const USDC: { [chainId: number]: Token } = {
  20: buildToken(20, '0xA06be0F5950781cE28D965E5EFc6996e88a8C141', 6, 'USDC', 'USD Coin'),
  21: buildToken(21, '0x175F5f4990D0Ee1B43Fa250357C5AFE7a9138908', 6, 'USDC', 'USD Coin'),
};

export const GOLD: { [chainId: number]: Token } = {
  20: buildToken(20, '0xaA9691BcE68ee83De7B518DfCBBfb62C04B1C0BA', 18, 'GOLD', 'ELA Gold'),
  21: buildToken(21, '0x181fBcD5349E47Db69Dd686A28eD450375Dfa815', 18, 'TOK', 'Non Mapped Token'),
};

export const ELK: { [chainId: number]: Token } = {
  20: buildToken(20, '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C', 18, 'ELK', 'Elk'),
  21: buildToken(21, '0x181fBcD5349E47Db69Dd686A28eD450375Dfa815', 18, 'TOK', 'Non Mapped Token'),
};

export const FILDA: { [chainId: number]: Token } = {
  20: buildToken(20, '0x00E71352c91Ff5B820ab4dF08bb47653Db4e32C0', 18, 'FILDA', 'FilDA'),
  21: buildToken(21, '0x181fBcD5349E47Db69Dd686A28eD450375Dfa815', 18, 'TOK', 'Non Mapped Token'),
};

export const BUNNY: { [chainId: number]: Token } = {
  20: buildToken(20, '0x75740FC7058DA148752ef8a9AdFb73966DEb42a8', 18, 'BUNNY', 'Bunny'),
  21: buildToken(21, '0x181fBcD5349E47Db69Dd686A28eD450375Dfa815', 18, 'TOK', 'Non Mapped Token'),
};

export const WETH: { [chainId: number]: Token } = {
  20: buildToken(20, '0x517E9e5d46C1EA8aB6f78677d6114Ef47F71f6c4', 18, 'WELA', 'Wrapped ELA'),
  21: buildToken(21, '0x517E9e5d46C1EA8aB6f78677d6114Ef47F71f6c4', 18, 'WELA', 'Wrapped ELA'),
};

export const CREDA: { [chainId: number]: Token } = {
  20: buildToken(20, '0xc136E6B376a9946B156db1ED3A34b08AFdAeD76d', 18, 'CREDA', 'CreDA'),
  21: buildToken(21, '0x181fBcD5349E47Db69Dd686A28eD450375Dfa815', 18, 'TOK', 'Non Mapped Token'),
};
