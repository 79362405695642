/* eslint-disable max-len */
import React from 'react';
import { useFormikContext } from 'formik';
import {
  Box,
  Typography,
  FormControl,
  Switch,
  FormHelperText,
  Button,
  CircularProgress,
  useTheme,
} from '@mui/material';
import type { SelectChangeEvent } from '@mui/material/Select';
import DateInput from 'src/components/form/DateInput';
import Price from 'src/components/@ui/Price';
import { usePayment, useRoyaltyLookup } from 'src/hooks';
import { ensureDate } from 'src/lib/utils';
import FeesDispatchDisplay from 'src/components/marketplace/common/FeesDispatchDisplay';
import type { AuctionFields } from './types.d';

interface AuctionModalFormProps extends AuctionFields {
  name?: string;
  forUpdate?: boolean;
  onCancelDone?: () => void | Promise<void>;
  contractAddress: string;
}

const AuctionModalForm: React.FC<AuctionModalFormProps> = ({
  name,
  forUpdate,
  onCancelDone,
  contractAddress,
}: AuctionModalFormProps) => {
  const { values, setFieldValue, isSubmitting, errors } = useFormikContext<AuctionFields>();

  const { royalty, royaltyValue, platformFee, earningValue } = useRoyaltyLookup({
    token: [contractAddress, values.tokenId],
    value: values.reservePrice,
    reservePrice: values.reservePrice,
  });

  const { native } = usePayment();
  React.useEffect(() => {
    if (!values.payToken || values.payToken?.length === 0) {
      setFieldValue('payToken', native.address);
    }
  }, []);

  return (
    <Box sx={{ my: { xs: 0, sm: 1, md: 2 } }}>
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{!forUpdate ? `Put on auction "${name}"` : `Update auction "${name}"`}</Typography>
        </Box>
      </Box>
      <Box sx={{ my: { xs: 2, md: 4 } }}>
        <Price.Input
          disabled={isSubmitting}
          preventCurrencyChange={forUpdate}
          nativeSupport
          label="Reserve Price"
          // eslint-disable-next-line max-len
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('reservePrice', e.target.value)}
          handleCurrencyChange={(e: SelectChangeEvent<string>) => setFieldValue('payToken', e.target.value)}
          currency={values.payToken?.toLowerCase()}
          {...(!values.reservePrice
            ? {
              autoFocus: true,
              value: '',
            }
            : {
              value: values.reservePrice,
            })}
          error={errors.reservePrice}
        />
      </Box>
      <Box sx={{ my: { xs: 2, md: 4 } }}>
        <FormControl fullWidth variant="outlined" disabled={isSubmitting} error={Boolean(errors.startTime)}>
          <DateInput
            type="mobile-datetime"
            label="Start time"
            format="dd/MM/yyyy p"
            value={ensureDate(values.startTime) || new Date(Date.now() + (1000 * 120))}
            minDate={forUpdate ? null : new Date()}
            onChange={(date: Date | null) => setFieldValue('startTime', date)}
            disabled={isSubmitting}
          />
          {errors.startTime && <FormHelperText error>{errors.startTime}</FormHelperText>}
        </FormControl>
      </Box>
      <Box sx={{ my: { xs: 2, md: 4 } }}>
        <FormControl fullWidth variant="outlined" disabled={isSubmitting} error={Boolean(errors.endTime)}>
          <DateInput
            type="mobile-datetime"
            label="Auction Expiration"
            format="dd/MM/yyyy p"
            value={ensureDate(values.endTime) || new Date(Date.now() + (1000 * 7 * 24 * 3600))}
            minDate={forUpdate ? null : new Date()}
            onChange={(date: Date | null) => setFieldValue('endTime', date)}
            disabled={isSubmitting}
          />
          {errors.endTime && <FormHelperText error>{errors.endTime}</FormHelperText>}
        </FormControl>
      </Box>
      <Box sx={{ mt: { xs: 2, md: 4 }, mb: 1 }}>
        <FormHelperText sx={{ marginLeft: 2 }}>Minimum bid should be equal or greater than reserve price</FormHelperText>
        <FormControl fullWidth variant="outlined" disabled={isSubmitting} error={Boolean(errors.minBidReserve)}>
          <Switch
            checked={values.minBidReserve}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('minBidReserve', e.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </FormControl>
      </Box>

      <Box sx={{ mb: 2 }}>
        <FeesDispatchDisplay payToken={values.payToken} {...{ royalty, royaltyValue, platformFee, earningValue }} />
      </Box>
    </Box>
  );
};

export default AuctionModalForm;

export const RemoveButton: React.FC<{ onClick: () => Promise<void> }> = ({ onClick }) => {
  const theme = useTheme();
  const [proc, setProc] = React.useState<boolean>(false);
  return (
    <Button
      variant="contained"
      color={theme.palette.mode === 'light' ? 'inherit' : 'secondary'}
      disabled={proc}
      startIcon={proc ? <CircularProgress size="1rem" /> : null}
      onClick={async () => {
        setProc(true);
        await onClick();
        setProc(false);
      }}
    >
      Remove
    </Button>
  );
};
