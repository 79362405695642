/* eslint-disable no-bitwise */
import { BigNumberish, BigNumber } from '@ethersproject/bignumber';
import { formatEther } from '@ethersproject/units';
import { chain } from 'lodash';

export const nFormat = (num: number, digits = 0): string => {
  if (num <= 1) {
    if (num.toFixed(digits).match(/\./g)) {
      return num.toFixed(digits).replace(/0{1,}$/, '').replace(/\.$/, '');
    }
    return num.toFixed(digits);
  }

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((i) => num >= i.value);

  const finalValue = item ? (num / item.value).toFixed(digits).replace(rx, '$1') : '0';

  if (finalValue.match(/\./g)) {
    return finalValue.replace(/0{1,}$/, '').replace(/\.$/, '') + (item?.symbol ?? '');
  }
  return finalValue + (item?.symbol ?? '');
};

export const timeFormat = (duration: number): string => {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';

  if (hrs > 0) {
    ret += `${hrs}:${mins < 10 ? '0' : ''}`;
  }

  ret += `${mins}:${secs < 10 ? '0' : ''}`;
  ret += `${secs}`;

  return ret;
};

export const sumBy = <T extends unknown>(
  data: T[],
  key: keyof T
): number => data.reduce((acc, item) => acc + Number(item[key]), 0);

export const ethToNumber = (value: BigNumberish) => parseFloat(formatEther(value?.toString()));

export const thumbnail = (path: string) => {
  if (path?.match(/^thumbnail:/)) {
    return `${process.env.REACT_APP_BACKEND_URL}/thumbnails/${path.replace(/^thumbnail:/, '')}`;
  }

  return path;
};

export const toBase64 = (file: File): Promise<string | ArrayBuffer> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    resolve(reader.result);
  };
  reader.onerror = (err) => {
    reject(err);
  };
});

/**
 * calculate cut off based on price, anchor price is in the 1st slot
 * we calculate cut off based on minimal price in remianing slots
 * @param listings
 */
export const calculateCutOff = <T extends {price?: number | string}>(listings: T[]): number => {
  // calculate cut off based on price, anchor price is in the 1st slot
  // we calculate cut off based on minimal price in remianing slots
  if (listings.length <= 1) {
    return 0;
  }

  const [p0, ...l] = listings;

  // with the current context we dont consider payToken
  // and we assume all listing have same paytoken (same unit)
  const minimalPrice = l.filter(
    (lx) => BigNumber.from(lx.price).lt(BigNumber.from(p0.price))
  ).reduce(
    (m, lx) => (
      (BigNumber.from(lx.price).lt(m))
        ? BigNumber.from(lx.price)
        : m
    ), BigNumber.from(p0.price) // simulate Infinity (anchor price)
  );

  // we enforce 100% multiplication before the sub operation as BigNumber doesnt have any decimal
  return ((BigNumber.from(p0.price).mul(100)).sub(minimalPrice.mul(100)))
    .div(BigNumber.from(p0.price))
    .toNumber();
};

export const groupByDate = <T extends {viewedAt: number; createdAt: number}>(
  data: T[],
  dateKey: keyof T
): Record<string, T[]> => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const lastSevenDays = new Date(today);
  lastSevenDays.setDate(lastSevenDays.getDate() - 7);

  return chain(data)
    .sortBy((d: T) => -d[dateKey])
    .groupBy((item) => {
      const itemDate = new Date(Number(item[dateKey]));

      if (itemDate.toDateString() === today.toDateString()) {
        return 'Today';
      } if (itemDate.toDateString() === yesterday.toDateString()) {
        return 'Yesterday';
      } if (itemDate > lastSevenDays) {
        return itemDate.toLocaleString('en-US', { weekday: 'long' });
      }
      const monthName = itemDate.toLocaleString('default', { month: 'long' });
      const dayNumber = itemDate.getDate();
      return `${monthName}, ${dayNumber}`;
    }).value();
};

/**
 * Ensure a valuda Date from any potential date
 *
 * @param value
 * @returns
 */
export const ensureDate = (value?: Date | string | number | null): Date | null => {
  if (!value) {
    return null;
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return new Date(value);
  }

  return value;
};
