import React from 'react';
import { INetwork } from '@elacity-js/lib';
import { useWeb3React } from '@web3-react/core';
import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
import {
  Backdrop as MuiBackdrop, CircularProgress, SxProps, Typography, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useErrorHandler, useAppSettings } from 'src/hooks';
import {
  EthereumLogoPurple as EthereumLogo, BinanceLogo, PolygonLogo, EvmosLogo,
} from 'src/assets/others';
import { ElastosNewLogoMark } from 'src/assets/elacity';
import {
  NetworkProvisionerHelpers, NetworkProvisionerState, Connector,
} from './types';
import reducer from './reducer';
import useNetworkRestriction from './useNetworkRestriction';

const Backdrop = styled(MuiBackdrop)(({ theme }) => ({
  ...theme.glassy(theme.palette.background.default, 0.7, 2),
  zIndex: theme.zIndex.drawer + 1000,

  '& .BackdropInner': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiTypography-root': {
      margin: theme.spacing(3, 0),
    },
  },
}));

const EmptyLogo = () => null;

const LogoMap = {
  ELA: ElastosNewLogoMark,
  ETH: EthereumLogo,
  BNB: BinanceLogo,
  MATIC: PolygonLogo,
  EVMOS: EvmosLogo,
};

export interface NetworkProvisionerContextValue extends NetworkProvisionerState, NetworkProvisionerHelpers {
  networks: INetwork[];
  network?: INetwork;
  Logo: React.ComponentType<{ sx?: SxProps }>;
}

const NetworkProvisionerContext = React.createContext<NetworkProvisionerContextValue>({
  networks: [],
  connected: false,
  switchNetwork: () => Promise.reject(new Error('[switchNetwork] is not implemented')),
  connect: () => Promise.reject(new Error('[connect] is not implemented')),
  selectNetwork: () => {},
  Logo: EmptyLogo,
});

interface NetworkProvisionerProps {
  networks: INetwork[];
  // connectors: Connector[];
  preferedNetwork: number;
}

export const NetworkProvisioner = ({ children, networks, preferedNetwork }: React.PropsWithChildren<NetworkProvisionerProps>) => {
  const { setValues: setAppSettings } = useAppSettings();
  const { chainId, activate, deactivate } = useWeb3React<Web3Provider>();
  const { handlerError } = useErrorHandler();
  const [state, dispatch] = React.useReducer(reducer, {
    connected: false,
    connecting: false,
    selectedChainId: chainId || preferedNetwork,
    message: '',
  });

  const switchWithProvider = React.useCallback(
    async (targetChainId: number, networkConfig: INetwork, provider?: ExternalProvider) => {
      const chainIdHExStr = `0x${targetChainId.toString(16)}`;

      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainIdHExStr }],
        });

        dispatch({ type: 'SET', payload: { connecting: false, connected: true, selectedChainId: targetChainId } });
        return Promise.resolve();
      } catch (switchError) {
        console.error('error while switching network', switchError);

        if (switchError?.code === -32002 &&
          JSON.stringify(switchError?.message).match('Request of type \'wallet_switchEthereumChain\'')) {
          dispatch({ type: 'SET', payload: { connecting: false } });
          return Promise.resolve();
        }

        if (switchError?.code === 4001 && JSON.stringify(switchError?.message).match('User rejected the request.')) {
          window.localStorage.removeItem('walletconnect');
          window.localStorage.removeItem('activeconnectorname');
          deactivate();
        }

        // This error code indicates that the chain has not been added to MetaMask.
        if (
          ![-32002, 4001].includes(switchError?.code) &&
          (switchError?.code === 4902 || // metamask web
          JSON.stringify(switchError).match('Try adding the chain using wallet_addEthereumChain') || // metamask mobile
          JSON.stringify(switchError?.message).match('[object Object]') || // EE (need followup as the error is very opaque)
          JSON.stringify(switchError?.message).match('Unsupported network')) // essentials
        ) {
          try {
            console.log('The network you are switching to does not exist, adding it...', { chainIdHExStr });
            const r = await provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: chainIdHExStr, // A 0x-prefixed hexadecimal string
                  chainName: networkConfig.name,
                  nativeCurrency: {
                    name: networkConfig.symbol,
                    symbol: networkConfig.symbol, // 2-6 characters long
                    decimals: networkConfig.decimals,
                  },
                  rpcUrls: [networkConfig.rpcUrl],
                  blockExplorerUrls: [networkConfig?.explorerUrl],
                  iconUrls: [], // Currently ignored.
                },
              ],
            });
            dispatch({ type: 'SET', payload: { connecting: false, connected: true, selectedChainId: targetChainId } });
            return r;
          } catch (addError) {
            dispatch({ type: 'SET', payload: { connecting: false } });
            return Promise.reject(new Error(`Add network Error : ${addError.message}`));
          }
        }

        dispatch({ type: 'SET', payload: { connecting: false } });
        return Promise.reject(
          new Error(`Switch network Error : ${JSON.stringify(switchError.message || switchError)}, code=${switchError.code}`)
        );
      }
    },
    [chainId]
  );

  const switchNetwork = React.useCallback(
    async (targetChainId: number, lib?: Web3Provider) => {
      const networkConfig = networks.find((n) => n.chainId === targetChainId);
      if (networkConfig === null) {
        return Promise.reject(new Error(`Network config for chain ${targetChainId} is null`));
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const provider = lib ? lib?.provider || null : (window as any).ethereum;
      if (!provider) {
        return Promise.reject(new Error('No provider available, cannot continue'));
      }

      dispatch({
        type: 'SET',
        payload: {
          connecting: true,
          message: `Switching to ${networkConfig?.name}, please check your wallet...`,
        },
      });
      return switchWithProvider(targetChainId, networkConfig, provider);
    },
    [chainId]
  );

  const connect = React.useCallback(async (targetChainId: number, connector: Connector) => {
    dispatch({ type: 'SET', payload: { connecting: true } });
    let connected = false;

    connector
      .connect(targetChainId, activate)
      // eslint-disable-next-line consistent-return
      .then(({ chainId: connectedChainId, provider }) => {
        connected = true;

        console.log('connection successfully established', { connectedChainId, targetChainId, provider });
        if (connectedChainId !== targetChainId) {
          return switchNetwork(targetChainId, new Web3Provider(provider));
        }
      })
      .catch(async (err: Error) => {
        handlerError(err);
      })
      .finally(() => {
        if (connected) {
          // remember which connector has been lastly used
          // we retrieve this value in src/lib/web3/hooks:useEagerConnect
          setAppSettings({ walletProvider: connector.name });
        }
        dispatch({ type: 'SET', payload: { connecting: false, connected } });
      });
  }, []);

  const selectNetwork = (targetChainId: number) => {
    dispatch({ type: 'SELECT', payload: targetChainId });
  };

  useNetworkRestriction({ switchNetwork, preferedNetwork, connecting: state.connecting });

  const { network: currentNetwork, Logo } = React.useMemo(() => {
    const n = networks.find((net) => net.chainId === Number(chainId));
    if (!n) {
      return {
        network: null,
        Logo: EmptyLogo,
      };
    }

    return {
      network: n,
      Logo: LogoMap[n.symbol] || EmptyLogo,
    };
  }, [chainId]);

  return (
    <NetworkProvisionerContext.Provider
      value={{
        networks,
        network: currentNetwork,
        switchNetwork,
        connect,
        selectNetwork,
        Logo,
        ...state,
      }}
    >
      {children}
      {state.connecting && (
        <Backdrop open>
          <Box className="BackdropInner">
            <CircularProgress color="inherit" />
            <Typography>{state.message}</Typography>
          </Box>
        </Backdrop>
      )}
    </NetworkProvisionerContext.Provider>
  );
};

export default NetworkProvisionerContext;
