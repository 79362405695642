import React from 'react';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  styled, Theme, Breakpoint, SxProps,
} from '@mui/material/styles';
import { RouterLink } from 'src/components/Link';
import PoweredDoc from 'src/components/section/PoweredDoc';
import { FullLogo as Logo } from 'src/assets/Logo';

interface ItemProps {
  hideFrom?: Breakpoint;
}

const Item = styled('div', {
  shouldForwardProp: (prop: string) => !['hideFrom'].includes(prop),
})<ItemProps>(({ theme, hideFrom }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  width: 220,
  [theme.breakpoints.down(hideFrom)]: {
    display: 'none',
  },
}));

interface FooterContainerProps {
  adjustWith?: number;
}

const FooterContainer = styled(Box, {
  name: 'UiFooter',
  slot: 'root',
  shouldForwardProp: (prop: string) => !['adjustWith'].includes(prop),
})<FooterContainerProps>(({ theme }) => ({
  // position: 'fixed',
  bottom: 0,
  // width: `calc(100vw - ${adjustWith}px)`,
  margin: theme.spacing(2, 'auto', 1),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    display: 'none',
  },
}));

interface Props {
  sx?: SxProps;
  hidePoweredByFrom?: Breakpoint;
}

export default function Footer({ sx, hidePoweredByFrom }: Props) {
  const isNotLarge = useMediaQuery((t: Theme) => t.breakpoints.down('lg'));

  return (
    <FooterContainer className="UiFooter-container" sx={sx}>
      <Stack direction={isNotLarge ? 'column' : 'row'} spacing={0} justifyContent="space-between" alignItems="center" px={0}>
        <Item hideFrom={hidePoweredByFrom || 'lg'}>
          <PoweredDoc position="relative" />
        </Item>
        <Item>
          <Link component={RouterLink} to="/">
            <Logo size={100} />
          </Link>
        </Item>
        <Item>
          <Box>
            <Link href="/" underline="hover" color="inherit">
              &copy;
              {' '}
              {new Date().getFullYear()}
              {' '}
              Elacity
            </Link>
          </Box>
          <Box sx={{ fontSize: '85%' }}>{`version ${process.env.REACT_APP_VERSION}`}</Box>
          {
            process.env.REACT_APP_VERCEL_ENV && (
              <Box sx={{ fontSize: '85%' }}>
                {`${process.env.REACT_APP_VERCEL_ENV}:${process.env.REACT_APP_VERCEL_GIT_COMMIT_REF}`}
              </Box>
            )
          }
        </Item>
      </Stack>
    </FooterContainer>
  );
}
