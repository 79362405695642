export enum VerifiableSocialCredential {
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  TELEGRAM = 'telegram',
  DISCORD = 'discord',
  MEDIUM = 'medium',
  EMAIL = 'email',
}

export interface ICredentialValue {
  id: string;
  value: string;
  status: 'pending' | 'approved';
  visible?: boolean;
}

export interface IClickableProps {
  disabled?: boolean;
  onClick?: () => Promise<void> | void;
}

export type IVerificatorProps = IClickableProps & {
  // When set `value` should hold the credential as string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: string;
};

export interface IVerificatorAdapter {
  resolveClickableProps(): IVerificatorProps;
}

export interface IVerificatorManager {
  isReady(): boolean;
  configure: () => void;
  getClickablePropsFor: (id: VerifiableSocialCredential) => IClickableProps;
  getValueOf: (id: VerifiableSocialCredential) => ICredentialValue | undefined;
  getKycStatus: () => boolean;
}
