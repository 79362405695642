/* eslint-disable react/destructuring-assignment */
import React, { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import Footer from 'src/components/minimal/Footer';
import AccountOverview from 'src/components/minimal/AccountPopover';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  maxWidth: 480,
  minHeight: '100%',
  overflow: 'hidden',
  textAlign: 'center',
  margin: theme.spacing(0, 'auto'),
}));

const Background = styled('div')(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  zIndex: -2,
  opacity: theme.palette.mode === 'light' ? 0.75 : 0.8,
  background:
    theme.palette.mode === 'light'
      ? `radial-gradient(circle at right bottom, ${theme.palette.vivid.main} -360%, ${theme.palette.background.default} 40%)`
      : `radial-gradient(circle at right bottom, ${theme.palette.vivid.main} -460%, ${theme.palette.background.default} 40%)`,
}));

interface LayoutProps {
  noPadding?: boolean;
  withBottomNav?: boolean;
}

const MainLayout = styled('div', {
  name: 'CoverLayout',
})<LayoutProps>(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
}));

export default function DashboardLayout(props: LayoutProps) {
  return (
    <RootStyle>
      <Background />
      <MainLayout {...props}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
          <Box sx={{ flexGrow: 1, pt: 5 }}>
            <AccountOverview noMenu size={128} />
          </Box>
          <Box sx={{ flexGrow: 4 }}>
            <Outlet />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Footer sx={{ bottom: 0 }} hidePoweredByFrom="xs" />
          </Box>
        </Box>
      </MainLayout>
    </RootStyle>
  );
}
