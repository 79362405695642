import React from 'react';
import {
  Formik, FormikErrors, FormikHelpers,
} from 'formik';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Alert,
  AlertTitle,
  Typography,
  LinearProgress,
  Box,
} from '@mui/material';
import { Breakpoint } from '@mui/material/styles';
import { FullLogo as Logo } from 'src/assets/Logo';

interface ProgressState {
  value?: number;
  label?: string | null;
}

interface ProgressUtil extends ProgressState {
  set: (label: string | null, value?: number) => void;
}

interface ErrorExt extends Error {
  reason?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ModalOptionsProps<FormDataShape = any> {
  title?: string;
  maxWidth?: Breakpoint;
  centerActions?: boolean;
  okLabel?: string;
  onOk?: (values: FormDataShape, helpers?: FormikHelpers<FormDataShape>) => Promise<void> | void;
  Component: React.ComponentType;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData?: FormDataShape;
  formValidator?: (values: FormDataShape) => void | Promise<FormikErrors<FormDataShape>>;

  // handling progress;
  withProgress?: boolean;
  progress?: ProgressUtil;

  // ability ot not have logo
  withoutLogo?: boolean;

  // make the dialog action extendable
  ActionsWrapper?: React.ComponentType;
  additionalActions?: React.ReactNode;
}

const TheModal: React.FC<ModalOptionsProps & { open?: boolean; onClose: () => void }> = ({
  Component,
  ActionsWrapper,
  additionalActions,
  title,
  open,
  onClose,
  maxWidth,
  centerActions,
  okLabel,
  onOk,
  formData,
  formValidator,
  withProgress,
  progress,
  withoutLogo,
  ...props
}: ModalOptionsProps & { open?: boolean; onClose: () => void }) => {
  const [err, setError] = React.useState<ErrorExt | null>(null);
  const touchedRef = React.useRef<Record<string, boolean>>({});

  const handleClose = () => {
    onClose();
    // flush progress if any
    setError(null);
    if (progress) {
      progress?.set(null, 0);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth || 'md'}
      open={Boolean(open)}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
    >
      <DialogTitle
        sx={{
          mb: 0,
          pt: { xs: 1, sm: 2, md: 3 },
          pb: { xs: 1, sm: 1, md: 2 },
          fontSize: 16,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!withoutLogo && (
          <Box sx={{ display: 'inline-flex', mt: { xs: 1, md: 2 } }}>
            <Logo size={170} responsive />
          </Box>
        )}
        {title && <Box sx={{ mt: 2 }}>{title}</Box>}
      </DialogTitle>
      <Formik
        initialValues={formData}
        validate={formValidator}
        validateOnChange={false}
        validateOnBlur
        onSubmit={async (values, helpers) => {
          if (onOk) {
            try {
              setError(null);
              progress.set('Processing...', 0);
              await onOk(values as unknown, helpers);
              progress.set('Process done', 100);
              handleClose();
            } catch (e) {
              setError(e as ErrorExt);
            }
          }
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <>
              <DialogContent sx={{ mt: 0, pt: 0, pb: 0 }}>
                <Component {...(props || {})} />
                {isSubmitting && withProgress && (
                  <Box sx={{ width: '100%' }}>
                    <Typography variant="subtitle2" fontWeight="light">
                      {progress?.label}
                    </Typography>
                    <LinearProgress variant="determinate" value={progress?.value} />
                  </Box>
                )}
                {err && (
                  <Alert severity="error" color="error" onClose={() => setError(null)}>
                    <AlertTitle>{err.name}</AlertTitle>
                    {err.reason || err.message}
                  </Alert>
                )}
              </DialogContent>
              <DialogActions
                sx={{
                  pb: { xs: 2, lg: 3 },
                  px: 3,
                  justifyContent: centerActions ? 'center' : 'flex-end',
                  ...(centerActions && {
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    '& > *': {
                      marginTop: 1,
                      ml: '0!important',
                    },
                  }),
                }}
              >
                <ActionsWrapper>
                  {additionalActions}
                  {onOk && (
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                      startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                      sx={{ color: (theme) => (theme.palette.mode === 'light' ? 'white' : 'black') }}
                    >
                      {okLabel || 'OK'}
                    </Button>
                  )}
                  <Button onClick={handleClose} disabled={isSubmitting}>
                    Cancel
                  </Button>
                </ActionsWrapper>
              </DialogActions>
            </>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

TheModal.defaultProps = {
  ActionsWrapper: React.Fragment,
  additionalActions: null,
};

export interface ModalContextValue {
  openModal: <T>(options: ModalOptionsProps<T>) => void;
  updateProgress?: ProgressUtil['set'];
  closeModal?: () => void;
}

const ModalContext = React.createContext<ModalContextValue>({
  openModal: () => {},
});

interface ModalProviderProps {}

export const ModalProvider: React.FC<React.PropsWithChildren<ModalProviderProps>> = ({
  children,
}: React.PropsWithChildren<ModalProviderProps>) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [progressState, setProgressState] = React.useState<ProgressState>({ value: 0, label: null });
  const [modalProps, setModalProps] = React.useState<ModalOptionsProps>({ Component: () => null });
  const openModal = <T, >(o: ModalOptionsProps<T>) => {
    setModalProps(o);
    setOpen(true);
  };

  const onClose = () => {
    setProgressState({ value: 0, label: null });
    setOpen(false);
  };

  const updateProgress = (label: string, value?: number) => {
    setProgressState({ label, value: value || 0 });
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        updateProgress,
        closeModal: onClose,
      }}
    >
      {children}
      <TheModal
        onClose={onClose}
        open={open}
        progress={{
          ...progressState,
          set: updateProgress,
        }}
        {...modalProps}
      />
    </ModalContext.Provider>
  );
};

export default ModalContext;
