import { ipfsLinkFor, TokenID } from '@elacity-js/lib';
import {
  ApiResponse, AccountActivities, IEvent, NFTArtApiResponse, ICollection,
} from 'src/types';
import { baseURL, ifThumbnail } from 'src/utils';
import type { RawCollection } from './types';

export const transformActivties = (r: ApiResponse<AccountActivities>) => {
  if (r.status !== 'success') {
    return [];
  }

  return ([
    ...(r.data.bids || []),
    ...(r.data.offers || []),
    ...(r.data.listings || []),
    ...(r.data.sold || []),
  ]).map(
    (e: IEvent<'Bid' | 'Sold' | 'Offer' | 'Listing' | 'Transfer'>) => ({
      ...e,
      image: e.imageURL,
      ...(e.event === 'Listing' && {
        // eslint-disable-next-line max-len
        title: `${TokenID.fromObject({ tokenID: e.tokenID, hexTokenID: e.hexTokenID }).toString()} | ${e.name} | Listed for`,
      }),
      ...(e.event === 'Sold' && {
        // eslint-disable-next-line max-len
        title: `${TokenID.fromObject({ tokenID: e.tokenID, hexTokenID: e.hexTokenID }).toString()} | ${e.name} | Sold at`,
      }),
      ...(e.event === 'Offer' && {
        // eslint-disable-next-line max-len
        title: `${TokenID.fromObject({ tokenID: e.tokenID, hexTokenID: e.hexTokenID }).toString()} | ${e.name} | Offer created at`,
      }),
      ...(e.event === 'Bid' && {
        // eslint-disable-next-line max-len
        title: `${TokenID.fromObject({ tokenID: e.tokenID, hexTokenID: e.hexTokenID }).toString()} | ${e.name} | Bid placed for`,
      }),
      ...(e.event === 'Transfer' && {
        // eslint-disable-next-line max-len
        title: `X unit(s) of ${TokenID.fromObject({ tokenID: e.tokenID, hexTokenID: e.hexTokenID }).toString()} | ${e.name} | Transferred`,
      }),
    })
  ).sort(
    (
      a: IEvent<'Bid' | 'Sold' | 'Offer' | 'Listing' | 'Transfer'>,
      b: IEvent<'Bid' | 'Sold' | 'Offer' | 'Listing' | 'Transfer'>
    ) => (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime()
  );
};

export const transformNftResponse = (resp: NFTArtApiResponse) => ({
  ...resp,
  data: {
    ...resp.data,
    tokens: (resp?.data?.tokens || [])
      .map(({ paymentToken, ...tk }) => ({
        ...tk,
        paymentToken: paymentToken?.toLowerCase(),
      })),
  },
});

export const transformCollections = (resp: ApiResponse<RawCollection[]>) => ({
  ...resp,
  data: resp.data
    .map(({ logoImageHash, coverImageHash, ...col }: RawCollection) => ({
      ...col,
      image: baseURL('/static/elacity/bella.png'),
      logoImageHash,
      coverImageHash,
      ...(logoImageHash && logoImageHash.match(/^thumbnail:/g) && {
        logoImageHash: ifThumbnail(logoImageHash, 320),
      }),
      ...(coverImageHash && {
        coverImageHash,
        ...(coverImageHash.match(/^thumbnail:/g) && {
          coverImageHash: ifThumbnail(coverImageHash, 1920),
        }),
      }),
    }))
    .map(
      ({ logoImageHash, coverImageHash, collectionName, erc721Address, ...col }: RawCollection) => ({
        ...col,
        ...(collectionName && ({
          name: collectionName,
        })),
        ...(erc721Address && ({
          address: erc721Address.toLowerCase(),
        })),
        ...(logoImageHash && ({
          image: ipfsLinkFor(logoImageHash),
        })),
        ...(coverImageHash && ({
          cover: ipfsLinkFor(coverImageHash),
        })),
      })
    ) as ICollection[],
});
