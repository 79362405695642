import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import MuiOutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';

const OutlinedInput = styled(MuiOutlinedInput, {
  shouldForwardProp: (prop: string) => prop !== 'rounded',
})<OutlinedInputProps & { rounded?: boolean }>(({ theme, rounded, size }) => ({
  ...(rounded && {
    borderRadius: theme.spacing(10),
  }),
  ...(size === 'small' && {
    fontSize: '0.85rem',
  }),
}));

interface TextInputPprops extends OutlinedInputProps {
  description?: string;
}

const TextInput = ({ label, description, ...props }: TextInputPprops) => (
  <>
    {label && <Typography>{label}</Typography>}
    {description && <Typography sx={{ fontSize: '0.70rem', color: 'text.secondary' }}>{description}</Typography>}
    <OutlinedInput type="text" {...props} />
  </>
);

export default TextInput;
