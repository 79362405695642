/* eslint-disable no-return-await */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import {
  ipfsLink, toIpfsGateway, ChainInfo, OwnerInfo, RawNFTMinimal, convertIfHiveURL, TokenID,
} from '@elacity-js/lib';
import type {
  ApiResponse,
  ILike,
  ICollection,
  NFTArtApiResponse,
  NFTQueryParams,
  INFTArt,
  INFTArtFull,
  IListing,
  ITransferHistory,
  MintedApiRequest,
  IpfsMetadata,
  TokenIdentififer,
} from 'src/types';
import {
  queryForApi, thumbnail, INVALID_THUMBNAIL,
} from 'src/utils';
import { RootState } from '../store';
import { addGalleryData } from '../slices/gallery';
import { api } from './query.base';
import { collectionApi } from './collection';
import { privateQuery } from './privateBaseQuery';
import { transformNftResponse } from './transform';
import {
  LikeBundleRequest,
  LikeItemRequest,
  FetchAccountTokenRequest,
  ItemRequest,
  FetchTokenRequest,
} from './types';

enum Tags {
  Token = 'Token',
  LikedToken = 'LikedToken',
  OwnedToken = 'OwnedToken',
  MintedToken = 'MintedToken',
  VideoAsset = 'VideoAsset',
}

const onQueryStarted = async (_, { queryFulfilled, updateCachedData, requestId }) => {
  const { data } = await queryFulfilled;

  // fetch collection then add it into response
  if (data.status === 'success') {
    updateCachedData((draft) => {
      draft.data.tokens.forEach((tk) => {
        tk.requestId = requestId;
      });
    });
  }
};

const onCacheEntryAdded = async (_, { dispatch, updateCachedData, cacheDataLoaded, cacheEntryRemoved, getCacheEntry, requestId, ...rest }) => {
  try {
    await cacheDataLoaded;

    dispatch(
      addGalleryData({
        requestId,
        result: getCacheEntry().data,
      })
    );
  } catch (e) {
    console.warn('[fetchTokens.cacheDataLoaded.ERR]', e);
  }

  await cacheEntryRemoved;
};

interface RandomTokenRequest {
  size?: number;
  type?: 'single' | 'bundle';
  view?: 'minimal' | 'normal'
}

const tokenApi = api
  .enhanceEndpoints({
    addTagTypes: [
      Tags.Token,
      Tags.LikedToken,
      Tags.OwnedToken,
      Tags.MintedToken,
      Tags.VideoAsset,
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      fetchRandomTokens: builder.query<ApiResponse<RawNFTMinimal[]>, RandomTokenRequest>({
        query: ({ size, type, view }: RandomTokenRequest) => `/nftitems/randomTokens?size=${size || 1}&type=${type || 'single'}&view=${view || 'minimal'}`,
      }),

      fetchMinted: builder.query<NFTArtApiResponse, MintedApiRequest>({
        query: ({ thumbSize, ...attrs }) => {
          const body: MintedApiRequest = { ...attrs };
          return {
            method: 'POST',
            url: '/nftitems/minted',
            body,
          };
        },
        transformResponse: transformNftResponse,
        providesTags: [{ type: Tags.Token, id: '_LIST' }],
      }),

      _fetchTokens: builder.query<NFTArtApiResponse, NFTQueryParams>({
        query: (attrs) => {
          // @ts-ignore
          // eslint-disable-next-line no-undef
          const body: NFTQueryParams = { ...attrs };
          if (!attrs.sortby) {
            body.sortby = 'oldest';
          }

          return {
            url: '/nftitems/fetchTokens',
            method: 'POST',
            body: queryForApi(body),
          };
        },
        // keepUnusedDataFor: 0,
        transformResponse: transformNftResponse,
        providesTags: [{ type: Tags.Token, id: '_LIST' }],
      }),

      // create this one to rely on when cache matters
      // see https://stackoverflow.com/a/69949350/10477320
      filterTokens: builder.mutation<NFTArtApiResponse, NFTQueryParams>({
        queryFn: async ({ thumbSize, ...body }, { dispatch, getState }) => {
          const attrs: NFTQueryParams = { ...body };
          if (!body.collectionAddresses) {
            // force to set the default collection if not set
            const { data: collection } = (await dispatch(collectionApi.endpoints.fetchCollections.initiate())) as {
              data: ApiResponse<ICollection[]>;
            };

            if (collection) {
              attrs.collectionAddresses = [
                // collection.data[0].address,
                ...collection.data.map((c) => c.address),
              ];
            }
          }

          const { data: tokensResult } = await dispatch(
            // eslint-disable-next-line no-underscore-dangle
            tokenApi.endpoints._fetchTokens.initiate(attrs)
          );

          const address = (getState() as RootState)?.user?.address;
          let likeResult = null;
          if (address && address.length > 0) {
          // adding liking data
            const { data: _likeResult } = await dispatch(
              tokenApi.endpoints.getItemsLiked.initiate({
                items: tokensResult.data.tokens.map((tk: INFTArt, index: number) => ({
                  index,
                  isLiked: tk.isLiked,
                  ...(tk.items
                    ? {
                      bundleID: tk._id,
                    }
                    : {
                      tokenID: tk.tokenID,
                      hexTokenID: tk?.hexTokenID,
                      contractAddress: tk.contractAddress,
                    }),
                })),
              })
            );
            likeResult = _likeResult;
          }

          // combine
          const tokens = await Promise.all<INFTArt>(
            (tokensResult?.data?.tokens || []).map(async ({ metadata: _metadata, ...tk }: INFTArt, index: number) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const overrides: any = {};
              let metadata: Partial<IpfsMetadata> = {};
              if (_metadata) {
                metadata = { ..._metadata };
                metadata.image = toIpfsGateway(convertIfHiveURL(metadata.image));
                overrides.imageURL = metadata.image;

                if (!INVALID_THUMBNAIL.includes(tk.thumbnailPath)) {
                  // metadata.image = thumbnail(tk.thumbnailPath, thumbSize || 0);
                  overrides.imageURL = thumbnail(tk.thumbnailPath, thumbSize || 0);
                }
              }

              return {
                ...tk,
                ...likeResult?.data[index],

                // add metadata from IPFS server
                // Attention: this statement face to 429 error
                // we will change its placement into where it is needed
                // @since: https://wausolutions.atlassian.net/browse/ELACITY-124
                // ---------------------------------------------------------------
                // updates 2022-12-21: no longer retrieve metadata from IPFS
                // should be stored in database now
                // @since: https://wausolutions.atlassian.net/browse/ELACITY-625
                ...(tk.tokenURI && {
                  metadata,
                  ...overrides,
                }),
              };
            })
          );

          return {
            data: {
              ...tokensResult,
              data: {
                ...tokensResult.data,
                tokens,
              },
            },
          };
        },
      }),

      fetchTokens: builder.query<NFTArtApiResponse, NFTQueryParams>({
        queryFn: async (body, { dispatch }) => (await dispatch(tokenApi.endpoints.filterTokens.initiate(body))) as { data: NFTArtApiResponse },
        onQueryStarted,
        onCacheEntryAdded,
      }),

      fetchAccountTokens: builder.query<NFTArtApiResponse, FetchAccountTokenRequest>({
        queryFn: async ({ thumbSize, ...body }, { dispatch, getState }) => {
          const attrs: FetchAccountTokenRequest = { ...body };
          const { data: tokensResult } = (await dispatch(
            tokenApi.endpoints.filterTokens.initiate({ ...attrs, thumbSize })
          )) as ApiResponse<NFTArtApiResponse>;

          // load collections
          const collections = (getState() as RootState)?.network?.collections;

          // combine
          const tokens = tokensResult.data.tokens.map((tk: INFTArt) => ({
            ...tk,
            ...(tk.contractAddress && {
              collection: collections[tk.contractAddress],
            }),
          }));

          // @todo: use thumbSize
          console.log({ thumbSize });

          return {
            data: {
              ...tokensResult,
              data: {
                ...tokensResult.data,
                tokens,
              },
            },
          };
        },
        onQueryStarted,
        onCacheEntryAdded,
        providesTags: (result: NFTArtApiResponse) => [
          ...(result?.data?.tokens || []).map(({ hexTokenID, contractAddress }: INFTArt) => ({
            type: Tags.OwnedToken,
            id: `${contractAddress}-${hexTokenID}`,
          })),
          { type: Tags.OwnedToken, id: 'LIST' },
        ],
      }),

      fetchMintedTokens: builder.query<NFTArtApiResponse, MintedApiRequest>({
        queryFn: async ({ thumbSize, ...body }, { dispatch, getState }) => {
          const attrs: MintedApiRequest = { ...body, thumbSize };
          const { data: tokensResult } = (await dispatch(
            tokenApi.endpoints.fetchMinted.initiate(attrs)
          )) as ApiResponse<NFTArtApiResponse>;

          // load collections
          const collections = (getState() as RootState)?.network?.collections;

          // adding liking data
          const hasConnected = Boolean((getState() as RootState)?.user?.token);
          let likeResult = {
            data: {},
          };
          if (hasConnected) {
            const { data: _likeResult } = await dispatch(
              tokenApi.endpoints.getItemsLiked.initiate({
                items: tokensResult.data.tokens.map((tk: INFTArt, index: number) => ({
                  index,
                  isLiked: tk.isLiked,
                  ...(tk.items
                    ? {
                      bundleID: tk._id,
                    }
                    : {
                      tokenID: tk.tokenID,
                      hexTokenID: tk.hexTokenID,
                      contractAddress: tk.contractAddress,
                    }),
                })),
              })
            );
            likeResult = _likeResult;
          }

          // combine
          const tokens = await Promise.all<INFTArt>(
            (tokensResult?.data?.tokens || []).map(async (tk: INFTArt, index: number) => ({
              ...tk,
              ...(likeResult.data[index] || {}),
              ...(tk.contractAddress && {
                collection: collections[tk.contractAddress],
              }),
              ...(!INVALID_THUMBNAIL.includes(tk.thumbnailPath) && {
                imageURL: thumbnail(tk.thumbnailPath, thumbSize),
              }),
            }))
          );

          return {
            data: {
              ...tokensResult,
              data: {
                ...tokensResult.data,
                tokens,
              },
            },
          };
        },
        onQueryStarted,
        onCacheEntryAdded,
        providesTags: (result: NFTArtApiResponse) => [
          ...(result?.data?.tokens || []).map(({ hexTokenID, contractAddress }: INFTArt) => ({
            type: Tags.MintedToken,
            id: `${contractAddress}-${hexTokenID}`,
          })),
          { type: Tags.MintedToken, id: 'LIST' },
        ],
      }),

      notifiyNewToken: builder.mutation({
        queryFn: async () => Promise.resolve({ data: { status: 'OK' } }),
        invalidatesTags: [
          { type: Tags.OwnedToken, id: 'LIST' },
          { type: Tags.Token, id: 'LIST' },
          { type: Tags.Token, id: '_LIST' },
        ],
      }),

      // eslint-disable-next-line max-len
      notifiyTokenRemoval: builder.mutation<ApiResponse<string>, FetchTokenRequest>({
        queryFn: async () => Promise.resolve({ data: { status: 'success', data: 'OK' } }),
        invalidatesTags: () => [
          { type: Tags.OwnedToken, id: 'LIST' },
          { type: Tags.Token, id: 'LIST' },
          { type: Tags.Token, id: '_LIST' },
        ],
      }),

      // eslint-disable-next-line max-len
      notifiyTokenUpdate: builder.mutation<ApiResponse<string>, FetchTokenRequest>({
        queryFn: async () => Promise.resolve({ data: { status: 'success', data: 'OK' } }),
        invalidatesTags: (result, error, { hexTokenID, contractAddress }) => [
          { type: Tags.OwnedToken, id: 'LIST' },
          { type: Tags.OwnedToken, id: `${contractAddress}-${hexTokenID}` },
          { type: Tags.Token, id: 'LIST' },
          { type: Tags.Token, id: '_LIST' },
          { type: Tags.Token, id: `${contractAddress}-${hexTokenID}` },
        ],
      }),

      fetchAuctionTokens: builder.query<NFTArtApiResponse, NFTQueryParams>({
        queryFn: async (body, { dispatch, getState }) => {
          const attrs: NFTQueryParams = { ...body };
          const { data: tokensResult } = (await dispatch(
            tokenApi.endpoints.filterTokens.initiate(attrs)
          )) as ApiResponse<NFTArtApiResponse>;

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const collections = (getState() as any)?.network?.collections;

          const datas = tokensResult.data.tokens.filter((t: INFTArt) => Boolean(t.tokenID));

          // combine initial result with al additional data (collection, likes,...)
          const tokens = datas.map((tk: INFTArt) => ({
            ...tk,
            ...(tk.contractAddress && {
              collection: collections[tk.contractAddress],
            }),
          }));

          return {
            data: {
              ...tokensResult,
              data: {
                ...tokensResult.data,
                tokens,
              },
            },
          };
        },
        providesTags: (result: NFTArtApiResponse) => [
          ...(result?.data?.tokens || []).map(({ hexTokenID, contractAddress }: INFTArt) => ({
            type: Tags.Token,
            id: `${contractAddress}-${hexTokenID}`,
          })),
          { type: Tags.Token, id: 'LIST' },
        ],
      }),

      fetchMyLikes: builder.query<NFTArtApiResponse, FetchAccountTokenRequest & { step?: number }>({
        query: (body) => ({
          url: '/like/getMyLikes',
          method: 'POST',
          body,
        }),
        transformResponse: transformNftResponse,
        providesTags: (result: NFTArtApiResponse) => [
          ...(result?.data?.tokens || []).map(({ hexTokenID, contractAddress }: INFTArt) => ({
            type: Tags.LikedToken,
            id: `${contractAddress}-${hexTokenID}`,
          })),
          { type: Tags.LikedToken, id: 'LIST' },
        ],
        // NOTE: this query will not process `getItemsLiked` so the list returned will not contains
        // information about wether the current user like or not the item provided in the list
        // we will run a cache update for these missing data
        onCacheEntryAdded,
        onQueryStarted: async (
          _: FetchAccountTokenRequest & { step?: number },
          { dispatch, getState, updateCachedData, queryFulfilled, requestId, getCacheEntry }
        ) => {
          await queryFulfilled;

          const collections = (getState() as RootState)?.network?.collections;

          // we will make a `getItemsLiked` for items in redux which doesn't have `isLiked` flag yet
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const likes = (getState() as any)?.token?.likes;
          const items = Object.values(likes)
            .filter((l: ILike) => typeof l.isLiked === 'undefined')
            .map(({ isLiked, ...l }) => l);

          if (items.length > 0) {
            const { data: res } = await dispatch(
              tokenApi.endpoints.getItemsLiked.initiate({ items })
            );

            if (res) {
              const likesMap: Record<string, ILike> = Object.fromEntries(
                (res?.data || []).map((l: ILike) => {
                  const key = l.contractAddress ? `${l.contractAddress}-${l.hexTokenID}` : `${l.bundleID}`;
                  return [key, l];
                })
              );

              updateCachedData((draft) => {
                draft?.data?.tokens.forEach(async (l: INFTArt, i) => {
                  l.collection = collections[l.contractAddress];
                  l.requestId = requestId;
                  const key = l.contractAddress ? `${l.contractAddress}-${l.hexTokenID}` : `${l.bundleID}`;
                  if (likesMap[key] && typeof likesMap[key].isLiked === 'boolean') {
                    l.isLiked = likesMap[key].isLiked;
                  }
                });
              });
            }
          }
        },
      }),

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getSingleItemDetails: builder.query<ApiResponse<INFTArtFull>, FetchTokenRequest>({
        query: (body) => ({
          method: 'POST',
          url: '/nftitems/getSingleItemDetails',
          body,
        }),
        onQueryStarted: async (
          { contractAddress }: FetchTokenRequest,
          { queryFulfilled, dispatch, updateCachedData }
        ) => {
          const { data: item } = await queryFulfilled;

          // fetch collection then add it into response
          if (item.status === 'success') {
            const { data: col } = await dispatch(tokenApi.endpoints.fetchCollection.initiate(contractAddress));

            if (col) {
              updateCachedData((draft) => {
                draft.data.collection = col.data;
              });
            }
          }
        },
        transformResponse: async (
          result: ApiResponse<INFTArtFull & { network: { name: string; chainId: number } }>
        ) => {
          if (result?.status !== 'success') {
            return result;
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const metadata: IpfsMetadata = result.data?.metadata;

          return {
            ...result,
            data: {
              ...result.data,
              tokenURI: result?.data?.uri,
              paymentToken: result?.data?.paymentToken?.toLowerCase(),
              ...(metadata && {
                name: metadata.name,
                description: metadata.description,
                metadata,
              }),
              owner: {
                ...(result.data?.owner || {}),
                ...(result.data?.owner?.avatar && {
                  avatar: (result.data?.owner?.avatar || '').startsWith('data:image/')
                    ? result.data.owner.avatar
                    : (`/ipfs/${result.data?.owner?.avatar}`),
                }),
              } as OwnerInfo,
              chainInfo: {
                network: result?.data?.network.name,
                chainId: result?.data?.network.chainId,
                collection: result?.data?.contractAddress,
              } as ChainInfo,
              listings: (result.data?.listings || []).map((l: IListing) => ({
                ...l,
                image: ipfsLink(`/ipfs/${l.image}`),
              })),
              // nfts: (result?.data?.nfts || []),
            },
          };
        },
        providesTags: (result: ApiResponse<INFTArtFull>) => [
          { type: Tags.LikedToken, id: `${result?.data?.contractAddress}-${result?.data?.hexTokenID}` },
          { type: Tags.OwnedToken, id: `${result?.data?.contractAddress}-${result?.data?.hexTokenID}` },
          { type: Tags.Token, id: `${result?.data?.contractAddress}-${result?.data?.hexTokenID}` },
        ],
      }),

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getBundleDetails: builder.query<any, { bundleID: string }>({
        query: (body) => ({
          method: 'POST',
          url: '/bundle/getBundleByID',
          body,
        }),
      }),

      getTransferHistory: builder.query<ApiResponse<ITransferHistory[]>, ItemRequest & { tokenType: string }>({
        query: ({ tokenID, hexTokenID, contractAddress, tokenType }) => ({
          method: 'POST',
          url: `/nftitems/transfer${tokenType}History`,
          body: {
            hexTokenID,
            tokenID,
            address: contractAddress,
          },
        }),
      }),

      increaseViewCount: builder.mutation<ApiResponse<number>, FetchTokenRequest>({
        query: ({ tokenID, hexTokenID, contractAddress, bundleID }) => ({
          method: 'POST',
          url: '/info/404',
          ...((hexTokenID || tokenID) && {
            url: 'nftitems/increaseViews',
            body: {
              hexTokenID,
              tokenID,
              contractAddress,
            },
          }),
          ...(bundleID && {
            url: 'bundle/increaseViews',
            body: {
              bundleID,
            },
          }),
        }),
      }),

      getTradeHistory: builder.query<ApiResponse<ITransferHistory[]>, ItemRequest>({
        query: ({ tokenID, hexTokenID, contractAddress }) => ({
          method: 'POST',
          url: '/nftitems/tradeHistory',
          body: {
            hexTokenID,
            tokenID,
            address: contractAddress,
          },
        }),
      }),
    }),
  });

const tokenPrivateApi = api
  .enhanceEndpoints({
    addTagTypes: [
      Tags.Token,
      Tags.LikedToken,
      Tags.OwnedToken,
      Tags.MintedToken,
      Tags.VideoAsset,
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getItemsLiked: builder.query<ApiResponse<ILike[]>, { items: ILike[] }>({
        queryFn: async ({ items }, bqApi, extraOptions) => privateQuery(
          {
            url: '/like/getPageLiked',
            method: 'POST',
            body: {
              items: JSON.stringify(items || []),
            },
          },
          bqApi,
          extraOptions
        ) as { data: ApiResponse<ILike[]> },
        keepUnusedDataFor: 1,
      }),

      likeItem: builder.mutation<ApiResponse<number | boolean>, LikeBundleRequest | LikeItemRequest>({
        queryFn: async (body, bqApi, extraOptions) => privateQuery(
          {
            url: '/like/update',
            method: 'POST',
            body,
          },
          bqApi,
          extraOptions
        ) as { data: ApiResponse<number | boolean> },
        invalidatesTags: (
          result,
          error,
          { hexTokenID, contractAddress }: LikeItemRequest
        ) => [
          { type: Tags.LikedToken, id: `${contractAddress}-${hexTokenID}` },
          { type: Tags.LikedToken, id: 'LIST' },
        ],
      }),

      setIsAppropriate: builder.mutation<ApiResponse<boolean>, { tokenID: number; hexTokenID: string; contractAddress: string }>({
        queryFn: async (body, bqApi, extraOptions) => privateQuery(
          {
            url: '/nftitems/setIsAppropriate',
            method: 'POST',
            body,
          },
          bqApi,
          extraOptions
        ) as { data: ApiResponse<boolean> },
      }),

      toggleUnpublish: builder.mutation<{ toggleUnpublish: boolean }, { contractAddress: string; tokenId: TokenID; unpublish: boolean }>({
        // @ts-ignore
        queryFn: async ({ contractAddress, tokenId, unpublish }, bqApi, extraOptions) => privateQuery({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            mutation ToggleUnpublish(
              $contractAddress: String!
              $tokenId: TokenID!
              $unpublish: Boolean!
            ) {
              toggleUnpublish(
                contractAddress: $contractAddress
                tokenId: $tokenId
                unpublish: $unpublish
              )
            }`,
            variables: {
              contractAddress,
              tokenId,
              unpublish,
            },
          },
        },
        bqApi,
        extraOptions),
        // @Dev: this transformResponse is not working, the output remaing the data object, not the boolean response.
        // transformResponse: async (r: GraphqQLResponse<boolean>): Promise<boolean> => r?.data.toggleUnpublish || false,
        invalidatesTags: [
          { type: Tags.VideoAsset, id: 'LIST' },
        ],
      }),

      bulkPublishment: builder.mutation<boolean[], { tokens: Omit<TokenIdentififer, 'tokenID' | 'hexTokenID'>; value: boolean }>({
        // @ts-ignore
        queryFn: async ({ tokens, value }, bqApi, extraOptions) => privateQuery({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            mutation BulkUnpublishAction($tokens: [TokenIdentifierInput]!, $value: Boolean!) {
              toggleUnpublishMultiple(tokens: $tokens, value: $value)
            }`,
            variables: {
              tokens,
              value,
            },
          },
        },
        bqApi,
        extraOptions),
        invalidatesTags: [
          { type: Tags.VideoAsset, id: 'LIST' },
        ],
      }),
    }),
  });

export { tokenApi, tokenPrivateApi };

export const {
  useGetSingleItemDetailsQuery,
  useGetBundleDetailsQuery,
  useFetchAccountTokensQuery,
  useFetchTokensQuery,
  useFetchMyLikesQuery,
  useIncreaseViewCountMutation,
  useNotifiyNewTokenMutation,
  useNotifiyTokenRemovalMutation,
  useNotifiyTokenUpdateMutation,
  useFetchAuctionTokensQuery,
  useGetTransferHistoryQuery,
  useFetchMintedTokensQuery,
  useFetchRandomTokensQuery,
  useGetTradeHistoryQuery,
} = tokenApi;

export const useFetchAsset = (
  params: { contractAddress?: string; tokenID?: TokenID; bundleID?: string },
  // @todo: find out UseQueryOptions location
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any
) => {
  if (!params.bundleID) {
    return tokenApi.useGetSingleItemDetailsQuery(params, options);
  }
  return tokenApi.useGetBundleDetailsQuery(params, options);
};

export const useFetchMyLikesLazyQuery = tokenApi.endpoints.fetchMyLikes.useLazyQuery;
export const useFetchRandomTokensLazyQuery = tokenApi.endpoints.fetchRandomTokens.useLazyQuery;

export const {
  useGetItemsLikedQuery,
  useLikeItemMutation,
  useToggleUnpublishMutation,
  useBulkPublishmentMutation,
  useSetIsAppropriateMutation,
} = tokenPrivateApi;
