import { replace } from 'lodash';
import numeral from 'numeral';

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: number) {
  return numeral(number).format();
}

export function fShortenNumber(number: number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number: number) {
  return numeral(number).format('0.0 b');
}

export const nFormat = (num: number, digits = 0): string => {
  if (!num) {
    return '0';
  }

  if (num <= 1) {
    if (num.toFixed(digits).match(/\./g)) {
      return num
        .toFixed(digits)
        .replace(/0{1,}$/, '')
        .replace(/\.$/, '');
    }
    return num.toFixed(digits);
  }

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((i) => num >= i.value);

  const finalValue = item ? (num / item.value).toFixed(digits).replace(rx, '$1') : '0';

  if (finalValue.match(/\./g)) {
    return finalValue.replace(/0{1,}$/, '').replace(/\.$/, '') + (item?.symbol ?? '');
  }
  return finalValue + (item?.symbol ?? '');
};

// eslint-disable-next-line max-len
export const sumBy = <T extends unknown>(data: T[], key: keyof T): number => data.reduce((acc, item) => acc + Number(item[key]), 0);
