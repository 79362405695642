import React, { useCallback } from 'react';
import { SnackbarKey } from 'notistack';
import { TokenID } from '@elacity-js/lib';
import { useEcosystem, useAddresses } from 'src/lib/web3/hooks';
import useErrorHandler, { StackButton } from 'src/hooks/useErrorHandler';
import { signLicenseRequest, LicenseRequest as SigLicenseReq } from 'src/lib/drm/license';
import { useDispatch, useSelector } from 'src/state/store';
import { PlayerCertificate, add } from 'src/state/slices/player';

export default (ledger: string, tokenId: TokenID) => {
  const { provider, chainId } = useEcosystem();
  const { throwError, closeError } = useErrorHandler();
  const { account, AUTHORITY_GATEWAY } = useAddresses();
  const dispatch = useDispatch();
  const certificate = useSelector((state) => {
    // @ts-ignore
    const s = state.player.certificates[`${ledger}-${tokenId?.toString()}`];
    // signature that is issued beyound 4h ago should be invalidated
    if (s && s.issuedAt + 1000 * 60 * 60 * 4 < Date.now()) {
      console.warn('Existing cetificate exceeds 4h, invalidating it');
      return null;
    }

    if (!account || account?.toLowerCase() !== s?.signer.toLowerCase()) {
      // not same account
      return null;
    }

    return s;
  });

  const registerPlayerSignature = (data: Omit<PlayerCertificate, 'issuedAt'>) => {
    dispatch(
      add({
        ledger,
        tokenIdStr: tokenId?.toString(),
        data: {
          ...data,
          issuedAt: Date.now(),
        },
      })
    );
  };

  const getCertificateFor = useCallback(
    async (uuid: string) => {
      console.log({ uuid, tokenId, ledger });
      if (uuid) {
        const lr = {
          from: account,
          provider,
          verifyingContract: AUTHORITY_GATEWAY,
          chainId,
          message: {
            entitlement: 'play',
            entity: {
              // shell command to decode base64 encoded using xxd
              // echo 'kids[0]' | base64 -d | xxd -p
              contentId: `0x${uuid}`,
              ledger,
              tokenId: tokenId?.toString(),
            },
          },
        };

        const errorKey = throwError(new Error('Please check your wallet and accept the license request to play this Video'), {
          variant: 'warning',
          persist: true,
          action: (key: SnackbarKey) => (
            <StackButton
              size="small" variant="text"
              onClick={() => closeError(key)}
            >
              Dismiss
            </StackButton>
          ),
        });
        const { signature, signer } = await signLicenseRequest<SigLicenseReq>(lr);

        registerPlayerSignature({ signature, signer });
        if (errorKey) {
          closeError(errorKey);
        }

        return { signature, signer };
      }

      return { signature: null, signer: null };
    },
    [AUTHORITY_GATEWAY, account, provider, chainId, ledger, tokenId.hexTokenID]
  );

  return {
    certificate,
    registerPlayerSignature,
    getCertificateFor,
  };
};
