import OAuthAdapter from './oauth.adapter';

interface InstagramOAuthOptions {
  clientId: string;
}

export default class InstagramOAuthAdapter extends OAuthAdapter {
  constructor({ clientId }: InstagramOAuthOptions) {
    super('instagram', {
      authorizeURLBuilder: {
        baseURL: 'https://api.instagram.com',
        path: '/oauth/authorize',
        query: {
          client_id: clientId,
          redirect_uri: `${window.location.origin}/callback/instagram`,
          // scope: 'instagram_graph_user_profile',
          scope: 'user_profile',
          response_type: 'code',
        },
      },
    });
  }
}
