import React from 'react';
import {
  Stack, IconButton as MuiIconButton, Tooltip, SxProps,
} from '@mui/material';
import { Theme, styled } from '@mui/material/styles';
import { NETWORKS } from './constants';
import { EmptyLogo, LogoMap } from './Logo';
import { useNetworkSupportCheck } from './useNetworkRestriction';

const IconButton = styled(MuiIconButton)(({ disabled }) => ({
  ...(disabled && {
    '& svg': {
      filter: 'grayscale(0.3) opacity(0.4)',
    },
  }),
}));

const NetworkSelectorContainer = styled(Stack, {
  name: 'NetworkSelectorRoot',
})(({ theme }) => ({
  '& .MuiIconButton-root': {
    margin: theme.spacing(0, 1),
  },
}));

interface NetworkSelectorProps {
  chainId?: number;
  handleChange?: (chainId: number) => (e?: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  sxBuilder?: (networkChainId: number, selectedChainId: number) => SxProps;
  sx?: SxProps;
}

const NetworkSelector = ({ chainId: selectedChainId, handleChange, sxBuilder, sx }: NetworkSelectorProps) => {
  const { checkNetworkSupport } = useNetworkSupportCheck();
  const handleChainChanged = React.useCallback(
    (chainId: number) => async (e: React.MouseEvent<HTMLButtonElement>) => {
      await handleChange?.(chainId).call(null, e);
    },
    [selectedChainId]
  );

  return (
    <NetworkSelectorContainer flexDirection="row" justifyContent="center" sx={sx}>
      {Object.entries(NETWORKS)
        .sort((a, b) => a[1].order - b[1].order)
        .filter(([k, n]) => n.mainnet === (process.env.REACT_APP_NETWORK === 'main'))
        .filter(([k, n]) => ['20', '21'].includes(k))
        .map(([key, n]) => {
          const ChainLogo = LogoMap[n.symbol] || EmptyLogo;

          return (
            <Tooltip key={key} title={n.name}>
              <div>
                <IconButton
                  disabled={!checkNetworkSupport(n.chainId)}
                  onClick={handleChainChanged?.(n.chainId)}
                  sx={{
                    ...sxBuilder?.(n.chainId, selectedChainId),
                  }}
                >
                  <ChainLogo sx={{ color: (t: Theme) => (t.palette.mode === 'light' ? '#000' : '#fff') }} />
                </IconButton>
              </div>
            </Tooltip>
          );
        })}
    </NetworkSelectorContainer>
  );
};

NetworkSelector.defaultProps = {
  chainId: process.env.REACT_APP_NETWORK === 'main' ? 20 : 21,
};

export default NetworkSelector;
