/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import { TokenID } from '@elacity-js/lib';
import useAuction from './useAuction';
import useListing from './useListing';
import useWeb3Application from './useWeb3Application';

const useIsItemSaleOrAuctionOnSc = (item: {
  id: string;
  address: string;
  contractAddress: string;
  dataVersion?: { auction: number; marketplace: number };
}) => {
  const { active, library, chainId } = useWeb3Application();
  const [isOnAuction, setIsOnAuction] = useState<boolean>(false);
  const [isOnSale, setIsOnSale] = useState<boolean>(false);

  const { isItemOnAuctionOnSc } = useAuction({ nftAddress: item.contractAddress });
  const { isItemOnMarketplaceOnSc } = useListing({ nftAddress: item.contractAddress });

  useEffect(() => {
    library?._ready().then(async () => {
      if (item.address && item.id) {
        isItemOnAuctionOnSc(TokenID.from(item.id)).then((isAuction: boolean) => {
          setIsOnAuction(isAuction);
        });

        isItemOnMarketplaceOnSc(TokenID.from(item.id), item.address?.toLowerCase()).then((isSale: boolean) => {
          setIsOnSale(isSale);
        });
      }
    });
  }, [chainId, item.address, active, library, item?.dataVersion?.marketplace]);

  return { isOnAuction, isOnSale };
};

export default useIsItemSaleOrAuctionOnSc;
