/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { encodeBase64Url, popupWindowCenter } from 'src/utils';
import { IVerificatorAdapter, IVerificatorProps } from '../types';

interface EmailFlowAdapterOptions {
  formURL: string;
}

const encoder = new TextEncoder();

export default class EmailFlowAdapter implements IVerificatorAdapter {
  protected options: EmailFlowAdapterOptions;

  protected providerName: string;

  /**
   * We set this value in localStorage per session
   */
  protected codeVerifier: string;

  protected codeChallengeMethod: string;

  constructor(providerName: string, options: EmailFlowAdapterOptions) {
    this.providerName = providerName;
    this.options = options;
  }

  protected genegateSalt() {
    return encodeBase64Url(crypto.getRandomValues(new Uint8Array(32)));
  }

  /**
   * https://www.rfc-editor.org/rfc/rfc7636.html#section-4.2
   *
   * @param codeVerifier
   * @returns
   */
  protected async calculatePKCECodeChallenge(codeVerifier: string): Promise<string> {
    switch (this.codeChallengeMethod) {
      case 'S256':
        return encodeBase64Url(
          await crypto.subtle.digest({ name: 'SHA-256' }, encoder.encode(codeVerifier))
        );
      case 'plain':
      default:
        return codeVerifier;
    }
  }

  resolveClickableProps(): IVerificatorProps {
    return {
      onClick: async () => {
        popupWindowCenter({ url: this.options.formURL, title: 'emailForm', w: 380, h: 520 });
      },
    };
  }
}
