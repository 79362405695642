import { api } from './query.base';
import { privateQuery } from './privateBaseQuery';
import { GraphqQLResponse } from '../../types';

interface LikeAssetRequest {
  contractAddress: string;
  tokenId: string;
  address?: string;
}

interface Reaction {
  count: number;
  isAmong?: boolean;
}

enum Tags {
  Reaction = 'Reaction',
}

const likeApi = api
  .enhanceEndpoints({
    addTagTypes: [Tags.Reaction],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      fetchDislikesByToken: builder.query<Reaction, LikeAssetRequest>({
        query: ({ contractAddress, tokenId, address }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            query FetchDislikesByToken($contractAddress: String!, $tokenId: TokenID!, $address: String) {
              fetchDislikesByToken(contractAddress: $contractAddress, tokenId: $tokenId, address: $address) {
                count
                isAmong
              }
            }`,
            variables: {
              contractAddress,
              tokenId,
              address,
            },
          },
        }),
        transformResponse: async (
          r: GraphqQLResponse<Reaction>
        ): Promise<Reaction> => r.data?.fetchDislikesByToken || { count: 0 },
        providesTags: (result, error, arg) => [{ type: Tags.Reaction, id: `${arg.contractAddress}-${arg.tokenId}` }],
      }),
      fetchLikesByToken: builder.query<Reaction, LikeAssetRequest>({
        query: ({ contractAddress, tokenId, address }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            query FetchLikesByToken($contractAddress: String!, $tokenId: TokenID!, $address: String) {
              fetchLikesByToken(contractAddress: $contractAddress, tokenId: $tokenId, address: $address) {
                count
                isAmong
              }
            }`,
            variables: {
              contractAddress,
              tokenId,
              address,
            },
          },
        }),
        transformResponse: async (
          r: GraphqQLResponse<Reaction>
        ): Promise<Reaction> => r.data?.fetchLikesByToken || { count: 0 },
        providesTags: (result, error, arg) => [{ type: Tags.Reaction, id: `${arg.contractAddress}-${arg.tokenId}` }],
      }),
      toggleLike: builder.mutation<boolean, LikeAssetRequest>({
      // @ts-ignore
        queryFn: async ({ contractAddress, tokenId }, bqApi, extraOptions) => privateQuery({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
              mutation ToggleLike($contractAddress: String!, $tokenId: TokenID!) {
                toggleLike(contractAddress: $contractAddress, tokenId: $tokenId)
              }`,
            variables: {
              contractAddress,
              tokenId,
            },
          },
        },
        bqApi,
        extraOptions),
        transformResponse: async (r: GraphqQLResponse<boolean>): Promise<boolean> => r.data?.toggleLike || false,
        invalidatesTags: (result, error, arg) => [{ type: Tags.Reaction, id: `${arg.contractAddress}-${arg.tokenId}` }],
      }),
      toggleDislike: builder.mutation<boolean, LikeAssetRequest>({
      // @ts-ignore
        queryFn: async ({ contractAddress, tokenId }, bqApi, extraOptions) => privateQuery({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
              mutation ToggleDislike($contractAddress: String!, $tokenId: TokenID!) {
                toggleDislike(contractAddress: $contractAddress, tokenId: $tokenId)
              }`,
            variables: {
              contractAddress,
              tokenId,
            },
          },
        }, bqApi, extraOptions),
        transformResponse: async (r: GraphqQLResponse<boolean>): Promise<boolean> => r.data?.toggleDislike || false,
        invalidatesTags: (result, error, arg) => [{ type: Tags.Reaction, id: `${arg.contractAddress}-${arg.tokenId}` }],
      }),
    }),
  });

export { likeApi };

export const {
  useFetchLikesByTokenQuery,
  useFetchDislikesByTokenQuery,
  useToggleLikeMutation,
  useToggleDislikeMutation,
} = likeApi;
