import React, { PropsWithChildren, CSSProperties } from 'react';
import { Box, Drawer as MuiDrawer } from '@mui/material';
import {
  styled, alpha, darken,
} from '@mui/material/styles';
import type { DrawerProps } from '@mui/material/Drawer';
import { layout } from '../../config';

const minimizedWidth = layout.DRAWER_WIDTH_MIN;

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    ...theme.glassy(darken(theme.palette.background.paper, 0.1), 0.1, 3),
    [theme.breakpoints.down('lg')]: {
      background: alpha(theme.palette.background.paper, 0.9),
    },
  },
}));

interface DrawerInnerProps extends DrawerProps {
  minimized?: boolean;
}

const DrawerInner = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== 'minimized',
  name: 'MinimizableDrawerInner',
  slot: 'Root',
})<DrawerInnerProps>(({ minimized }) => ({
  maxWidth: layout.DRAWER_WIDTH,
  overflowX: 'hidden',

  // minimized overrides
  ...(minimized && {
    width: minimizedWidth,
    maxWidth: minimizedWidth,
  }),
}));

interface DrawerUIProps extends DrawerProps {
  minimized?: boolean;
  mbCorrection?: number;
  innerBackground?: CSSProperties['background'];
}

const DrawerUI = ({
  children,
  minimized,
  mbCorrection,
  innerBackground,
  PaperProps,
  ...props
}: PropsWithChildren<DrawerUIProps>) => (
  <Drawer
    {...props}
    PaperProps={{
      sx: {
        width: minimized ? layout.DRAWER_WIDTH_MIN : layout.DRAWER_WIDTH,
        bgcolor: 'background.default',
        ...PaperProps?.sx,
      },
      ...PaperProps,
    }}
  >
    <DrawerInner
      minimized={minimized}
      sx={{
        overflowY: {
          md: 'auto',
          lg: 'auto',
        },
        // this margin is aimed to compensate the fixed content below
        mb: mbCorrection,
        bgcolor: innerBackground,
      }}
    >
      {children}
    </DrawerInner>
  </Drawer>
);

DrawerUI.defaultProps = {
  minimized: false,
  mbCorrection: 8,
  innerBackground: 'transparent',
};

export default DrawerUI;
