import {
  useEffect, useState, useMemo,
} from 'react';
import { TokenID } from '@elacity-js/lib';

interface RoyaltyResult {
  status?: string;
  data: {
    royalty?: number;
    message?: string;
  }
}

interface Input {
  token: [string, TokenID];
  value: number;
  fee?: number;
  reservePrice?: number;
}

export default ({ token, value, fee, reservePrice }: Input) => {
  const reserveValue = reservePrice || 0;

  // the platform Fee is set to 2% (default value)
  const platformFeePercent = (fee || 2) / 100;

  const [royaltyPercent, setRoyalty] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded && token && token.length === 2) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/nftitems/royalties/${token[0]}/${token[1]}`)
        .then(
          (r) => r.json()
        ).then(
          (m: RoyaltyResult) => {
            if (!m?.status || m?.status !== 'success') {
              setRoyalty(0);
              console.log('failed to retrieve royalty', m?.data);
              return;
            }

            setLoaded(true);
            setRoyalty(Number(m?.data?.royalty || 0) / 100);
          }
        ).catch(
          (e) => {
            console.log(`failed to fetch royalty value: ${e.message}`);
          }
        );
    }
  }, [token]);

  const platformFee = useMemo(
    () => (value >= reserveValue ? value - reserveValue : value) * platformFeePercent, [value]
  );

  const royaltyValue = useMemo(
    () => (((value - platformFee) * royaltyPercent) / 100), [value, royaltyPercent, platformFee]
  );

  const earningValue = useMemo(
    () => value - royaltyValue - platformFee, [value, royaltyValue, platformFee]
  );

  return {
    royalty: royaltyPercent,
    royaltyValue,
    platformFee,
    earningValue,
  };
};
