import { hexlify } from '@ethersproject/bytes';
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import { toUtf8Bytes } from '@ethersproject/strings';

interface SignMessageOption {
  account: string;
  provider: Web3Provider
}

/**
 * Process signature from a string content
 *
 * @param message
 * @param param1
 * @returns signature
 */
export const signMessage = async (message: string, { account, provider }: SignMessageOption): Promise<string> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const signer = provider?.getSigner() as JsonRpcSigner & { connection?: any };
  if (signer?.connection?.wc) {
    // only for walletconnect case
    return signer?.connection?.wc.signPersonalMessage([hexlify(toUtf8Bytes(message)), account]);
  }

  return provider?.getSigner().signMessage(message);
};
