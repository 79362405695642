/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { CSSProperties } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { ElastosNewLogoMark } from 'src/assets/elacity';
import { links } from 'src/config';
import MiniNavItem from './MiniNavItem';

const ImgLogo = styled('img')(({ theme }) => ({
  width: 95,
  height: 20,
  marginLeft: theme.spacing(1),
  marginTop: theme.spacing(0.3),
}));

const Container = styled(Box, {
  shouldForwardProp: (prop: string) => !['position'].includes(prop),
})<BoxProps & { position?: CSSProperties['position'] }>(({ theme, position }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),

  position: position || 'absolute',
  bottom: 0,
  width: '100%',
  left: 0,
}));

interface PoweredDocProps {
  minimized?: boolean;
  position?: CSSProperties['position'];
}

const PoweredDoc = ({ minimized, position }: PoweredDocProps) => {
  const theme = useTheme();
  const mode = theme.palette.mode === 'light' ? 'dark' : 'light';

  if (minimized) {
    return (
      <Container position={position}>
        <MiniNavItem
          id="mini-nav-bottom"
          item={{
            title: '',
            path: '',
            icon: <ElastosNewLogoMark sx={{ color: theme.palette.mode === 'light' ? 'black' : 'white' }} />,
            children: [
              { external: true, title: 'Twitter', path: links.twitter },
              { external: true, title: 'Discord', path: links.discord },
              { external: true, title: 'Documentation', path: links.documentation },
            ],
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={
            {
              // display: 'contents',
            }
          }
        />
      </Container>
    );
  }

  return (
    <Container position={position}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: theme.spacing(2),
          width: '100%',
        }}
      >
        <Typography>Powered by</Typography>
        <ImgLogo crossOrigin="anonymous" src={`/elastos_new_logo_${mode}.png`} alt="elastos" />
      </Box>
    </Container>
  );
};

export default PoweredDoc;
