import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  FetchBaseQueryMeta,
  type BaseQueryFn,
  type FetchArgs,
  type FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Web3Provider } from '@ethersproject/providers';
import { verifyMessage } from '@ethersproject/wallet';
import type { Message, SignatureResult } from '@elacity-js/lib';
import { JsonLocalStorage } from 'src/lib/storage';
import { signMessage } from 'src/lib/web3/signature';
import { JWT_VAULT } from 'src/api/client';
import { defaultSignatureMessage } from 'src/constants';
import { UserState } from 'src/state/slices/types';
import { AuthResponse } from './types';

interface RootState {
  user: UserState;
}

const baseUrl = process.env?.REACT_APP_BACKEND_URL || 'http://localhost:3001';

const getTokenFrom = async (state: RootState) => new Promise<string>((resolve, reject) => {
  const { token } = state.user;
  if (!token) {
    reject(new Error('No token found'));
  } else {
    resolve(token);
  }
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const graphQLRequest = async <T, P extends unknown = any>(payload: P) => {
  const res = await fetch(`${baseUrl}/2.0/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (res.status >= 400) {
    const err = await res.json();
    const { errors } = err;
    if (errors) {
      const [message] = errors;
      err.statusText = message;
    }
    return Promise.reject(err);
  }

  const response = await res.json() as {data: T};

  return (response.data as T);
};

const getSignature = async (
  account: string,
  library: Web3Provider,
  message: Message = defaultSignatureMessage
): Promise<SignatureResult> => {
  if (!account) {
    return Promise.reject(new Error('no wallet connected'));
  }

  // get nonce
  const { getNonce: nonce } = await graphQLRequest<{ getNonce: number }>({
    query: 'query ($address: String!) { getNonce(address: $address)}',
    variables: { address: account },
  });

  if (typeof nonce !== 'number') {
    Promise.reject(new Error('failed to get nonce'));
    return {};
  }

  const m = typeof message === 'function'
    ? message(nonce)
    : message;

  const signature = await signMessage(String(m), { provider: library, account });
  const address = verifyMessage(m, signature);

  return {
    address,
    signature,
  };
};

const sign = async (account, api) => {
  const { dispatch, extra: { library } } = api;
  if (account) {
    dispatch({ type: 'user/setUserRequestSigning', payload: { isRequestingSigning: true } });

    const storage = new JsonLocalStorage<Record<string, AuthResponse &
    {createdAt?: number, signed?: boolean}>>(JWT_VAULT, {});

    try {
      const sig = await getSignature(account, library);
      const { userLogin: credentials } = await graphQLRequest<{ userLogin: AuthResponse }>({
        query: `
        mutation UserLogin($address: String!, $signature: String!) {
          userLogin(address: $address, signature: $signature) {
            token
            expiresIn
            isAdmin
          }
        }`,
        variables: { ...sig },
      });

      const userData = {
        ...credentials,
        createdAt: Date.now(),
        signed: true,
      };

      storage.set({
        [account]: userData,
      });

      dispatch({ type: 'user/setUserToken', payload: { account, ...userData } });

      return Promise.resolve(credentials);
    } catch (err) {
      return Promise.reject(new Error(`failed to signin: ${err.message}`));
    } finally {
      dispatch({ type: 'user/setUserRequestSigning', payload: { isRequestingSigning: false } });
    }
  }

  return Promise.reject(new Error('no wallet connected'));
};

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: async (headers, { getState }) => {
    // console.log('[privateBaseQuery] prepareHeaders', (getState() as RootState)?.user);
    try {
      const token = await getTokenFrom(getState() as RootState);

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
    } catch (e) {
      console.error('Authenticated API failed', e);
    }

    return headers;
  },
});

/**
 * A custom baseQuery
 * https://redux-toolkit.js.org/rtk-query/usage/customizing-queries
 * @param args
 * @param api
 * @param extraOptions
 * @returns
 */
export const privateBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta> =
async (args, api, extraOptions) => {
  // console.log('[privateBaseQuery]', { args, api, extraOptions }, (api.getState() as RootState)?.user);
  const { user: { address, token, createdAt, expiresIn } } = (api.getState() as RootState);
  try {
    // tokens usually expire within 14 Days, when created date exceeds that time, we need to re-sign
    const isTokenExpired = (createdAt && expiresIn) ? (Number(createdAt) + Number(expiresIn)) < Date.now() : false;
    if ((!token && address) || isTokenExpired) {
      await sign(address, api);
    }
    return await baseQuery(args, api, extraOptions);
  } catch (err) {
    return { error: err.message };
  }
};

export const privateQuery = privateBaseQuery;
