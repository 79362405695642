import React from 'react';

interface MobileExploreContextValue {
  isMobileExploreOpen: boolean;
  openMobileExplore: () => void;
  closeMobileExplore: () => void;
}

const MobileExploreContext = React.createContext<MobileExploreContextValue>({
  isMobileExploreOpen: false,
  openMobileExplore: () => {},
  closeMobileExplore: () => {},
});

interface MobileExploreProviderProps {}

export const MobileExploreProvider: React.FC<React.PropsWithChildren<MobileExploreProviderProps>> = ({
  children,
}: React.PropsWithChildren<MobileExploreProviderProps>) => {
  const [isMobileExploreOpen, setIsMobileExploreOpen] = React.useState<boolean>(false);

  const openMobileExplore = () => {
    setIsMobileExploreOpen(true);
  };

  const closeMobileExplore = () => {
    setIsMobileExploreOpen(false);
  };

  return (
    <MobileExploreContext.Provider
      value={{
        isMobileExploreOpen,
        openMobileExplore,
        closeMobileExplore,
      }}
    >
      {children}
    </MobileExploreContext.Provider>
  );
};

export default MobileExploreContext;
