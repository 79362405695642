import React from 'react';
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import store, { dynamicMiddleware } from 'src/state/store';
import { EcosystemProvider } from './Ecosystem';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLibrary = (provider: any): Web3Provider => {
  const lib = new Web3Provider(provider);
  lib.pollingInterval = 12000;
  if (store && dynamicMiddleware) {
    dynamicMiddleware.updateExtraArgument({ library: lib });
  }
  return lib;
};

const Web3ReactProviderElacity = createWeb3ReactRoot('elacity');

interface Web3ProviderProps {}

const Web3AdapterProvider: React.FC<React.PropsWithChildren<Web3ProviderProps>> = ({
  children,
}: React.PropsWithChildren<Web3ProviderProps>) => (
  <Web3ReactProvider getLibrary={getLibrary}>
    <Web3ReactProviderElacity getLibrary={getLibrary}>
      <EcosystemProvider defaultChainId={Number(process.env.REACT_APP_CHAIN_ID || 20)}>{children}</EcosystemProvider>
    </Web3ReactProviderElacity>
  </Web3ReactProvider>
);

export default Web3AdapterProvider;
