/* eslint-disable no-useless-concat */
export const baseURL = (path?: string) => {
  if (path?.startsWith('http')) {
    return path;
  }

  const publicURL = process.env.PUBLIC_URL || '/';

  const result = [
    publicURL.replace(/\/$/, ''),
    (path || '').replace(/\/$/, '').replace(/^\//, ''),
  ].join('/');

  return result.length > 1 ? result.replace(/\/$/, '') : result;
};
