import {
  api, profileFragment, commentFragment,
} from './query.base';
import { privateQuery } from './privateBaseQuery';
import { Comment, GraphqQLResponse } from '../../types';

interface DeleteCommentRequest {
  id: string;
}

export interface CommentInput {
  contractAddress: string;
  tokenId: string;
  content: string;
  parent?: string;
}

interface CommentResult {
  total: number;
  offset?: number;
  data?: Comment[];
}

export interface FilterPaginationInput {
  offset?: number;
  limit?: number;
  sortBy?: string;
  searchBy?: string;
  sort?: Record<string, number>;
}

export interface CommentQueryInput {
  contractAddress: string;
  tokenId: string;
  parent?: string;
  account?: string;
}

enum Tags {
  CommentTag = 'Comment',
}

const commentApi = api.enhanceEndpoints({
  addTagTypes: [Tags.CommentTag],
}).injectEndpoints({
  endpoints: (builder) => ({
    fetchComments: builder.query<CommentResult, { query: CommentQueryInput; filters: FilterPaginationInput }>({
      query: ({ query, filters }) => ({
        url: '/2.0/graphql',
        method: 'POST',
        body: {
          query: `
            ${profileFragment}
            ${commentFragment}
            query FetchComments($query: CommentQueryInput, $filters: FilterPaginationInput) {
              fetchComments(query:$query, filters: $filters) {
                total
                offset
                data {
                  ...commentFields
                  replies {
                    total
                    offset
                  }
                }
              }
            }`,
          variables: {
            query,
            filters,
          },
        },
      }),
      transformResponse: async (r: GraphqQLResponse<CommentResult>): Promise<CommentResult> => r.data?.fetchComments,
      providesTags: (result, error, arg) => [
        { type: Tags.CommentTag, id: `${arg.query.contractAddress}-${arg.query.tokenId?.toString()}` },
      ],
    }),
    deleteComment: builder.mutation<Comment, DeleteCommentRequest>({
      // @note not in use for now
      query: ({ id }) => ({
        url: '/2.0/graphql',
        method: 'POST',
        body: {
          query: `
            mutation deleteComment($id: String!) {
              deleteComment(id: $id) {
                _id
              }
            }`,
          variables: {
            id,
          },
        },
      }),
      transformResponse: async (r: GraphqQLResponse<Comment>): Promise<Comment> => r.data?.deleteComment,
    }),
  }),
});

const commentPrivateApi = api.enhanceEndpoints({
  addTagTypes: [Tags.CommentTag],
}).injectEndpoints({
  endpoints: (builder) => ({
    createComment: builder.mutation<Comment, { input: CommentInput }>({
      // @ts-ignore
      queryFn: async ({ input }, bqApi, extraOptions) => privateQuery({
        url: '/2.0/graphql',
        method: 'POST',
        body: {
          query: `
            ${profileFragment}
            mutation createComment($input: CommentInput!) {
              createComment(input: $input) {
                _id
                content
                by {
                  ...profileFields
                }
                createdAt
              }
            }`,
          variables: {
            input,
          },
        },
      },
      bqApi,
      extraOptions),
      transformResponse: async (r: GraphqQLResponse<Comment>): Promise<Comment> => r.data?.createComment,
      invalidatesTags: (result, error, arg) => [
        ...((result && !error) ? [
          { type: Tags.CommentTag, id: `${arg.input.contractAddress}-${arg.input.tokenId?.toString()}` },
        ] : []),
      ],
    }),

    toggleLikeComment: builder.mutation<number, { id: string }>({
      // @ts-ignore
      queryFn: async ({ id }, bqApi, extraOptions) => privateQuery({
        url: '/2.0/graphql',
        method: 'POST',
        body: {
          query: `
            mutation ToggleLike($id: String!) {
              toggleLikeComment(id: $id)
            }`,
          variables: {
            id,
          },
        },
      },
      bqApi,
      extraOptions),
      transformResponse: async (r: GraphqQLResponse<number>): Promise<number> => r.data?.toggleLikeComment,
    }),
    toggleDislikeComment: builder.mutation<number, { id: string }>({
      // @ts-ignore
      queryFn: async ({ id }, bqApi, extraOptions) => privateQuery({
        url: '/2.0/graphql',
        method: 'POST',
        body: {
          query: `
            mutation ToggleDislike($id: String!) {
              toggleDislikeComment(id: $id)
            }`,
          variables: {
            id,
          },
        },
      },
      bqApi,
      extraOptions),
      transformResponse: async (r: GraphqQLResponse<number>): Promise<number> => r.data?.toggleDislikeComment,
    }),
  }),
});

export { commentApi, commentPrivateApi };

export const {
  useFetchCommentsQuery,
  useLazyFetchCommentsQuery,
  useDeleteCommentMutation,
} = commentApi;

export const {
  useCreateCommentMutation,
  useToggleLikeCommentMutation,
  useToggleDislikeCommentMutation,
} = commentPrivateApi;
