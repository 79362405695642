/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { encodeBase64Url, popupWindowCenter } from 'src/utils';
import { IVerificatorAdapter, IVerificatorProps } from '../types';

interface URLBuilderOptions {
  baseURL: string;
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: Record<string, any>;
}

interface OAuthFlowAdapterOptions {
  authorizeURLBuilder: URLBuilderOptions;
  pkce?: {
    codeChallengeMethod: string;
  }
}

const encoder = new TextEncoder();

export default class OAuthFlowAdapter implements IVerificatorAdapter {
  protected options: OAuthFlowAdapterOptions;

  protected providerName: string;

  /**
   * We set this value in localStorage per session
   */
  protected codeVerifier: string;

  protected codeChallengeMethod: string;

  constructor(providerName: string, options: OAuthFlowAdapterOptions) {
    this.providerName = providerName;
    this.options = options;

    this.setCodeChallenge(options.pkce?.codeChallengeMethod || 'plain');
  }

  public setCodeChallenge(method: string) {
    const codeVerifier = this.genegateCodeVerifier();
    localStorage.setItem(`__oauth2_${this.providerName}_code_challenge`, codeVerifier);

    this.codeChallengeMethod = method;
    this.codeVerifier = codeVerifier;
  }

  /**
   * Generate a code verifier as specified
   * https://www.rfc-editor.org/rfc/rfc7636.html#section-4
   *
   * @returns
   */
  protected genegateCodeVerifier() {
    return encodeBase64Url(crypto.getRandomValues(new Uint8Array(32)));
  }

  /**
   * https://www.rfc-editor.org/rfc/rfc7636.html#section-4.2
   *
   * @param codeVerifier
   * @returns
   */
  protected async calculatePKCECodeChallenge(codeVerifier: string): Promise<string> {
    switch (this.codeChallengeMethod) {
      case 'S256':
        return encodeBase64Url(
          await crypto.subtle.digest({ name: 'SHA-256' }, encoder.encode(codeVerifier))
        );
      case 'plain':
      default:
        return codeVerifier;
    }
  }

  /**
   * Build the authorize URL
   *
   * @returns
   */
  protected async authorizeURL(): Promise<string> {
    const { authorizeURLBuilder } = this.options;
    const { baseURL, path, query } = authorizeURLBuilder;

    let code_challenge = '';

    try {
      if (this.codeVerifier && this.codeVerifier.length > 0) {
        code_challenge = await this.calculatePKCECodeChallenge(this.codeVerifier);
        console.log('[OAuth.Adapter] Acquired code challenge', [code_challenge]);
      }
    } catch (e) {
      console.error('[OAuth.Adapter]', e.message);
    }

    const url = new URL(path, baseURL);
    const q = Object.entries({
      ...query,
      ...((this.options?.pkce && code_challenge && code_challenge.length > 0) && {
        code_challenge,
        code_challenge_method: this.codeChallengeMethod,
      }),
    }).map(([key, value]) => `${key}=${encodeURIComponent(value)}`);

    console.log('[OAuth.Adapter] Authorization URL components', [url, q, query, code_challenge]);

    return `${url.toString()}?${q.join('&')}`;
  }

  resolveClickableProps(): IVerificatorProps {
    return {
      onClick: async () => {
        const authorizeURL = await this.authorizeURL();
        popupWindowCenter({ url: authorizeURL, title: 'authorize', w: 380, h: 520 });
      },
    };
  }
}
