/* eslint-disable max-len */
import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export default createSvgIcon(
  <svg viewBox="0 0 156 156" enableBackground="new 0 0 156 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="77.5713" cy="77.5713" r="77.5713" fill="#ED4E33" />
    <path
      d="M63.5871 41.5434C42.4254 49.6802 40.4869 70.5184 34.419 80.2503C28.2792 90.0978 14.2132 95.5313 16.1367 100.55C18.0601 105.568 32.1429 100.178 43.2844 103.38C54.2939 106.544 69.655 120.732 90.8167 112.595C101.597 108.45 109.403 99.9185 112.993 89.8133C113.379 88.7286 112.657 87.5686 111.512 87.4614C110.8 87.3945 110.117 87.7678 109.796 88.4072C106.549 94.8851 100.979 100.22 93.6894 103.022C81.6555 107.649 68.4886 104.016 60.4019 94.9052C58.5653 92.8363 56.9944 90.4811 55.7544 87.8782C55.4135 87.1618 55.0893 86.4337 54.8019 85.6804C54.5128 84.9272 54.2671 84.1689 54.0415 83.4073C60.4019 80.4378 67.7415 77.2926 76.0622 74.0938C84.2207 70.9569 91.6456 68.4126 98.2566 66.3772C102.729 65.0012 106.828 63.8563 110.529 62.9206C110.797 62.8536 111.061 62.7867 111.323 62.7214C111.883 62.5825 112.458 62.8821 112.665 63.4211L112.669 63.4311C112.791 63.7508 112.896 64.0722 113.009 64.3936C113.741 66.4676 114.29 68.5633 114.652 70.664C114.811 71.583 115.814 72.0835 116.636 71.6416C119.674 70.0078 122.453 68.4143 124.936 66.881C134.19 61.1731 139.32 56.3322 138.267 53.5887C137.216 50.8435 130.17 50.6862 119.48 52.6446C116.083 53.2673 112.316 54.1042 108.257 55.1404C107.555 55.3195 106.845 55.5053 106.126 55.6961C102.71 56.6034 99.1072 57.6428 95.3555 58.8062C88.3785 60.9705 80.8934 63.5634 73.1661 66.5345C65.9367 69.3148 59.085 72.1973 52.8015 75.0697C52.7247 62.8788 60.0827 51.354 72.1166 46.7274C79.4045 43.9253 87.1084 44.1547 93.8498 46.7944C94.5149 47.0555 95.272 46.8747 95.7583 46.3475C96.537 45.5021 96.298 44.1563 95.287 43.6073C85.8684 38.507 74.3676 37.3989 63.5871 41.5434Z"
      fill="white"
    />
  </svg>,
  'EvmosLogo'
);
