/* eslint-disable no-return-await */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { ipfsLink } from '@elacity-js/lib';
import type {
  ApiResponse,
  ApiResponseWithTotal,
  QuotedApiResponseWithPaginate,
  ICollection,
  ICollectionStat,
  IEvent,
} from 'src/types';
import { baseURL, ifThumbnail } from 'src/utils';
import { api } from './query.base';
import { privateQuery } from './privateBaseQuery';
import {
  transformActivties,
  transformCollections,
} from './transform';
import {
  RawCollection,
  CollectionActivitiesReq,
  ManageCollectionRequest,
  CollectionSearchQuery,
} from './types';

enum Tags {
  Collection = 'Collection',
}

const prefixHttps = (key: string, url?: string) => ({
  ...(url && {
    [key]: url.match(/^http/ig) ? url : `https://${url}`,
  }),
});

const collectionApi = api
  .enhanceEndpoints({
    addTagTypes: [
      Tags.Collection,
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // retrieve basic collection data, with their stat
      fetchCollections: builder.query<ApiResponseWithTotal<ICollection[]>, void>({
        query: () => '/info/getcollections?onlyAppropriate=1&withStats=1',
        transformResponse: transformCollections,
        providesTags: (result: ApiResponseWithTotal<ICollection[]>) => [
          ...(result?.data || []).map(({ address, erc721Address }: ICollection) => ({
            type: Tags.Collection,
            id: address || erc721Address,
          })),
          { type: Tags.Collection, id: 'LIST' },
        ],
      }),

      searchCollections: builder.query<QuotedApiResponseWithPaginate<ICollection[]>, CollectionSearchQuery>({
        query: (body: CollectionSearchQuery) => ({
          method: 'POST',
          url: '/collection/search?withStats=1',
          body,
        }),
        transformResponse: transformCollections,
        providesTags: (result: ApiResponseWithTotal<ICollection[]>) => [
          ...(result?.data || []).map(({ address, erc721Address }: ICollection) => ({
            type: Tags.Collection,
            id: address || erc721Address,
          })),
          { type: Tags.Collection, id: 'LIST' },
        ],
      }),

      // retrieve only stats of all available collections
      // data about collection are all extremely minified
      fetchCollectionStatsAll: builder.query<ApiResponseWithTotal<(ICollectionStat & {image: string})[]>, number>({
        query: (count: number) => ({
          method: 'POST',
          url: '/collection/stats',
          body: { count },
        }),
        // @ts-ignore
        transformResponse: (resp: ApiResponseWithTotal<(ICollectionStat & {logoImageHash: string})[]>) => ({
          ...resp,
          data: resp.data.map(
            ({ logoImageHash, collectionName, ...stat }: ICollectionStat & {logoImageHash: string}) => ({
              ...stat,
              ...(collectionName && ({
                name: collectionName,
              })),
              image: baseURL('/static/elacity/bella.png'),
              ...(logoImageHash && logoImageHash.match(/^thumbnail:/i) && ({
                image: ifThumbnail(logoImageHash, 36),
              })),
              ...(logoImageHash && !logoImageHash.match(/^thumbnail:/i) && ({
                image: ipfsLink(`/ipfs/${logoImageHash}`),
              })),
            })
          ),
        }),
      }),

      // retrieve only stats of a collections
      // data about collection are all extremely minified
      fetchCollectionStats: builder.query<ApiResponseWithTotal<ICollectionStat>, string>({
        query: (address: string) => `/collection/${address}/stats`,
      }),

      fetchCollectionActivities: builder.query<IEvent<'Bid' | 'Sold' | 'Offer' | 'Listing'| 'Transfer'>[], CollectionActivitiesReq>({
        query: ({ address, ...body }: CollectionActivitiesReq) => ({
          method: 'POST',
          url: `/collection/${address}/activities`,
          body,
        }),
        transformResponse: transformActivties,
      }),

      // return full collection object
      fetchCollection: builder.query<ApiResponseWithTotal<ICollection>, string>({
        query: (contractAddress) => ({
          method: 'POST',
          url: '/collection/getCollectionInfo',
          body: {
            contractAddress,
          },
        }),
        transformResponse: (resp: ApiResponseWithTotal<RawCollection>) => ({
          ...resp,
          data: {
            ...resp.data || {},
            ...(resp?.data?.collectionName && ({
              name: resp?.data?.collectionName,
            })),
            ...(resp?.data?.erc721Address && ({
              address: resp?.data?.erc721Address,
            })),
            image: baseURL('/static/elacity/bella.png'),
            ...(resp?.data?.logoImageHash && ({
              ...(resp?.data?.logoImageHash.match(/^thumbnail:/i) ? {
                image: ifThumbnail(resp?.data?.logoImageHash, 320),
              } : {
                image: ipfsLink(`/ipfs/${resp?.data?.logoImageHash}`),
              }),
            })),
            ...(resp?.data?.coverImageHash && ({
              ...(resp?.data?.coverImageHash.match(/^thumbnail:/i) ? {
                cover: ifThumbnail(resp?.data?.coverImageHash, 1920),
              } : {
                cover: ipfsLink(`/ipfs/${resp?.data?.coverImageHash}`),
              }),
            })),
            ...prefixHttps('siteUrl', resp?.data?.siteUrl),
            ...prefixHttps('mediumHandle', resp?.data?.mediumHandle),
            ...prefixHttps('twitterHandle', resp?.data?.twitterHandle),
            ...prefixHttps('telegram', resp?.data?.telegram),
            ...prefixHttps('discord', resp?.data?.discord),
          } as ICollection,
        }),
        providesTags: (result: ApiResponseWithTotal<ICollection>) => [
          { type: Tags.Collection, id: result?.data?.address || result?.data?.erc721Address },
        ],
      }),

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      notifiyNewCollection: builder.mutation<any, void>({
        queryFn: async () => Promise.resolve({ data: { status: 'OK' } }),
        invalidatesTags: [
          { type: Tags.Collection, id: 'LIST' },
        ],
      }),
    }),
  });

const collectionPrivateApi = api
  .enhanceEndpoints({
    addTagTypes: [
      Tags.Collection,
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // return collection needing review
      // @todo: at this stage, not sure if all colleciton data are full
      fetchReviewableCollections: builder.query<ApiResponseWithTotal<ICollection[]>, void>({
        // @ts-ignore
        queryFn: async (_, bqApi, extraOptions) => {
          const resp = await privateQuery({
            url: '/collection/getReviewApplications',
            method: 'POST',
          },
          bqApi,
          extraOptions) as {data?: ApiResponseWithTotal<RawCollection[]>; error?: Error};

          if (resp?.error) {
            return resp;
          }

          return {
            data: transformCollections(resp.data) as ApiResponseWithTotal<ICollection[]>,
          };
        },
        providesTags: (result: ApiResponseWithTotal<ICollection[]>) => [
          ...(result?.data || []).map(({ address, erc721Address }: ICollection) => ({
            type: Tags.Collection,
            id: address || erc721Address,
          })),
          { type: Tags.Collection, id: 'LIST' },
        ],
      }),

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      reviewCollection: builder.mutation<any, {contractAddress: string; status?: number; reason?: string}>({
        queryFn: (body, bqApi, extraOptions) => privateQuery({
          url: '/collection/reviewApplication',
          method: 'POST',
          body,
        },
        bqApi,
        extraOptions),
        invalidatesTags: (result, error, { contractAddress }) => [
          { type: Tags.Collection, id: 'LIST' },
          { type: Tags.Collection, id: contractAddress },
        ],
      }),

      manageCollection: builder.mutation<ApiResponse<{queueId: string}>, ManageCollectionRequest>({
        // @ts-ignore
        queryFn: ({ address, ...body }: ManageCollectionRequest, bqApi, extraOptions) => privateQuery({
          url: `/collection/${address}/manage`,
          method: 'POST',
          body,
        },
        bqApi,
        extraOptions),
      }),
    }),
  });

export { collectionApi, collectionPrivateApi };

export const {
  useFetchCollectionsQuery,
  useFetchCollectionQuery,
  useFetchCollectionStatsAllQuery,
  useFetchCollectionStatsQuery,
  useFetchCollectionActivitiesQuery,
  useNotifiyNewCollectionMutation,
  useSearchCollectionsQuery,
  useLazySearchCollectionsQuery,
} = collectionApi;

export const {
  useFetchReviewableCollectionsQuery,
  useReviewCollectionMutation,
  useManageCollectionMutation,
} = collectionPrivateApi;
