import { uid } from '@elacity-js/lib';
import { IStorage } from './types';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface StorageOptions<T> {
  defaultValue?: T;
  flush?: boolean;
}

export default class JsonLocalStorage<T = any> implements IStorage<T> {
  /**
   * This key represents the slot in the localStorage
   */
  protected key: string;

  public hash: string;

  /**
   * The option that determines how the object behaves
   *
   * @attribute defaultValue: a value to use when nothing has been set yet while retrieving data .toJSON()
   * @attribute flush: if true, the default value will be set during instanciation
   */
  protected options: StorageOptions<T>;

  /**
   * Instanciate a new object
   *
   * @param key
   * @param o
   */
  constructor(key: string, o?: StorageOptions<T>) {
    this.key = key;
    if (o?.flush && o?.defaultValue) {
      this.persist(o?.defaultValue);
    }

    this.options = o;
  }

  hydrate() {
    // just update the hash
    this.hash = uid(32);
  }

  /**
   * Return the json object
   *
   * @returns
   */
  toJSON() {
    try {
      const value = localStorage.getItem(
        this.key
      );

      if (!value) {
        return this.options?.defaultValue || {};
      }

      return JSON.parse(value);
    } catch (e) {
      console.warn('[JsonLocalStorage] failed to parse', e);
      return this.options?.defaultValue || {};
    }
  }

  /**
   * Persist data into the storage
   *
   * @param value
   * @returns
   */
  persist(value: T) {
    localStorage.setItem(this.key, JSON.stringify(value));

    this.hash = uid(32);

    return this;
  }

  /**
   * Remove data by its key
   *
   * @param key
   * @returns
   */
  delete(key: string) {
    const prev = this.toJSON();
    if (prev && prev[key]) {
      delete prev[key];
    }

    return this.persist(prev);
  }

  /**
   * Add new set of data
   *
   * @param value
   * @returns
   */
  set(value: Partial<T>) {
    const prev = this.toJSON();
    return this.persist({
      ...prev,
      ...value,
    });
  }

  /**
   * Retrieve a piece of data in the storage by its key
   * @todo: nested / deep content retrieval
   *
   * @param key
   * @returns
   */
  get(key: string) {
    return (this.toJSON() || {})[key];
  }

  /**
   * Switch key, the object remains the same
   *
   * @param key
   * @returns
   */
  withKey(key: string) {
    this.key = key;

    this.hash = uid(32);

    return this;
  }

  /**
   * Remove whole data from the storage
   */
  kill() {
    localStorage.removeItem(this.key);
  }
}
