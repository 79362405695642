import React from 'react';
import { uid } from '@elacity-js/lib';
import { pick } from 'lodash';
import { DID } from '@elastosfoundation/elastos-connectivity-sdk-js';
import {
  VerifiableCredential, DID as DIDObj, VerifiablePresentation,
} from '@elastosfoundation/did-js-sdk';
import { JsonLocalStorage, IStorage } from 'src/lib/storage';
import { useAppSettings } from 'src/hooks';
import { DID_STORAGE_KEY } from './constants';
import {
  DIDValues, DIDBroadcastMessageType, DIDBroadcastMessage,
} from './types';
import {
  IVerificatorManager, VerificatorManager, VerifiableSocialCredential,
} from './verification';
import { ConnectorName } from '../web3/connectors';

interface ICredentialImporter {
  importCredential: (credential: VerifiableCredential) => Promise<DID.ImportedCredential | null>;
}

interface ICredentialDeleter {
  deleteCredential: (credentialId: string) => Promise<void>;
}

interface IKycCredentialRequester {
  requestKycCredentials: () => Promise<VerifiablePresentation>;
}

class DIDHelper<T> implements ICredentialImporter, ICredentialDeleter, IKycCredentialRequester {
  protected conn: DID.DIDAccess;

  protected appContext: { storage: IStorage<T> };

  constructor(protected did: string) {
    this.conn = new DID.DIDAccess();
  }

  setContext(ctx: { storage: IStorage<T> }) {
    this.appContext = ctx;
  }

  /**
   * Import a credential to the DID, normally this should be the step forward from backend callback
   *
   * @param credential
   * @returns
   */
  async importCredential(credential: VerifiableCredential): Promise<DID.ImportedCredential | null> {
    const vc = VerifiableCredential.parse(JSON.stringify(credential), DIDObj.from(this.did));
    const imported = await this.conn.importCredentials([vc]);

    const credentialName = vc.getId().toString().split('#').pop();

    // when done, save the credential to local storage
    // use input value as output value is potentially empty
    if (this.appContext?.storage) {
      const socials = this.appContext.storage.get('socials') || {};
      this.appContext?.storage.set({
        // @ts-ignore
        socials: {
          ...socials,
          [credentialName]: {
            ...pick(credential, ['id', 'issuer', 'credentialSubject', 'expirationDate']),
            status: 'approved',
          },
        },
      });
    }

    return imported ? imported[0] : null;
  }

  /**
   * Delete a credential from the DID
   *
   * @docs deleteCredentials
   * @param credentialId
   */
  async deleteCredential(credentialId: string): Promise<void> {
    const credentialName = credentialId.split('#').pop();

    console.log('Deleting credential...', [credentialName, credentialId]);

    await this.conn.deleteCredentials([credentialId]);

    if (this.appContext?.storage) {
      const socials: Partial<T> = this.appContext.storage.get('socials') || {};
      if (socials[credentialName]) {
        delete socials[credentialName];
        this.appContext.storage.set({
          // @ts-ignore
          socials,
        });
      }
    }
  }

  /**
   * Request KYC credentials from the DID
   *
   * @returns
   */
  async requestKycCredentials(): Promise<VerifiablePresentation> {
    // KYC Crendentials should have this piece in context
    // did://elastos/iqjN3CLRjd7a4jGCZe6B3isXyeLy7KKDuK
    const presentation = await this.conn.requestCredentials({
      claims: [
        new DID.ClaimDescription()
          .withReason('Detect whether you completed KYC-ME process. Please select at least one of the result if any.')
          .withClaim(
            DID.claimWithJsonPathQuery(
              '$[?(@.issuer == "did:elastos:iqjN3CLRjd7a4jGCZe6B3isXyeLy7KKDuK" && @.credentialSubject.mrtdVerified == true)]'
            ).withIssuers(['did:elastos:iqjN3CLRjd7a4jGCZe6B3isXyeLy7KKDuK'])
          )
          .withMax(4)
          .withMin(0),
      ],
      nonce: uid(),
      realm: uid(),
    });

    if (presentation.getCredentials().length > 0) {
      if (this.appContext?.storage) {
        this.appContext.storage.set({
          // @ts-ignore
          kycOk: true,
        });
      }
    }

    return presentation;
  }
}

export interface DIDContextValue extends ICredentialImporter, ICredentialDeleter, IKycCredentialRequester {
  isConnectedWithDID: () => boolean;
  syncChannel: BroadcastChannel;
  did?: string;
  sessionId: string;
  setDIDValue?: (did: string) => void;
  verificator: IVerificatorManager;
  storage: IStorage<DIDValues<VerifiableSocialCredential>>;
}

export const DIDContext = React.createContext<DIDContextValue>({
  syncChannel: new BroadcastChannel('xxx'),
  verificator: new VerificatorManager(new JsonLocalStorage('')),
  sessionId: '',
  storage: new JsonLocalStorage(''),
  importCredential: (credential: VerifiableCredential) => Promise.reject(new Error('Not implemented')),
  deleteCredential: (credentialId: string) => Promise.reject(new Error('Not implemented')),
  requestKycCredentials: () => Promise.reject(new Error('Not implemented')),
  isConnectedWithDID: () => false,
});

interface DIDProviderProps {}

export const DIDProvider: React.FC<React.PropsWithChildren<DIDProviderProps>> = ({
  children,
}: React.PropsWithChildren<DIDProviderProps>) => {
  const storage = new JsonLocalStorage(DID_STORAGE_KEY);
  const syncChannel = new BroadcastChannel(DID_STORAGE_KEY);
  const verificator = new VerificatorManager(storage);
  const [sessionId, setSessionId] = React.useState(uid(64));

  const { values } = useAppSettings();

  const [did, setDIDValue] = React.useState<string>(storage.get('did') || null);

  const { importCredential, deleteCredential, requestKycCredentials } = React.useMemo(() => {
    const h = new DIDHelper<DIDValues<VerifiableSocialCredential>>(did);
    h.setContext({ storage });

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const importCredential = (credential: VerifiableCredential) => h.importCredential(credential);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const deleteCredential = (credentialId: string) => h.deleteCredential(credentialId);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const requestKycCredentials = () => h.requestKycCredentials();

    return {
      importCredential,
      deleteCredential,
      requestKycCredentials,
    };
  }, [storage.hash]);

  // listen to changes notification from popup (VerificationCallbackProxy)
  React.useEffect(() => {
    const messageHandler = (event: MessageEvent<DIDBroadcastMessage<null>>) => {
      switch (event.data?.type) {
        case DIDBroadcastMessageType.STORAGE_UPDATE:
          console.log('[DIDContext] received storage update', event.data);
          // enforce components update
          // storage.hydrate();
          // setSessionId(uid(64));
          window.location.reload();
          break;
        default:
          console.warn('event caught, no handler for type', event.data);
          break;
      }
    };

    syncChannel.addEventListener('message', messageHandler);

    return () => {
      syncChannel.removeEventListener('message', messageHandler);
    };
  }, []);

  const isConnectedWithDID = React.useCallback(
    () => did && values.walletProvider === ConnectorName.ElastosDID,
    [values.walletProvider, did]
  );

  return (
    <DIDContext.Provider
      value={{
        did,
        sessionId,
        storage,
        syncChannel,
        setDIDValue,
        verificator,
        importCredential,
        deleteCredential,
        requestKycCredentials,
        isConnectedWithDID,
      }}
    >
      {children}
    </DIDContext.Provider>
  );
};

export default DIDContext;
