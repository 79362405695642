import { useState, useEffect } from 'react';
import useBellaOwner from './useBellaOwner';

export default (owner: string, discountRate = 28) => {
  const [discount, setDiscount] = useState<number>(0);
  const { isBellaOwner } = useBellaOwner(owner);

  useEffect(() => {
    if (owner && isBellaOwner) {
      setDiscount(discountRate);
    }
  }, [owner, isBellaOwner]);

  return discount;
};
