import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { JsonLocalStorage } from 'src/lib/storage';
import {
  ActivateFunc, ConnectResponse, rpcMap, getChainIds,
} from '../network';
import { ConnectorName } from './types';

// Wallet Connect
// Attention: since we use walletconnect, we had to set supported chains
// to all existing ones to prevent erorr: `Missing or invalid topic field`
// when trying to switch from a non-supported chain
// followup on https://github.com/WalletConnect/walletconnect-monorepo/issues/315
export const walletconnect = new WalletConnectConnector({
  supportedChainIds: getChainIds(),
  rpc: rpcMap,
  bridge: 'https://walletconnect.elastos.net/v2',
  qrcode: true,
  qrcodeModalOptions: {
    mobileLinks: [
      'metamask',
      'essentials',
    ],
    desktopLinks: [
      'encrypted ink',
    ],
  },
});

export default {
  name: ConnectorName.WalletConnect,
  handler: walletconnect,
  // eslint-disable-next-line max-len
  icon: 'https://raw.githubusercontent.com/WalletConnect/walletconnect-assets/492d95c038bbcde1517cab5fb90ed4514690e919/png/square/walletconnect-square-white.png',
  connect: async (chainId: number, activate: ActivateFunc): Promise<ConnectResponse> => {
    // see https://github.com/NoahZinsmeister/web3-react/tree/v6/docs#understanding-error-bubbling
    // for more details about error handling
    await activate(walletconnect, undefined, true);

    walletconnect.walletConnectProvider?.on('disconnect', async () => {
      window.localStorage.removeItem('walletconnect');

      // remove also .walletProvider from __ela_app_settings
      const settings = new JsonLocalStorage('__ela_app_settings');
      settings.delete('walletProvider');
    });

    return {
      chainId: Number(await walletconnect.getChainId()),
      provider: await walletconnect.getProvider(),
    };
  },
  resurrect: async (activate: ActivateFunc): Promise<void> => {
    await activate(walletconnect, undefined, true);
  },
};
