/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICollectionInput, ICollectionMinimalInput } from 'src/types';
import clientHttp, { ICallResults } from './client';

export const postCollectionDetails = async (data: ICollectionInput, client: typeof clientHttp) => {
  const results = await client.call<ICallResults<any>>({
    method: 'post',
    url: '/collection/collectiondetails',
    data: JSON.stringify({
      ...data,
      // @todo: remove below, royalty registration should be
      // processed by an admin during collection approval
      // just remove it when approval process is in place
      withApproval: true,
    }),
  });

  return results.data;
};

export const updateCollectionDetails = async (
  address: string,
  data: Partial<ICollectionMinimalInput>,
  client: typeof clientHttp
) => {
  const results = await client.call<ICallResults<any>>({
    method: 'post',
    url: `/collection/${address.toLowerCase()}/update`,
    data: JSON.stringify({
      ...data,
    }),
  });

  return results.data;
};

export const getMintableCollections = async (client: typeof clientHttp) => {
  const results = await client.call<ICallResults<any>>({
    method: 'post',
    url: '/collection/getMintableCollections',
  });

  return results.data;
};
