import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export { default as RangeInput } from './RangeInput';
export * from './RangeInput';

export const BeautifulInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: theme.spacing(4),
    backgroundColor: theme.palette.mode === 'light' ? 'white' : 'black',
    '&:hover, &.Mui-focused': {
      borderColor: 'transparent',
      borderWidth: 2,

      '&:after': {
        content: '""',
        position: 'absolute',
        top: 'calc(-1 * 3px)',
        left: 'calc(-1 * 3px)',
        height: 'calc(100% + 3px * 2)',
        width: 'calc(100% + 3px * 2)',
        background: `linear-gradient(120deg, ${theme.palette.primary.main}, 35%, ${theme.palette.vivid.main})`,
        borderRadius: theme.spacing(4),
        zIndex: -1,
        backgroundSize: '300% 300%',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiInputAdornment-positionEnd': {
    position: 'absolute',
    right: 2,

    '& .MuiIconButton-root': {
      background: alpha(theme.palette.grey[300], 0.5),
      '&:hover': {
        background: alpha(theme.palette.grey[400], 0.5),
      },
    },
  },
}));
