/* eslint-disable max-len */
import React from 'react';
import {
  Box, Typography, Slider, SliderProps,
} from '@mui/material';

import { styled } from '@mui/material/styles';

interface SliderInputProps extends SliderProps {
  label: string;
  value: number;
  description?: string;
}

const BoxInner = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  mt: 2,
}));

const SliderInput = ({ label, value, description, ...props }: SliderInputProps) => (
  <>
    <BoxInner>
      <Typography>{label}</Typography>
      <Typography>{value}</Typography>
    </BoxInner>
    <Typography sx={{ fontSize: '0.70rem', color: 'text.secondary' }}>{description}</Typography>
    <Slider
      size="small"
      valueLabelDisplay="auto"
      {...props}
      {...{
        ...(typeof value !== 'undefined' && {
          value,
        }),
      }}
    />
  </>
);

export default SliderInput;
