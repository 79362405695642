import { uid, FileHelper } from '@elacity-js/lib';
import { connectivity, DID } from '@elastosfoundation/elastos-connectivity-sdk-js';
import * as DIDSdk from '@elastosfoundation/did-js-sdk';
import { EssentialsConnector } from '@elastosfoundation/essentials-connector-client-browser';
import { ScriptRunner } from '@elastosfoundation/hive-js-sdk';
import { InjectedConnector } from '@web3-react/injected-connector';
import { JsonLocalStorage } from 'src/lib/storage';
import Web3WalletconnectConnector from 'src/lib/did/addons/Web3WalletconnectConnector';
import { APP_DID, DID_STORAGE_KEY } from './constants';
import { BrowserConnectivitySDKHiveAuthHelper } from './services/hive.auth.helper';
import { DIdentity } from './types';

export const resolverURLs: Record<number, string> = {
  20: 'mainnet',
};

const getRawFromHiveDataUrl = async (didAccess: DID.DIDAccess, userDID: string, hiveUrl: string): Promise<string | null> => {
  try {
    // we always use mainnet (chainId=20) for this method as it will not work with our test network (ropsten: 3)
    const hiveAuthHelper = (new BrowserConnectivitySDKHiveAuthHelper(resolverURLs[20], APP_DID)).use(didAccess);
    const appContext = await hiveAuthHelper.getAppContext(userDID);
    const scriptRunner = new ScriptRunner(appContext);
    const avatarBuffer = await scriptRunner.downloadFileByHiveUrl(hiveUrl);

    const hiveAvatarDataUrl = await FileHelper.fromBufferToBase64DataURL(avatarBuffer);
    console.log('[ensureDIDConnectionWith]', { hiveAvatarDataUrl });

    return hiveAvatarDataUrl;
  } catch (e) {
    console.error('failed to get hive URL', e);
    return null;
  }
};

interface DIDConnectionOptions {
  init?: boolean;
  handleAccess?: (didAccess: DID.DIDAccess) => Promise<void>;
}

/**
 * This helper will ensure to connect the application via walletconnect
 * we use @wallectconnect/web3-provider as main provider
 *
 * @param connector
 * @returns
 */
export const ensureDIDConnectionWith = async (
  connector: Web3WalletconnectConnector | InjectedConnector,
  options?: DIDConnectionOptions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<[Record<string, any>?, Record<string, DIDSdk.VerifiableCredential>?]> => {
  // init the library
  connectivity.setApplicationDID(APP_DID);
  const essentialsConnector = new EssentialsConnector();

  if (options.init && essentialsConnector.hasWalletConnectSession()) {
    // disconnect all previous session on init
    // await essentialsConnector.disconnectWalletConnect();
  }

  // @ts-ignore
  const concreteProvider = connector?.walletConnectProvider || connectivity.getActiveConnector().getWeb3Provider();

  // handle disconnect
  // @ts-ignore
  if (connector?.walletConnectProvider) {
    // @ts-ignore
    connector.walletConnectProvider.on('disconnect', async () => {
      await essentialsConnector.disconnectWalletConnect();
      window.localStorage.removeItem('walletconnect');
      window.localStorage.removeItem('activeconnectorname');
      window.localStorage.removeItem(DID_STORAGE_KEY);

      // remove also .walletProvider from __ela_app_settings
      const settings = new JsonLocalStorage('__ela_app_settings');
      settings.delete('walletProvider');
    });
  }

  const availableConnectors = connectivity.getAvailableConnectors();
  if (availableConnectors.length > 0) {
    console.info('found an available connector', availableConnectors[0].name);
    await connectivity.setActiveConnector(availableConnectors[0].name);
    essentialsConnector.setWalletConnectProvider(concreteProvider);
  } else {
    await connectivity.registerConnector(essentialsConnector);
    essentialsConnector.setWalletConnectProvider(concreteProvider);
  }

  const storage = new JsonLocalStorage(DID_STORAGE_KEY, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValue: { exists: false } as any,
    flush: !!options.init,
  });

  // request credentials and ask for usage approval
  const didAccess = new DID.DIDAccess();
  console.log({ didAccess }, concreteProvider.chainId);

  if (!options.init) {
    return [storage.toJSON()];
  }

  try {
    const presentation = await didAccess.requestCredentials({
      claims: [
        DID.simpleIdClaim('Access your name', 'name', true),
        DID.simpleIdClaim('Show who you are to others', 'avatar', false),
        DID.simpleIdClaim('Show your biography', 'description', false),
      ],
      nonce: uid(),
      realm: uid(),
    });

    if (presentation) {
      const credentials: Record<string, DIDSdk.VerifiableCredential> = presentation.getCredentials().reduce(
        (result: Record<string, DIDSdk.VerifiableCredential>, credential: DIDSdk.VerifiableCredential) => ({
          ...result,
          [credential.getId().getFragment()]: credential,
        }), {}
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const jsonCredentials: Record<string, any> = presentation.getCredentials().reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (result: Record<string, any>, credential: DIDSdk.VerifiableCredential) => ({
          ...result,
          [credential.getId().getFragment()]: credential.getSubject().getProperty(
            credential.getId().getFragment()
          ),
        }), {}
      );

      if (jsonCredentials.avatar) {
        jsonCredentials.avatar = {
          ...jsonCredentials.avatar,
          base64: await getRawFromHiveDataUrl(
            didAccess,
            presentation.holder.toString(),
            (jsonCredentials.avatar as DIdentity['credentials']['avatar']).data
          ),
        } as DIdentity['credentials']['avatar'];
      }

      storage.set({
        exists: true,
        credentials: jsonCredentials,
        did: presentation.holder,
      });

      return [jsonCredentials, credentials];
    }

    return [];
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
};
