import React, { CSSProperties } from 'react';
import { useFormikContext } from 'formik';
import {
  Container, IconButton, Stack, Tooltip, CircularProgress, SxProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CasinoIcon from '@mui/icons-material/Casino';
import BoltIcon from '@mui/icons-material/Bolt';
import { useClientHttp } from 'src/hooks';
import { aiApi } from 'src/api';
import { useAIContext } from '../context/AiResultContext';
import { StableDiffusionModel } from '../type';

const InnerContainer = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(2, 'auto'),
}));

declare type ActionKey = 'random' | 'parrot';

interface PromptGroupProps {
  justifyContent?: CSSProperties['justifyContent'];
  sx?: SxProps;
}

export const PromptGroup = ({ justifyContent, sx }: PromptGroupProps) => {
  const { getClient } = useClientHttp();
  const { inputRef } = useAIContext();
  const { values, setFieldValue } = useFormikContext<StableDiffusionModel>();
  const [processing, setProcessing] = React.useState<Record<ActionKey, boolean>>({
    random: false,
    parrot: false,
  });

  const handleProcessing = (key: ActionKey, value: boolean) => setProcessing((prev) => ({
    ...prev,
    [key]: value,
  }));

  const handleRandomPrompt = async () => {
    handleProcessing('random', true);
    const response = await aiApi.randomPrompt(await getClient());
    if (response.status === 'succeeded') {
      setFieldValue('prompt', response.output);
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.parentElement.classList.add('heartbeat');
        setTimeout(() => {
          inputRef.current.parentElement.classList.remove('heartbeat');
        }, 2000);
      }
    }
    handleProcessing('random', false);
  };

  const handlePromptParrot = async () => {
    handleProcessing('parrot', true);
    const response = await aiApi.promptParrot(values.prompt, await getClient());
    if (response.status === 'succeeded') {
      // output should be array of 4~5
      setFieldValue('prompt', response.output[Math.floor((response?.output || []).length * Math.random())]);
    }
    handleProcessing('parrot', false);
  };

  return (
    <InnerContainer direction="row" justifyContent={justifyContent} spacing={1} sx={sx}>
      <Tooltip title="Generate a random prompt">
        <IconButton disabled={processing.random} onClick={handleRandomPrompt} aria-label="random prompt">
          {processing.random ? <CircularProgress size={24} /> : <CasinoIcon />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Generate more expressive prompt">
        <IconButton
          onClick={handlePromptParrot}
          aria-label="prompt parrot"
          disabled={values.prompt?.length === 0 || processing.parrot}
        >
          {processing.parrot ? <CircularProgress size={24} /> : <BoltIcon />}
        </IconButton>
      </Tooltip>
    </InnerContainer>
  );
};

PromptGroup.defaultProps = {
  justifyContent: 'center',
};

export default () => (
  <Container>
    <PromptGroup />
  </Container>
);
