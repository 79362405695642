import React from 'react';
import { INTERFACE_ERC20 } from '@elacity-js/lib';
import { Contract } from '@ethersproject/contracts';
import { parseUnits } from '@ethersproject/units';
import { useSnackbar, SnackbarKey } from 'notistack';

import {
  useMounted, useProfile, useModal, useWeb3Application, usePayment, useSignature,
} from 'src/hooks';
import useBellaDiscount from 'src/hooks/useBellaDiscount';
import {
  useFetchProcessingTimeQuery,
  useNotifyProcessingTimeChangeMutation,
  useFillFreeProcessingTimeMutation,
} from 'src/state/api';
import ProcessingTimeModal from './ProcessingTimeModal';
import { BuyInputs } from './type';

export default () => {
  const isFree = false;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const mounted = useMounted();
  const signMessage = useSignature({});
  const { supportedArr: currencies } = usePayment();
  const { library, account } = useWeb3Application();
  const { openModal } = useModal();
  const { profile } = useProfile();
  const [notifyChange] = useNotifyProcessingTimeChangeMutation();
  const [fillFreePT] = useFillFreeProcessingTimeMutation();
  const queryResult = useFetchProcessingTimeQuery(profile?.address, {
    skip: !mounted.current || !profile?.address,
  });

  const discount = useBellaDiscount(account, 28);

  const openProcessingTimeModal = React.useCallback(() => {
    openModal<BuyInputs>({
      Component: ProcessingTimeModal,
      formData: {
        processingTime: 1000,
        discount,
      },
      maxWidth: 'xs',
      okLabel: isFree ? 'Claim' : 'Buy',
      onOk: async (values) => {
        let refreshAfter = 5000;
        let snkKey: SnackbarKey = null;

        const snkTimeout = setTimeout(() => {
          // if not processing within 3sec, we show a snackbar
          // invitung the user to check wallet to sign
          snkKey = enqueueSnackbar('Waiting for signature confirmation, please check your wallet', {
            variant: 'warning',
            persist: true,
            preventDuplicate: true,
          });
        }, 3000);

        if (isFree) {
          refreshAfter = 100;
          const { signature, address: signatureAddress } = await signMessage();
          closeSnackbar(snkKey);

          await fillFreePT({ signature, signatureAddress });
          clearTimeout(snkTimeout);
        } else {
          const erc20 = new Contract(values.payToken, INTERFACE_ERC20, library?.getSigner());
          const decimals = currencies.find((c) => c.address?.toLowerCase() === values.payToken?.toLowerCase())?.decimals;

          const tx = await erc20.transfer(
            process.env.REACT_APP_PROCESSING_TIME_RECIPIENT,
            parseUnits((values.amount * ((100 - discount) / 100))?.toFixed(6), decimals || 6)
          );
          await tx.wait();
        }
        setTimeout(() => {
          notifyChange(profile?.address);
        }, refreshAfter);
      },
      centerActions: true,
    });
  }, [discount]);

  return {
    isFree,
    queryResult,
    openProcessingTimeModal,
  };
};
