/* eslint-disable default-case */
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { Icon } from '@iconify/react';
import { styled, alpha } from '@mui/material/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import homeFill from '@iconify/icons-eva/home-fill';
import sidebarMenu from 'src/components/minimal/SidebarConfig';
import { SubmenuPopover } from 'src/components/section/MiniNavItem';
import { useNavigate } from 'src/hooks';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import ImageIcon from '@mui/icons-material/Image';
// import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

const Container = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  borderRadius: 0,
  left: 0,
  right: 0,
  zIndex: 999,
  background: alpha(theme.palette.background.default, 0.6),
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)',
  overflow: 'hidden',
}));

const subpathStruct = ['/home', '/marketplace'];

export default React.forwardRef<boolean, { menu: typeof sidebarMenu }>(({ menu }, ref: React.MutableRefObject<boolean>) => {
  const [value, setValue] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.SyntheticEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const changeRef = React.useCallback(
    (node) => {
      ref.current = Boolean(node);
    },
    [ref, value]
  );

  React.useEffect(() => {
    if (matchPath({ path: '/home', end: false }, location.pathname)) {
      setValue(0);
    }

    if (matchPath({ path: '/marketplace/explore', end: false }, location.pathname)) {
      setValue(1);
    }

    if (matchPath({ path: '/marketplace/collections', end: false }, location.pathname)) {
      setValue(1);
    }

    if (matchPath({ path: '/marketplace/shops', end: false }, location.pathname)) {
      setValue(1);
    }

    if (matchPath({ path: '/ai', end: false }, location.pathname)) {
      setValue(2);
    }

    if (anchorEl) {
      handlePopoverClose();
    }
  }, [location.pathname]);

  const handleNavChange = (e: React.SyntheticEvent<HTMLDivElement>, newValue: number) => {
    switch (newValue) {
      case 0:
      case 1:
        handlePopoverOpen(e);
        break;
      case 2:
        // navigate('/ai');
        navigate('/cinema');
        break;
    }

    setValue(newValue);
  };

  const id = anchorEl ? 'simple-popover' : undefined;

  return (
    <Container elevation={3}>
      <BottomNavigation sx={{ bgcolor: 'transparent', borderRadius: 0 }} value={value} onChange={handleNavChange} ref={changeRef}>
        <BottomNavigationAction sx={{ maxWidth: 60 }} icon={<Icon icon={homeFill} width={24} height={24} />} />
        {/* <BottomNavigationAction sx={{ maxWidth: 60 }} icon={<EmojiObjectsIcon />} /> */}
        <BottomNavigationAction sx={{ maxWidth: 60 }} icon={<ImageIcon />} />
        <BottomNavigationAction sx={{ maxWidth: 60 }} icon={<SmartDisplayIcon />} />
        <SubmenuPopover
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          path="/markeplace"
          submenu={menu.find(({ path }) => path === subpathStruct[value])?.children || []}
          sx={{
            pointerEvents: 'none',
            '& .MuiPopover-paper': {
              pointerEvents: 'auto',
            },
          }}
        />
      </BottomNavigation>
    </Container>
  );
});
