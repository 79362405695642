import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { orange, green } from '@mui/material/colors';
import { baseURL } from 'src/utils';
import { RouterLink } from 'src/components/Link';
import {
  useGenerateAffiliationLinkMutation, useGetHolderAffiliationQuery,
  useUpdateAffiliationLinkMutation,
} from 'src/state/api';
import { Affiliation } from 'src/types';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useAddresses, useErrorHandler } from 'src/hooks';
import useMobile from 'src/hooks/useMobile';
import useBellaOwner from 'src/hooks/useBellaOwner';
import { AddressZero } from '@ethersproject/constants';
import { Spinner } from './@ui/Spinner';
import CopyCapsule from './CopyCapsule';
import Price from './@ui/Price';

const Image = styled('img')(({ theme }) => ({
  width: 120,
  margin: theme.spacing(0, 'auto', 0),
  [theme.breakpoints.down('sm')]: {
    width: 100,
    margin: theme.spacing(0, 'auto', 0),
  },
}));

const Title = styled(DialogTitle)(({ theme }) => ({
  fontSize: 24,
  margin: 'auto',
  padding: 0,
}));

const FeeInput = styled(Input)(({ theme }) => ({
  marginRight: theme.spacing(1),
  width: 80,
}));

const AffiliateDialog = () => {
  const { account: owner } = useWeb3React<Web3Provider>();
  const [open, setOpen] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>();
  const isMobile = useMobile();
  const { isChecking, isBellaOwner } = useBellaOwner(owner);
  const { BELLA_COLLECTION_ADDRESS } = useAddresses();
  const { handlerError } = useErrorHandler();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const {
    data: list,
    isLoading: isLoadingAffiliationList,
    isFetching: isFetchingNewList,
    refetch,
  } = useGetHolderAffiliationQuery(null, { refetchOnMountOrArgChange: true, skip: !open });

  const [generateAffiliationLink, { isLoading: isGeneratingAffiliationLink }] = useGenerateAffiliationLinkMutation();
  const [updateAffiliationLink, { isLoading: isUpdatingAffiliationLink }] = useUpdateAffiliationLinkMutation();

  const generateNewLink = React.useCallback(async () => {
    try {
      await generateAffiliationLink({ owner, percentage });
      refetch();
    } catch (err) {
      handlerError(err);
    }
  }, [percentage]);

  const archivingAffiliationLink = React.useCallback(async (refKey: string) => {
    try {
      await updateAffiliationLink({ refKey, input: { archived: true } });
      refetch();
    } catch (err) {
      handlerError(err);
    }
  }, []);

  const TitleCell = ({ token, ledger, tokenID, refKey }: Affiliation) => (
    <>
      {token ? (
        <Typography
          noWrap
          component={RouterLink}
          sx={{
            textDecoration: 'none',
            lineHeight: 2.5,
            color: 'inherit',
            width: !isMobile ? 275 : 200,
          }}
          onClick={handleClose}
          to={`cinema/view/${ledger}/${tokenID}`}
        >
          {token?.name}
        </Typography>
      ) : (
        <Typography
          noWrap sx={{ width: !isMobile ? 275 : 200 }}
        >
          {refKey}
        </Typography>
      )}
    </>
  );

  const PercentCell = (({ percentage: percent }: Affiliation) => (
    <Typography>
      {percent}
      % fee
    </Typography>
  ));

  const ActionCell = ({ refKey, used }: Affiliation) => (
    <Box display="flex">
      <CopyCapsule text={`${window.location.origin}/cinema/create/${refKey}`} />
      {!used && (
        <IconButton
          color="error" onClick={() => archivingAffiliationLink(refKey)}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );

  const StatusCell = ({ used }: Affiliation) => (
    <Typography fontWeight="bold" color={used ? green.A400 : orange.A400}>
      {used ? 'Used' : 'Unused'}
    </Typography>
  );

  const EarntCell = ({ earnt }: Affiliation) => (
    <>
      {!!earnt && (
        <Box display="flex">
          {(earnt || 0) === 0 ? null : (
            <>
              <Price.View
                size={16} fontWeight={400}
                value={earnt}
                currency={AddressZero}
                hideUSD
                precision={3}
              />
              <Typography sx={{ ml: 1 }}>
                Earnt
              </Typography>
            </>
          )}
        </Box>
      )}
    </>
  );

  return (
    <>
      <Typography
        variant="subtitle2"
        color="primary"
        sx={{ mt: '2%', cursor: 'pointer' }}
        onClick={handleOpen}
      >
        Affiliate Links
      </Typography>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <Image alt="Affiliate Links" src={baseURL('/static/elacity/elacity-treasury.png')} />
        <Title>Affiliate Links</Title>
        {isChecking
          ? <Spinner.Dots sx={{ mt: 2 }} />
          : (
            <>
              {isBellaOwner
                ? (
                  <>
                    <Typography variant="body2" textAlign="center" sx={{ '& p': { margin: 0 } }}>
                      <p>Bella Holder! Check content, share a link and let other to publish content</p>
                      <p>for free in exchange for a fee. Sell proceeds go directly to your wallet.</p>
                    </Typography>
                    <Box display="flex" justifyContent="center" margin={4}>
                      <FeeInput
                        type="number"
                        endAdornment="%"
                        inputProps={{
                          min: 0.1,
                          step: 0.1,
                          max: 45,
                          placeholder: 'Fee',
                        }}
                        onChange={(e) => setPercentage(Number(e.target.value))}
                      />
                      <LoadingButton
                        variant="contained"
                        onClick={generateNewLink}
                        disabled={isGeneratingAffiliationLink}
                        loading={isGeneratingAffiliationLink}
                      >
                        Generate
                      </LoadingButton>
                    </Box>
                    <DialogContent>
                      <Box>
                        {(
                          isLoadingAffiliationList ||
                      isFetchingNewList ||
                      isUpdatingAffiliationLink) ? <Spinner.Dots />
                          : (
                            <Table size="small">
                              <TableBody>
                                {
                                  (list || []).map((data: Affiliation) => (
                                    isMobile ? (
                                      <>
                                        <TableRow>
                                          <TableCell>
                                            <TitleCell {...data} />
                                          </TableCell>
                                          <TableCell>
                                            <PercentCell {...data} />
                                          </TableCell>
                                          <TableCell>
                                            <ActionCell {...data} />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            <StatusCell {...data} />
                                          </TableCell>
                                          <TableCell>
                                            <EarntCell {...data} />
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    )
                                      : (
                                        <TableRow key={`t.r.${data.refKey}`}>
                                          <TableCell>
                                            <TitleCell {...data} />
                                          </TableCell>
                                          <TableCell>
                                            <PercentCell {...data} />
                                          </TableCell>
                                          <TableCell>
                                            <StatusCell {...data} />
                                          </TableCell>
                                          <TableCell>
                                            <EarntCell {...data} />
                                          </TableCell>
                                          <TableCell>
                                            <ActionCell {...data} />
                                          </TableCell>
                                        </TableRow>
                                      )
                                  ))
                                }
                              </TableBody>
                            </Table>
                          )}
                      </Box>
                    </DialogContent>
                  </>
                )
                : (
                  <>
                    <Typography variant="body2" textAlign="center" sx={{ '& p': { margin: 0 } }}>
                      <p>You need to own a Bella to generate affiliation links</p>
                    </Typography>
                    <Box display="flex" justifyContent="center">
                      <Button
                        component={RouterLink}
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        to={`marketplace/collections/${BELLA_COLLECTION_ADDRESS}`}
                      >
                        Bella
                      </Button>

                    </Box>
                  </>
                )}
            </>
          )}
      </Dialog>
    </>
  );
};

export default AffiliateDialog;
