import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const CERT_KEY = 'player__certificates';

export interface PlayerCertificate {
  signature: string;
  signer: string;
  issuedAt: number;
}

export interface AddPayload {
  ledger: string;
  tokenIdStr: string;
  data: PlayerCertificate;
}

export interface PlayerState {
  certificates: Record<string, PlayerCertificate>;
}

const initialState: PlayerState = {
  certificates: JSON.parse(localStorage.getItem(CERT_KEY) || '{}'),
};

const slice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<AddPayload>) => {
      const { ledger, tokenIdStr, data } = action.payload;
      state.certificates[`${ledger}-${tokenIdStr}`] = data;

      localStorage.setItem(CERT_KEY, JSON.stringify(state.certificates));
    },
  },
});

export const { reducer } = slice;
export const {
  actions: { add },
} = slice;
export default slice;
