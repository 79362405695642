import { UnsupportedChainIdError } from '@web3-react/core';
import { Web3WalletconnectConnector, ensureDIDConnectionWith } from 'src/lib/did';
import { baseURL, isMobile } from 'src/utils';
import { JsonLocalStorage } from 'src/lib/storage';
import {
  ActivateFunc, ConnectResponse, rpcMap, getChainIds,
} from '../network';
import { ConnectorName } from './types';
import { injected } from './metamask';

// Elastos DID connector, essentials@wallectconnect
// Attention: since we use walletconnect, we had to set supported chains
// to all existing ones to prevent erorr: `Missing or invalid topic field`
// when trying to switch from a non-supported chain
// followup on https://github.com/WalletConnect/walletconnect-monorepo/issues/315
export const essentialConnect = new Web3WalletconnectConnector({
  supportedChainIds: getChainIds(),
  rpc: rpcMap,
  bridge: 'https://walletconnect.elastos.net/v2',
  qrcode: true,
  qrcodeModalOptions: {
    mobileLinks: [
      'essentials',
    ],
    desktopLinks: [
      'encrypted ink',
    ],
  },
});

export default {
  name: ConnectorName.ElastosDID,
  label: 'Essentials',
  handler: essentialConnect,
  icon: baseURL('/static/elacity/Essentials.webp'),
  connect: async (chainId: number, activate: ActivateFunc): Promise<ConnectResponse> => {
    const concreteConnector = window?.elastos ? injected : essentialConnect;

    if (window?.elastos && Number(window?.ethereum?.chainId) < 0) {
      // EE is connected to a non-EVM chain
      // without this checking, it will throw an invariant error
      throw new UnsupportedChainIdError(-1);
    }

    if (isMobile() && !window?.elastos) {
      window.open(`https://elink.web3essentials.io/open?u=${window.location.host}`, '_blank');
      return {
        chainId: null,
      };
    }

    // see https://github.com/NoahZinsmeister/web3-react/tree/v6/docs#understanding-error-bubbling
    // for more details about error handling
    await activate(concreteConnector, undefined, true);

    const [jsonCredentials, credentials] = await ensureDIDConnectionWith(concreteConnector, { init: true });
    console.log('joined successfully', { jsonCredentials, credentials });

    if (essentialConnect.walletConnectProvider) {
      essentialConnect.walletConnectProvider?.on('disconnect', async () => {
        window.localStorage.removeItem('walletconnect');

        // remove also .walletProvider from __ela_app_settings
        const settings = new JsonLocalStorage('__ela_app_settings');
        settings.delete('walletProvider');
      });
    }

    const connectedChainId = Number(await concreteConnector.getChainId());
    if (connectedChainId === chainId) {
      // enforce reload in order to digest new identity
      // this reload statement will ensure to call /api/account/did/link
      // in the login flow
      // for the case of different chain id the reload should be handled
      // by the networkChange listener
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }

    return {
      chainId: connectedChainId,
      provider: await essentialConnect.getProvider(),
    };
  },
  resurrect: async (activate: ActivateFunc): Promise<void> => {
    const concreteConnector = window?.elastos ? injected : essentialConnect;
    await new Promise((resolve, reject) => {
      activate(concreteConnector, undefined, true)
        .then(() => (
          ensureDIDConnectionWith(concreteConnector as Web3WalletconnectConnector, {})
            .then(() => {
              console.log('[DID] Completed connection flow');
            })
            .catch(reject)
        ))
        .catch(reject)
        .finally(
          () => resolve(concreteConnector)
        );
    });
  },
};
