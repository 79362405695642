import {
  SliderFieldInput, TextFieldInput, ModelType,
} from './type';

export const sliderFields: SliderFieldInput[] = [
  {
    type: 'slider',
    key: 'width',
    label: 'Width',
    description: 'Width of output image.',
    defaultValue: 512,
    step: null,
    min: 128,
    max: 1024,
    marks: [128, 256, 384, 448, 512, 576, 640, 704, 768, 832, 896, 960, 1024].map(
      (value) => ({ value })
    ),
    order: 1,
    vaildFor: [2, 3, 4],
  },
  {
    type: 'slider',
    key: 'height',
    label: 'Height',
    description: 'Height of output image.',
    defaultValue: 512,
    step: null,
    min: 128,
    max: 1024,
    marks: [128, 256, 384, 448, 512, 576, 640, 704, 768, 832, 896, 960, 1024].map(
      (value) => ({ value })
    ),
    order: 2,
    vaildFor: [2, 3, 4],
  },
  {
    type: 'slider',
    key: 'width',
    label: 'Width',
    description: 'Width of output image.',
    defaultValue: 512,
    step: null,
    min: 128,
    max: 1024,
    marks: [128, 256, 512, 768, 896, 1024].map(
      (value) => ({ value })
    ),
    order: 1,
    vaildFor: [1],
  },
  {
    type: 'slider',
    key: 'height',
    label: 'Height',
    description: 'Height of output image.',
    defaultValue: 512,
    step: null,
    min: 128,
    max: 1024,
    marks: [128, 256, 512, 768, 896, 1024].map(
      (value) => ({ value })
    ),
    order: 2,
    vaildFor: [1],
  },
  {
    type: 'slider',
    key: 'guidance_scale',
    label: 'Cfg Scale',
    description:
      'Cfg scale adjusts how much the image will be like your prompt. Higher values keep your image closer to your prompt.',
    defaultValue: 7,
    step: 0.5,
    min: 1,
    max: 20,
    marks: true,
    order: 4,
    vaildFor: [],
  },
  {
    type: 'slider',
    key: 'num_inference_steps',
    label: 'Steps',
    description: 'How many steps to spend generating (diffusing) your image.',
    defaultValue: 50,
    step: 1,
    min: 1,
    max: 500,
    order: 5,
    vaildFor: [],
  },
  {
    type: 'slider',
    key: 'num_outputs',
    label: 'Number of Images',
    // eslint-disable-next-line max-len
    description: 'Number of images to output.Permitted values: 1 to 10. Due to NSFW filter, you might get fewer outputs than this',
    defaultValue: 4,
    step: 1,
    min: 1,
    max: 10,
    order: 6,
    vaildFor: [2, 3, 4],
  },
  {
    type: 'slider',
    key: 'num_outputs',
    label: 'Number of Images',
    // eslint-disable-next-line max-len
    description: 'Number of images to output.Permitted values: 1 to 10. Due to NSFW filter, you might get fewer outputs than this',
    defaultValue: 4,
    min: 1,
    max: 4,
    marks: [{ value: 1 }, { value: 4 }],
    order: 6,
    vaildFor: [1],
  },
];

export const textFields: TextFieldInput[] = [
  {
    type: 'text',
    key: 'negative_prompt',
    label: 'Negative Prompt',
    description: 'Specify things to not see in the output',
    order: 7,
    vaildFor: [2, 3, 4],
  },
];

export const numberFields: TextFieldInput[] = [
  {
    type: 'number',
    key: 'seed',
    label: 'Seed',
    description: 'Random seed. Leave blank to randomize the seed',
    min: 0,
    max: 4294967295,
    order: 8,
    vaildFor: [],
  },
];

type FieldInput = SliderFieldInput | TextFieldInput;

export const imageFields = [
  {
    type: 'image',
    key: 'init_image',
    label: 'Initial Image',
    description: 'Inital image to generate variations of. Will be resized to the specified width and height',
    vaildFor: [2, 3],
  },
  {
    type: 'image',
    key: 'image',
    label: 'Initial Image',
    description: 'Input image for img2img or inpaint mode',
    vaildFor: [4],
  },
];

interface ModelDefinition {
  id: number;
  type: ModelType,
  label: string;
  model: string;
  inputs: FieldInput[];
}

export const models: ModelDefinition[] = [
  {
    id: 1,
    type: 'text2image',
    label: 'Stable Diffusion 1.5',
    model: 'stability-ai/stable-diffusion@b3d14e1cd1f9470bbb0bb68cac48e5f483e5be309551992cc33dc30654a82bb7',
    inputs: [...sliderFields, ...textFields, ...numberFields, ...imageFields].filter(
      ((f) => f.vaildFor?.length === 0 || f.vaildFor?.includes(1))
    ) as FieldInput[],
  },
  // {
  //   id: 2,
  //   type: 'text2image',
  //   label: 'Stable Diffusion 2.0',
  //   model: 'stability-ai/stable-diffusion@0827b64897df7b6e8c04625167bbb275b9db0f14ab09e2454b9824141963c966',
  //   inputs: [...sliderFields, ...textFields, ...numberFields].filter(
  //     ((f) => f.vaildFor?.length === 0 || f.vaildFor?.includes(2))
  //   ) as FieldInput[],
  // },
  // {
  //   id: 3,
  //   type: 'text2image',
  //   label: 'Stable Diffusion 2.1',
  //   model: 'stability-ai/stable-diffusion@ac732df83cea7fff18b8472768c88ad041fa750ff7682a21affe81863cbe77e4',
  //   inputs: [...sliderFields, ...textFields, ...numberFields, ...imageFields].filter(
  //     ((f) => f.vaildFor?.length === 0 || f.vaildFor?.includes(3))
  //   ) as FieldInput[],
  // },
  {
    id: 4,
    type: 'text2image',
    label: 'Stability SDXL',
    model: 'stability-ai/sdxl@2b017d9b67edd2ee1401238df49d75da53c523f36e363881e057f5dc3ed3c5b2',
    inputs: [...sliderFields, ...textFields, ...numberFields, ...imageFields].filter(
      ((f) => f.vaildFor?.length === 0 || f.vaildFor?.includes(4))
    ) as FieldInput[],
  },
];
