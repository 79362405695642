import { useState, useEffect } from 'react';
import { INTERFACE_ERC721 } from '@elacity-js/lib';
import { BigNumberish } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import useAddresses from 'src/hooks/useAddresses';
import { useEcosystem } from 'src/lib/web3/hooks';

export default (owner: string) => {
  const { provider } = useEcosystem();
  const [isBellaOwner, setIsBellaOwner] = useState<boolean>(false);
  const [isChecking, setIsChecking] = useState<boolean>(true);
  const { BELLA_COLLECTION_ADDRESS } = useAddresses();

  useEffect(() => {
    if (owner && BELLA_COLLECTION_ADDRESS) {
      if (provider) {
        const contract = new Contract(
          BELLA_COLLECTION_ADDRESS,
          INTERFACE_ERC721,
          provider
        );

        contract.balanceOf(owner)
          .then(
            (count: BigNumberish) => {
              if (count && Number(count.toString()) > 0) {
                setIsBellaOwner(true);
              } else {
                console.log('not owning this NFT', { collection: BELLA_COLLECTION_ADDRESS, owner });
              }
            }
          )
          .catch(
            (e: Error) => {
              console.error(`failed to verify ownership: ${e.message}`, { collection: BELLA_COLLECTION_ADDRESS, owner });
            }
          )
          .finally(() => {
            setIsChecking(false);
          });
      }
    } else {
      setIsChecking(false);
    }
  }, [owner, BELLA_COLLECTION_ADDRESS, provider]);

  return { isBellaOwner, isChecking };
};
