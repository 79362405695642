import React, { useRef, MutableRefObject } from 'react';
import {
  useSnackbar, SnackbarKey, OptionsObject as NotiOptions, SnackbarMessage, SnackbarContent,
} from 'notistack';
import { UnsupportedChainIdError } from '@web3-react/core';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

interface MessageWrapper {
  id: SnackbarKey;
  message: SnackbarMessage;
}

export const StackButton = styled(Button)(({ theme }) => ({
  color: 'white',
  '&.MuiButton-contained': {
    backgroundColor: theme.palette.grey[700],
  },
}));

export const SigningErrorWrapper = React.forwardRef<HTMLDivElement, MessageWrapper>((props, ref) => {
  const { id, message, ...other } = props;
  return (
    <SnackbarContent ref={ref} {...other}>
      <Alert severity="error" sx={{ mt: 3 }}>
        {message}
      </Alert>
    </SnackbarContent>
  );
});

export default (Wrapper?: React.ComponentType<MessageWrapper>) => {
  const snackbarId: MutableRefObject<SnackbarKey | null> = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleNotistackCloseAction = (key: SnackbarKey, url?: string) => () => {
    closeSnackbar(key);
    if (url) {
      window.open(url);
    }
  };

  const provider = React.useMemo(() => window?.ethereum || window?.elastos, [window?.ethereum, window?.elastos]);
  const handleElastosConnect = React.useCallback(async () => {
    if (provider) {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${Number(process.env.REACT_APP_CHAIN_ID || 20).toString(16)}` }],
      });
    }
  }, [provider]);

  const handlerError: (err?: Error) => void = (err?: Error) => {
    if (!err) {
      return;
    }

    if (err instanceof UnsupportedChainIdError) {
      snackbarId.current = enqueueSnackbar('Wrong network, only Elastos Smart Chain is supported', {
        persist: true,
        preventDuplicate: true,
        variant: 'error',
        action: (key: SnackbarKey) => (
          <>
            {provider && ![20, 21].includes(Number(provider.chainId)) && (
              <StackButton size="small" variant="contained" onClick={handleElastosConnect}>
                Connect to Elastos
              </StackButton>
            )}
            {!provider && (
              <StackButton
                size="small"
                variant="contained"
                onClick={handleNotistackCloseAction(key, 'https://docs.ela.city/getting-setup')}
              >
                Learn more
              </StackButton>
            )}

            <StackButton size="small" variant="text" onClick={handleNotistackCloseAction(key)}>
              Dismiss
            </StackButton>
          </>
        ),
      });
      return;
    }

    enqueueSnackbar(err.message, {
      variant: 'error',
      ...(Wrapper && {
        content: (key, message) => (
          <Wrapper id={key} message={message} />
        ),
      }),
    });
  };

  return {
    handlerError,
    closeError: closeSnackbar,
    key: snackbarId.current,
    scopedClose: () => closeSnackbar(snackbarId?.current || undefined),
    throwError: (
      err: Error,
      options?: NotiOptions
    ): SnackbarKey => enqueueSnackbar(err.message, {
      variant: 'error',
      ...(Wrapper && {
        content: (key, message) => (
          <Wrapper id={key} message={message} />
        ),
      }),
      ...(options || {}),
    }),
  };
};
