import React, {
  FC, PropsWithChildren, useState, createContext,
} from 'react';
import {
  fetchImageFormUrl, resolveImgName, recoverReplicateURL,
} from 'src/utils';
import useProfile from 'src/hooks/useProfile';
import { useDID } from 'src/lib/did/hooks';
import { Prediction, HiveFile } from 'src/types/prediction';
import { HiveFileHelper } from 'src/lib/did/services/hive.file.helper';

interface DataToMint {
  image?: File;
  mimetype?: string;
  description?: string;
  name?: string;
}

interface PredictionContextContextValue {
  prediction: Prediction;
  setPrediction: (pr: Prediction) => void;
  selected: string;
  selectedIndex?: number;
  select: (s: string) => void;
  dataToMint?: DataToMint;
  setDataToMint?: (data: DataToMint) => void;
  prepareImageForMint?: (image: string, prompt: string, name?: string) => Promise<DataToMint>;

  // hive vault
  hiveUploadOutput: (output: string[]) => void;
  removeHiveFile: (userDid: string, path: string) => Promise<void>;
  loadHiveFiles: (userDid: string, onDone?: () => void) => void;
  isLoadingHive?: boolean;
  hiveLoaded?: boolean;
  hiveFiles: HiveFile[];

  // UI/UX
  drawerOpen?: boolean;
  toggleDrawer?: (value?: boolean) => void;

  // carousel handling
  loadBySource?: () => void;
}

export const PredictionContext = createContext<PredictionContextContextValue>({
  prediction: null,
  setPrediction: () => {},
  hiveUploadOutput: () => {},
  selected: '',
  select: () => {},
  setDataToMint: () => {},
  prepareImageForMint: () => Promise.resolve({}),
  loadHiveFiles: (userDid: string) => {},
  removeHiveFile: (userDid: string, path: string) => Promise.reject(),
  hiveFiles: [],
});

interface PredictionProps {
  hiveVault?: string;
}

export const PredictionProvider: FC<PropsWithChildren<PredictionProps>> = ({ hiveVault, children }) => {
  const { profile } = useProfile();
  const { isConnectedWithDID } = useDID();
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>();
  const [prediction, setPrediction] = useState<Prediction>();
  const [selected, select] = useState<string>();
  const [dataToMint, setDataToMint] = useState<DataToMint>({});

  const [hiveLoaded, setHiveLoaded] = React.useState<boolean>(false);
  const [isLoadingHive, setHiveLoading] = React.useState<boolean>(false);
  const [hiveFiles, setHiveFiles] = React.useState<(HiveFile & { index?: number })[]>([]);

  const toggleDrawer = (value?: boolean) => {
    if (typeof value !== 'undefined') {
      setDrawerOpen(value);
    } else {
      setDrawerOpen((prev) => !prev);
    }
  };

  const loadHiveFiles = (userDid: string, onDone?: () => void) => {
    if (!hiveLoaded) {
      setHiveLoading(true);
      HiveFileHelper.fetchAssets(userDid, { path: hiveVault, reverse: true })
        .wait()
        .on('file', ({ index, ...chunk }: HiveFile & { index: number }) => {
          setHiveFiles((prev) => [...prev, { ...chunk, index }]);
        })
        .catch(console.error)
        .done(() => {
          setHiveLoading(false);
          setHiveLoaded(true);
          onDone?.();
        });
    }
  };

  const removeHiveFile = async (userDid: string, fullpath: string) => {
    try {
      await HiveFileHelper.removeFile(userDid, fullpath);

      // remove from list
      setHiveFiles((prev) => prev.filter((f) => f.path !== fullpath));
    } catch (e) {
      console.warn(e);
    }
  };

  const prepareImageForMint = async (_imageURL: string, prompt: string, _name?: string) => {
    const imageURL = recoverReplicateURL(_imageURL);
    const asBlob: Blob = await fetchImageFormUrl(imageURL);
    const name = _name || resolveImgName(selected);
    const image: File = new File([asBlob], name, {
      type: `image/${name?.split('.').pop() || 'png'}`,
    });
    select(imageURL);
    const payload: DataToMint = {
      image,
      description: prompt,
      name,
      mimetype: `image/${name?.split('.').pop() || 'png'}`,
    };
    setDataToMint(payload);

    return payload;
  };

  const hiveUploadOutput = React.useCallback(
    (output: string[]) => {
      if (isConnectedWithDID() && profile?.settings?.autoSaveAI) {
        Promise.all(
          // eslint-disable-next-line max-len
          output.map(async (url: string) => HiveFileHelper.uploadByURLToUserVault(profile?.did?.did, url, hiveVault))
        ).then((newFiles: HiveFile[]) => {
          setHiveFiles((prev) => [
            ...newFiles.map((f: HiveFile) => ({
              ...f,
              url: URL.createObjectURL(new Blob([f.content.buffer], { type: 'image/png' })),
            })),
            ...prev,
          ]);
        });
      }
    },
    [profile?.did?.did, profile?.settings?.autoSaveAI]
  );

  return (
    <PredictionContext.Provider
      value={{
        prediction,
        setPrediction,
        selected,
        select,
        dataToMint,
        setDataToMint,
        prepareImageForMint,
        isLoadingHive,
        hiveFiles,
        hiveLoaded,
        loadHiveFiles,
        removeHiveFile,
        hiveUploadOutput,

        drawerOpen,
        toggleDrawer,
      }}
    >
      {children}
    </PredictionContext.Provider>
  );
};
