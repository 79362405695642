import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  loadJsonValue, storeJsonValue, getNFTSortKey,
} from 'src/utils';
import type { RootState, AppDispatch } from '../store';
import { QueryParams, FilterByValue } from './types';

const STORAGE_KEY = '__ela_filters';

export type QueryState = {
  auction: QueryParams;
  marketplace: QueryParams;
  collection: QueryParams;
  home: QueryParams;
  drm: QueryParams & {
    seller?: string;
    scarcity?: {
      min?: number;
      max?: number;
    };
    remainingSale?: number;
  };
  shopdrm: QueryParams;
}

const initialState: QueryState = {
  auction: {
    type: 'single',
    from: 0,
    count: 24,
    filterby: ['onAuction'],
    sortby: 'deadline$asc',
    category: 'all',
  },
  home: {
    type: 'single',
    from: 0,
    count: 24,
    filterby: [],
    sortby: 'createdAt$desc',
    category: 'all',
  },
  marketplace: {
    type: 'single',
    from: 0,
    count: 24,
    filterby: [],
    sortby: 'listedAt$desc',
    category: 'all',
  },
  collection: {
    type: 'single',
    from: 0,
    count: 200,
    filterby: ['buyNow'],
    sortby: 'listedAt$desc',
    category: 'all',
  },
  drm: {
    type: 'single',
    from: 0,
    count: 24,
    filterby: [],
    sortby: 'createdAt$desc',
    category: [],
  },
  shopdrm: {
    type: 'single',
    from: 0,
    count: 24,
    filterby: [],
    sortby: 'createdAt$desc',
    category: [],
  },
};

const slice = createSlice({
  name: 'filters',
  initialState: loadJsonValue(STORAGE_KEY, initialState),
  reducers: {
    updateQuery(state: QueryState, action: PayloadAction<{ key: keyof QueryState, data: Partial<QueryParams> }>) {
      state[action.payload.key] = {
        ...state[action.payload.key],
        ...action.payload.data,
      };
      storeJsonValue(STORAGE_KEY, state);
    },
    setFilterBy(state: QueryState, action: PayloadAction<{ key: keyof QueryState, filterBy: FilterByValue }>) {
      state[action.payload.key] = {
        ...state[action.payload.key],
        filterby: action.payload.filterBy ? [action.payload.filterBy] : [],
        from: 0,
      };
      storeJsonValue(STORAGE_KEY, state);
    },
    addFilterBy(state: QueryState, action: PayloadAction<{ key: keyof QueryState, filterBy: FilterByValue }>) {
      state[action.payload.key] = {
        ...state[action.payload.key],
        filterby: [
          ...(state[action.payload.key].filterby || []),
          action.payload.filterBy,
        ],
        from: 0,
      };
      storeJsonValue(STORAGE_KEY, state);
    },
    removeFilterBy(state: QueryState, action: PayloadAction<{ key: keyof QueryState, filterBy: FilterByValue }>) {
      state[action.payload.key] = {
        ...state[action.payload.key],
        filterby: (state[action.payload.key].filterby || []).filter(
          (f: FilterByValue) => f !== action.payload.filterBy
        ),
        from: 0,
      };
      storeJsonValue(STORAGE_KEY, state);
    },
  },
});

export const { reducer } = slice;
export const { updateQuery, setFilterBy, addFilterBy, removeFilterBy } = slice.actions;

export const selectCollectionFilter = (state: RootState) => state.filters.collection;
export const selectFilter = (sliceKey: keyof QueryState) => (state: RootState) => state.filters[sliceKey];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleChangeCreator = <T>(sliceKey: keyof QueryState, dispatch: AppDispatch) => (key: keyof T, value: any) => {
  // eslint-disable-next-line default-case
  switch (key) {
    case 'sortBy':
      dispatch(
        updateQuery({
          key: sliceKey,
          data: {
            sortby: getNFTSortKey(value),
            from: 0,
          },
        })
      );
      break;
    case 'filterBy':
      updateQuery({
        key: sliceKey,
        data: {
          filterby: value,
          from: 0,
        },
      });
      break;
    case 'collectionAddresses':
      updateQuery({
        key: sliceKey,
        data: {
          collectionAddresses: value,
          from: 0,
        },
      });
      break;
    case 'category':
    case 'categories':
      updateQuery({
        key: sliceKey,
        data: {
          category: value,
          from: 0,
        },
      });
      break;
    case 'price':
      updateQuery({
        key: sliceKey,
        data: {
          price: value,
          from: 0,
        },
      });
      break;
    case 'storageType':
    case 'searchBy':
    case 'seller':
    case 'scarcity':
    case 'remainingSale':
      updateQuery({
        key: sliceKey,
        data: {
          [key]: value,
          from: 0,
        },
      });
      break;
  }
};
