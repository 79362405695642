import {
  CollectionOf, ipfsLinkFor, TokenID,
} from '@elacity-js/lib';
import {
  MediaTokenAsset,
  PaginatedQuery,
  GraphqQLResponse,
  MediaViewState,
  AssetLogsItem,
  LedgerAssetQuery,
  LedgerAssetRequest,
  AssetQuery,
  AssetRequest,
} from 'src/types';
import {
  api, profileFragment, nftFragment,
} from './query.base';
import { authenticatedApi } from './query.private';

enum Tags {
  AssetLogs = 'AssetLogs',
  VideoAsset = 'VideoAsset',
}

const ledgerApi = api
  .enhanceEndpoints({
    addTagTypes: [Tags.VideoAsset],
  }).injectEndpoints({
    endpoints: (builder) => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fetchNFTItems: builder.query<any, unknown>({
        query: ({ query, filters, account }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          headers: {
            ...(account && {
              'x-eth-signer': account,
            }),
          },
          body: {
            query: `
            ${profileFragment}
            ${nftFragment}
            
            query FetchNFTItems(
              $query: NFTItemQueryInput
              $filters: FilterPaginationInput
            ) {
              fetchNFTItems(query: $query, filters: $filters) {
                total
                offset
                limit
                data {
                  __typename
                  ... on StandardAsset {
                    ...nftFields
                    metadata {
                      type
                      properties {
                        symbol
                        mimeType
                        account {
                          ...profileFields
                        }
                        royalty
                        collection
                        size
                        mimeType
                      }
                    }
                  }
                  ... on ProtectedAsset {
                    ...nftFields
                    metadata {
                      kid
                      iscc
                      media {
                        contentType
                        protectionType
                      }
                      properties {
                        publisher {
                          ...profileFields
                        }
                        authority
                        labelType
                        distribution
                      }
                      attributes {
                        trait_type
                        value
                      }
                    }
                    operative {
                      opType
                      resellerCut
                      access {
                        totalSupply
                        listings {
                          seller
                          quantity
                          price
                          payToken
                        }
                      }
                    }
                    access {
                      haveAccess
                      entitlement
                    }
                  }
                }
              }
            }`,
            variables: {
            // @todo: make change on media-upload to support these inputs
            // for now we have "BAD_USER_INPUT"
              filters,
              query,
            },
          },
        }),
        transformResponse: async (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
          r: GraphqQLResponse<any>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ): Promise<CollectionOf<any>> => {
          if (r.data?.fetchNFTItems) {
            return {
              ...r.data?.fetchNFTItems,
              items: r.data?.fetchNFTItems.data.map((item) => ({
                ...item,
                tokenId: item.tokenID,
                image: item.imageURL,
              })),
            };
          }

          return Promise.reject(r.errors[0]);
        },
      }),
      fetchVideoAssets: builder.query<CollectionOf<MediaTokenAsset>, AssetRequest<AssetQuery>>({
        query: ({ query, filters }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
          query FetchVideoAssets($query: NFTItemQueryInput, $filters: FilterPaginationInput) {
            results: fetchNFTItems(query: $query, filters: $filters) {
              total
              offset
              limit
              data {
                ... on ProtectedAsset {
                  address: contractAddress
                  tokenID
                  hexTokenID
                  name
                  description
                  image: imageURL
                  metadata {
                    kid
                    iscc
                    media {
                      contentType
                      protectionType
                    }
                    properties {
                      chainId
                      publisher {
                        ...profileFields
                      }
                    }
                    attributes {
                      trait_type
                      value
                    }
                  }
                  operative {
                    address
                    opType
                    access {
                      address
                      totalSupply
                      listings {
                        seller
                        price
                        quantity
                        payToken
                      }
                    }
                  }
                  createdAt
                  views
                  unpublished
                }
              }
            }
          }
          ${profileFragment}
          `,
            variables: {
              query,
              filters,
            },
          },
        }),
        transformResponse: async (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
          r: GraphqQLResponse<any>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ): Promise<CollectionOf<any>> => {
          if (r.data?.results) {
            const { data, ...results } = r.data?.results || {};
            return {
              ...results,
              items: data?.map((item) => ({
                ...item,
                tokenId: TokenID.fromObject({
                  tokenID: item.tokenID,
                  hexTokenID: item.hexTokenID,
                }).toJSON(),
              })),
            };
          }

          return Promise.reject(r.errors[0]);
        },
        providesTags: (result, error) => (
          result && !error
            ? [
              ...result.items.map(({ address, hexTokenID }) => ({
                type: Tags.VideoAsset as const,
                id: `${address}-${hexTokenID}`,
              })),
              { type: Tags.VideoAsset, id: 'LIST' },
              Tags.VideoAsset,
            ]
            : [Tags.VideoAsset]
        ),
      }),
      fetchLedgerTokens: builder.query<CollectionOf<MediaTokenAsset>, LedgerAssetQuery>({
        query: ({ address, query }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            query FetchLedgerAssets($address: String!, $query: LedgerAssetQuery) {
              fetchLedgerAssets(address: $address, query: $query) {
                total
                offset
                limit
                items {
                  address
                  tokenId
                  name
                  description
                  image
                  metadata {
                    kid
                    iscc
                    media {
                      contentType
                      protectionType
                    }
                    properties {
                      publisher {
                        ...profileFields
                      }
                    }
                    attributes {
                      trait_type
                      value
                    }                    
                  }
                  isProtected
                  operative {
                    address
                    opType
                    access {
                      totalSupply
                      listings {
                        seller
                        price
                        quantity
                        payToken
                      }
                    }
                  }
                  createdAt
                  views
                  comments
                }
              }
            }
            ${profileFragment}
            `,
            variables: {
              address,
              query,
            },
          },
        }),
        transformResponse: async (
          r: GraphqQLResponse<CollectionOf<Omit<MediaTokenAsset, 'tokenId'> & { tokenId: { hexTokenID: string } }>>
        ): Promise<CollectionOf<MediaTokenAsset>> => {
          if (r.data?.fetchLedgerAssets) {
            return {
              ...r.data?.fetchLedgerAssets,
              items: r.data?.fetchLedgerAssets.items.map((item) => ({
                ...item,
                tokenId: TokenID.from(item.tokenId.hexTokenID).toJSON(),
                image: ipfsLinkFor(item.image),
              })),
            };
          }

          return Promise.reject(r.errors[0]);
        },
      }),
      fetchLedgerToken: builder.query<Omit<MediaViewState, 'loading'>, LedgerAssetRequest>({
        query: ({ address, tokenId, owner }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          headers: {
            ...(owner && {
              'x-eth-signer': owner,
            }),
          },
          body: {
            query: `
            query FetchLedgerAsset($address: String!, $tokenId: TokenID!) {
              getLedgerAsset(address:$address, tokenId: $tokenId) {
                tokenId
                tokenURI
                image
                name
                metadata {
                  kid
                  iscc
                  name
                  description
                  properties {
                    contract
                    publisher {
                      alias
                      address
                      avatar
                      did {
                        did
                        trustLevel
                        credentials {
                          name
                          avatar {
                            data
                            thumbnail
                          }
                        }
                      }
                    }
                    ledger
                    chainId
                    authority
                    labelType
                    distribution
                  }
                  media {
                    uri
                    contentType
                    protectionType
                    object
                    size
                  }
                  attributes {
                    trait_type
                    value
                  }
                }
                isProtected
                operative {
                  address
                  owner
                  opType
                  contentId
                  resellerCut
                  royalties {
                    distributions
                  }
                  access {
                    totalSupply
                    listings {
                      seller
                      price
                      quantity
                      payToken
                    }
                  }
                }
                access {
                  haveAccess
                  entitlement
                }
                views
                createdAt
              }
            }
            `,
            variables: {
              address,
              tokenId,
            },
          },
          refetchOnMountOrArgChange: true,
          keepUnusedDataFor: 10,
        }),
        transformResponse: async (
          r: GraphqQLResponse<Omit<MediaViewState, 'tokenId' | 'loading'> & { tokenId: { hexTokenID: string } }>
        ): Promise<MediaViewState> => {
          if (r.data?.getLedgerAsset) {
            const item = r.data?.getLedgerAsset;
            return {
              ...item,
              tokenId: TokenID.from(item.tokenId.hexTokenID).toJSON(),
              image: ipfsLinkFor(item.image),
              mediaURL: `${ipfsLinkFor(item?.metadata?.media?.uri)}/stream.mpd`,
            };
          }

          return Promise.reject(r.errors[0]);
        },
      }),
      incrementViews: builder.mutation<number, LedgerAssetRequest>({
        query: ({ address, tokenId, owner }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            mutation IncrementViews($address: String!, $tokenId: TokenID!, $owner: String) {
              incrementViews(address: $address, tokenId: $tokenId, owner: $owner)
            }
            `,
            variables: {
              address,
              tokenId,
              owner: owner || null,
            },
          },
        }),
        transformResponse: async (r: GraphqQLResponse<number>): Promise<number> => r.data?.incrementViews || 0,
      }),
    }),
  });

const ledgerAuthApi = authenticatedApi
  .enhanceEndpoints({
    addTagTypes: [Tags.AssetLogs],
  }).injectEndpoints({
    endpoints: (builder) => ({
      fetchAccessibleTokens: builder.query<CollectionOf<MediaTokenAsset>, PaginatedQuery>({
        query: (query) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            query FetchAccessibleAssets($query: LedgerAssetQuery) {
              fetchAccessibleAssets(query: $query) {
                total
                offset
                limit
                items {
                  address
                  tokenId
                  name
                  description
                  image
                  metadata {
                    kid
                    iscc
                    media {
                      contentType
                      protectionType
                    }
                    properties {
                      chainId
                      publisher {
                        ...profileFields
                      }
                    }
                    attributes {
                      trait_type
                      value
                    }
                  }
                  isProtected
                  operative {
                    address
                    opType
                    access {
                      address
                      totalSupply
                      holdings
                      listings {
                        seller
                        price
                        quantity
                        payToken
                      }
                    }
                  }
                  createdAt
                  views
                }
              }
            }
            ${profileFragment}
            `,
            variables: {
              query,
            },
          },
        }),
        // transformResponse: (r: GraphqQLResponse<CollectionOf<MediaTokenAsset>>) => r?.data.fetchAccessibleAssets,
        transformResponse: async (
          r: GraphqQLResponse<CollectionOf<Omit<MediaTokenAsset, 'tokenId'> & { tokenId: { hexTokenID: string } }>>
        ) => ({
          ...r.data?.fetchAccessibleAssets,
          items: (r.data?.fetchAccessibleAssets?.items || []).map((item) => ({
            ...item,
            tokenId: TokenID.from(item.tokenId.hexTokenID).toJSON(),
            image: ipfsLinkFor(item.image),
          })),
        }),
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fetchAssetsLogs: builder.query<any, unknown>({
        query: ({ query, filters }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            ${profileFragment}
            ${nftFragment.replace('createdAt', 'createdAt\n  viewedAt')}
            
            query FetchAssetsLogs($query: AssetLogQuery, $filters: FilterPaginationInput) {
              fetchAssetsLog(query: $query, filters: $filters) {
                total
                offset
                limit
                data {
                  __typename
                  ... on ProtectedAsset {
                    ...nftFields
                    metadata {
                      kid
                      iscc
                      media {
                        contentType
                        protectionType
                      }
                      properties {
                        publisher {
                          ...profileFields
                        }
                        authority
                        labelType
                        distribution
                      }
                      attributes {
                        trait_type
                        value
                      }
                    }
                    operative {
                      opType
                      resellerCut
                      access {
                        totalSupply
                        listings {
                          seller
                          quantity
                          price
                          payToken
                        }
                      }
                    }
                  }
                }
              }
            }`,
            variables: {
              filters,
              query,
            },
          },
        }),
        transformResponse: async (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
          r: GraphqQLResponse<any>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ): Promise<CollectionOf<any>> => {
          if (r.data?.fetchAssetsLog) {
            return {
              ...r.data?.fetchAssetsLog,
              items: r.data?.fetchAssetsLog.data.map((item) => ({
                ...item,
                tokenId: item.tokenID,
                image: item.imageURL,
              })),
            };
          }

          return Promise.reject(r.errors[0]);
        },
        providesTags: (result, error) => (
          result && !error
            ? [...result.data.map(({ _id }) => ({ type: Tags.AssetLogs as const, id: _id })), Tags.AssetLogs]
            : [Tags.AssetLogs]
        ),
      }),
      removeAssetsLogsItem: builder.mutation<GraphqQLResponse<boolean>, AssetLogsItem>({
        query: ({ input }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
              mutation deleteHistoryItems($input: AssetLogItemsInput!) {
                deleteHistoryItems(input: $input)
              }
            `,
            variables: {
              input,
            },
          },
        }),
        invalidatesTags: [Tags.AssetLogs],
      }),
    }),
  });

export { ledgerApi, ledgerAuthApi };

export const {
  useFetchNFTItemsQuery,
  useFetchLedgerTokenQuery,
  useFetchVideoAssetsQuery,
  useLazyFetchLedgerTokenQuery,
  useFetchLedgerTokensQuery,
  useIncrementViewsMutation,
} = ledgerApi;

export const {
  useFetchAccessibleTokensQuery,
  useFetchAssetsLogsQuery,
  useRemoveAssetsLogsItemMutation,
} = ledgerAuthApi;
