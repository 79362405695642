import {
  useCallback, useEffect, useState,
} from 'react';

/* eslint-disable max-len */
import { ThumbnailSize } from 'src/types';

export const INVALID_THUMBNAIL = ['.', '-', 'non-image', undefined, null];

export const getThumbnail = (url: string, thumbSize?: ThumbnailSize): string => {
  if (url.match(/thumbnails/gi) && thumbSize && thumbSize > 0) {
    const [name, ...urlSlice] = url.split('/').reverse();
    return [name, thumbSize, ...urlSlice].reverse().join('/');
  }

  return url;
};

export const thumbnail = (file: string, thumbSize?: ThumbnailSize): string => getThumbnail(`${process.env.REACT_APP_BACKEND_URL}/thumbnails/${file}`, thumbSize);

export const ifThumbnail = (value: string, thumbSize?: ThumbnailSize | number): string => {
  if (!value?.match(/thumbnail:/gi)) {
    return value;
  }

  return thumbnail(value.replace(/thumbnail:/gi, ''), thumbSize);
};

export const useThumbnailSize = (): ThumbnailSize => {
  const [baseWidth, setWidth] = useState<number>(window.innerWidth);
  const guessThumbSize = useCallback(
    (w: number) => {
      if (w >= 1920) return ThumbnailSize.large;
      if (w > 1200) return ThumbnailSize.medium;
      if (w > 1024) return ThumbnailSize.medium;
      if (w > 768) return ThumbnailSize.medium;
      if (w <= 420) return ThumbnailSize.small;
      return ThumbnailSize.medium;
    },
    [baseWidth]
  );

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return guessThumbSize(baseWidth);
};

export const mediaThumbnail = (path: string) => {
  if (path?.match(/^thumbnail:/)) {
    // return `https://staging.ela.city/api/thumbnails/${path.replace(/^thumbnail:/, '')}`;
    return `${process.env.REACT_APP_BACKEND_URL}/thumbnails/${path.replace(/^thumbnail:/, '')}`;
  }

  return path;
};
