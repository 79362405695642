/* eslint-disable no-case-declarations */
import {
  useState, useEffect, useMemo, useCallback, useReducer,
} from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { Contract, ContractInterface } from '@ethersproject/contracts';
import { useWeb3React } from '@web3-react/core';
import { useAppSettings } from 'src/hooks';
import useErrorHandler from 'src/hooks/useErrorHandler';
import Web3WalletconnectConnector from 'src/lib/did/addons/Web3WalletconnectConnector';
import { ensureDIDConnectionWith } from 'src/lib/did';
import { useEcosystem } from './Ecosystem';
import {
  injected, walletconnect, essentialConnect, ConnectorName,
} from './connectors';

interface ReadinessState {
  ready: boolean;
  tried: boolean;
  processing?: boolean;
}

type SetValueAction = {
  type: 'CHANGE',
  payload: Partial<ReadinessState>
}

const reducer = (state: ReadinessState, action: SetValueAction) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export function useEagerConnect() {
  const { key, handlerError, closeError } = useErrorHandler();
  const { activate, active } = useWeb3React<Web3Provider>();
  const { values } = useAppSettings();

  const [{ tried, ready, processing }, dispatch] = useReducer(reducer, {
    tried: false,
    ready: false,
  });

  const setState = (payload: Partial<ReadinessState>) => {
    dispatch({ type: 'CHANGE', payload });
  };

  useEffect(() => {
    console.log('[Connect.useEagerConnect] resuming connection...', [values.walletProvider]);

    if (!processing) {
      setState({ processing: true });
      // As we previously stored the walletProvider as user preference
      // we will retrieve it and connect the wallet accordingly
      // eslint-disable-next-line default-case
      switch (values.walletProvider as ConnectorName) {
        case ConnectorName.Metamask:
          injected
            .isAuthorized()
            .then((isAuthorized: boolean) => {
              // @todo: find why `isAuthorized` is false on reload
              // it come to true on hot-reload
              setState({ ready: true });

              if (isAuthorized) {
                activate(injected, undefined, true)
                  .catch((err: Error) => handlerError(err))
                  .finally(
                    () => {
                      setState({ tried: true, processing: false });
                      console.log('[Connect.metamask] workflow ended');
                    }
                  );
              } else {
                setState({ tried: true, processing: false });
              }
            });
          break;
        case ConnectorName.WalletConnect:
          activate(walletconnect, undefined, true)
            .then(() => {
              setState({ ready: true });
            })
            .catch((err: Error) => handlerError(err))
            .finally(() => {
              setState({ tried: true, processing: false });
            });
          break;
        case ConnectorName.ElastosDID:
          const concreteConnector = window?.elastos ? injected : essentialConnect;
          activate(concreteConnector, undefined, true)
            .then(() => {
              ensureDIDConnectionWith(concreteConnector as Web3WalletconnectConnector, {})
                .then(() => {
                  setState({ ready: true });
                  console.log('[Connect.DID] Completed connection flow');
                })
                .catch((err: Error) => handlerError(err));
            })
            .catch((err: Error) => handlerError(err))
            .finally(() => {
              setState({ tried: true, processing: true });
            });

          break;
        default:
          console.warn('no connection to check', [values.walletProvider]);
          setState({ processing: true, ready: true });
          break;
      }
    }
  }, []); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setState({ tried: true });
    }

    if (active && key) {
      closeError(key);
    }
  }, [tried, active]);

  return { tried, ready, processing };
}

export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3React();
  const { handlerError, scopedClose } = useErrorHandler();
  const { values } = useAppSettings();

  console.log('[Connect.useInactiveListener] executing...', { active, error, suppress });
  const checkActivation = () => activate(injected, undefined, true).then(scopedClose).catch(handlerError);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { ethereum } = window as any;
    if (ConnectorName.Metamask === values.walletProvider && ethereum && ethereum.on && !error && !suppress) {
      const handleConnect = () => {
        checkActivation();
      };
      const handleChainChanged = (chainId: string | number) => {
        console.log('Handling \'chainChanged\' event with payload', chainId);
        window.location.reload();
      };
      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          checkActivation();
        }
        window.location.reload();
      };

      ethereum.on('connect', handleConnect);
      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect);
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
        }
      };
    }

    return () => {};
  }, [active, error, suppress, activate]);
}

export const useAddresses = () => {
  const { contracts } = useEcosystem();
  const { chainId, account } = useWeb3React();

  return {
    account,
    ...contracts,

    // @deprecated from 0.2.0, use contracts instead (EcosystemContext)
    TEMP721: chainId === 20 ? process.env.REACT_APP_TOKEN721_MAINNET : process.env.REACT_APP_TOKEN721_TESTNET,
    ISSUER_TOKEN: chainId === 20 ? process.env.REACT_APP_ISSUER_TOKEN_MAINNET : process.env.REACT_APP_ISSUER_TOKEN_TESTNET,
    MEDIA_TOKEN: chainId === 20 ? process.env.REACT_APP_MEDIA_TOKEN_MAINNET : process.env.REACT_APP_MEDIA_TOKEN_TESTNET,
  };
};

export const useContract = (address: string, abi: ContractInterface) => {
  const { provider } = useEcosystem();
  return useMemo(() => (address ? new Contract(address, abi, provider) : null), [address, provider]);
};

export const useContractWithSigner = (address: string, abi: ContractInterface) => {
  const { provider } = useEcosystem();
  // eslint-disable-next-line max-len
  return useMemo(() => (address ? new Contract(address, abi, provider?.getSigner()) : null), [address, provider?.getSigner()]);
};

export const useContractFactory = (abi: ContractInterface) => {
  const { provider } = useEcosystem();
  return useCallback((address: string) => new Contract(address, abi, provider), [provider]);
};

export const useContractWithSignerFactory = (abi: ContractInterface) => {
  const { provider } = useEcosystem();
  return useCallback((address: string) => new Contract(address, abi, provider?.getSigner()), [provider?.getSigner()]);
};

export { useEcosystem };
