/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { Client } from '../../api/client';
import { IWalletUtils, IContractLoader } from './types';
import TypeEvent from './TypedEvent';

interface ContractExecutorOptions {
  account?: string;
  chainId?: string;
}

export default class ContractExecutor extends TypeEvent {
  public account: string;

  public chainId: string;

  protected _wUtils: IWalletUtils;

  protected _balance: string;

  protected _loadContract: IContractLoader;

  protected _provider: any;

  protected _client: Client;

  public constructor({ account, chainId }: ContractExecutorOptions) {
    super();
    this.account = account;
    this.chainId = chainId ? chainId.toString() : '';
  }

  public setClientHttp(client: Client) {
    this._client = client;
  }

  public setContractLoader(contractLoader: IContractLoader) {
    this._loadContract = contractLoader;
  }

  public setProvider(provider: any) {
    this._provider = provider;

    // overwrite network id
    if (!this.chainId || this.chainId === '') {
      this.chainId = (provider._network || {}).chainId || (provider.connection || {}).chainID;
    }
  }

  public setWalletUtils(walletUtils: IWalletUtils) {
    this._wUtils = walletUtils;
  }

  protected _calculateGasMargin(value: BigNumberish, minimal?: number): BigNumberish {
    // eslint-disable-next-line
    return (value == 0 ? BigNumber.from(minimal || 21000) : BigNumber.from(value))
      .mul(BigNumber.from(10000).add(BigNumber.from(1000)))
      .div(BigNumber.from(10000));
  }

  protected async _getHigherGWEI() {
    const price = (await this._provider.getGasPrice()).mul(2);

    return price;
  }

  protected async _getGasOptions() {
    const options = {
      gasPrice: await this._getHigherGWEI(),
      gasLimit: 1000 * 1000,
    };

    return options;
  }
}
