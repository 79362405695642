/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React from 'react';
import type { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { baseURL } from 'src/utils';
import { useResponsivenessWith } from './utils';

interface LogoProps {
  size?: number;
  responsive?: boolean;
}

export const FlintLogo: FC<LogoProps> = ({ size, responsive }: LogoProps) => {
  const theme = useTheme();

  const responsiveSize = useResponsivenessWith(size);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img
        crossOrigin="anonymous"
        alt="elacity"
        src={baseURL(`/static/elacity/flint/flint.logo.${theme.palette.mode}.png`)}
        style={{
          width: responsive ? responsiveSize : size,
          height: 'auto',
          position: 'relative',
        }}
      />
    </div>
  );
};

FlintLogo.defaultProps = {
  size: 120,
};

export default FlintLogo;
