import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Provider from './provider';
import * as serviceWorker from './serviceWorker';
import './sentry';
import { isMobile } from './utils';

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// Updates since 2022-01-11: as we might encounter injection issue with
// Essentials dapps browser, we will deactivate service worker in that
// browser context, until we have a precise way to determine wether we
// are using such a browser, this will be applied on all mobile devices
if (isMobile() && window?.elastos) {
  serviceWorker.unregister();
} else {
  serviceWorker.register();
}

// reportWebVitals(console.log);
