import React, { useState } from 'react';
import { styled, SxProps } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import MuiOutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid';
import MuiInputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MuiButton from '@mui/material/Button';

const InputLabel = styled(MuiInputLabel, {
  shouldForwardProp: (prop: string) => prop !== 'size',
})<InputLabelProps & { size?: OutlinedInputProps['size'] }>(({ theme, size }) => ({
  ...(size === 'small' && {
    fontSize: '0.9rem!important',
    '&.MuiInputLabel-shrink': {
      top: 2,
    },
  }),
}));

const Button = styled(MuiButton)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  padding: theme.spacing(1, 3),
}));

const OutlinedInput = styled(MuiOutlinedInput, {
  shouldForwardProp: (prop: string) => prop !== 'rounded',
})<OutlinedInputProps & { rounded?: boolean }>(({ theme, rounded, size }) => ({
  ...(rounded && {
    borderRadius: theme.spacing(10),
  }),
  ...(size === 'small' && {
    fontSize: '0.85rem',
  }),
}));

export interface RangeValue<T = string | number> {
  min?: T;
  max?: T;
}

export interface RangeInputProps {
  value?: RangeValue;
  defaultValue?: RangeValue;
  onChange?: (e: React.MouseEvent<HTMLElement> | null, value: RangeValue) => void;

  size?: OutlinedInputProps['size'];
  setAnchorEl?: (el: HTMLElement) => void;
  sx?: SxProps;
}

const RangeInput = ({
  children,
  onChange,
  value,
  defaultValue,
  size,
  setAnchorEl,
  sx,
}: React.PropsWithChildren<RangeInputProps>) => {
  const [innerValue, setValue] = useState<RangeValue>(value || defaultValue || {});
  const handleInputChange = (key: 'min' | 'max') => (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      (prev) => ({
        ...prev,
        [key]: Number(e.target.value),
      } as RangeValue)
    );
  };

  return (
    <>
      <Box sx={sx}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel margin="dense" sx={{ top: -8, '&.MuiInputLabel-shrink': { top: 0 } }}>
                Min
              </InputLabel>
              <OutlinedInput
                label="Min"
                type="number"
                size="small"
                value={innerValue?.min || ''}
                onChange={handleInputChange('min')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel margin="dense" sx={{ top: -8, '&.MuiInputLabel-shrink': { top: 0 } }}>
                Max
              </InputLabel>
              <OutlinedInput
                label="Max"
                type="number"
                size="small"
                value={innerValue?.max || ''}
                onChange={handleInputChange('max')}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {children}

      <Box sx={{ display: 'flex', justifyContent: 'space-evenly', p: 1 }}>
        {typeof defaultValue !== 'undefined' && (
          <Button
            variant="text"
            size={size || 'medium'}
            onClick={() => {
              onChange?.(null, defaultValue);
              setAnchorEl?.(null);
            }}
          >
            Clear
          </Button>
        )}
        <Button
          variant="contained"
          size={size || 'medium'}
          onClick={() => {
            onChange?.(null, innerValue);
            setAnchorEl?.(null);
          }}
        >
          Apply
        </Button>
      </Box>
    </>
  );
};

interface RangeInputContextValue<T = string | number> {
  innerValue: RangeValue<T>;
  setValue: React.Dispatch<React.SetStateAction<RangeValue<T>>>;
  textValue?: string;
}
const RangeInputContext = React.createContext<RangeInputContextValue>({ innerValue: null, setValue: () => {} });

interface RangeInputProviderProps<T = string | number> {
  value?: RangeValue<T>;
  defaultValue?: RangeValue<T>;
  label?: OutlinedInputProps['label'];
}

export const RangeInputProvider = ({
  children,
  value,
  defaultValue,
  label,
}: React.PropsWithChildren<RangeInputProviderProps>) => {
  const [innerValue, setValue] = useState<RangeValue>(value || defaultValue || {});
  const textValue = React.useMemo((): string => {
    if (!innerValue?.min && !innerValue?.max) {
      return String(label || '');
    }
    if (!innerValue?.min && innerValue?.max) {
      return `To ${innerValue?.max}`;
    }
    if (innerValue?.min && !innerValue?.max) {
      return `From ${innerValue?.min}`;
    }
    if (innerValue?.min && innerValue?.max) {
      return `${innerValue?.min} - ${innerValue?.max}`;
    }
    return String(label || '');
  }, [innerValue]);

  return (
    <RangeInputContext.Provider value={{ innerValue, setValue, textValue }}>
      {children}
    </RangeInputContext.Provider>
  );
};

export const RangeInputConsumer = RangeInputContext.Consumer;

interface RangeInputSelectorProps<
  T extends string | number = string | number
> extends Omit<OutlinedInputProps, 'onChange' | 'value'> {
  fullWidth?: boolean;
  value?: RangeValue<T>;
  defaultValue?: RangeValue<T>;
  onChange?: (e: React.MouseEvent<HTMLElement> | null, value: RangeValue<T>) => void;
  applyChangeOnBlur?: boolean;
  rounded?: boolean;
}

export const RangeInputSelector = <T extends string | number = string | number>({
  children,
  fullWidth,
  value,
  label,
  applyChangeOnBlur,
  rounded,
  defaultValue,
  onChange,
  ...props
}: React.PropsWithChildren<RangeInputSelectorProps<T>>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleTogglePopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <RangeInputProvider value={value} defaultValue={defaultValue} label={label}>
      <RangeInputContext.Consumer>
        {({ innerValue, textValue }) => (
          <FormControl fullWidth={fullWidth} sx={{ m: 1 }}>
            {label && [innerValue?.min, innerValue?.max].filter((v) => !!v).length > 0 && (
              <InputLabel size={props.size || 'medium'}>{label}</InputLabel>
            )}
            <ClickAwayListener
              onClickAway={() => {
                setAnchorEl(null);
                if (applyChangeOnBlur) {
                  onChange?.(null, innerValue as unknown as RangeValue<T>);
                }
              }}
            >
              <Box>
                <OutlinedInput
                  type="text"
                  readOnly
                  rounded={rounded}
                  onClick={handleTogglePopper}
                  {...(label &&
                    [innerValue?.min, innerValue?.max].filter((v) => !!v).length > 0 && {
                    label,
                  })}
                  inputProps={{
                    style: {
                      cursor: 'pointer',
                    },
                  }}
                  value={textValue}
                  {...props}
                  fullWidth={fullWidth}
                />
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  disablePortal={false}
                >
                  <RangeInput
                    onChange={onChange}
                    value={value}
                    defaultValue={defaultValue}
                    size={props.size}
                    setAnchorEl={setAnchorEl}
                    sx={{ p: 2, width: 300 }}
                  >
                    {children}
                  </RangeInput>
                </Popover>
              </Box>
            </ClickAwayListener>
          </FormControl>
        )}
      </RangeInputContext.Consumer>
    </RangeInputProvider>
  );
};

export default RangeInput;
