/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import * as React from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { styled, useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import MuiListItem from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import useLoginFlow from 'src/hooks/useLogin';
import { AnimateButton } from 'src/components/@material-extend';
import {
  isMobile, isMetaMaskBuiltInBrowser, baseURL,
} from 'src/utils';
import connectors, {
  ConnectorName, essentialsConnector, injectedConnector,
} from 'src/lib/web3/connectors';
import {
  useNetworkProvisioner, NetworkSelector, Connector,
} from 'src/lib/web3/network';
import useAppSettings from 'src/hooks/useAppSettings';

const ListItem = styled(MuiListItem)(({ theme }) => ({
  padding: theme.spacing(1, 2, 1, 1),
  borderRadius: Number(theme.shape.borderRadius) * 3,
}));

const Image = styled('img')(({ theme }) => ({
  width: 180,
  margin: theme.spacing(4, 'auto', 1),
  [theme.breakpoints.down('sm')]: {
    width: 140,
    margin: theme.spacing(2, 'auto', 0),
  },
}));

interface EthereumProvider {
  isMetaMask?: boolean;
  chainId: string;
}

declare global {
  interface Window {
    ethereum?: EthereumProvider;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    elastos?: any;
  }
}

interface PartialRedirectProps {
  // eslint-disable-next-line react/no-unused-prop-types
  redirectTo?: string;
}

export interface SelectDialogProps extends PartialRedirectProps {
  open: boolean;
  onClose: () => void;
}

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(0, 4, 1),
}));

const CreditWrapper = styled('span')({
  fontSize: 12,
});

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  textAlign: 'center',
  maxWidth: 360,
  margin: theme.spacing(0, 3, 1),
  paddingTop: theme.spacing(1),
}));

const NeedHelpLink = styled(Link)(({ theme }) => ({
  fontSize: '90%',
  color: theme.palette.getContrastText(theme.palette.background.paper),
}));

interface MetamaskSupport {
  supported: boolean;
  elastos?: boolean;
  chainId: number;
}

/**
 * To implement correct this selector we have some rules/concerns to consider
 *
 * M: Metamask
 * W: Walletconnect
 *
 * 1. M + W always available on desktop
 * 2. on mobile browser we should disable 1st level M and only allow W as nothing is injected on metamask
 * 3. on built-in browser and andoid, we should always use `injected` to automatic lookup
 * 4. on built-in browser and ios, only W should be available (similar situation to 2)
 *
 * @param props
 * @returns
 */
export function SelectDialog(props: SelectDialogProps) {
  const { selectedChainId, connect: networkConnect, selectNetwork } = useNetworkProvisioner();
  const { onClose, open } = props;
  const { library, chainId } = useWeb3React<Web3Provider>();
  const [mm, setSupport] = React.useState<MetamaskSupport | null>(null);

  React.useEffect(() => {
    // as the provider may be not available all the time on mobile, we need this side-effect implementation
    // see https://docs.metamask.io/guide/mobile-best-practices.html#the-provider-window-ethereum
    // also see https://github.com/MetaMask/detect-provider
    const handleEthereum = () => {
      const { ethereum, elastos } = window;
      setSupport({
        supported: Boolean(ethereum),
        elastos: Boolean(elastos),
        chainId: Number(ethereum?.chainId),
      });
    };

    window.addEventListener('ethereum#initialized', handleEthereum);

    const timer = setTimeout(handleEthereum, 3000);

    return () => {
      window.removeEventListener('ethereum#initialized', handleEthereum);
      clearTimeout(timer);
    };
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleConnectorSelect = React.useCallback(
    (targetChainId: number, c: Connector) => async () => {
      await networkConnect(targetChainId, c);
      handleClose();
    },
    [library, chainId]
  );

  const handleClick = React.useCallback(
    (c: Connector, m: MetamaskSupport) => async () => {
      handleConnectorSelect(selectedChainId, c).call(null);
    },
    [mm, selectedChainId]
  );

  React.useEffect(() => {
    if (open && isMobile()) {
      let targetConnector = null;
      // For metamask built-in browser
      if (isMetaMaskBuiltInBrowser()) {
        targetConnector = injectedConnector;
      }
      // For EE built-in browser
      if (window?.elastos) {
        targetConnector = essentialsConnector;
      }

      if (targetConnector) {
        handleClick(targetConnector, mm).call(null);
      }
    }
  }, [open]);

  const handleChainChanged = (newChainId: number) => async (e?: React.MouseEvent) => {
    selectNetwork(newChainId);
  };

  return (
    <Dialog onClose={handleClose} open={open && !isMetaMaskBuiltInBrowser()} maxWidth="sm">
      <DialogContent>
        <Box sx={{ mt: 0, mx: 'auto' }}>
          <Image crossOrigin="anonymous" alt="Swap Station" src={baseURL('/static/elacity/waving.png')} />
        </Box>
        <DialogTitle>Connect your wallet</DialogTitle>
        <NetworkSelector
          chainId={selectedChainId}
          handleChange={handleChainChanged}
          sx={{ '& .MuiIconButton-root': { mx: 1 }, mb: 1 }}
          sxBuilder={(c, s) => ({
            ...(c !== s && {
              '& svg': {
                filter: 'grayscale(0.7) opacity(0.7)',
              },
            }),
          })}
        />
        <List sx={{ pt: 0, maxWidth: '180px', display: 'inline-block' }}>
          {connectors.map((c: Connector) => (
            <ListItem
              disabled={c.name === ConnectorName.WalletConnect && isMobile() && mm?.supported}
              onClick={handleClick(c, mm)}
              key={c.name}
            >
              <ListItemAvatar>
                <Avatar src={c.icon} imgProps={{ crossOrigin: 'anonymous' }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={c.label || c.name} />
            </ListItem>
          ))}
        </List>
        <Box sx={{ py: 2, fontSize: '0.9rem', borderTop: '1px solid #a4a4a4' }}>
          Download Essentials
          <br />
          <NeedHelpLink
            href="https://play.google.com/store/apps/details?id=org.elastos.essentials.app"
            target="_blank"
            rel="noreferrer"
            underline="none"
          >
            Android
          </NeedHelpLink>
          &nbsp;|&nbsp;
          <NeedHelpLink
            href="https://apps.apple.com/us/app/elastos-essentials/id1568931743"
            target="_blank"
            rel="noreferrer"
            underline="none"
          >
            IOS
          </NeedHelpLink>
        </Box>
        {process.env.REACT_APP_VERSION && (
          <CreditWrapper>
            &copy; Elacity version
            {` ${process.env.REACT_APP_VERSION}`}
          </CreditWrapper>
        )}
      </DialogContent>
    </Dialog>
  );
}

/**
 * Connector context
 * @param param0
 * @returns
 */
type HandleFunc<T extends HTMLElement> = (e?: React.MouseEvent<T>) => void;
type HandleFuncSignature = (sig: { address: string, signature: string }) => void;

interface ConnectorContextValue<T extends HTMLElement> {
  open: boolean;
  promptConnector?: () => void;
  wrapInConnector?: (fn: HandleFunc<T>) => HandleFunc<T>;
  handleClose?: () => void;
  disconnect?: () => void;
}

const ConnectorContext = React.createContext<ConnectorContextValue<HTMLElement>>({
  open: false,
});

interface ConnectorProviderProps extends PartialRedirectProps {}

export const ConnectorProvider = ({ children, redirectTo }: React.PropsWithChildren<ConnectorProviderProps>) => {
  const [open, setOpen] = React.useState(false);
  const { account, deactivate } = useWeb3React();
  const [login, { isSigned }] = useLoginFlow({});
  const { setValues } = useAppSettings();

  const handleOpen: HandleFunc<HTMLElement> = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const wrapInConnector = React.useCallback(
    (fn: HandleFunc<HTMLElement>) => {
      if (!account) {
        return handleOpen;
      }

      if (!isSigned) {
        return () => login().then(() => fn());
      }

      return fn;
    },
    [account]
  );

  const disconnect = React.useCallback(() => {
    window.localStorage.removeItem('walletconnect');
    window.localStorage.removeItem('activeconnectorname');
    setValues({ walletProvider: null });

    deactivate();
  }, []);

  return (
    <ConnectorContext.Provider
      value={{
        open,
        handleClose,
        promptConnector: handleOpen,
        wrapInConnector,
        disconnect,
      }}
    >
      {children}
      <SelectDialog open={open} onClose={handleClose} redirectTo={redirectTo} />
    </ConnectorContext.Provider>
  );
};

export const useConnector = () => React.useContext(ConnectorContext);

/**
 * Connect button
 *
 * @param param0
 * @returns
 */
export default function ConnectorSelect() {
  const theme = useTheme();

  return (
    <ConnectorContext.Consumer>
      {({ promptConnector }) => (
        <AnimateButton
          variant={theme.palette.mode === 'light' ? 'contained' : 'outlined'}
          color="primary"
          onClick={promptConnector}
          label="Connect"
          altLabel="Enter City"
        />
      )}
    </ConnectorContext.Consumer>
  );
}
