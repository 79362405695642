import { INetwork } from '@elacity-js/lib';

// Chain Id augmentation
declare module '@elacity-js/lib' {
  enum ChainID {
    EVMOS = 9001,
    LOCAL = 1337
  }
}

export interface INetworkCollection {
  [chainId: number]: INetwork & {order?: number}
}

export const NETWORKS: INetworkCollection = {
  137: {
    name: 'Polygon',
    symbol: 'MATIC',
    chainId: 137,
    rpcUrl: 'https://polygon-rpc.com',
    explorerUrl: 'https://polygonscan.com',
    decimals: 18,
    mainnet: true,
    order: 1,
  },
  1: {
    name: 'Ethereum',
    symbol: 'ETH',
    chainId: 1,
    rpcUrl: 'https://eth.public-rpc.com',
    explorerUrl: 'https://etherscan.io',
    decimals: 18,
    mainnet: true,
    order: 2,
  },
  20: {
    name: 'Elastos Smart Chain',
    symbol: 'ELA',
    chainId: 20,
    rpcUrl: 'https://api.ela.city/esc',
    explorerUrl: 'https://eth.elastos.io',
    decimals: 18,
    mainnet: true,
    order: 3,
  },
  21: {
    name: 'ESC Testnet',
    symbol: 'ELA',
    chainId: 21,
    rpcUrl: 'https://api2-testnet.elastos.net/eth',
    explorerUrl: 'https://esc-testnet.elastos.io',
    decimals: 18,
    mainnet: false,
    order: 3,
  },
  5: {
    name: 'Goerli Testnet',
    symbol: 'ETH',
    chainId: 5,
    rpcUrl: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    explorerUrl: 'https://goerli.etherscan.io',
    decimals: 18,
    mainnet: false,
    order: 2,
  },
  56: {
    name: 'Binance',
    symbol: 'BNB',
    chainId: 56,
    // rpcUrl: 'https://bsc-dataseed.binance.org',
    rpcUrl: 'https://bscrpc.com',
    explorerUrl: 'https://bscscan.com',
    decimals: 18,
    mainnet: true,
    order: 4,
  },
  9001: {
    name: 'EVM x Cosmos',
    symbol: 'EVMOS',
    chainId: 9001,
    rpcUrl: 'https://evmos-evm.publicnode.com',
    explorerUrl: 'https://evm.evmos.org/',
    decimals: 18,
    mainnet: true,
    order: 5,
  },
  1337: {
    name: 'Development Network',
    symbol: 'ETH',
    chainId: 1337,
    rpcUrl: 'http://localhost:8545',
    explorerUrl: 'http://localhost:7545',
    decimals: 18,
  },
};

export const supportedChainIds = [
  1, 5, 20, 21, 56, 1337, 9001,
];

export const marketplaceSupportedChainIds = [
  20, 21,
];

export const RPC_URLS: { [chainId: number]: string } = Object.fromEntries(
  Object.entries(NETWORKS).map(
    ([chainId, net]) => [chainId, net.rpcUrl]
  )
);
