/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { AddressZero } from '@ethersproject/constants';
import {
  useFetchCollectionQuery as useApiFetchCollectionQuery,
  useFetchCollectionsQuery as useApiFetchCollectionsQuery,
  useFetchCollectionStatsAllQuery as useApiFetchCollectionStatsAllQuery,
  useFetchCollectionStatsQuery as useApiFetchCollectionStatsQuery,
} from 'src/state/api';
import type {
  ICollectionStat as ICollectionStatBase, ICollection, QuotedApiResponse,
} from 'src/types';
import {
  GLIDE, USDC, GOLD, ELK, FILDA, BUNNY, WETH, CREDA,
} from 'src/constants/currencies';
import { useWeb3Application, useUSDCPrice } from 'src/hooks';

interface QueryResult<T> {
  data?: QuotedApiResponse<T>;
  error?: Error;
  isLoading: boolean;
  isFetching?: boolean;
  total?: number;
}

declare type ICollectionStat = Omit<ICollectionStatBase, 'address' | 'collectionName' | 'count' | 'owners'>
declare type ICollectionFull = ICollection & ICollectionStatBase;

/**
 * Retrieve USD price of all used currencies
 *
 * @returns
 */
const useCurrencyPrices = (): Record<string, number> => {
  const { chainId } = useWeb3Application();
  const prices: Record<string, number> = {};

  // native token
  prices[AddressZero] = useUSDCPrice(AddressZero);
  prices[GLIDE[chainId]?.address.toLowerCase()] = useUSDCPrice(GLIDE[chainId]?.address || AddressZero);
  prices[USDC[chainId]?.address.toLowerCase()] = useUSDCPrice(USDC[chainId]?.address || AddressZero);
  prices[GOLD[chainId]?.address.toLowerCase()] = useUSDCPrice(GOLD[chainId]?.address || AddressZero);
  prices[ELK[chainId]?.address.toLowerCase()] = useUSDCPrice(ELK[chainId]?.address || AddressZero);
  prices[FILDA[chainId]?.address.toLowerCase()] = useUSDCPrice(FILDA[chainId]?.address || AddressZero);
  prices[BUNNY[chainId]?.address.toLowerCase()] = useUSDCPrice(BUNNY[chainId]?.address || AddressZero);
  prices[WETH[chainId]?.address.toLowerCase()] = useUSDCPrice(WETH[chainId]?.address || AddressZero);
  prices[CREDA[chainId]?.address.toLowerCase()] = useUSDCPrice(CREDA[chainId]?.address || AddressZero);

  return Object.fromEntries(
    Object.entries(prices).filter(([_, price]) => !!price)
  );
};

/**
 * Aggregate price / volume for each token, then convert into ELA if possible
 *
 * @param prices
 * @param result
 * @returns
 */
const aggregateByPrice = <T extends ICollectionStat>(prices: Record<string, number>, data: T): T => {
  let tradeHistory = (data?.tradeHistory || []).map(
    (th) => ({
      ...th,
      ...(prices[th.paymentToken.toLowerCase()] ? {
        volumeUSD: th.volume * parseFloat(prices[th.paymentToken.toLowerCase()].toFixed(5)),
      } : {
        volumeUSD: 0,
      }),
    })
  );

  const totalVolumeUSD = tradeHistory.reduce(
    (r, th) => r + th.volumeUSD, 0
  );

  const floor = {
    ...(data?.floor || {}),
    priceInUSD: (data?.floor && prices[data?.floor.paymentToken?.toLowerCase()])
      // eslint-disable-next-line max-len
      ? data?.floor.price * parseFloat(prices[data?.floor.paymentToken?.toLowerCase()].toFixed(5))
      : 0,
  };

  // convert USDC -> ELA
  if (prices[AddressZero]) {
    floor.paymentToken = AddressZero;
    floor.price = floor.priceInUSD / parseFloat(prices[AddressZero].toFixed(5));

    tradeHistory = [{
      paymentToken: AddressZero,
      volumeUSD: totalVolumeUSD,
      volume: totalVolumeUSD / parseFloat(prices[AddressZero].toFixed(5)),
    }];
  }

  return {
    ...data,
    tradeHistory,
    floor,
  };
};

/**
 * Inject price in USD for a single result
 *
 * @param result
 * @returns
 */
const useUSDCPriceInjectForSingle = <T extends ICollectionStat>(result: QueryResult<T>): QueryResult<T> => {
  const prices = useCurrencyPrices();

  return {
    ...result,
    data: {
      ...result?.data,
      data: aggregateByPrice(prices, result?.data?.data),
    },
  };
};

/**
 * Inject price in USD for an array result
 *
 * @param result
 * @returns
 */
const useUSDCPriceInjectForMany = <T extends ICollectionStat>(result: QueryResult<T[]>): QueryResult<T[]> => {
  const prices = useCurrencyPrices();

  return {
    ...result,
    data: {
      ...result?.data,
      data: (result?.data?.data || []).map(
        (data: T) => aggregateByPrice(prices, data)
      ),
    },
  };
};

/**
 * Query for fetching list of collections
 *
 * @returns
 */
export const useFetchCollectionsQuery = () => {
  // @ts-ignore
  const result: QueryResult<ICollectionFull[]> = useApiFetchCollectionsQuery();

  return useUSDCPriceInjectForMany({
    ...result,
    data: {
      ...result?.data,
      data: (result?.data?.data || []).map(
        (col: ICollectionFull) => ({
          ...col,
          volumeUSD: (col.tradeHistory || []).reduce(
            (totalUSD, { volumeUSD }) => totalUSD + volumeUSD, 0
          ),
        })
      ),
    },
  });
};

/**
 * Query for fetching a single collection
 *
 * @param address
 * @returns
 */
export const useFetchCollectionQuery = (address: string) => {
  // @ts-ignore
  const result: QueryResult<ICollectionFull> = useApiFetchCollectionQuery(address, { skip: !address });

  return result;
};

/**
 * Query for fetching stats for a list of collections
 *
 * @param count
 * @returns
 */
export const useFetchCollectionStatsAllQuery = (count: number) => {
  // @ts-ignore
  const result:QueryResult<ICollectionStatBase[]> = useApiFetchCollectionStatsAllQuery(count);

  return result;
};

/**
 * Query for fetching stats for a single collection
 *
 * @param address
 * @returns
 */
export const useFetchCollectionStatsQuery = (address: string) => {
  // @ts-ignore
  const result: QueryResult<ICollectionStatBase> = useApiFetchCollectionStatsQuery(address, { skip: !address });

  return useUSDCPriceInjectForSingle(result);
};
