/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { INTERFACE_MARKETPLACE, TokenID } from '@elacity-js/lib';
import { parseUnits } from '@ethersproject/units';
import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { CallOverrides, ContractReceipt } from '@ethersproject/contracts';
import { selectCurrency } from 'src/constants/currencies';
import { TxExecutable } from 'src/lib/web3/executable/tx';
import type {
  IListItemParams,
  IUpdateListingParams,
  IBuyItemParams,
  ICreateOfferParams,
  ICancelOfferParams,
  IAcceptOfferParams,
} from '../types';
import events from '../events';
import { ADDRESS } from '../constants';

// eslint-disable-next-line @typescript-eslint/ban-types
type GenericConstructor<T = {}> = new (...args: any[]) => T;

export default <T extends GenericConstructor<any>>(BaseClass: T) => class MixedClass extends BaseClass {
  /**
  * Retrieve listing data
  */
  public async getListings(tokenID: TokenID, ownerAdr: string): Promise<any> {
    const salesContract = this._loadContract(this._salesContractAddress, INTERFACE_MARKETPLACE);

    return salesContract.listings(this._nftAddress, tokenID.toBigNumber(), ownerAdr);
  }

  /**
  * List this nft on Marketplace
  * @param params
  */
  public async listItem(params: IListItemParams): Promise<ContractReceipt> {
    this.emit(events.SALES_LIST_ITEM_START, { params });

    try {
      const salesContract = this._loadContract(this._salesContractAddress, INTERFACE_MARKETPLACE);
      const payToken = selectCurrency(this.chainId, params?.payToken);

      const args: BigNumberish[] = [
        this._nftAddress,
        params.tokenId.toBigNumber(),
        BigNumber.from(params?.quantity || 1),
        payToken.address,
        parseUnits(params.pricePerItem, payToken.decimals),
        BigNumber.from(Math.floor((params?.startingTime || new Date().getTime()) / 1000)),
      ];

      this.emit(events.SALES_LIST_ITEM_WAIT_TX);
      const { receipt } = await TxExecutable.invoke(
        'list NFT',
        { callee: salesContract, method: 'listItem' },
        { args: () => args }, { estimateGas: true }
      );

      this.emit(events.SALES_LIST_ITEM_TX_CONFIRMED, receipt);

      this.emit(events.SALES_LIST_ITEM_DONE, {
        tokenId: params.tokenId,
        contractAddress: this._salesContractAddress,
      });
      return receipt;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);
      return Promise.reject(e);
    }
  }

  /**
  * Cancel a listing
  * @param tokenId
  */
  public async cancelListing(tokenId: TokenID): Promise<ContractReceipt> {
    this.emit(events.SALES_CANCEL_LISTING_START, tokenId);

    try {
      const salesContract = this._loadContract(this._salesContractAddress, INTERFACE_MARKETPLACE);

      const args: BigNumberish[] = [this._nftAddress, tokenId.toBigNumber()];

      this.emit(events.SALES_CANCEL_LISTING_WAIT_TX);
      const { receipt } = await TxExecutable.invoke(
        'cancel listing',
        { callee: salesContract, method: 'cancelListing' },
        { args: () => args }, { estimateGas: true }
      );

      this.emit(events.SALES_CANCEL_LISTING_TX_CONFIRMED, receipt);

      this.emit(events.SALES_CANCEL_LISTING_DONE, { tokenId, contractAddress: this._salesContractAddress });
      return receipt;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);
      return Promise.reject(e);
    }
  }

  /**
  * Updates a listing price and/or payToken
  * @param params
  */
  public async updateListing(params: IUpdateListingParams): Promise<ContractReceipt> {
    this.emit(events.SALES_UPDATE_LISTING_START, params);

    try {
      const salesContract = this._loadContract(this._salesContractAddress, INTERFACE_MARKETPLACE);
      const payToken = selectCurrency(this.chainId, params?.payToken);

      const args: BigNumberish[] = [
        this._nftAddress,
        params.tokenId.toBigNumber(),
        payToken.address,
        parseUnits(params.pricePerItem, payToken.decimals),
      ];

      this.emit(events.SALES_UPDATE_LISTING_WAIT_TX);
      const { receipt } = await TxExecutable.invoke(
        'update listing',
        { callee: salesContract, method: 'updateListing' },
        { args: () => args }, { estimateGas: true }
      );

      this.emit(events.SALES_UPDATE_LISTING_TX_CONFIRMED, receipt);

      this.emit(events.SALES_UPDATE_LISTING_DONE);
      return receipt;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);
      return Promise.reject(e);
    }
  }

  /**
  * Buy an item from an owner
  * @param params
  */
  public async buyItem(params: IBuyItemParams): Promise<ContractReceipt> {
    this.emit(events.SALES_BUY_START, params);

    try {
      const salesContract = this._loadContract(this._salesContractAddress, INTERFACE_MARKETPLACE);

      let contractMethod: string;

      const args: BigNumberish[] = [
        this._nftAddress,
        params.tokenId.toBigNumber(),
        // params?.payToken || ADDRESS.PAYTOKEN,
        // params.ownerAddress,
      ];

      const options: CallOverrides = {};

      if (params?.payToken === ADDRESS.ZERO) {
        args.push(
          params.ownerAddress
        );
        options.value = parseUnits(params.pricePerItem.toString(), 18);
        contractMethod = 'buyItem(address,uint256,address)';
      } else {
        args.push(
          params?.payToken || ADDRESS.PAYTOKEN,
          params.ownerAddress
        );
        contractMethod = 'buyItem(address,uint256,address,address)';
      }

      this.emit(events.SALES_BUY_WAIT_TX);
      const { receipt } = await TxExecutable.invoke(
        'buy NFT',
        { callee: salesContract, method: contractMethod },
        { args: () => args }, { estimateGas: true, ...options }
      );

      this.emit(events.SALES_BUY_TX_CONFIRMED, receipt);
      this.emit(events.SALES_BUY_DONE, { tokenId: params.tokenId, contractAddress: this._salesContractAddress });
      return receipt;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);
      return Promise.reject(e);
    }
  }

  /**
  * Creates an offer for an item
  * @param params
  */
  public async createOffer(params: ICreateOfferParams): Promise<ContractReceipt> {
    this.emit(events.SALES_CREATE_OFFER_START, params);

    try {
      const salesContract = this._loadContract(this._salesContractAddress, INTERFACE_MARKETPLACE);
      const payToken = selectCurrency(this.chainId, params?.payToken);

      let contractMethod: string;

      const args: BigNumberish[] = [
        this._nftAddress,
        params.tokenId.toBigNumber(),
      ];

      const options: CallOverrides = {};

      if (params?.payToken === ADDRESS.ZERO) {
        args.push(
          params.quantity ? BigNumber.from(params.quantity) : BigNumber.from('1'),
          params.deadline ? BigNumber.from(params.deadline) : BigNumber.from(Math.floor(new Date().getTime() / 1000))
        );
        options.value = parseUnits(
          params.pricePerItem.toString(), 18
        ).mul(
          args[2]
        );
        contractMethod = 'createOffer(address,uint256,uint256,uint256)';
      } else {
        args.push(
          params?.payToken || ADDRESS.PAYTOKEN,
          params.quantity ? BigNumber.from(params.quantity) : BigNumber.from('1'),
          parseUnits(params.pricePerItem, payToken.decimals),
          params.deadline ? BigNumber.from(params.deadline) : BigNumber.from(Math.floor(new Date().getTime() / 1000))
        );
        contractMethod = 'createOffer(address,uint256,address,uint256,uint256,uint256)';
      }

      this.emit(events.SALES_CREATE_OFFER_WAIT_TX);
      const { receipt } = await TxExecutable.invoke(
        'create offer',
        { callee: salesContract, method: contractMethod },
        { args: () => args }, { estimateGas: true, ...options }
      );

      this.emit(events.SALES_CREATE_OFFER_TX_CONFIRMED, receipt);
      this.emit(events.SALES_CREATE_OFFER_DONE);
      return receipt;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);
      return Promise.reject(e);
    }
  }

  /**
  * Cancels an offer for an item
  * @param params
  */
  public async cancelOffer(params: ICancelOfferParams): Promise<ContractReceipt> {
    this.emit(events.SALES_CANCEL_OFFER_START, params);

    try {
      const salesContract = this._loadContract(this._salesContractAddress, INTERFACE_MARKETPLACE);

      const args = [this._nftAddress, params.tokenId.toBigNumber()];

      this.emit(events.SALES_CANCEL_OFFER_WAIT_TX);
      const { receipt } = await TxExecutable.invoke(
        'cancel offer',
        { callee: salesContract, method: 'cancelOffer' },
        { args: () => args }, { estimateGas: true }
      );

      this.emit(events.SALES_CANCEL_OFFER_TX_CONFIRMED, receipt);
      this.emit(events.SALES_CANCEL_OFFER_DONE);
      return receipt;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);
      return Promise.reject(e);
    }
  }

  /**
  * Accept an offer for an item
  * @param params
  */
  public async acceptOffer(params: IAcceptOfferParams): Promise<ContractReceipt> {
    this.emit(events.SALES_ACCEPT_OFFER_START, params);

    try {
      const salesContract = this._loadContract(this._salesContractAddress, INTERFACE_MARKETPLACE);

      const args = [this._nftAddress, params.tokenId.toBigNumber(), params.creator];

      this.emit(events.SALES_ACCEPT_OFFER_WAIT_TX);
      const { receipt } = await TxExecutable.invoke(
        'accept offer',
        { callee: salesContract, method: 'acceptOffer' },
        { args: () => args }, { estimateGas: true }
      );

      this.emit(events.SALES_ACCEPT_OFFER_TX_CONFIRMED, receipt);

      this.emit(events.SALES_ACCEPT_OFFER_DONE, {
        tokenId: params.tokenId,
        contractAddress: this._salesContractAddress,
      });
      return receipt;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);
      return Promise.reject(e);
    }
  }
};
