import { SxProps } from '@mui/system';
import React, {
  FC, PropsWithChildren, useState, createContext,
} from 'react';
import type { AppBarProps } from '@mui/material/AppBar';

interface AppBarUIContextValue {
  sx: SxProps;
  setSx: (sx: SxProps) => void;
}

export const AppBarUIContext = createContext<AppBarUIContextValue>({
  sx: null,
  setSx: () => {},
});

interface AppBarUIProps extends AppBarProps {}

export const AppBarUIProvider: FC<PropsWithChildren<AppBarUIProps>> = ({ children }) => {
  const [sx, setSx] = useState<SxProps>();

  const asSetSx = (s: SxProps) => {
    setSx(s);
  };

  return <AppBarUIContext.Provider value={{ sx, setSx: asSetSx }}>{children}</AppBarUIContext.Provider>;
};
