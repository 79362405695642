/* eslint-disable no-prototype-builtins */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import axios, {
  AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse,
} from 'axios';
import { JsonLocalStorage } from 'src/lib/storage';

export const JWT_VAULT = '__ela_user_vault';

export interface ICallResults<T extends any> {
  status: 'success' | 'errored';
  data?: T;
  error?: Error | AxiosError;
}

export class Client {
  public instance: AxiosInstance;

  protected _address: string;

  protected _authToken: string;

  public constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  public async authenticate(address: string) {
    const s = new JsonLocalStorage<Record<string, { token?: string }>>(JWT_VAULT);
    this._address = address;
    this._authToken = s.get(address)?.token;

    if (!this._authToken) {
      throw new Error('Please make sure you fulfilled authentication process');
    }
  }

  protected async _ensureNoThrow<T>(call: () => Promise<T>): Promise<T> {
    try {
      return await call();
    } catch (e) {
      return e;
    }
  }

  protected async _ensureAuth(
    call: () => Promise<AxiosResponse|AxiosError>,
    noRetry?: boolean
  ): Promise<AxiosResponse|AxiosError> {
    const firstCallResults = await this._ensureNoThrow<AxiosResponse|AxiosError>(call);
    let needRetry = false;
    const error = (firstCallResults as AxiosError);
    const errorStatus = error?.response?.status;

    if (errorStatus === 403 || errorStatus === 400) {
      // console.log('Needed retry');
      needRetry = !noRetry;
    }

    if (!needRetry) {
      return firstCallResults as AxiosResponse;
    }

    if (!this._address) {
      return {
        ...error,
        response: {
          ...error.response,
          data: {
            message: 'Address is empty. Please authenticate once first',
          },
        },
      };
    }

    await this._ensureNoThrow<void>(() => this.authenticate(this._address));
    return this._ensureNoThrow<AxiosResponse|AxiosError>(call);
  }

  public async call<T extends any>(_opts: AxiosRequestConfig & { noRetry?: boolean }): Promise<ICallResults<T>> {
    const { noRetry, ...opts } = _opts;
    const results = await this._ensureAuth(() => this.instance.request({
      ...opts,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this._authToken}`,
      },
    }), noRetry);

    if ((results as AxiosError)?.isAxiosError) {
      return {
        status: 'errored',
        error: results as Error,
      };
    }

    return {
      status: 'success',
      data: (results as AxiosResponse)?.data,
    };
  }
}

const axiosInstance = axios.create({
  baseURL: process.env?.REACT_APP_BACKEND_URL || 'http://localhost:3001',
});

const client = new Client(axiosInstance);

export default client;
