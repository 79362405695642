/* eslint-disable @typescript-eslint/no-explicit-any */
import { retry } from '@elacity-js/lib';
import { isAddress } from '@ethersproject/address';
import { ContractReceipt, Contract } from '@ethersproject/contracts';
import { calculateOverides } from 'src/lib/web3/utils';

// eslint-disable-next-line @typescript-eslint/ban-types
type GenericConstructor<T = {}> = new (...args: any[]) => T;

export default <T extends GenericConstructor<any>>(BaseClass: T) => class MixedClass extends BaseClass {
  /**
   * Transer a token to another recipient
   *
   * @param to
   * @param params
   * @returns
   */
  public async approve(operator: string, val: boolean): Promise<ContractReceipt | boolean> {
    if (!isAddress(operator)) {
      return Promise.reject(new Error(`The operator address ${operator} is not valid`));
    }

    const args: any[] = [
      operator,
      val,
    ];

    const contract = new Contract(
      this.address,
      [
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'operator',
              type: 'address',
            },
          ],
          name: 'isApprovedForAll',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
          constant: true,
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'operator',
              type: 'address',
            },
            {
              internalType: 'bool',
              name: 'approved',
              type: 'bool',
            },
          ],
          name: 'setApprovalForAll',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      this.provider
    );

    // check if approved
    const approved = await contract.isApprovedForAll(this.account, operator);

    if (!approved) {
      try {
        console.log('[ApprovableMixin] Approving...', { address: this.address, args });
        return retry(async () => {
          const tx = await contract.setApprovalForAll(...args, await calculateOverides(
            this.provider,
            contract,
            'setApprovalForAll',
            ...args
          ));

          const receipt: ContractReceipt = await tx.wait();

          return receipt;
        }, {
          retries: 5,
          interval: 1000,
        });
      } catch (e) {
        return Promise.reject(e);
      }
    }

    // already approved
    return Promise.resolve(true);
  }
};
