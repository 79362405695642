import React from 'react';
import { SnackbarKey } from 'notistack';
import * as Sentry from '@sentry/react';
import { useWeb3React } from '@web3-react/core';
import {
  JsonRpcSigner, Web3Provider, JsonRpcProvider,
} from '@ethersproject/providers';
import Spinner from 'src/components/@ui/Spinner';
import useErrorHandler, { StackButton } from 'src/hooks/useErrorHandler';
import { TxExecutable } from 'src/lib/web3/executable';
import { useEagerConnect } from 'src/lib/web3/hooks';
import { signMessage as signStr } from 'src/lib/web3/signature';
import { Erc20ContractType, WrappedErc20Contract } from '../lib/web3/contract/erc20';
import { getPayTokenByChain } from '../lib/nfts/constants';
import { NETWORKS, marketplaceSupportedChainIds } from '../lib/web3/network';

interface PaymentMethod {
  tokenAddress: string;
  decimals: number;
  symbol: string;
  contract?: Erc20ContractType;
  use?: (address: string) => Erc20ContractType;
  useWrapped?: (address: string) => WrappedErc20Contract;
}

export interface Web3ApplicationContextValue {
  ready: boolean;
  account?: string;
  active?: boolean;
  chainId?: number;
  library?: JsonRpcProvider;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSigner: () => (JsonRpcSigner & { connection?: any }) | null;
  signMessage: (message: string) => Promise<string>;

  payment?: PaymentMethod;
}

interface Web3ApplicationProviderProps {}

const Web3ApplicationContext = React.createContext<Web3ApplicationContextValue>({
  ready: false,
  getSigner: () => null,
  signMessage: () => Promise.resolve(''),
});

export const Web3ApplicationProvider: React.FC<React.PropsWithChildren<Web3ApplicationProviderProps>> = ({
  children,
}: React.PropsWithChildren<Web3ApplicationProviderProps>) => {
  const { throwError, closeError } = useErrorHandler();
  const { ready } = useEagerConnect();

  const { active, library: _library, chainId: _chainId, account } = useWeb3React<Web3Provider>();

  const chainId = React.useMemo(() => _chainId ?? parseInt(process.env.REACT_APP_CHAIN_ID, 10), [_chainId]);
  const library = React.useMemo(() => {
    if (_library) {
      return _library;
    }

    const lib = new JsonRpcProvider(NETWORKS[process.env.REACT_APP_CHAIN_ID || 20].rpcUrl);

    return lib;
  }, [_library]);

  // add sentry context
  React.useEffect(() => {
    if (active && account) {
      Sentry.setContext('wallet', {
        address: account,
        chainId,
      });
    }
  }, [chainId, account, active]);

  React.useEffect(() => {
    // register UX handler for TxExecutable
    TxExecutable.registerUXHandler({
      onWait: (ctx, description?: string) => {
        if (description) {
          ctx.key = throwError(new Error(`Waiting for transaction confirmation, please check your wallet to ${description}`), {
            variant: 'warning',
            persist: true,
            action: (key: SnackbarKey) => (
              <StackButton
                size="small" variant="text"
                onClick={() => closeError(key)}
              >
                Dismiss
              </StackButton>
            ),
          });
        }
      },
      onSuccess: (ctx) => {
        if (ctx?.key) {
          closeError(ctx.key);
        }
      },
      onError: (ctx) => {
        if (ctx?.key) {
          closeError(ctx.key);
        }
      },
    });
  }, []);

  // payment methods
  const useErc20 = React.useCallback((addr: string) => new Erc20ContractType(addr, library), [library, chainId]);

  const useWrapped = React.useCallback((addr: string) => new WrappedErc20Contract(addr, library), [library, chainId]);

  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod>({
    tokenAddress: '',
    decimals: 0,
    symbol: '',
  });

  const getSigner = React.useCallback(() => library?.getSigner(), [library]);
  const signMessage = React.useCallback(
    async (message: string): Promise<string> => signStr(message, {
      provider: library as Web3Provider,
      account,
    }),
    [library]
  );

  React.useEffect(() => {
    if (marketplaceSupportedChainIds.includes(chainId)) {
      const payTokenAddress = getPayTokenByChain(chainId);
      const payToken = new Erc20ContractType(payTokenAddress, library);
      if (active && payToken && payToken.ready) {
        payToken.load().then(({ symbol, decimals }) => {
          setPaymentMethod((prev: PaymentMethod) => ({
            ...prev,
            symbol,
            decimals,
            tokenAddress: payTokenAddress,
            contract: payToken,
          }));
        });
      }
    }
  }, [active, library, chainId]);

  console.log('[Connect.Web3Context]', { ready, active, account, chainId });

  if (!ready) {
    return <Spinner />;
  }

  return (
    <Web3ApplicationContext.Provider
      value={{
        ready,
        active,
        account: account as string,
        chainId,
        getSigner,
        signMessage,
        library,
        payment: {
          ...paymentMethod,
          use: useErc20,
          useWrapped,
        },
      }}
    >
      {children}
    </Web3ApplicationContext.Provider>
  );
};

export default Web3ApplicationContext;
