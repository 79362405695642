import { useMemo } from 'react';
import { Currency } from '@elacity-js/lib';
import useWeb3Application from './useWeb3Application';
import { currencies, nativeCurrencies } from '../constants';

const reducer = (result: Record<string, Currency>, current: Currency) => ({
  ...result,
  [current.address]: {
    ...current,
  },
});

export default () => {
  const { chainId } = useWeb3Application();

  const supportedArr: Currency[] = useMemo(
    () => currencies.filter(
      (current: Currency) => current.chainId === chainId
    ), [chainId]
  );

  const supportedMap: Record<string, Currency> = useMemo(
    () => currencies.filter(
      (current: Currency) => current.chainId === chainId
    ).reduce(reducer, {}), [chainId]
  );

  const prefered: Currency = useMemo(
    () => currencies.filter(
      (current: Currency) => current.chainId === chainId && current.preferred
    ).shift(), [chainId]
  );

  const native: Currency = useMemo(
    () => nativeCurrencies[chainId], [chainId]
  );

  return {
    native,
    supportedArr,
    supportedMap,
    currencies,
    prefered,
    lookup: (payToken: string) => [
      ...currencies,
      nativeCurrencies[chainId || process.env.REACT_APP_CHAIN_ID || 20],
    ].find(
      (c) => c?.address.toLowerCase() === payToken?.toLowerCase()
    ),
    lookupV2: (payToken: string) => [
      ...currencies,
      nativeCurrencies[chainId || process.env.REACT_APP_CHAIN_ID || 20],
    ].find(
      (c) => c?.address.toLowerCase() === payToken?.toLowerCase() || c?.logo === payToken
    ),
  };
};
