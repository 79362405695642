import React from 'react';
import {
  Box, Typography, Button, useTheme,
} from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import useProcessingTime from './useProcessingTime';

const toHHMMSS = (_sec: number) => {
  const isNeg = _sec < 0;
  const sec = Math.abs(_sec);
  const secNum = Math.floor(sec);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor((secNum - hours * 3600) / 60);
  const seconds = secNum - hours * 3600 - minutes * 60;

  return `${isNeg ? '-' : ''}${[hours, minutes, seconds]
    .map((val) => (val < 10 ? `0${val}` : val.toString()))
    .join(':')}`;
};

interface ProcessingTimeViewProps {
  minimal?: boolean;
  size?: ButtonProps['size'];
}

const ProcessingTimeView = ({ minimal, size }: ProcessingTimeViewProps) => {
  const theme = useTheme();
  const { openProcessingTimeModal, queryResult } = useProcessingTime();
  const processingTime = React.useMemo(
    () => queryResult?.data?.data?.processingTime || 0,
    [queryResult?.data?.data?.processingTime]
  );

  return (
    <>
      {!minimal && (
        <Box display="flex" justifyContent="center" sx={{ mb: 1.5 }}>
          <Typography variant="h6">Time:&nbsp;</Typography>
          <Typography variant="h6" sx={{ mt: 0.1, color: processingTime >= 0 ? 'primary.main' : 'error.main' }}>
            {toHHMMSS(processingTime)}
          </Typography>
        </Box>
      )}
      {(!minimal || processingTime === 0) && (
        <Box display="flex" justifyContent="center">
          <Button
            onClick={openProcessingTimeModal}
            size={size}
            color={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
            variant="contained"
          >
            Buy time
          </Button>
        </Box>
      )}
    </>
  );
};

ProcessingTimeView.defaultProps = {
  size: 'small',
};

export default ProcessingTimeView;
