/* eslint-disable @typescript-eslint/no-explicit-any */
import { Contract } from '@ethersproject/contracts';
import { TokenID } from '@elacity-js/lib';
import { ICallResults } from 'src/api/client';

export interface IWalletUtils {
  balanceOf: (address: string) => Promise<string>;
}

interface INftCreateParamsMinimal {
  nftAddress: string;
  type: '721' | '1155';
}

export interface IMintableNftCreateParams extends INftCreateParamsMinimal {
  account: string;
  chainId: number;
  name: string;
  image?: any;
  description?: string;
  symbol?: string;
  xtra?: string;
  royalty?: number;
  supply?: string;
}

export interface ITransferableNftCreateParams extends INftCreateParamsMinimal {
  account: string;
  chainId: string;
}

export interface IBurnableNftCreateParams extends INftCreateParamsMinimal {
  account: string;
  chainId: string;
}

export interface IMuseumNftCreateParams extends INftCreateParamsMinimal {
  salesContract: string;
}

export interface IAuctionNftCreateParams extends INftCreateParamsMinimal {
  auctionContract: string;
  account?: string;
}

export interface IMintableFactoryHelpersParams {
  loadContract: any;
  clientHttp: any;
  WalletUtils: any;
  library: any;
  registerRoyalty: any;
  salesContract?: string;
  auctionContract?: string;
  private721Factory?: string;
}

export interface ITransferableFactoryHelpersParams {
  loadContract: any;
  library: any;
}

export interface IListableFactoryHelpersParams {
  loadContract: any;
  library: any;
}

export interface IAuctionFactoryHelpersParams {
  loadContract: any;
  library: any;
}

export interface INftCreateParams extends IMintableNftCreateParams {
  nftAddress: string;
  supply?: string;
  type: '721' | '1155';
  collection?: string;
  salesContract?: string;
  auctionContract?: string;
  private721Factory?: string;
}

export interface IContractLoader {
  (address: string, abi: any): Contract;
}

export interface IRoyaltyRegisterer {
  (nft: string, tokenId: TokenID, royalty: number): Promise<any>;
}

export interface ICancelOfferParams {
  tokenId: TokenID;
}

interface IBaseSalesItemParams {
  tokenId: TokenID;
  payToken?: string;
  swap?: boolean;
}

export interface ISellableItemParams extends IBaseSalesItemParams {
  pricePerItem: string;
  quantity?: number;
}

export interface IOwnedSellableItem extends ISellableItemParams {
  ownerAddress: string;
}

export interface IListItemParams extends ISellableItemParams {
  startingTime?: number;
}

export interface ICreateAuctionParams {
  nftAddress: string;
  tokenId: TokenID;
  payToken?: string;
  reservePrice: number;
  startTime: Date;
  minBidReserve: boolean;
  endTime: Date;
}

export interface IUpdateAuctionParams {
  nftAddress: string;
  tokenId: TokenID;
  reservePrice?: number;
  payToken?: string;
  startTime?: Date;
  minBidReserve?: boolean;
  endTime?: Date;
}

export interface IAuction {
  owner?: string;
  payToken?: string;
  reservePrice?: string | number;
  startTime?: Date | number | null | string;
  endTime?: Date | number | null | string;
  resulted?: any;
  minBid?: number;

  highestBid?: {
    bid: number;
    bidder: string;
    lastBidTime: Date | number;
  };
}

export interface IUpdateListingParams extends ISellableItemParams {
  pricePerItem: string;
}

export interface IBuyItemParams extends IBaseSalesItemParams {
  ownerAddress: string;
  pricePerItem: number;
}

export interface ICreateOfferParams extends ISellableItemParams {
  deadline?: string;
  nftAddress: string;
}

export interface IAcceptOfferParams {
  tokenId: TokenID;
  creator?: string;
}

export interface IAcceptOfferWithPrice extends IAcceptOfferParams {
  pricePerItem: string;
  payToken: string;
  nftAddress?: string;
}

export interface INFTActions {
  onTransfer?: () => void;
  onBurn?: () => void;
  onAuction?: () => void;
  onBuy?: () => void;
  onSale?: () => void;
  onSaleCancel?: () => void;
  onAuctionCancel?: () => void;
  onAuctionUpdate?: () => void;
  onSaleUpdate?: () => void;
  onCreateOffer?: () => void;
  onBid?: () => void;
  onWithdrawBid?: () => void;
  onAuctionResult?: () => void;
}

export interface IPlaceBidFormData {
  balance: number;
  amount: number;
  payToken?: string;
  nftAddress?: string;
  reservePrice?: number;
  minBid?: number;
  tokenID?: TokenID;
  tokenURI?: string;
  swap?: boolean;
}

export interface IAuctionResultFormData {
  tokenURI: string;
  amount: number;
  reservePrice?: number;
  bidder: string;
  payToken?: string;
  nftAddress?: string;
  tokenID?: TokenID;
  swap?: boolean;
  royalty?: number;
}

export interface IAuctionWithdrawBidFormData {
  amount?: number;
  payToken?: string;
  nftAddress?: string;
  tokenID?: TokenID;
  swap?: boolean;
}

export interface IOptionalActions {
  onDone?: () => void | Promise<void>;
  onError?: () => void | Promise<void>;
  onStart?: () => void | Promise<void>;
}

export enum IStorageType {
  IPFS = 'ipfs',
  HIVE_OWNED = 'hive:owned',
  HIVE_PUBLIC = 'hive:public',
}

export enum IStorageTypeRaw {
  IPFS = 'ipfs',
  HIVE = 'hive',
}

export interface IMintFormValues extends Omit<IMintableNftCreateParams, 'chainId' | 'account'> {
  nftAddress: string;
  collection?: string;
  link?: string;
  unlockable?: boolean;
  mimeType?: string;
  storageType: IStorageTypeRaw;

  acknowledged?: boolean;
  fileError?: boolean;

  toListing?: {
    pricePerItem?: number;
    payToken?: string;
  };

  toAuction?: {
    reservePrice?: number;
    payToken?: string;
    startTime?: Date;
    endTime?: Date;
    minBidReserve?: boolean;
  };
}

export type IUploadResult = ICallResults<{ jsonHash: string; imageHash?: string; status?: 'success' | 'failed' }>;

export type IUploadHelper = (data: FormData) => Promise<IUploadResult>;
