import { isPast } from 'date-fns';
import {
  NFTQueryParams, QueryFilter, TokenID,
} from 'src/types';

export const getNFTSortKey = (key: string): string => {
  const [sortKey, direction] = key.split('$');

  const map = {
    createdAt: {
      asc: 'oldest',
      desc: 'createdAt',
    },
    price: {
      asc: 'cheapest',
      desc: 'price',
    },
    lastPrice: {
      desc: 'lastSalePrice',
    },
    viewed: {
      desc: 'viewed',
    },
    listedAt: {
      desc: 'listedAt',
    },
    soldAt: {
      desc: 'soldAt',
    },
    saleEndsAt: {
      asc: 'saleEndsAt',
    },
  };

  if (map[sortKey] && map[sortKey][direction]) {
    return map[sortKey][direction];
  }

  return key;
};

/**
 * Reverse of getNFTSortKey func
 * @param sortKey
 * @returns
 */
export const getSortKeyReverse = (sortKey: string, initialValue?: string): string => {
  if ((sortKey || initialValue || '').match(/\$/ig)) {
    return sortKey;
  }

  const map = {
    oldest: 'createdAt$asc',
    createdAt: 'createdAt$desc',
    cheapest: 'price$asc',
    price: 'price$desc',
    lastSalePrice: 'lastSalePrice$desc',
    viewed: 'viewed$desc',
    listedAt: 'listedAt$desc',
    soldAt: 'soldAt$desc',
    saleEndsAt: 'saleEndsAt$asc',
  };

  return map[sortKey] ?? sortKey;
};

export const queryForApi = (q: NFTQueryParams): NFTQueryParams => {
  const concreteQuery = { ...q };
  if (concreteQuery.category === 'all') {
    delete concreteQuery.category;
  }

  return concreteQuery;
};

export const defaultNFTSortBuilder = <D extends {_id?: string; tokenID?: TokenID; deadline?: Date | number}>(q: QueryFilter) => {
  // frontend sort
  if (q.sortBy === 'deadline$asc') {
    return (entries: D[]) => entries.sort(
      (a: D, b: D) => {
        if (isPast(new Date(a.deadline)) && isPast(new Date(b.deadline))) {
          return new Date(b.deadline).getTime() - new Date(a.deadline).getTime();
        }

        if (isPast(new Date(a.deadline))) {
          return 1;
        }

        if (isPast(new Date(b.deadline))) {
          return -1;
        }

        return new Date(a.deadline).getTime() - new Date(b.deadline).getTime();
      }
    );
  }
  return (entries: D[]) => entries;
};
