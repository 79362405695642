import { Theme } from '@mui/material/styles';

export default function Paper(theme: Theme) {
  return {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  };
}
