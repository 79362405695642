/* eslint-disable no-underscore-dangle */
import {
  CallOverrides,
  Contract, ContractReceipt, ContractTransaction,
} from '@ethersproject/contracts';
import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { Interface } from '@ethersproject/abi';
import { JsonRpcSigner } from '@ethersproject/providers';
import { ChainID, INTERFACE_MARKETPLACE_AGGREGATOR } from '@elacity-js/lib';
import { TxExecutable } from 'src/lib/web3/executable/tx';

export interface ContractCallParams {
  target: string;
  func: string;
  data: BigNumberish[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abi: any;
}

const addresses: Partial<Record<ChainID, string>> = {
  [ChainID.MAINNET]: process.env.REACT_APP_MARKETPLACE_AGGREGATOR_MAINNET,
  [ChainID.TESTNET]: process.env.REACT_APP_MARKETPLACE_AGGREGATOR_TESTNET,
};

export default class MarketplaceAggregator {
  protected contractAddress: string;

  protected contract: Contract;

  constructor(contractAddress: string, provider: JsonRpcSigner) {
    this.contractAddress = contractAddress;
    this.contract = new Contract(contractAddress, INTERFACE_MARKETPLACE_AGGREGATOR, provider);
  }

  getAddress() {
    return this.contractAddress;
  }

  public static setupFromProvider(chainId: ChainID, provider: JsonRpcSigner): MarketplaceAggregator {
    return new MarketplaceAggregator(addresses[chainId || ChainID.MAINNET], provider);
  }

  protected _calculateGasMargin = (value: BigNumberish, minimal?: number): BigNumberish =>
    // eslint-disable-next-line
    (value == 0 ? BigNumber.from(minimal || 21000) : BigNumber.from(value))
      .mul(BigNumber.from(10000).add(BigNumber.from(1000)))
      .div(BigNumber.from(10000))

  /**
   * Build contract call parameters to fit signature of pipeline calldata: tuple(address,bytes)[]
   *
   * @param params
   * @returns
   */
  protected buildDataFromParams = (params: ContractCallParams) => {
    const iface = new Interface(params.abi);
    return [params.target, iface.encodeFunctionData(params.func, params.data)];
  }

  /**
   * execute multicall on aggregator contract
   */
  async multicall(
    calls: ContractCallParams[],
    options?: CallOverrides
  ): Promise<ContractTransaction> {
    const cCalls = calls.map(this.buildDataFromParams);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const args: (string[][] | string)[] = [cCalls];
    const { transaction } = await TxExecutable.invoke(
      'run aggregated calls',
      { callee: this.contract, method: 'multicall' },
      { args: () => args }, { estimateGas: Boolean(options), ...options }
    );

    return transaction;
  }

  /**
   * Process mint with addition actions (registerRoyalty, listItem|createAuction) in same transaction
   *
   * @param nftAddress
   * @param tokenURI
   * @param calls
   * @param options
   * @returns
   */
  async pipeMint(
    nftAddress: string,
    tokenURI: string,
    calls?: ContractCallParams[],
    options?: CallOverrides
  ): Promise<ContractTransaction> {
    const cCalls = calls.map(this.buildDataFromParams);
    const args: string | (string[][] | string)[] = [nftAddress, tokenURI, cCalls];

    console.log('mintAndPipe', this.contract.address, args);

    const { transaction } = await TxExecutable.invoke(
      'mint NFT',
      { callee: this.contract, method: 'mintAndPipe' },
      { args: () => args }, { estimateGas: Boolean(options), ...options }
    );

    return transaction;
  }

  async aggregateCalls(calls: ContractCallParams[]): Promise<ContractReceipt> {
    const { receipt } = await TxExecutable.invoke(
      'run aggregated calls',
      { callee: this.contract, method: 'multicall' },
      { args: () => calls.map(this.buildDataFromParams) }
    );

    return receipt;
  }
}
