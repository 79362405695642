/* eslint-disable max-len */
export const convertBase64ToHex = (b64: string) => atob(b64)
  .split('')
  .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
  .join('');

// Considering https://github.com/shaka-project/shaka-player/issues/1647#issuecomment-435844418
// https://github.com/Dash-Industry-Forum/dash.js/issues/3387#issuecomment-682059210
// Issue with chrome and safari, we need to remove padding and replace - and _ by + and /
// We need a backward convesrion of these problematic string
// "+" => "-"
// "/" => "_"
export const sanitizeBase64Forward = (b64: string) => b64.replace(/-/g, '+').replace(/_/g, '/').replace(/=+$/g, '');

/**
 * We need this backward conversion to be able to convert back to sanitized
 * base64 to its original form as that is what the player has registered in the system
 *
 * @see [Utils.base64urlEncode](https://github.com/chromium/chromium/blob/main/media/test/data/eme_player_js/utils.js#L48)
 */
export const sanitizeBase64Backward = (b64: string) => b64.replace(/\+/g, '+').replace(/\//g, '_');

export const k64Decode = (b64: string): string => convertBase64ToHex(sanitizeBase64Forward(b64));

export const bytesToHex = (bytes: ArrayBuffer): string => Array.from([...new Uint8Array(bytes)])
  .map((x) => x.toString(16).padStart(2, '0'))
  .join('');
