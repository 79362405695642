import { get } from 'lodash';
import { IStorage } from 'src/lib/storage';
import { DIDValues } from 'src/lib/did/types';
import {
  IVerificatorAdapter, VerifiableSocialCredential, IVerificatorManager, IClickableProps, ICredentialValue,
} from './types';
import PlaceholderAdapter from './adapters/placeholder';
import TwitterOAuthAdapter from './adapters/twitter.adapter';
import DiscordOAuthAdapter from './adapters/discord.adapter';
import InstagramOAuthAdapter from './adapters/instagram.adapter';
import EmailOAuthAdapter from './adapters/email.adapter';

export class VerificatorManager implements IVerificatorManager {
  protected ready = false;

  protected adapters: Partial<Record<VerifiableSocialCredential, IVerificatorAdapter>> = {};

  constructor(protected storage: IStorage<DIDValues<VerifiableSocialCredential>>) {}

  /**
   * Initialize the manager.
   */
  public configure() {
    // setup twitter for OAuth flow
    this.addAdapter(VerifiableSocialCredential.TWITTER, new TwitterOAuthAdapter({
      clientId: process.env.REACT_APP_TWITTER_CLIENT_ID,
      codeChallengeMethod: 'S256',
    }));

    this.addAdapter(VerifiableSocialCredential.DISCORD, new DiscordOAuthAdapter({
      clientId: process.env.REACT_APP_DISCORD_CLIENT_ID,
    }));

    this.addAdapter(VerifiableSocialCredential.INSTAGRAM, new InstagramOAuthAdapter({
      clientId: process.env.REACT_APP_INSTAGRAM_CLIENT_ID,
    }));

    this.addAdapter(VerifiableSocialCredential.EMAIL, new EmailOAuthAdapter(VerifiableSocialCredential.EMAIL, {
      formURL: '/home/profile/verify-email',
    }));

    [
      // VerifiableSocialCredential.TWITTER,
      VerifiableSocialCredential.TELEGRAM,
      // VerifiableSocialCredential.INSTAGRAM,
      // VerifiableSocialCredential.DISCORD,
      VerifiableSocialCredential.MEDIUM,
      // VerifiableSocialCredential.EMAIL,
    ].forEach((id: VerifiableSocialCredential) => {
      this.addAdapter(id, new PlaceholderAdapter());
    });

    this.ready = true;
  }

  public isReady(): boolean {
    return this.ready;
  }

  /**
   * Register a new adapter.
   *
   * @param id
   * @param adapter
   */
  protected addAdapter(id: VerifiableSocialCredential, adapter: IVerificatorAdapter) {
    this.adapters[id] = adapter;
  }

  /**
   * Returns props for a clickable component.
   *
   * @param id
   * @returns
   */
  public getClickablePropsFor(id: VerifiableSocialCredential): IClickableProps {
    return this.adapters[id]?.resolveClickableProps() ?? { disabled: true };
  }

  /**
   * Returns values components would need to show credential information.
   *
   * @param id
   * @returns
   */
  public getValueOf(id: VerifiableSocialCredential): ICredentialValue | undefined {
    const values = this.storage.get('socials');

    return {
      id: get(values, [id, 'id']),
      value: get(values, [id, 'credentialSubject', id]),
      status: get(values, [id, 'status'], 'pending'),
      visible: get(values, [id, 'visible'], false),
    };
  }

  public getKycStatus(): boolean {
    // @ts-ignore
    return this.storage.get('kycOk') ?? false;
  }
}
