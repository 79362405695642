import React from 'react';

export interface MobileCommentContextValue {
  anchorRef: React.MutableRefObject<HTMLDivElement>;
  open: boolean;
  openStream: () => void,
  closeStream: () => void;
  anchorY: () => number;
}

const MobileCommentContext = React.createContext<MobileCommentContextValue>({
  anchorRef: null,
  open: false,
  openStream: () => {},
  closeStream: () => {},
  anchorY: () => 0,
});

export interface MobileCommentOptionsProps {}

export const MobileCommentProvider: React.FC<React.PropsWithChildren<MobileCommentOptionsProps>> = ({
  children,
}: React.PropsWithChildren<MobileCommentOptionsProps>) => {
  const [isStreamOpen, setOpen] = React.useState<boolean>(false);

  const anchorRef = React.useRef<HTMLDivElement>(null);

  const openStream = () => {
    setOpen(true);
  };

  const closeStream = () => {
    setOpen(false);
  };

  const anchorY = React.useCallback(
    () => (anchorRef?.current?.getBoundingClientRect()?.y || 0) + (anchorRef?.current?.getBoundingClientRect()?.height || 0),
    [anchorRef?.current]
  );

  return (
    <MobileCommentContext.Provider
      value={{
        anchorRef,
        open: isStreamOpen,
        openStream,
        closeStream,
        anchorY,
      }}
    >
      {children}
    </MobileCommentContext.Provider>
  );
};

export default MobileCommentContext;

export const useMobileComment = () => React.useContext(MobileCommentContext);
