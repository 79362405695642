import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ApiResponse, QuoteValue } from 'src/types';
import { priceApi } from 'src/state/api/price';

type PriceState = Record<string, number>

const initialState: PriceState = {};

const slice = createSlice({
  name: 'prices',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addMatcher(
      priceApi.endpoints.fetchQuotes.matchFulfilled,
      (state, { payload }: PayloadAction<ApiResponse<QuoteValue[]>>) => {
        (payload.data || []).forEach((q: QuoteValue) => {
          state[q.tokenAddress?.toLowerCase()] = q.quote;
        });
      }
    );
  },
});

export const { reducer } = slice;
