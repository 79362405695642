import { useContext } from 'react';
import Context from './NetworkProvisioner';
import type { NetworkProvisionerContextValue as Type } from './NetworkProvisioner';

export const useNetworkProvisioner = (): Type => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error('useNetworkProvisioner could only be used within NetworkProvisioner context');
  }

  return ctx;
};

export { default as useNetworkRestriction } from './useNetworkRestriction';
