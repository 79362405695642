/* eslint-disable implicit-arrow-linebreak */
import React, {
  Suspense, lazy, ComponentType,
} from 'react';
import { Navigate } from 'react-router-dom';
import Spinner from './components/@ui/Spinner';
import RequireAdmin from './components/admin/RequireAdmin';
import AiLayout from './components/text2image/AiLayout';
import { Capabilities } from './components/text2image/type';
import { MinimalLayout, CoverLayout } from './layouts';
import ErrorPage from './views/Error';
import { withFlintServiceAgreement } from './contexts/agreement/FlintServiceAgreementContext';
import { withCinemaAgreement } from './contexts/agreement/CinemaAgreementContext';
import { baseURL } from './utils';

const Loadable =
  <T, >(Component: ComponentType<T>) =>
    (props: T) =>
      (
        <Suspense fallback={<Spinner />}>
          <Component {...props} />
        </Suspense>
      );

// Home
const Home = Loadable(lazy(() => import('./views/Home')));
const Mint = Loadable(lazy(() => import('./views/home/Mint')));
const Profile = Loadable(lazy(() => import('./views/home/Profile')));
const VaultHosting = Loadable(lazy(() => import('./views/home/VaultHosting')));
const PersonalVault = Loadable(lazy(() => import('./views/home/PersonalVault')));
const AdminSites = Loadable(lazy(() => import('./views/home/AdminSites')));
const Collection = Loadable(lazy(() => import('./views/marketplace/Collection')));
// const Map = Loadable(lazy(() => import('./views/Map')));

// Marketplace
// const MarketplaceHome = Loadable(lazy(() => import('./views/Marketplace')));
const ElanautsRegistry = Loadable(lazy(() => import('./views/marketplace/ElanautsRegistry')));
const ElanautProfile = Loadable(lazy(() => import('./views/marketplace/ElanautProfile')));
const ArtMuseum = Loadable(withCinemaAgreement(lazy(() => import('./views/marketplace/ArtMuseum'))));
const ArtAssetView = Loadable(withCinemaAgreement(lazy(() => import('./views/marketplace/ArtView'))));
const CollectionList = Loadable(lazy(() => import('./views/marketplace/CollectionList')));
const CollectionView = Loadable(lazy(() => import('./views/marketplace/CollectionView')));
const CollectionReviewList = Loadable(lazy(() => import('./views/marketplace/CollectionReviewList')));

// Admin
const AdminConsole = Loadable(lazy(() => import('./views/admin/AdminConsole')));

// OAuth
const VerificationCallback = Loadable(lazy(() => import('./views/VerificationCallbackProxy')));
const EmailVerificationForm = Loadable(lazy(() => import('./views/home/VerifyEmail')));

// AI
const Text2Image = Loadable(withFlintServiceAgreement(lazy(() => import('./views/ai/Text2Image'))));
const ImageView = Loadable(withFlintServiceAgreement(lazy(() => import('./views/ai/ImageView'))));

// Cinema
const CinemaExploreView = Loadable(withCinemaAgreement(lazy(() => import('./views/cinema/Explore'))));
const CinemaCreateTypeFormView = Loadable(withCinemaAgreement(lazy(() => import('./views/cinema/TypeformView'))));
const CinemaPlayerView = Loadable(withCinemaAgreement(lazy(() => import('./views/cinema/PlayerView'))));
const CinemaHistoryView = Loadable(withCinemaAgreement(lazy(() => import('./views/cinema/History'))));
const CinemaPlaylistView = Loadable(withCinemaAgreement(lazy(() => import('./views/cinema/Playlist'))));

export default [
  // default layout (with padding)
  {
    path: baseURL(),
    element: <MinimalLayout />,
    children: [
      {
        path: '',
        element: <Navigate to={baseURL('/cinema')} replace />,
      },
      {
        path: 'home',
        children: [
          {
            path: '',
            element: <Home />,
          },
          {
            path: 'assets/mint',
            element: <Mint />,
          },
          // {
          //   path: 'map',
          //   element: <Map />,
          // },
        ],
      },
      {
        path: 'cinema',
        children: [
          {
            path: '',
            element: <Navigate to={baseURL('/cinema/explore')} replace />,
          },
          {
            path: 'explore',
            element: <CinemaExploreView />,
          },
          {
            path: 'create',
            element: <CinemaCreateTypeFormView />,
          },
          {
            path: 'create/:hash',
            element: <CinemaCreateTypeFormView />,
          },
          {
            path: 'history',
            element: <CinemaHistoryView />,
          },
          {
            path: 'playlists/:id',
            element: <CinemaPlaylistView />,
          },
          {
            path: 'view/:ledger/:id',
            element: <CinemaPlayerView />,
          },
        ],
      },
      {
        path: 'marketplace',
        children: [
          {
            path: '',
            // element: <MarketplaceHome />,
            element: <Navigate to={baseURL('/marketplace/explore')} replace />,
          },
          {
            path: 'explore',
            children: [
              {
                path: '',
                element: <ArtMuseum />,
              },
            ],
          },
          {
            path: 'shops',
            children: [
              {
                path: '',
                element: <ElanautsRegistry />,
              },
            ],
          },
          {
            path: 'collections',
            children: [
              {
                path: '',
                element: <CollectionList />,
              },
              {
                path: 'create',
                element: <Collection />,
              },
              {
                path: 'register',
                element: <Collection action="register" />,
              },
              {
                path: 'review',
                element: <RequireAdmin />,
                children: [
                  {
                    path: '',
                    element: <CollectionReviewList />,
                  },
                ],
              },
              {
                path: ':address',
                element: <CollectionView />,
              },
            ],
          },
          {
            path: ':address/:id',
            element: <ArtAssetView />,
          },
        ],
      },
      {
        path: 'ai',
        element: <AiLayout />,
        children: [
          {
            path: '',
            element: <Navigate to={baseURL('/ai/flint-ai-district')} replace />,
          },
          {
            path: 'flint-ai-district',
            children: [
              {
                path: '',
                element: <Text2Image />,
              },
              {
                path: 'hive',
                element: <ImageView source="hive" capabilities={[Capabilities.HIVE_REMOVE]} />,
              },
              {
                path: 'r/:predictionId/:index',
                element: <ImageView source="prediction" />,
              },
            ],
          },
        ],
      },
      {
        path: 'admin',
        children: [
          {
            path: '',
            element: <Navigate to={baseURL('/admin/console')} replace />,
          },
          {
            path: 'console',
            element: <RequireAdmin />,
            children: [
              {
                path: '',
                element: <AdminConsole />,
              },
            ],
          },
        ],
      },
    ],
  },

  // layout without padding
  {
    path: baseURL().replace(/^\//, ''),
    element: <MinimalLayout noPadding />,
    children: [
      {
        path: 'home',
        children: [
          {
            path: 'profile',
            element: <Profile />,
          },
          {
            path: 'profile/vault',
            children: [
              {
                path: '',
                element: <Navigate to={baseURL('/home/profile/vault/assets')} />,
              },
              {
                path: 'hosting',
                element: <VaultHosting />,
              },
              {
                path: 'assets',
                element: <PersonalVault />,
              },
              {
                path: 'sites',
                element: <RequireAdmin />,
                children: [
                  {
                    path: '',
                    element: <AdminSites />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'marketplace',
        children: [
          {
            path: 'shops',
            children: [
              {
                path: ':address',
                element: <ElanautProfile />,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: baseURL().replace(/^\//, ''),
    element: <CoverLayout />,
    children: [
      {
        path: 'callback/:provider',
        element: <VerificationCallback />,
      },
      {
        path: 'home/profile/verify-email',
        element: <EmailVerificationForm />,
      },
    ],
  },

  // other layouts
  {
    path: '*',
    element: <ErrorPage code="404" message="Page not found" />,
  },
];
