/* eslint-disable no-bitwise */
import { format, formatDistanceToNow } from 'date-fns';

declare type DateType = string | number | Date;

export function fDate(date: DateType) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: DateType) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date: DateType) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: DateType) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export const timeFormat = (duration: number): string => {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';

  if (hrs > 0) {
    ret += `${hrs}:${mins < 10 ? '0' : ''}`;
  }

  ret += `${mins}:${secs < 10 ? '0' : ''}`;
  ret += `${secs}`;

  return ret;
};
