import React from 'react';
import { Grid, Box } from '@mui/material';

interface Props {
  sidebar?: React.ReactNode;
}

export default ({ children, sidebar }: React.PropsWithChildren<Props>) => (
  <Grid container spacing={1}>
    <Grid item xs={12} sm={4} md={3} lg={2}>
      {sidebar}
    </Grid>
    <Grid item xs={12} sm={8} md={9} lg={10}>
      <Box sx={{ mr: { xs: 1, md: 4 }, ml: 1, mt: { xs: 0, md: 2 } }}>{children}</Box>
    </Grid>
  </Grid>
);
