import { IVerificatorAdapter, IVerificatorProps } from '../types';

export default class PlaceholderAdapter implements IVerificatorAdapter {
  resolveClickableProps(): IVerificatorProps {
    return {
      disabled: true,
      onClick: () => {
        console.error('Not implemented', this);
      },
    };
  }
}
