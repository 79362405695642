export const isValidEmail = (email: string) => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/ig).test(email);

export const getURLParams = (urlParam: string) => urlParam
  .split('&')
  .map((p) => p.split('='))
  .reduce((obj, pair) => {
    // eslint-disable-next-line prefer-const
    let [key, value] = pair.map(decodeURIComponent);
    if (key.length === 0) {
      return obj;
    }

    if (key.endsWith('[]')) {
      key = key.replace(/\[\]/, '');
      if (!obj[key]) {
        obj[key] = [];
      }
      return {
        ...obj,
        [key]: [...obj[key], value],
      };
    }

    return {
      ...obj,
      [key]: value,
    };
  }, {});

export function decodeBase64Url(input: string) {
  try {
    const binary = atob(input.replace(/-/g, '+').replace(/_/g, '/').replace(/\s/g, ''));
    const bytes = new Uint8Array(binary.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return bytes;
  } catch {
    throw new TypeError('The input to be decoded is not correctly encoded.');
  }
}

const CHUNK_SIZE = 0x8000;
export function encodeBase64Url(input: Uint8Array | ArrayBuffer) {
  if (input instanceof ArrayBuffer) {
    // eslint-disable-next-line no-param-reassign
    input = new Uint8Array(input);
  }

  const arr = [];
  for (let i = 0; i < input.byteLength; i += CHUNK_SIZE) {
    // @ts-expect-error
    arr.push(String.fromCharCode.apply(null, input.subarray(i, i + CHUNK_SIZE)));
  }
  return btoa(arr.join('')).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}
