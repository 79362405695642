import React, { FC } from 'react';
import {
  Typography,
  Box,
  Button,
  CircularProgress,
  useTheme,
} from '@mui/material';
import type { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import { generateFields } from 'src/utils/formFields';
import { ListingNftFormFields } from 'src/types';
import { useRoyaltyLookup } from 'src/hooks';
import Price from 'src/components/@ui/Price';
import FeesDispatchDisplay from 'src/components/marketplace/common/FeesDispatchDisplay';

interface IEditListingModalProps {
  onCancelDone?: () => Promise<void> | void;
  contractAddress: string;
}

const EditListingModalForm: FC<IEditListingModalProps> = () => {
  const { values, setFieldValue, isSubmitting, errors } = useFormikContext<ListingNftFormFields>();
  const boxProps = { sx: { my: 4 } };

  const { royalty, royaltyValue, platformFee, earningValue } = useRoyaltyLookup({
    token: [values.nftAddress, values.tokenID],
    value: parseFloat(values.pricePerItem),
  });

  return (
    <Grid container spacing={{ lg: 2, md: 0 }} sx={{ mt: 2 }}>
      <Grid item lg={12} style={{ textAlign: 'center' }}>
        <Typography sx={{ width: '100%', textAlign: 'center' }} variant="h5">
          {`Edit Listing for "${values.name}"`}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Price.Input
            disabled={isSubmitting}
            label="Price"
            nativeSupport
            // eslint-disable-next-line max-len
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('pricePerItem', e.target.value)}
            handleCurrencyChange={(e: SelectChangeEvent<string>) => setFieldValue('payToken', e.target.value)}
            currency={values.payToken?.toLowerCase()}
            {...(!values.pricePerItem
              ? {
                autoFocus: true,
                value: '',
              }
              : {
                value: values.pricePerItem,
              })}
            error={errors.pricePerItem}
          />
        </Box>
        <Box>
          {generateFields([
            {
              params: {
                name: 'startingTime',
                isSubmitting,
                errors,
                values,
                setFieldValue,
                label: 'Starting Time',
                isDate: true,
              },
              boxProps,
              readonly: true,
            },
          ])}
        </Box>

        <Box sx={{ mb: 2 }}>
          <FeesDispatchDisplay payToken={values.payToken} {...{ royalty, royaltyValue, platformFee, earningValue }} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default EditListingModalForm;

export const RemoveButton: React.FC<{ onClick: () => Promise<void> }> = ({ onClick }) => {
  const theme = useTheme();
  const [proc, setProc] = React.useState<boolean>(false);
  return (
    <Button
      variant="contained"
      color={theme.palette.mode === 'light' ? 'inherit' : 'secondary'}
      disabled={proc}
      startIcon={proc ? <CircularProgress size="1rem" /> : null}
      onClick={async () => {
        setProc(true);
        await onClick();
        setProc(false);
      }}
    >
      Remove
    </Button>
  );
};
