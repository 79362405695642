import React from 'react';
import { NFTGalleryItem } from 'src/types';
import {
  useDispatch, useSelector, RootState,
} from 'src/state/store';
import {
  setCurrentSlot,
  itemsSelector,
  mediaSelector,
  setCurrentMedia,
  goNextMediaAmong,
  goPrevMediaAmong,
} from 'src/state/slices/gallery';

// this context will handle group of items that should be
// part of same gallery
// basically, the group is determined when an item is opened
// on fullscreen view, once close, the gallery items should reset
// At later stage, we should be able to determine which gallery slot
// to use when fully viewing an NFT (ArtAssetView)

export interface GalleryContextAction {
  setCurrentSlot: (slotId: string) => void;
  setCurrentMedia: (media: Partial<NFTGalleryItem>) => void;
  onNext: () => void;
  onPrev: () => void;
}

export interface GalleryContextvalue extends GalleryContextAction {
  items?: NFTGalleryItem[];
  currentMedia?: [number, NFTGalleryItem | null];
  slotId?: string;
}

const GalleryContext = React.createContext<GalleryContextvalue>({
  setCurrentSlot: (slotId: string) => {},
  setCurrentMedia: (media: Partial<NFTGalleryItem>) => {},
  onPrev: () => {},
  onNext: () => {},
});

interface GalleryProviderProps {}

export const GalleryProvider = ({ children }: React.PropsWithChildren<GalleryProviderProps>) => {
  const dispatch = useDispatch();
  const slotId = useSelector((state: RootState) => state.gallery.currentSlot);
  const items = useSelector(itemsSelector);
  const currentMedia = useSelector(mediaSelector);

  const setCurrentSlotDispatch = (id: string) => dispatch(setCurrentSlot(id));
  const setCurrentMediaDispatch = (media: Partial<NFTGalleryItem>) => dispatch(setCurrentMedia(media));
  const onPrev = React.useCallback(() => dispatch(goPrevMediaAmong(items)), [items]);
  const onNext = React.useCallback(() => dispatch(goNextMediaAmong(items)), [items]);

  return (
    <GalleryContext.Provider
      value={{
        slotId,
        setCurrentSlot: setCurrentSlotDispatch,
        setCurrentMedia: setCurrentMediaDispatch,
        currentMedia,
        items,
        onPrev,
        onNext,
      }}
    >
      {children}
    </GalleryContext.Provider>
  );
};

export default GalleryContext;
