import React from 'react';
import { Instagram as InstagramIcon } from '@mui/icons-material';
import { SxProps } from '@mui/system';

interface Props {
  sx?: SxProps;
}

export default ({ sx }: Props) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="instagramColors" x2="0.35" y2="1">
        <stop offset="0%" stopColor="#f09433" />
        <stop offset="25%" stopColor="#e6683c" />
        <stop offset="50%" stopColor="#dc2743" />
        <stop offset="75%" stopColor="#cc2366" />
        <stop offset="100%" stopColor="#bc1888" />
      </linearGradient>
    </svg>
    <InstagramIcon sx={{ fill: 'url(#instagramColors)', ...sx }} />
  </>
);
