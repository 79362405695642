import { ApiResponse, QuoteValue } from 'src/types';
import { api } from './query.base';

const priceApi = api
  .injectEndpoints({
    endpoints: (builder) => ({
      fetchQuote: builder.query<ApiResponse<number>, string>({
        query: (address: string) => `/quotes/${address}`,
      }),
      fetchQuotes: builder.query<ApiResponse<QuoteValue[]>, void>({
        query: () => '/quotes',
      }),
    }),
  });

export { priceApi };

export const {
  useLazyFetchQuoteQuery,
  useLazyFetchQuotesQuery,
  useFetchQuoteQuery,
  useFetchQuotesQuery,
} = priceApi;
