/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-case-declarations */
import React from 'react';
import { FormikErrors, useFormik } from 'formik';
import type { Currency, SwapConvert } from '@elacity-js/lib';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Price from 'src/components/@ui/Price';
import { currencies as erc20Currencies, nativeCurrencies } from 'src/constants';
import {
  useProfile, useWeb3Application, useSwap,
} from 'src/hooks';
import { baseURL } from 'src/utils';

interface SwapDialogProps {
  open: boolean;
  handleClose: () => void;
}

interface SwapFormValues {
  fromAmount: string;
  fromToken: string;
  toAmount: string;
  toToken: string;
  rate: number;
}

const Image = styled('img')(({ theme }) => ({
  width: 180,
  margin: theme.spacing(6, 'auto', 0),
  [theme.breakpoints.down('sm')]: {
    width: 140,
    margin: theme.spacing(2, 'auto', 0),
  },
}));

export default ({ open, handleClose }: SwapDialogProps) => {
  const { chainId } = useWeb3Application();
  const { balance, loadBalances } = useProfile();
  const { execSwap } = useSwap();
  const currencies = [...erc20Currencies, nativeCurrencies[chainId]];

  const { values, errors, setFieldValue, isSubmitting, submitForm, resetForm } = useFormik<SwapFormValues>({
    initialValues: {
      fromAmount: '',
      fromToken: '',
      toAmount: '',
      toToken: '',
      rate: 1,
    },
    validateOnChange: false,
    validateOnBlur: true,
    validate: async ({ fromAmount, fromToken, toToken }) => {
      const err: FormikErrors<SwapFormValues> = {};

      if (!fromAmount || fromAmount.length === 0) {
        err.fromAmount = 'Required';
      }

      if (!fromToken || fromToken.length === 0) {
        err.fromToken = 'Please choose currency you want to swap from';
      }

      if (!toToken || toToken.length === 0) {
        err.toToken = 'Please choose currency you want to swap to';
      }

      if (parseFloat(fromAmount) > 0 && balance[values.fromToken] < parseFloat(fromAmount)) {
        err.fromAmount = 'Insufficient balance';
      }

      return err;
    },
    onSubmit: async ({ fromAmount, fromToken, toAmount, toToken }: SwapFormValues) => {
      console.log({ fromAmount, fromToken, toAmount, toToken });
      await execSwap({
        fromAmount: parseFloat(fromAmount),
        fromToken,
        toToken,
      });

      await loadBalances();
      resetForm();
      handleClose();
    },
  });

  const handleTokenChange = (key: keyof SwapFormValues) => (e: SelectChangeEvent) => {
    setFieldValue(key, e.target.value);

    // eslint-disable-next-line default-case
    switch (key) {
      case 'fromToken':
        const fromCurrency = currencies
          .filter((c: Currency) => c.address?.toLowerCase() === e.target.value?.toLowerCase())
          .shift();

        // now we will take the swapConvert that have rate=1 as default to set for the other currency
        if (fromCurrency) {
          if (fromCurrency.swapSupports) {
            const toCurrency = fromCurrency.swapSupports.filter((c: SwapConvert) => c.rate === 1).shift();
            if (toCurrency) {
              setFieldValue('toToken', toCurrency.address);
              setFieldValue('rate', toCurrency.rate);
              if (values.fromAmount && values.fromAmount.length > 0) {
                setFieldValue('toAmount', parseFloat(values.fromAmount) * toCurrency.rate);
              }
            }
          }
        }
        break;
      case 'toToken':
        const toCurrency = currencies.filter((c: Currency) => c.address?.toLowerCase() === e.target.value?.toLowerCase()).shift();

        // now we will take the swapConvert that have rate=1 as default to set for the other currency
        if (toCurrency) {
          if (toCurrency.swapSupports) {
            const fromCurrency = toCurrency.swapSupports.filter((c: SwapConvert) => c.rate === 1).shift();
            if (fromCurrency) {
              setFieldValue('fromToken', fromCurrency.address);
              setFieldValue('rate', fromCurrency.rate);
              if (values.fromAmount && values.fromAmount.length > 0) {
                setFieldValue('toAmount', parseFloat(values.fromAmount) / fromCurrency.rate);
              }
            }
          }
        }
        break;
    }
  };

  const handleAmountChange = (key: keyof SwapFormValues) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(key, e.target.value);

    // eslint-disable-next-line default-case
    switch (key) {
      case 'fromAmount':
        if (values.fromToken?.length > 0) {
          setFieldValue('toAmount', parseFloat(e.target.value) * values.rate);
        }
        break;
    }
  };

  return (
    <>
      <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
        <Image crossOrigin="anonymous" alt="Swap Station" src={baseURL('/static/elacity/9.png')} />
        <DialogTitle>Swap Station</DialogTitle>
        <DialogContent>
          <DialogContentText>Welcome to the Elacity Swap Station! Here you can swap your ELA and wELA 1:1.</DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
              pt: { xs: 1, sm: 3 },
            }}
          >
            <Box sx={{ my: 2 }}>
              <Price.Input
                disabled={isSubmitting}
                swap
                label="From"
                type="number"
                onChange={handleAmountChange('fromAmount')}
                handleCurrencyChange={handleTokenChange('fromToken')}
                currency={values.fromToken?.toLowerCase()}
                {...(!values.fromAmount
                  ? {
                    autoFocus: true,
                    value: '',
                  }
                  : {
                    value: values.fromAmount,
                  })}
                helpText={
                  values.fromToken &&
                  values.fromToken.length > 0 && (
                    <FormHelperText sx={{ mx: 0 }}>
                      Balance:
                      {' '}
                      <strong>{balance[values.fromToken]}</strong>
                    </FormHelperText>
                  )
                }
                error={errors.fromAmount || errors.fromToken}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ArrowDownwardIcon />
            </Box>
            <Box sx={{ my: 2 }}>
              <Price.Input
                disabled={isSubmitting}
                preventInputChange
                swap
                label="To"
                type="number"
                handleCurrencyChange={handleTokenChange('toToken')}
                currency={values.toToken?.toLowerCase()}
                value={Number.isNaN(values.toAmount) ? '' : values.toAmount}
                error={errors.toToken}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: { xs: 1, sm: 3 }, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={submitForm}
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
          >
            Swap
          </Button>
          <Button onClick={handleClose} disabled={isSubmitting}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
