import { useNavigate } from 'react-router';
import { baseURL } from 'src/utils';

export default () => {
  const navigate = useNavigate();

  return (path: string | number) => {
    if (typeof path === 'number') {
      navigate(path);
    } else {
      navigate(baseURL(path));
    }
  };
};
