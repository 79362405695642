import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseUrl = process.env?.REACT_APP_BACKEND_URL || 'http://localhost:3001';

export const api = createApi({
  reducerPath: 'api',
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  endpoints: () => ({}),
});

export const profileFragment = `
fragment profileFields on Account {
  address
  alias
  avatar
  did {
    trustLevel
    kycOk
    did
    credentials {
      name
      avatar {
        thumbnail
      }
    }
  }
}`;

export const nftFragment = `
fragment nftFields on ERC721Token {
  _id
  variant
  contractAddress
  tokenID
  hexTokenID
  tokenURI
  name
  description
  category
  imageURL
  views
  likes
  dislikes
  owner {
    ...profileFields
  }
  price
  paymentToken
  createdAt
}`;

export const tokenFieldsFragment = `
fragment tokenFields on TokenSummary {
  contractAddress
  tokenID
  hexTokenID
  name
  tokenURI
  imageURL
  thumbnailPath
  variant
}`;

export const eventFragment = `
${profileFragment}
${tokenFieldsFragment}
fragment eventFields on ActivityEvent {
  _id
  event
  token {
    ...tokenFields
  }
  from {
    ...profileFields
  }
  price
  paymentToken
  quantity
  createdAt
  blockNumber
}`;

export const commentFragment = `
fragment commentFields on Comment {
  _id
  contractAddress
  tokenID
  hexTokenID
  by {
    ...profileFields
  }
  content
  createdAt
  likes {
    count
    isAmong
  }
  dislikes {
    count
    isAmong
  }
}`;
