interface IConfig {
  layout: {
    APPBAR_MOBILE: number;
    APPBAR_DESKTOP: number;
    DRAWER_WIDTH: number;
    CARD_MOBILE_XS_WIDTH: number;
    DRAWER_WIDTH_MIN: number;
  };
  web3: {
    POLLING_INTERVAL?: number;
    INFURA_ID: string;
  };
  links: {
    documentation: string;
    twitter: string;
    discord: string;
    telegram: string;
    smartweb: string;
    whitepaper: string;
  }
}

export const layout: IConfig['layout'] = {
  APPBAR_MOBILE: 64,
  APPBAR_DESKTOP: 56,
  DRAWER_WIDTH: 305,
  CARD_MOBILE_XS_WIDTH: 328,
  DRAWER_WIDTH_MIN: 80,
};

export const web3: IConfig['web3'] = {
  POLLING_INTERVAL: parseInt(process.env.REACT_APP_POLLING_INTERVAL || '12000', 10),
  INFURA_ID: process.env.REACT_APP_INFURA_ID || 'b00193a4a5fa4b7da1d2336ea76a0614',
};

export const links: IConfig['links'] = {
  documentation: 'https://docs.ela.city',
  twitter: 'https://twitter.com/Elacityofficial',
  discord: 'https://discord.gg/bh2FKFhbZJ',
  telegram: 'https://t.me/elacity',
  smartweb: 'https://docs.ela.city/undefined/welcome-to-elacity/smartweb-infrastructure',
  whitepaper: 'https://www.docdroid.net/YEeOIqG/elacity-access-economy-whitepaper-pdf',
};

export default {
  layout,
  web3,
  links,
} as IConfig;
