import type { IProfile } from 'src/types';
import { useFindAccountsQuery, FindProfileQuery } from 'src/state/api';
import { useSelector } from 'src/state/store';
import useWeb3Application from './useWeb3Application';

export default (query: FindProfileQuery = {}, o?: {skip?: boolean}) => {
  const { account } = useWeb3Application();
  const { currentData: result, isFetching: isLoadingAccounts, requestId, originalArgs } = useFindAccountsQuery(query, {
    // refetchOnMountOrArgChange: true,
    ...(o || {}),
  });

  const { followings, userLoaded } = useSelector((state) => ({
    userLoaded: state.user?.loaded,
    followings: state.user?.followings || [],
  }));

  if (isLoadingAccounts) {
    return {
      isLoading: true,
      accounts: [],
    };
  }

  if (!account) {
    return {
      isLoading: false,
      requestId,
      originalArgs,
      accounts: result?.data || [],
      total: result?.total,
      offset: result?.offset,
    };
  }

  const followingAddresses: string[] = (followings || []).map(({ address }) => address);

  return {
    isLoading: false,
    requestId,
    originalArgs,
    accounts: result?.data.map((a: IProfile) => ({
      ...a,
      isLiked: userLoaded ? followingAddresses.includes(a.address) : false,
    })),
    total: result?.total,
    offset: result?.offset,
  };
};
