// eslint-disable-next-line max-len
export const isMobile = (): boolean => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile/i.test(navigator.userAgent);
export const isAppleMobile = (): boolean => /iPhone|iPad|iPod/i.test(navigator.userAgent);
export const isMetaMaskBuiltInBrowser = (): boolean => /MetaMaskMobile/i.test(navigator.userAgent);

export const popupWindowCenter = ({ url, title, w, h }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  // eslint-disable-next-line max-len, no-nested-ternary, no-restricted-globals
  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  // eslint-disable-next-line max-len, no-nested-ternary, no-restricted-globals
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(url, title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `);

  if (window.focus) newWindow.focus();
};

export const tapLink = (link: string) => {
  const a = document.createElement('a');
  a.href = link;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  a.remove();
};
