import React from 'react';

type DataType<T> = string | Partial<T>;

interface HookOptions<T> {
  reverseId?: (id: string) => Partial<T>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleBulkAction?: (items: DataType<T>[], ...args: any[]) => void;

  // used to enforce enpdate of selected state over page navigation
  updateWitness?: number;
}

export default <T extends string>(source: T[], o?: HookOptions<T>) => {
  const [selected, setSelected] = React.useState<readonly T[]>([]);

  React.useEffect(() => {
    if (typeof o?.updateWitness === 'number') {
      setSelected([]);
    }
  }, [o?.updateWitness]);

  const toggleSelectAll = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        // select all elements defined by source
        setSelected(source);
      } else {
        // unselect all elements
        setSelected([]);
      }
    },
    [source]
  );

  const handleSelect = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, id: T) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: readonly T[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    },
    [selected]
  );

  const isSelected = React.useCallback((id: T) => selected.indexOf(id) !== -1, [source]);

  const handleSelection = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: React.MouseEvent<HTMLButtonElement>, ...args: any[]) => {
      if (selected.length === 0) {
        console.log('[handleSelection]', 'empty selection, skipping action...');
        return;
      }

      if (!o?.handleBulkAction) {
        console.warn('[handleSelection] no handler for bulk action');
        return;
      }

      o?.handleBulkAction?.(selected.map(
        (s: string) => o?.reverseId?.(s) || s
      ), ...args);
    },
    [selected]
  );

  return {
    selected,
    isSelected,
    handleSelect,
    toggleSelectAll,
    handleSelection,
  };
};
