import { combineReducers } from '@reduxjs/toolkit';
import { api } from '../api/query.base';
import { authenticatedApi } from '../api/query.private';
import { reducer as filtersReducer } from '../slices/filters';
import { reducer as userReducer } from '../slices/user';
import { reducer as tokenReducer } from '../slices/token';
import { reducer as networkReducer } from '../slices/network';
import { reducer as galleryReducer } from '../slices/gallery';
import { reducer as priceReducer } from '../slices/prices';
import { reducer as playerReducer } from '../slices/player';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [authenticatedApi.reducerPath]: authenticatedApi.reducer,
  filters: filtersReducer,
  user: userReducer,
  network: networkReducer,
  token: tokenReducer,
  prices: priceReducer,
  gallery: galleryReducer,
  player: playerReducer,
});

export default rootReducer;
