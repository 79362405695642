import React from 'react';
import { Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface BadgeProps {
  did: string;
  level?: number;
}

const colorByLevel = {
  // default
  1: 'badge1',

  // completed at least 1 social credentials
  2: 'info',

  // verified with kyc-me.io
  3: 'success',
};

export const additionalText = [
  '',
  '',
  'The owner using their DID has additionally logged-in and verified an external social account.',
  // eslint-disable-next-line max-len
  'The owner using their DID has additionally performed a know your customer (KYC) check with their passport or national identity card via https://kyc-me.io/ using the third-party https://passbase.com/.',
];

export default ({ did, level }: BadgeProps) => {
  const theme = useTheme();

  return (
    <Tooltip title={`This account is bound to a DID: ${did} ${additionalText[level || 1]}`} placement="top">
      <CheckCircleIcon
        onClick={() => window.open(`https://www.profile.site/did/${did?.split(':').pop()}`)}
        style={{
          marginLeft: theme.spacing(0.5),
          color: theme.palette[colorByLevel[level || 1]].main,
          cursor: 'pointer',
        }}
        fontSize="small"
      />
    </Tooltip>
  );
};
