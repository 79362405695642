import { useDispatch as useReduxDispatch, useSelector as useBaseSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import type { Action } from '@reduxjs/toolkit';
import { api } from '../api/query.base';
import { authenticatedApi } from '../api/query.private';
import '../api/auth';
import '../api/account';
import '../api/collection';
import '../api/token';
import '../api/stats';
import '../api/search';
import '../api/ledger.token';
import '../api/subscribe';
import '../api/playlist';
import '../api/comment';
import '../api/like';
import rootReducer from './reducer';

const loggerMiddleware = createLogger({});

function createDynamicThunkMiddleware() {
  let currentExtraArgument = { library: null };

  let dynamicThunk = thunk.withExtraArgument(currentExtraArgument);

  const middleware = (store) => (next) => (action) => dynamicThunk(store)(next)(action);

  // Provide a way to update extra argument
  middleware.updateExtraArgument = (args) => {
    currentExtraArgument = { ...args };
    console.log('[redux.middleware] Adding extra args into thunk middleware...', currentExtraArgument);
    // Update the thunk middleware with new extra argument
    dynamicThunk = thunk.withExtraArgument(currentExtraArgument);
  };

  return middleware;
}

export const dynamicMiddleware = createDynamicThunkMiddleware();

// Middleware Configuration
const middleware = (getDefaultMiddleware) => {
  // Start with default middleware, customize as needed
  const defaultMiddleware = getDefaultMiddleware({
    thunk: false, // Disable default thunk middleware to avoid conflicts and unnecessary middleware processing.
    serializableCheck: {
      ignoredActions: [],
      ignoredActionPaths: [
        '<root>.',
        'api.extra.library',
        'extra.library',
        'meta.baseQueryMeta.request',
        'meta.baseQueryMeta.response',
      ],
    },
  }).prepend(
    // prepend dynamic middleware & get back thunk middleware
    dynamicMiddleware
  ).concat(
    // Add API middlewares
    api.middleware,
    authenticatedApi.middleware
  );

  // Conditionally add logger middleware in development
  if (process.env.NODE_ENV === 'development') {
    defaultMiddleware.push(loggerMiddleware);
  }

  return defaultMiddleware;
};

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true',
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useBaseSelector;

export default store;
