/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import {
  Typography, Box, Divider,
} from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';

interface Props extends TypographyProps {
  Icon?: React.ComponentType<{ height?: number }>;
}

const Title = ({ children, Icon, ...props }: React.PropsWithChildren<Props>) => (
  <Box sx={{ mb: 1 }}>
    <Typography {...props} sx={{ display: 'flex', alignItems: 'center', ...(props.sx || {}) }}>
      {Icon && <Icon height={30} />}
      {children}
    </Typography>
    <Divider sx={{ my: 1 }} />
  </Box>
);

Title.defaultProps = {
  variant: 'h5',
  fontWeight: 400,
};

export default Title;
