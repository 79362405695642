import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import {
  Box, Stack, IconButton,
} from '@mui/material';
import type { BoxProps } from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import Scrollbar from 'src/components/@ui/Scrollbar';
import PoweredDoc from 'src/components/section/PoweredDoc';
import { RouterLink } from 'src/components/Link';
import { FullLogo as Logo } from 'src/assets/Logo';
import NavSection from '../section/NavSection';
import { MHidden } from '../@material-extend';
import { layout } from '../../config';
import sidebarConfig from './SidebarConfig';
import DrawerUI from '../@ui/DrawerUI';

const minimizedWidth = layout.DRAWER_WIDTH_MIN;

interface RootStyleProps {
  minimized?: boolean;
}

const RootStyle = styled(Stack, {
  shouldForwardProp: (prop: string) => prop !== 'minimized',
})<RootStyleProps>(({ theme, minimized }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: layout.DRAWER_WIDTH,
    ...(minimized && {
      width: minimizedWidth,
    }),
  },
}));

interface MinimizeContainerProps extends BoxProps {
  minimized?: boolean;
}

const MinimizeContainer = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== 'minimized',
  name: 'MinimizableBtnContainer',
  slot: 'Root',
})<MinimizeContainerProps>(({ theme, minimized }) => ({
  display: 'inline-block',
  position: 'fixed',
  left: 14 - 30 + layout.DRAWER_WIDTH,
  top: 80,
  zIndex: 1300,
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: 'blur(6px)',
  boxShadow: theme.shadows[6],
  border: `1px solid ${theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800]}`,
  borderRadius: 30,
  clear: 'both',

  // minimized overrides
  ...(minimized && {
    left: 14 - 30 + minimizedWidth,
  }),
}));

// ----------------------------------------------------------------------

interface DashboardProps {
  // only for little size
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;

  minimized?: boolean;
  toggleMinimize?: () => void;
}

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,

  // handle minized state
  minimized,
  toggleMinimize,
}: DashboardProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <>
      <Box
        sx={{
          px: minimized ? 1 : 2.5,
          pb: 3,
          pt: 5,
          textAlign: 'center',
        }}
      >
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo minimized size={170} />
        </Box>
      </Box>

      <Scrollbar
        sx={{
          // height: '100%',
          '& .simplebar-content': {
            // height: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <NavSection minimized={minimized} navConfig={sidebarConfig} />

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
    </>
  );

  return (
    <RootStyle minimized={minimized}>
      <MHidden width="lgDown">
        <MinimizeContainer minimized={minimized}>
          <IconButton onClick={toggleMinimize} size="small" color="primary" sx={{ width: 28, height: 28 }}>
            {minimized ? <ArrowForwardIcon sx={{ fontSize: 16 }} /> : <ArrowBackIcon sx={{ fontSize: 16 }} />}
          </IconButton>
        </MinimizeContainer>
      </MHidden>
      <MHidden width="lgUp">
        <DrawerUI open={isOpenSidebar} onClose={onCloseSidebar} minimized={minimized}>
          {renderContent}
          <Box sx={{ flexGrow: 1, width: '100%', margin: '0 auto', maxWidth: layout.DRAWER_WIDTH_MIN }}>
            <PoweredDoc />
          </Box>
        </DrawerUI>
      </MHidden>

      <MHidden width="lgDown">
        <DrawerUI open variant="persistent" minimized={minimized}>
          {renderContent}
          <PoweredDoc minimized={minimized} />
        </DrawerUI>
      </MHidden>
    </RootStyle>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};
