import React from 'react';
import { FileHelper } from '@elacity-js/lib';
import {
  Box, Tooltip, Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import Upload from 'src/components/form/Upload';

interface ImageInputProps {
  label: string;
  image: File;
  description: string;
  error?: string;
  onChange: (value: File) => void;
}

const ImageInput = ({ label, description, image, error, onChange }: ImageInputProps) => (
  <Box sx={{ mt: 2 }}>
    <Typography>{label}</Typography>
    <Typography sx={{ fontSize: '0.70rem', color: 'text.secondary' }}>{description}</Typography>
    <Upload.Avatar
      sx={{
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto',
        width: '100%',
        p: 0,
        cursor: 'pointer',
        '& .MuiBox-root': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        '& img': {
          width: 'inherit!important',
          borderRadius: 0,
        },
      }}
      hideName
      onDropped={(acceptedFile: File) => {
        onChange(acceptedFile);
      }}
      /* onMimeTypeAcquired={(mimeType: string) => {
      setFieldValue('mimeType', mimeType);
    }} */
      supportedFileDescription={`${['JPG', 'PNG'].join(', ')} Max 20MB`}
      accept={['image/jpeg', 'image/png'].join(',')}
    >
      <Tooltip title="Click to upload image">
        <Box sx={{ width: '100%', height: 160, border: '1px dashed', mt: 1, borderRadius: (t: Theme) => t.spacing(1.5) }}>
          {image ? (
            <img
              alt="initial_img"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                borderRadius: 0,
              }}
              src={FileHelper.asImageURL(image)}
              crossOrigin="anonymous"
            />
          ) : (
            <Box>
              {error ? (
                <Typography sx={{ color: 'error.main' }}>{error}</Typography>
              ) : (
                <Typography fontSize="0.8rem">Click or drag/drop image here to upload</Typography>
              )}
            </Box>
          )}
        </Box>
      </Tooltip>
    </Upload.Avatar>
  </Box>
);

export default ImageInput;
