import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type {
  ILike, NFTArtApiResponse, INFTArt, ApiResponse,
} from 'src/types';
import { tokenApi } from '../api/token';
import type { LikeItemRequest, LikeBundleRequest } from '../api';
import type { Metadata } from './types';

type TokenValue = ILike & {liked?: number; updater?: string};

interface TokenState {
  likes: Record<string, TokenValue>;
}

const initialState = {
  likes: {},
} as TokenState;

const populateState = (state: TokenState, action: PayloadAction<NFTArtApiResponse>) => {
  state.likes = (action.payload?.data?.tokens || []).reduce(
    (result: typeof state.likes, tk: INFTArt) => {
      const key = tk.bundleID ? tk.bundleID : `${tk.contractAddress}-${tk.hexTokenID}`;
      return {
        ...result,
        [key]: {
          ...(result[key] || {}),
          contractAddress: tk.contractAddress,
          tokenID: tk.tokenID,
          hexTokenID: tk.hexTokenID,
          liked: tk.liked,
          ...(tk.isLiked && {
            // can be undefined (fetchMyLikes doesn't provide this key)
            isLiked: tk.isLiked,
          }),
        },
      };
    }, state.likes || {}
  );
};

const slice = createSlice({
  name: 'token',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      tokenApi.endpoints.fetchTokens.matchFulfilled, populateState
    );

    builder.addMatcher(
      tokenApi.endpoints.fetchMyLikes.matchFulfilled, populateState
    );

    builder.addMatcher(
      tokenApi.endpoints.getItemsLiked.matchFulfilled,
      (state: TokenState, action: PayloadAction<ApiResponse<ILike[]>>) => {
        const response = action.payload;
        if (response.status === 'success') {
          // browse response, then write `isLiked` flag result into the state
          response.data.forEach(
            (lk: ILike) => {
              const key = lk.bundleID ? lk.bundleID : `${lk.contractAddress}-${lk.hexTokenID}`;
              state.likes[key] = {
                ...(state.likes[key] || {}),
                isLiked: lk.isLiked,
              };
            }
          );
        }
      }
    );

    builder.addMatcher(
      tokenApi.endpoints.likeItem.matchFulfilled,
      (
        state: TokenState,
        action: PayloadAction<ApiResponse<number | boolean>, string, Metadata<LikeItemRequest | LikeBundleRequest>>
      ) => {
        if (action.payload.status === 'success' && typeof action.payload.data === 'number') {
          const tk = action.meta?.arg?.originalArgs;
          // @ts-ignore
          const key = tk?.bundleID ? tk.bundleID : `${tk.contractAddress}-${tk.hexTokenID}`;

          state.likes[key] = {
            ...(state.likes[key] || {}),
            liked: action.payload.data,
            isLiked: (typeof (state.likes[key] || {}).isLiked === 'boolean')
              ? !(state.likes[key] || {}).isLiked
              : action.payload.data > ((state.likes[key] || {}).liked || 0),
          };
        }
      }
    );
  },
});

export const { reducer } = slice;

// export const selectCurrentUser = (state: RootState) => state.user.data;
