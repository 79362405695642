export declare type SourceType = 'hive' | 'prediction';

export interface AIResultState {
  error?: Error;
  isLoading?: boolean;
  source?: SourceType;
  currentView?: string;
  currentIndex: number;

  downloading?: boolean;
  upscaling?: boolean;
  removing?: boolean;
}

type ErrorAction = {
  type: 'ERROR';
  error: Error;
};

type SetAction = {
  type: 'SET';
  payload: Partial<AIResultState>;
};

type Action = ErrorAction | SetAction;

export default (state: AIResultState, action: Action) => {
  switch (action.type) {
    case 'ERROR':
      return { ...state, error: action.error, isLoading: false };
    default:
      return { ...state, ...(action.payload || {}) };
  }
};
