/* eslint-disable default-case */
import React from 'react';
import { toIpfsGateway } from '@elacity-js/lib';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import FullscreenDialog from 'src/components/@ui/FullscreenDialog';
import MediaViewer, { FileType } from 'src/components/MediaViewer';
import { useGallery } from 'src/hooks';
import { ObjectWithTokenID } from 'src/types';

const ButtonContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '&:first-of-type': {
    marginRight: 2,
  },
  '&:last-of-type': {
    marginLeft: 2,
  },
});

export interface ModalOptionsProps extends ObjectWithTokenID {
  src: string;
  type?: FileType;
  mimeType?: string;
  galleryId?: string;
  contractAddress?: string;
}

const TheModal: React.FC<ModalOptionsProps & { open?: boolean; onClose: () => void }> = ({
  src,
  open,
  type,
  mimeType,
  onClose,
  ...props
}: ModalOptionsProps & { open?: boolean; onClose: () => void }) => {
  const { items, currentMedia, onPrev, onNext, setCurrentMedia } = useGallery();

  const handleClose = () => {
    setCurrentMedia(null);
    onClose();
  };

  return (
    <FullscreenDialog open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          width: 'auto',
          height: '98vh',
          marginTop: '1vh',
          justifyContent: 'center',
        }}
      >
        {items.length > 1 && (
          <ButtonContainer>
            <IconButton onClick={onPrev}>
              <ArrowBackIcon />
            </IconButton>
          </ButtonContainer>
        )}

        <MediaViewer.Preview
          src={toIpfsGateway(currentMedia[1]?.mediaURL || src)}
          mode="fullscreen"
          type={currentMedia[1]?.type || 'default'}
          mimeType={currentMedia[1]?.mimeType}
          style={{
            flexGrow: 1,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            boxShadow: 'none',
            objectFit: 'contain',
            maxWidth: '92vw',
          }}
        />

        {items.length > 1 && (
          <ButtonContainer>
            <IconButton onClick={onNext}>
              <ArrowForwardIcon />
            </IconButton>
          </ButtonContainer>
        )}
      </Box>
    </FullscreenDialog>
  );
};

export interface ModalContextValue {
  openModal: (options: ModalOptionsProps) => void;
  closeModal?: () => void;
}

const ModalContext = React.createContext<ModalContextValue>({
  openModal: () => {},
});

interface ModalProviderProps {}

export const FullscreenProvider: React.FC<React.PropsWithChildren<ModalProviderProps>> = ({
  children,
}: React.PropsWithChildren<ModalProviderProps>) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [modalProps, setModalProps] = React.useState<ModalOptionsProps>({ src: '' });
  const { setCurrentSlot, setCurrentMedia } = useGallery();
  const openModal = ({ galleryId, ...o }: ModalOptionsProps) => {
    setCurrentSlot(galleryId);
    setCurrentMedia({
      mediaURL: o.src,
      contractAddress: o.contractAddress,
      tokenID: o.tokenID,
      hexTokenID: o.hexTokenID,
    });
    setModalProps(o);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal: onClose,
      }}
    >
      {children}
      <TheModal onClose={onClose} open={open} {...modalProps} />
    </ModalContext.Provider>
  );
};

export default ModalContext;
