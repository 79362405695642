// scroll bar
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar/src/simplebar.css';

import React from 'react';
import { useSnackbar, SnackbarKey } from 'notistack';
import { useRoutes } from 'react-router-dom';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ApiResponse } from 'src/types';
import { useFetchQuotesQuery } from './state/api';
import routes from './routes';
import './i18n';
import './App.css';

interface ServerInfo {
  version: string;
}

const StackButton = styled(Button)(({ theme }) => ({
  color: 'white',
  '&.MuiButton-contained': {
    backgroundColor: theme.palette.grey[700],
  },
}));

const BodyWrapper = styled('div')(({ theme }) => ({
  '@global': {
    body: {
      backgroundColor: '#FF0000',
    },
  },
}));

const App = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const content = useRoutes(routes);

  // retrieve prices once, will be stored in redux state right after fulfilled
  useFetchQuotesQuery();

  React.useEffect(() => {
    // disable context menu
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    const version = process.env.REACT_APP_VERSION;
    // retrieve latest version tag registered in server side
    fetch(`${process.env.REACT_APP_BACKEND_URL}/info/server`)
      .then((r) => r.json())
      .then(({ status, data }: ApiResponse<ServerInfo>) => {
        if (status === 'success' && version !== data.version) {
          enqueueSnackbar(
            // eslint-disable-next-line max-len
            `A more recent release is available (${data.version}). If you keep receiving this message, then please close all tabs navigating on ela.city then re-open a new one to get latest version`,
            {
              variant: 'warning',
              persist: true,
              action: (key: SnackbarKey) => (
                <>
                  <StackButton size="small" variant="text" onClick={() => closeSnackbar(key)}>
                    Dismiss
                  </StackButton>
                </>
              ),
            }
          );
        }
      })
      .catch((err: Error) => {
        console.log('Unable to fetch version from server', err.message);
      });
  }, []);

  return <BodyWrapper>{content}</BodyWrapper>;
};

export default App;
