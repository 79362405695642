import React, { useCallback, ComponentType } from 'react';
import { useSnackbar } from 'notistack';
import { FormikErrors } from 'formik';
import { Box } from '@mui/material';
import { TokenID } from '@elacity-js/lib';
import { Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';
import type { INFTArt as IRawNFTArt } from 'src/types';
import type {
  IAuction,
  ICreateAuctionParams,
  IPlaceBidFormData,
  IAuctionResultFormData,
  IAuctionWithdrawBidFormData,
  IOptionalActions,
} from 'src/lib/nfts/types';
import { AuctionNftFactory } from 'src/lib/nfts/factories';
import events from 'src/lib/nfts/events';
import { ADDRESS, ABIS } from 'src/lib/nfts/constants';
import { AuctionFields } from 'src/components/marketplace/auction/types.d';
import { useNotifiyTokenRemovalMutation, useNotifiyTokenUpdateMutation } from 'src/state/api/token';
import useWeb3Application from './useWeb3Application';
import useErrorHandler from './useErrorHandler';
import useApproval from './useApproval';
import useAddresses from './useAddresses';
import usePayment from './usePayment';
import useModal, { ModalOptionsProps } from './useModal';
import { useApprovalContextFor } from './useContractApproval';
import useScContracts from './contracts/sc.contracts';
import { RemoveButton } from '../components/marketplace/auction/AuctionModalForm';

type INFTArt = Omit<IRawNFTArt, 'tokenID'> & { tokenID: TokenID };

interface PlaceBidActionOptions extends ModalOptionsProps<IPlaceBidFormData> {
  onDone?: () => void;
}

interface AuctionResultOptions extends ModalOptionsProps<IAuctionResultFormData> {
  onDone?: () => void;
}

interface AuctionBidWithdrawOptions extends ModalOptionsProps<IAuctionWithdrawBidFormData> {
  onDone?: () => void;
}

interface FactoryOverride {
  library?: Provider;
}

interface UseAuctionProps {
  nftAddress: string;
}

const asDate = (date: Date | null | string | number): Date => {
  // eslint-disable-next-line valid-typeof
  if (date instanceof Date) {
    return date;
  }

  return new Date(date || '');
};

export const formValidator = (values: AuctionFields) => {
  const errors: FormikErrors<AuctionFields> = {};

  if (!values.reservePrice || '') {
    errors.reservePrice = 'Please enter a reserve price';
  }

  if (!values.startTime || null) {
    errors.startTime = 'Please enter a start time';
  }

  if (!values.endTime || null) {
    errors.endTime = 'Please enter a end time';
  }

  if (asDate(values.startTime) > asDate(values.endTime)) {
    errors.endTime = 'End time must be greater than start time';
  }

  return Promise.resolve(errors);
};

export default ({ nftAddress }: UseAuctionProps) => {
  const { account, library, payment } = useWeb3Application();
  const { loadContract } = useScContracts();
  const { throwError } = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { AUCTION_ADDRESS } = useAddresses();
  const { supportedMap: currencies } = usePayment();
  const [triggerTokenRemoval] = useNotifiyTokenRemovalMutation();
  const [triggerTokenUpdate] = useNotifiyTokenUpdateMutation();
  const { ensureApproval } = useApproval();
  const { updateProgress, openModal } = useModal();
  const ctx = useApprovalContextFor(AUCTION_ADDRESS, nftAddress);

  const getAuctionNftObject = useCallback(
    (options?: FactoryOverride) => {
      // create auction contract instance
      const NftFactory = new AuctionNftFactory();
      return NftFactory.create(
        {
          nftAddress,
          type: '721',
          auctionContract: AUCTION_ADDRESS,
          account,
        },
        {
          loadContract,
          library: options?.library || library,
        }
      );
    },
    [library, nftAddress, AUCTION_ADDRESS]
  );

  const getAuction = useCallback(
    // @todo [tkid]
    async (tokenId: TokenID): Promise<IAuction> => {
      if (!library) {
        return Promise.resolve(null);
      }

      const Nft = getAuctionNftObject();

      return Nft.getAuction(nftAddress, tokenId);
    },
    [library, nftAddress]
  );

  const isItemOnAuction = (item: INFTArt): boolean => typeof item.enteredAuction !== 'undefined' && item.enteredAuction !== null;

  const isItemOnAuctionOnSc = useCallback(
    async (tokenID: TokenID): Promise<boolean> => {
      if (!library) {
        return Promise.resolve(false);
      }

      const auction = await getAuction(tokenID);

      return !!auction && !!auction.endTime;
    },
    [library, nftAddress]
  );

  const processAuctionTask = async (values: AuctionFields) => {
    const Nft = getAuctionNftObject();

    Nft.on(events.AUCTION_CREATE_START, () => updateProgress('Start auction', 10));
    Nft.on(events.AUCTION_CREATE_WAIT_TX, () => updateProgress('Waiting for TX to validate', 50));
    Nft.on(events.AUCTION_CREATE_TX_CONFIRMED, (tx) => updateProgress(`'Tx confirmed (${tx})`, 80));
    Nft.on(events.AUCTION_CREATE_DONE, () => {
      enqueueSnackbar('Auction started', {
        variant: 'success',
      });
      updateProgress('Tx done!', 100);
      triggerTokenUpdate({ contractAddress: nftAddress, tokenID: values.tokenId });
    });
    Nft.on(events.PROCESS_ERRORED, (e) => {
      enqueueSnackbar(`There was an error: ${e.reason || e.message}`, {
        variant: 'error',
      });
    });

    await Nft.createAuction({ ...values, nftAddress } as ICreateAuctionParams);
    Nft.flush();
  };

  const handleAuctionModal = (item: INFTArt, Component: ComponentType, optionalActions?: IOptionalActions) => (): void => {
    openModal<AuctionFields>({
      withProgress: true,
      Component,
      okLabel: 'Start',
      formValidator,
      onOk: async (values) => {
        if (typeof optionalActions?.onStart === 'function') {
          optionalActions?.onStart();
        }
        await processAuctionTask(values);
        if (typeof optionalActions?.onDone === 'function') {
          optionalActions?.onDone();
        }
      },
      maxWidth: 'xs',
      formData: {
        reservePrice: item.price || 0,
        startTime: new Date(new Date().getTime() + 2 * 60 * 1000),
        endTime: new Date(item.saleEndsAt || '0'),
        minBidReserve: true,
        tokenId: item.tokenID,
        tokenURI: item.tokenURI,
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAuctionBtnClick = async (item: INFTArt, next: any): Promise<void> => {
    await ensureApproval(
      await (new Contract(
        item.contractAddress || nftAddress,
        ABIS.SINGLE_NFT_ABI,
        library
      )).isApprovedForAll(account, AUCTION_ADDRESS),
      async () => ctx.contract.approve(nftAddress),
      (err: Error) => {
        throw new Error(`Auction contract approval failed, ${err.message}`);
      },
      () => {
        next?.call(null, item);
      }
    );
  };

  const processUpdateAuctionTask = async (values, updatedValues) => {
    const Nft = getAuctionNftObject();

    Nft.on(events.AUCTION_UPDATE_START, () => {
      updateProgress('Processing update auction...', 10);
    });
    Nft.on(events.AUCTION_UPDATE_WAIT_TX, () => updateProgress('Waiting for TX to validate', 50));
    Nft.on(events.AUCTION_UPDATE_TX_CONFIRMED, () => updateProgress('Tx confirmed', 80));
    Nft.on(events.AUCTION_UPDATE_DONE, () => {
      enqueueSnackbar('Auction updated', {
        variant: 'success',
      });
      updateProgress('Auction updated successfully', 100);
      triggerTokenUpdate({ contractAddress: nftAddress, tokenID: values.tokenId });
    });
    Nft.on(events.PROCESS_ERRORED, (e) => {
      enqueueSnackbar(`There was an error: ${e.message}`, {
        variant: 'error',
      });
    });

    await Nft.updateAuction(values, updatedValues);

    Nft.flush();
  };

  const handleCancelAuction = async (tokenId: TokenID) => {
    const Nft = getAuctionNftObject();

    Nft.on(events.AUCTION_CANCEL_START, () => updateProgress('Processing auction cancellation ...', 10));
    Nft.on(events.AUCTION_CANCEL_WAIT_TX, () => updateProgress('Waiting for TX to validate', 50));
    Nft.on(events.AUCTION_CANCEL_TX_CONFIRMED, (tx) => updateProgress(`Tx confirmed (${tx})`, 90));
    Nft.on(events.AUCTION_CANCEL_DONE, ({ contractAddress }) => {
      updateProgress('Auction cancelled successfully', 100);
      enqueueSnackbar('Auction cancelled', {
        variant: 'success',
      });
      triggerTokenRemoval({ ...tokenId.toJSON(), contractAddress });
    });

    Nft.on(events.PROCESS_ERRORED, (e) => {
      enqueueSnackbar(`There was an error: ${e.message}`, {
        variant: 'error',
      });
    });

    await Nft.cancelAuction(tokenId);
    Nft.flush();
  };

  const handleEditAuctionModal = (item: INFTArt, Component: ComponentType, optionalActions?: IOptionalActions) => (): void => {
    openModal<AuctionFields>({
      withProgress: true,
      maxWidth: 'xs',
      formData: {
        reservePrice: item.price,
        startTime: item.enteredAuction ? new Date(item.enteredAuction) : new Date(new Date().getTime() + 2 * 60 * 1000),
        endTime: new Date(item.saleEndsAt || '0'),
        minBidReserve: true,
        tokenId: item.tokenID,
        tokenURI: item.tokenURI,
        payToken: item.paymentToken,
      },
      formValidator,
      Component,
      okLabel: 'Save',
      onOk: async (values) => {
        if (typeof optionalActions?.onStart === 'function') {
          optionalActions.onStart();
        }

        const {
          reservePrice,
          // startTime,
          endTime,
        } = values;
        const updatedValues = [];
        if (reservePrice !== item.price) {
          updatedValues.push('reservePrice');
        }

        if (Math.round(new Date(endTime).getTime() / 1000) !== Math.round(new Date(item.saleEndsAt).getTime() / 1000)) {
          updatedValues.push('endTime');
        }
        if (updatedValues.length > 0) {
          await processUpdateAuctionTask(values, updatedValues);

          if (typeof optionalActions?.onDone === 'function') {
            optionalActions.onDone();
          }

          triggerTokenRemoval({ ...item.tokenID?.toJSON(), contractAddress: item.contractAddress });
        }
      },
      additionalActions: (
        <>
          <RemoveButton
            onClick={async () => {
              await handleCancelAuction(item.tokenID);
            }}
          />
          <Box sx={{ flex: 1 }} />
        </>
      ),
    });
  };

  const handlePlaceBid = useCallback(
    (auction: IAuction, { onDone, ...options }: PlaceBidActionOptions) => async () => {
      let minBid = 0;

      if (auction.highestBid && auction.highestBid?.bidder === ADDRESS.ZERO) {
        // no bid placed yet, we will use Reserve Price as min bid
        minBid = Number(auction.reservePrice);
      } else if ((auction.highestBid?.bidder || ADDRESS.ZERO) !== ADDRESS.ZERO) {
        // auction has a bid
        minBid = Number(auction.highestBid?.bid);
      }

      openModal({
        ...options,
        withProgress: true,
        title: 'Place Bid',
        okLabel: 'Bid',
        maxWidth: 'xs',
        formData: {
          ...(options.formData || {}),
          payToken: auction.payToken?.toLowerCase(),
          reservePrice: Number(auction.reservePrice),
          minBid,
        } as IPlaceBidFormData,
        formValidator: (values: IPlaceBidFormData) => {
          const errors: FormikErrors<IPlaceBidFormData> = {};

          // we need to check here if the address have enough token to place the bid
          if (!values.swap && (!values.balance || values.balance < values.amount)) {
            errors.amount = 'insufficient balance';
          } else if (values.amount < values.minBid) {
            errors.amount = `amount should not be less than the reserve price or last bid value ${values.minBid}`;
          }

          return Promise.resolve(errors);
        },
        onOk: async (values: IPlaceBidFormData) => {
          updateProgress('Initializing...', 0);

          try {
            // 1.0 approve auction contract (one-time operation for each account)
            if (!ctx.status?.approved) {
              updateProgress('Approving Auction to interact with your account', 5);
              await ensureApproval(
                await (new Contract(nftAddress, ABIS.SINGLE_NFT_ABI, library)).isApprovedForAll(account, AUCTION_ADDRESS),
                async () => ctx.contract.approve(nftAddress),
                (err: Error) => {
                  throw new Error(`Auction contract approval failed, ${err.message}`);
                },
                () => {}
              );
            }

            const pc = payment?.useWrapped(values.payToken);

            // 1.1. swap if requested
            if (values.swap) {
              updateProgress('Swaping your token to fullfil the operation...', 7);
              await pc?.deposit(values.amount);
            }

            // . approve payToken contract
            if (auction.payToken !== ADDRESS.ZERO) {
              const pk = currencies[auction.payToken?.toLowerCase()];
              updateProgress(`Allowing contract to spend your ${pk?.symbol} token`, 10);
              await pc?.ensureAllowance(account, AUCTION_ADDRESS, values.amount);
            }

            // 3. place the bid
            const Nft = getAuctionNftObject();

            Nft.on(events.AUCTION_PLACE_BID_START, () => updateProgress('Placing bid...', 10));
            Nft.on(events.AUCTION_PLACE_BID_WAIT_TX, () => updateProgress('Waiting for TX to validate', 50));
            Nft.on(events.AUCTION_PLACE_BID_TX_CONFIRMED, () => updateProgress('Tx confirmed', 90));
            Nft.on(events.AUCTION_PLACE_BID_DONE, () => {
              enqueueSnackbar('Bid placed successfully', {
                variant: 'success',
              });

              updateProgress('Bid placed successfully', 100);

              if (onDone) {
                onDone();
              }
            });

            await Nft.placeBid(values);
            Nft.flush();
          } catch (e) {
            throwError(e);
          }
        },
      });
    },
    [library, payment, account]
  );

  const handleAuctionResult = useCallback(
    (auction: IAuction, { onDone, ...options }: AuctionResultOptions) => async () => {
      openModal({
        ...options,
        withProgress: true,
        centerActions: true,
        title: 'Auction Ended, claim result',
        okLabel: 'Yes, Accept',
        maxWidth: 'xs',
        formData: {
          ...(options.formData || {}),
          payToken: auction.payToken,
        } as IAuctionResultFormData,
        onOk: async (values: IAuctionResultFormData) => {
          updateProgress('Initializing...', 0);

          try {
            const Nft = getAuctionNftObject();

            Nft.on(events.AUCTION_RESULT_START, () => updateProgress('Accepting highest bid...', 10));
            Nft.on(events.AUCTION_RESULT_WAIT_TX, () => updateProgress('Waiting for TX to validate', 50));
            Nft.on(events.AUCTION_RESULT_TX_CONFIRMED, () => updateProgress('Tx confirmed', 90));
            Nft.on(events.AUCTION_RESULT_DONE, ({ tokenId, contractAddress }) => {
              enqueueSnackbar('Auction result successfully claimed', {
                variant: 'success',
              });

              if (onDone) {
                onDone();
              }

              triggerTokenRemoval({ ...tokenId.toJSON(), contractAddress });
            });

            await Nft.resultAuction(values);
            Nft.flush();
          } catch (e) {
            throwError(e);
          }
        },
      });
    },
    [library, payment]
  );

  const handleBidWithdraw = useCallback(
    (auction: IAuction, { onDone, ...options }: AuctionBidWithdrawOptions) => async () => {
      openModal({
        ...options,
        withProgress: true,
        centerActions: true,
        title: 'Withdraw bid',
        okLabel: 'Withdraw',
        maxWidth: 'xs',
        formData: {
          ...(options.formData || {}),
          amount: auction.highestBid.bid,
          payToken: auction.payToken,
        } as IAuctionWithdrawBidFormData,
        onOk: async (values: IAuctionWithdrawBidFormData) => {
          updateProgress('Initializing...', 0);

          const Nft = getAuctionNftObject();

          Nft.on(events.AUCTION_WITHDRAW_BID_START, () => updateProgress('Withdrawing bid...', 10));
          Nft.on(events.AUCTION_WITHDRAW_BID_WAIT_TX, () => updateProgress('Waiting for TX to validate', 50));
          Nft.on(events.AUCTION_WITHDRAW_BID_TX_CONFIRMED, () => updateProgress('Tx confirmed', 90));
          Nft.on(events.AUCTION_WITHDRAW_BID_DONE, () => {
            enqueueSnackbar('Bid successfully withdrawn', {
              variant: 'success',
            });

            if (onDone) {
              onDone();
            }
          });

          await Nft.withdrawBid(values);
          Nft.flush();
        },
      });
    },
    [library, payment]
  );

  return {
    auctionContractApproved: ctx.status?.approved,
    onApprovingAuctionAdr: ctx.status?.approving,
    isCheckingAuctionApprovals: ctx.status?.checking,
    checkAuctionNftApproval: ctx.contract?.isApproved,
    handleAuctionBtnClick,
    processAuctionTask,
    handleAuctionModal,
    handleEditAuctionModal,
    handlePlaceBid,
    handleAuctionResult,
    handleCancelAuction,
    handleBidWithdraw,
    isItemOnAuction,
    isItemOnAuctionOnSc,
    getAuction,
  };
};
