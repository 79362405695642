import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import {
  InputAdornment,
  FormControl,
  MenuList as MuiMenuList,
  Popover,
  Box,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  InputLabel as MuiInputLabel,
  IconButton as MuiIconButton,
  Button as MuiButton,
  ClickAwayListener,
  Divider,
  List,
  ListItem as MuiListItem,
  ListItemButton,
  ListItemText as MuiListItemText,
  ListItemIcon,
  SxProps,
  Tooltip,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { SelectProps } from '@mui/material/Select';
import { InputLabelProps } from '@mui/material/InputLabel';
import { IconButtonProps } from '@mui/material/IconButton';
import MuiOutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HelpIcon from '@mui/icons-material/HelpOutlineRounded';
import DotIcon from '@mui/icons-material/FiberManualRecord';
import CheckIcon from '@mui/icons-material/Check';
import SelectContext from 'src/contexts/InputContext';

const InputLabel = styled(MuiInputLabel, {
  shouldForwardProp: (prop: string) => prop !== 'size',
})<InputLabelProps & { size?: OutlinedInputProps['size'] }>(({ theme, size }) => ({
  ...(size === 'small' && {
    fontSize: '0.9rem!important',
    '&.MuiInputLabel-shrink': {
      top: 2,
    },
  }),
}));

const IconButton = styled(MuiIconButton)<IconButtonProps>(({ theme, size }) => ({
  ...(size === 'small' && {
    right: theme.spacing(-1),
    display: 'flex',
  }),
}));

export const ListItemText = styled(MuiListItemText)({
  '& .MuiListItemText-primary': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const MenuList = styled(MuiMenuList)({
  '&::-webkit-scrollbar': {
    width: 1,
  },
  '&::-webkit-track': {
    backgroundColor: 'rgba(0,0,0,.6)',
    WebkitBoxShadow: '0 0 6px rbga(0,0,0,0.3)',
    borderRadius: 8,
  },
  '&::-webkit-track-piece': {},
  '&::-webkit-track-thumb': {
    borderRadius: 8,
    backgroundColor: '#c4c4c4',
    WebkitBoxShadow: '0 0 6px rbga(0,0,0,0.6)',
    outline: '1px solid slategrey',
  },
});

const commonInputStyles = (theme: Theme) => ({
  '& .MuiInputBase-inputAdornedStart': {
    paddingLeft: theme.spacing(1),
  },
});

const OutlinedInput = styled(MuiOutlinedInput, {
  shouldForwardProp: (prop: string) => prop !== 'rounded',
})<OutlinedInputProps & { rounded?: boolean }>(({ theme, rounded, size }) => ({
  ...commonInputStyles(theme),
  ...(rounded && {
    borderRadius: theme.spacing(10),
  }),
  ...(size === 'small' && {
    fontSize: '0.85rem',
  }),
}));

const Select = styled(MuiSelect, {
  shouldForwardProp: (prop: string) => prop !== 'rounded',
})<SelectProps & { rounded?: boolean }>(({ theme, rounded, size }) => ({
  ...commonInputStyles(theme),
  ...(rounded && {
    borderRadius: theme.spacing(10),
  }),
  ...(size === 'small' && {
    fontSize: '0.85rem',
  }),
}));

const Button = styled(MuiButton)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  padding: theme.spacing(1, 3),
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .MuiListItemIcon-root': {
    justifyContent: 'flex-end',
  },
  padding: theme.spacing(1, 4, 1, 3),
}));

const ListItem = styled(MuiListItem)(({ theme }) => ({
  padding: theme.spacing(0, 0),
  '& .MuiListItemButton-root': {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    '&:hover': {
      background: 'transparent',
    },
    '&.MuiSelected': {
      '& .MuiListItemText-root': {
        color: theme.palette.primary.main,
        '& *': {
          fontWeight: 500,
        },
      },
      '& .MuiListItemIcon-root': {
        '& .UiDotMarker, & > .MuiSvgIcon-root': {
          fill: theme.palette.primary.main,
        },
      },
    },
    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        fontSize: '0.9rem',
      },
    },
    '& .MuiListItemIcon-root': {
      justifyContent: 'flex-end',
      '& .UiDotMarker': {
        fill: theme.palette.grey[400],
        fontSize: 16,
      },
      '&.Uikit-Icon-before': {
        minWidth: 'inherit',
        marginRight: theme.spacing(1),
      },
    },
  },
}));

const FlatListItem = styled(MuiListItem)(({ theme }) => ({
  padding: theme.spacing(0, 0),
  borderRadius: Number(theme.shape.borderRadius) * 2.5,
  '& .MuiListItemButton-root': {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    '&:hover': {
      background: 'transparent',
    },
    '&.MuiSelected': {
      '& .FlatMuiListItemIcon-root': {
        '& .UiDotMarker, & > .MuiSvgIcon-root': {
          fill: theme.palette.primary.main,
        },
      },
      backgroundColor: theme.palette.background.paper,
      borderRadius: Number(theme.shape.borderRadius) * 2.5,
    },
    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        fontSize: '0.9rem',
      },
    },
    '& .FlatMuiListItemIcon-root': {
      justifyContent: 'flex-end',
      '& .UiDotMarker': {
        fill: theme.palette.grey[400],
        fontSize: 16,
      },
      '&.Uikit-Icon-before': {
        minWidth: 'inherit',
        marginRight: theme.spacing(1),
      },
    },
  },
  '&:hover, &.MuiSelected:hover': {
    borderRadius: Number(theme.shape.borderRadius) * 2.5,
    backgroundColor: theme.palette.background.paper,
  },
}));

export interface SelectOption<T> {
  label: string;
  value: T;
  disabled?: boolean;
  helpText?: string;
  Icon?: React.ComponentType;
}

interface SimpleSelectProps<T> extends SelectProps {
  options: SelectOption<T>[];
  fullWidth?: boolean;
  rounded?: boolean;
  formControlSx?: SxProps;
}

const SimpleSelect = <T extends string>({
  options,
  label,
  fullWidth,
  rounded,
  MenuProps,
  formControlSx,
  ...props
}: SimpleSelectProps<T>) => (
  <SelectContext.Provider value={{ value: props.value }}>
    <FormControl fullWidth={fullWidth} sx={{ m: 1, ...formControlSx }}>
      {label && <InputLabel size={props.size || 'medium'}>{label}</InputLabel>}

      <Select
        label={label}
        renderValue={(value: T) => (
          <span className="CuiOutlined-value">{(options || []).filter((o) => o.value === value).shift()?.label}</span>
        )}
        rounded={rounded}
        {...(props as SelectProps)}
        MenuProps={{
          disablePortal: true,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          transformOrigin: { vertical: 'top', horizontal: 'right' },
          ...MenuProps,
        }}
        fullWidth={fullWidth}
      >
        {options.map(({ value: oValue, label: oLabel }: SelectOption<T>, index: number) => (
          <MenuItem key={`${oValue}.${index + 1}`} value={String(oValue)}>
            {oLabel}
            {oValue === props.value && (
              <ListItemIcon>
                <CheckIcon color="primary" />
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </SelectContext.Provider>
);

interface PopperSelectProps<T> extends Omit<OutlinedInputProps, 'onChange'> {
  options: SelectOption<T>[];
  fullWidth?: boolean;
  onChange?: (e: React.MouseEvent<HTMLElement>, value: SelectOption<T>) => void;
  renderOption?: (option: SelectOption<T>) => React.ReactNode;
  renderValue?: (value: T) => React.ReactNode;
  rounded?: boolean;
}

const PopperSelect = <T = string>({
  children,
  options,
  fullWidth,
  value,
  label,
  readOnly,
  disabled,
  onChange,
  renderOption,
  renderValue,
  rounded,
  ...props
}: React.PropsWithChildren<PopperSelectProps<T>>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [paperWidth, setPaperWidth] = React.useState<number>(0);
  const handleTogglePopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  React.useEffect(() => {
    if (anchorEl) {
      setPaperWidth(anchorEl.clientWidth);
    }
  }, [anchorEl]);

  return (
    <SelectContext.Provider value={{ value }}>
      <FormControl fullWidth={fullWidth} sx={{ m: 1 }}>
        {label && <InputLabel size={props.size || 'medium'}>{label}</InputLabel>}
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Box>
            <OutlinedInput
              type="text"
              readOnly
              onClick={handleTogglePopper}
              rounded={rounded}
              value={(options || []).filter((o: SelectOption<T>) => o.value === value).shift()?.label || 'None'}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    color="inherit"
                    edge="end"
                    disableFocusRipple
                    disableRipple
                    sx={{
                      bgcolor: 'transparent',
                    }}
                    size={props.size || 'medium'}
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </InputAdornment>
              )}
              label={label}
              inputProps={{
                style: {
                  cursor: 'pointer',
                },
              }}
              disabled={disabled}
              fullWidth={fullWidth}
              {...props}
            />
            <Popover
              open={readOnly || disabled ? false : Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              disablePortal={false}
            >
              <MenuList
                sx={{
                  py: 1,
                  // bgcolor: 'background.default',
                  ...(paperWidth && {
                    minWidth: paperWidth,
                  }),
                }}
              >
                {options.map((o: SelectOption<T>, index: number) => (
                  <MenuItem
                    key={`${o.value}.${index + 1}`}
                    value={String(o.value)}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      onChange?.(e, o);
                      setAnchorEl(null);
                    }}
                  >
                    {renderOption ? renderOption(o) : o.label}
                    {o.value === value && (
                      <ListItemIcon>
                        <CheckIcon color="primary" />
                      </ListItemIcon>
                    )}
                  </MenuItem>
                ))}
              </MenuList>

              {children}
            </Popover>
          </Box>
        </ClickAwayListener>
      </FormControl>
    </SelectContext.Provider>
  );
};

interface MultipleSelectProps<T> extends Omit<OutlinedInputProps, 'onChange' | 'value'> {
  options: SelectOption<T>[];
  fullWidth?: boolean;
  rounded?: boolean;
  maxHeight?: CSSProperties['maxHeight'];
  value?: T[];
  defaultValue?: T[];
  onChange?: (e: React.MouseEvent<HTMLElement> | null, value: T[]) => void;
  applyChangeOnBlur?: boolean;
  disableRules?: (option: SelectOption<T>, value: T[]) => boolean;
  nextValueResolver?: (prevValue: T[], selected: SelectOption<T>) => T[];
  renderOption?: (option: SelectOption<T>) => React.ReactNode;
}

const MultipleSelect = <T extends string | number>({
  children,
  options,
  fullWidth,
  rounded,
  value,
  label,
  applyChangeOnBlur,
  defaultValue,
  disableRules,
  onChange,
  maxHeight,
  readOnly,
  disabled,
  renderOption,
  nextValueResolver,
  ...props
}: React.PropsWithChildren<MultipleSelectProps<T>>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [paperWidth, setPaperWidth] = React.useState<number>(0);
  const [innerValue, setValue] = React.useState<T[]>(value || defaultValue || []);
  const handleTogglePopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  React.useEffect(() => {
    setValue(value);
  }, [value]);

  React.useEffect(() => {
    if (anchorEl) {
      setPaperWidth(anchorEl.clientWidth);
    }
  }, [anchorEl]);

  // Attention, we only keep .value, without others defined properties in options
  // (not like on PopperSelect)
  const handleChange = React.useCallback((_, o: SelectOption<T>) => {
    if (nextValueResolver) {
      setValue(
        nextValueResolver(innerValue, o)
      );
    } else {
      // check if selected value is among current
      // if so, we will remove it
      // otherwise, we should add it
      const indexOfCurrent = innerValue.indexOf(o.value);
      if (indexOfCurrent < 0) {
        setValue((prev) => [...prev, o.value]);
      } else {
        setValue((prev) => prev.filter((v) => v !== o.value));
      }
    }
  }, [innerValue]);

  return (
    <SelectContext.Provider value={{ value: innerValue, setValue }}>
      <FormControl fullWidth={fullWidth} sx={{ m: 1 }}>
        {label && innerValue.length > 0 && <InputLabel size={props.size || 'medium'}>{label}</InputLabel>}
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null);
            if (applyChangeOnBlur) {
              onChange?.(null, innerValue);
            }
          }}
        >
          <Box>
            <OutlinedInput
              type="text"
              readOnly
              onClick={handleTogglePopper}
              rounded={rounded}
              value={
                innerValue.length === 0
                  ? label
                  : (options || [])
                    .filter((o: SelectOption<T>) => innerValue.includes(o.value))
                    .map((o: SelectOption<T>) => o.label)
                    .join(', ')
              }
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    color="inherit"
                    edge="end"
                    disableFocusRipple
                    disableRipple
                    sx={{
                      bgcolor: 'transparent',
                    }}
                    size={props.size || 'medium'}
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </InputAdornment>
              )}
              {...(label &&
                innerValue.length > 0 && {
                label,
              })}
              inputProps={{
                style: {
                  cursor: 'pointer',
                },
              }}
              disabled={disabled}
              fullWidth={fullWidth}
              {...props}
            />
            <Popover
              open={readOnly || disabled ? false : Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              disablePortal={false}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <MenuList
                sx={{
                  py: 1,
                  // bgcolor: 'background.default',
                  ...(paperWidth && {
                    minWidth: paperWidth,
                  }),
                  ...(maxHeight && {
                    maxHeight,
                    overflowY: 'auto',
                  }),
                }}
              >
                {options.map((o: SelectOption<T>, index: number) => (
                  <MenuItem
                    key={`${o.value}.${index + 1}`}
                    value={o.value}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      handleChange(e, o);
                    }}
                    disabled={o.disabled || disableRules(o, innerValue)}
                  >
                    {renderOption ? renderOption(o) : o.label}
                    {innerValue.includes(o.value) && (
                      <ListItemIcon>
                        <CheckIcon color="primary" />
                      </ListItemIcon>
                    )}
                  </MenuItem>
                ))}
              </MenuList>

              <Box sx={{ flexGrow: 1 }}>
                {children}

                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', p: 1 }}>
                  {typeof defaultValue !== 'undefined' && (
                    <Button
                      variant="text"
                      onClick={() => {
                        onChange?.(null, defaultValue);
                        setAnchorEl(null);
                      }}
                    >
                      Clear
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    onClick={() => {
                      onChange?.(null, innerValue);
                      setAnchorEl(null);
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </Popover>
          </Box>
        </ClickAwayListener>
      </FormControl>
    </SelectContext.Provider>
  );
};

MultipleSelect.defaultProps = {
  disableRules: () => false,
};

interface FlatSelectProps<T> extends Omit<OutlinedInputProps, 'onChange' | 'value' | 'unselectable'> {
  options: SelectOption<T>[];
  value?: T;
  onChange?: (e: React.MouseEvent<HTMLElement> | null, value: SelectOption<T>) => void;
  renderOption?: (option: SelectOption<T>) => React.ReactNode;
  sx?: SxProps;
  unselectable?: boolean;
}

export const FlatSelect = <T = string>({
  children,
  value,
  options,
  renderOption,
  onChange,
  sx,
  unselectable,
}: React.PropsWithChildren<FlatSelectProps<T>>) => {
  const handleChange = React.useCallback(
    (e: React.MouseEvent<HTMLElement>, o: SelectOption<T>) => {
      if (value !== o.value) {
        // only dispatch change if previous value is different from selected one
        onChange?.(e, o);
      } else if (unselectable) {
        onChange?.(e, { value: undefined, label: '' });
      }
    },
    [value]
  );

  return (
    <SelectContext.Provider value={{ value }}>
      <List sx={{ py: 0, ...sx }}>
        {options.map(({ Icon, ...o }, i) => (
          <FlatListItem key={`$opt-smp-${i + 1}`}>
            <ListItemButton
              {...{
                ...((value !== o.value || unselectable) && {
                  onClick: (e: React.MouseEvent<HTMLElement>) => {
                    handleChange(e, o);
                  },
                }),
              }}
              className={classNames({ MuiSelected: value === o.value })}
              disabled={o.disabled}
              disableTouchRipple
            >
              {
                Icon ? (
                  <ListItemIcon
                    sx={{
                      display: 'flex',
                      justifyContent: 'center !important',
                    }}
                  >
                    <Icon />
                  </ListItemIcon>
                ) : (
                  <Box sx={{
                    width: '1rem',
                  }}
                  />
                )
              }
              <ListItemText>
                {renderOption ? renderOption(o) : o.label}
                {o.helpText && (
                  <Tooltip title={o.helpText} placement="right">
                    <HelpIcon sx={{ fontSize: 18, ml: 0.5 }} />
                  </Tooltip>
                )}
              </ListItemText>
              <ListItemIcon
                className="FlatMuiListItemIcon-root"
                sx={{ pr: 1 }}
              >
                <DotIcon className="UiDotMarker" />
              </ListItemIcon>
            </ListItemButton>
          </FlatListItem>
        ))}
      </List>
      {children}
    </SelectContext.Provider>
  );
};

interface FlatSelectMultipleProps<T> extends Omit<OutlinedInputProps, 'onChange' | 'value'> {
  options: SelectOption<T>[];
  value?: T[];
  onChange?: (e: React.MouseEvent<HTMLElement> | null, value: T[]) => void;
  disableRules?: (option: SelectOption<T>, value: T[]) => boolean;
  renderOption?: (option: SelectOption<T>) => React.ReactNode;
  sx?: SxProps;
}

export const FlatSelectMultiple = <T = string>({
  children,
  value,
  options,
  renderOption,
  disableRules,
  onChange,
  sx,
}: React.PropsWithChildren<FlatSelectMultipleProps<T>>) => {
  const handleChange = React.useCallback(
    (e: React.MouseEvent<HTMLElement>, o: SelectOption<T>) => {
      // check if selected value is among current
      // if so, we will remove it
      // otherwise, we should add it
      const indexOfCurrent = (value || []).indexOf(o.value);
      if (indexOfCurrent < 0) {
        onChange?.(e, [...value, o.value]);
      } else {
        onChange?.(
          e,
          (value || []).filter((v) => v !== o.value)
        );
      }
    },
    [value]
  );

  return (
    <SelectContext.Provider value={{ value }}>
      <List sx={{ py: 0, ...sx }}>
        {options.map(({ Icon, ...o }, i) => (
          <ListItem key={`$opt-${i + 1}`}>
            <ListItemButton
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                handleChange(e, o);
              }}
              className={classNames({ MuiSelected: value.includes(o.value) })}
              disabled={o.disabled || disableRules?.(o, value) || false}
              disableTouchRipple
            >
              {Icon && (
                <ListItemIcon className="Uikit-Icon-before">
                  <Icon />
                </ListItemIcon>
              )}
              <ListItemText>
                {renderOption ? renderOption(o) : o.label}
                {o.helpText && (
                  <Tooltip title={o.helpText} placement="right">
                    <HelpIcon sx={{ fontSize: 18, ml: 0.5 }} />
                  </Tooltip>
                )}
              </ListItemText>
              <ListItemIcon>
                <DotIcon className="UiDotMarker" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {children}
    </SelectContext.Provider>
  );
};

export default {
  Simple: React.memo(SimpleSelect),
  Popper: React.memo(PopperSelect),
  Multiple: React.memo(MultipleSelect),
  Flat: React.memo(FlatSelect),
  FlatMultiple: React.memo(FlatSelectMultiple),
  Consumer: SelectContext.Consumer,
};
