import React, { useContext, useEffect } from 'react';
import Context, { ApprovalFns, ApprovalState } from '../contexts/ContractApprovalContext';

interface ContextForParams {
  status: ApprovalState;
  contract: ApprovalFns;
  isApprovedInContext: (opAddress: string, nftAddress: string) => ApprovalState;
}

export const useApprovalContextFor = (
  checkedContractAddress: string,
  address: string
): ContextForParams => {
  const ctx = useContext(Context);
  const loweredAddr = address?.toLowerCase();

  useEffect(() => {
    if (address && checkedContractAddress) {
      if (!ctx.contracts[checkedContractAddress][loweredAddr] && address) {
        ctx.addAddressForApproval(address.toLowerCase());
      }
    }
  }, [checkedContractAddress, address]);

  const isApprovedInContext = React.useCallback(
    (opAddress: string, nftAddress: string) => ctx.status[opAddress?.toLowerCase()][nftAddress],
    [ctx]
  );

  return {
    status: ctx.status[checkedContractAddress][loweredAddr],
    contract: ctx.contracts[checkedContractAddress],
    isApprovedInContext,
  };
};
