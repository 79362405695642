import React from 'react';
import {
  Box, IconButton, Popover,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { useWeb3React } from '@web3-react/core';
import NetworkSelector from './NetworkSelector';
import { useNetworkProvisioner } from './hooks';

const NetworkIndicatorContainer = styled(Box, {
  name: 'NetworkIndicatorRoot',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    marginRight: `${theme.spacing(0)}!important`,
    marginLeft: `${theme.spacing(2.25)}!important`,
    '& + .MuiBox-root': {
      marginLeft: 0,
    },
  },
}));

const NetworkIndicator = () => {
  const { switchNetwork, network, Logo } = useNetworkProvisioner();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { chainId, active, account, library } = useWeb3React();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectChainId = React.useCallback(
    (newChainId: number) => async () => {
      if (chainId !== newChainId) {
        await switchNetwork(newChainId, library);
      }
      handleClose();
    },
    [chainId, library]
  );

  const id = anchorEl ? 'simple-popover' : undefined;

  if (!active || !account || !network) {
    return null;
  }

  return (
    <NetworkIndicatorContainer>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <Logo
          sx={{
            color: (t: Theme) => (t.palette.mode === 'light' ? '#444' : '#EEE'),
            width: 20,
            height: 20,
          }}
        />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        id={id}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            mt: 1.25,
            px: 1,
            py: 0.5,
          },
        }}
      >
        <NetworkSelector handleChange={handleSelectChainId} />
      </Popover>
    </NetworkIndicatorContainer>
  );
};

export default NetworkIndicator;
