/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import { downloadImage, imageToBase64 } from 'src/utils';
import { aiApi } from 'src/api';
import type { AIResultHandlers, BuilderParams } from './types';

export default class HiveHandlers implements AIResultHandlers {
  constructor(protected params: BuilderParams) {}

  onCarouselMove(now?: number, prev?: number) {
    this.params.navigate?.(`/ai/flint-ai-district/hive#${this.params.hiveFiles[now]?.path}`);
  }

  async onMint(): Promise<void> {
    const fileName = `hivefile-${this.params.hiveFiles[this.params.currentIndex]?.path.split('/').pop()}.png`;
    this.params.navigate?.(`/home/assets/mint?image=${encodeURIComponent(this.params.currentView)}&name=${fileName}`);
  }

  async onUpscale(): Promise<void> {
    this.params.wrapProcess('upscaling', async () => {
      console.log(`Upscaling ${this.params.currentView}...`);
      const response = await aiApi.upscalePredict(
        await imageToBase64(
          new Blob(
            [this.params.hiveFiles[this.params.currentIndex].content.buffer], { type: 'image/png' }
          )
        ) as string,
        await this.params.getClient()
      );
      if (response.status === 'succeeded') {
        await downloadImage(response.output, `upscale-8x-${response.id}.png`);
      }
    });
  }

  handleDownload(index: number) {
    return async () => {
      const fileName = `hivefile-${this.params.hiveFiles[index]?.path.split('/').pop()}.png`;
      this.processDownload(fileName);
    };
  }

  async onDownload() {
    this.processDownload();
  }

  private async processDownload(fileName?: string) {
    this.params.wrapProcess('downloading', async () => {
      console.log(`Downloading ${this.params.currentView}...`);
      await downloadImage(this.params.currentView, fileName || 'hive-file.png');
    });
  }

  handleRemove(index: number) {
    return async () => {
      await this.processRemove(this.params.hiveFiles[index]?.path);
    };
  }

  private async processRemove(fileName?: string) {
    this.params.wrapProcess('removing', async () => {
      try {
        console.log(`Removing ${this.params}, ${fileName}...`);
        return this.params.removeHiveFile?.(fileName);
      } catch (e) {
        console.error(e);
        return Promise.resolve();
      }
    });
  }
}
