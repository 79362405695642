// import { Icon } from '@iconify/react';
import React, {
  useRef, useState, useEffect,
} from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
// import homeFill from '@iconify/icons-eva/home-fill';
// import personFill from '@iconify/icons-eva/person-fill';
// import helpOutline from '@iconify/icons-eva/book-open-outline';
import { alpha } from '@mui/material/styles';
import {
  Button, Box, Divider, MenuItem, Typography, IconButton, /* Link, */
} from '@mui/material';
// import TwitterLogo from '@mui/icons-material/Twitter';
// import TelegramLogo from '@mui/icons-material/Telegram';
// import { DiscordLogo } from 'src/assets/others';
// import { links } from 'src/config';
import Avatar from 'src/components/@ui/Avatar';
import MenuPopover from 'src/components/@ui/MenuPopover';
import { RouterLink } from 'src/components/Link';
import CopyCapsule from 'src/components/CopyCapsule';
import useBellaOwner from 'src/hooks/useBellaOwner';
import WalletSummary from '../profile/WalletSummary';
import { ConnectorSelect, displayAddress } from '../../lib/web3';
import useAppSettings from '../../hooks/useAppSettings';
import useProfile from '../../hooks/useProfile';
import AffiliateDialog from '../AffiliateDialog';

// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     icon: homeFill,
//     linkTo: '/',
//   },
//   {
//     label: 'Profile',
//     icon: personFill,
//     linkTo: '/home/profile',
//   },
//   // {
//   //   label: 'Settings',
//   //   icon: settings2Fill,
//   //   linkTo: '/home/settings',
//   // },
//   {
//     label: 'Documentation',
//     icon: helpOutline,
//     linkTo: links.documentation,
//     external: true,
//     linkProps: {
//       target: '_blank',
//       rel: 'noopener noreferrer',
//     },
//   },
// ];

// ----------------------------------------------------------------------

interface Props {
  noMenu?: boolean;
  size?: number;
}

function AccountPopover({ noMenu, size }: Props) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const { setValues } = useAppSettings();
  const { profile, isAdmin } = useProfile();
  const { active, deactivate, account } = useWeb3React<Web3Provider>();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDisconnect = () => {
    window.localStorage.removeItem('walletconnect');
    window.localStorage.removeItem('activeconnectorname');
    setValues({ walletProvider: null });

    deactivate();
    setOpen(false);
  };

  useEffect(() => {
    if (!anchorRef.current) {
      setOpen(false);
    }
  }, [anchorRef.current]);

  return (
    <>
      {!active ? (
        <ConnectorSelect />
      ) : (
        <>
          <IconButton
            ref={anchorRef}
            onClick={handleOpen}
            sx={{
              padding: 0,
              width: size,
              height: size,
              ...(open && {
                '&:before': {
                  zIndex: 1,
                  content: '\'\'',
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  position: 'absolute',
                  bgcolor: (theme) => alpha(theme.palette.grey[800], 0.6),
                },
              }),
            }}
          >
            <Avatar
              src={profile?.image}
              alt={profile?.alias}
              sx={{ width: `calc(${size}px - 4px)`, height: `calc(${size}px - 4px)` }}
              imgProps={{ crossOrigin: 'anonymous' }}
            />
          </IconButton>

          {!noMenu && (
            <MenuPopover
              open={open && Boolean(anchorRef.current)}
              onClose={handleClose}
              anchorEl={anchorRef.current}
              sx={{ width: 260, mt: 0.5 }}
            >
              <Box sx={{ my: 1.5, px: 2.5 }}>
                <Typography
                  variant="subtitle1"
                  noWrap
                  fontWeight={500}
                  component={RouterLink}
                  to="/home/profile"
                  sx={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {profile?.alias || 'Elanaut'}
                </Typography>
                <CopyCapsule text={account}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} fontFamily="monospace">
                    {displayAddress(account as string, 14, 5)}
                  </Typography>
                </CopyCapsule>
                {profile?.did && (
                  <CopyCapsule text={profile.did.did} sx={{ mt: 1 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        overflowWrap: 'anywhere',
                      }}
                      fontFamily="monospace"
                    >
                      {displayAddress(profile.did.did, 14, 5)}
                    </Typography>
                  </CopyCapsule>
                )}
              </Box>

              <Box display="flex" justifyContent="space-around">
                <AffiliateDialog />
              </Box>

              <Divider sx={{ my: 1 }} />

              <Box sx={{ my: 1.5, px: 2.5 }}>
                <WalletSummary />
              </Box>

              {/* <Divider sx={{ my: 1 }} /> */}

              {/* {MENU_OPTIONS.map((option) => (
                <MenuItem
                  key={option.label}
                  {...(option.external
                    ? {
                      onClick: () => {
                        window.open(option.linkTo, '_blank');
                        handleClose();
                      },
                    }
                    : {
                      to: option.linkTo,
                      component: RouterLink,
                      onClick: handleClose,
                    })}
                  sx={{ typography: 'body2', py: 1, px: 2.5 }}
                  {...(option.linkProps || {})}
                >
                  {option.icon && (
                    <Box
                      component={Icon}
                      icon={option.icon}
                      sx={{
                        mr: 2,
                        width: 24,
                        height: 24,
                      }}
                    />
                  )}

                  {option.label}
                </MenuItem>
              ))} */}

              {isAdmin && (
                <>
                  <Divider sx={{ my: 1 }} />
                  <MenuItem
                    to="/marketplace/collections/review"
                    component={RouterLink}
                    onClick={handleClose}
                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                  >
                    Review Collections
                  </MenuItem>
                  <MenuItem
                    to="/admin/console"
                    component={RouterLink}
                    onClick={handleClose}
                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                  >
                    Admin Console
                  </MenuItem>
                  <Divider sx={{ my: 1 }} />
                </>
              )}

              {/* <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', '& > *': { mx: 1 } }}>
                <IconButton LinkComponent={Link} href={links.discord} target="_blank" rel="noreferrer">
                  <DiscordLogo />
                </IconButton>
                <IconButton LinkComponent={Link} href={links.twitter} target="_blank" rel="noreferrer">
                  <TwitterLogo />
                </IconButton>
                <IconButton LinkComponent={Link} href={links.telegram} target="_blank" rel="noreferrer">
                  <TelegramLogo />
                </IconButton>
              </Box> */}

              <Box sx={{ p: 2, pt: 1.5 }}>
                <Button fullWidth color="inherit" variant="outlined" onClick={handleDisconnect}>
                  Disconnect
                </Button>
              </Box>
            </MenuPopover>
          )}
        </>
      )}
    </>
  );
}

AccountPopover.defaultProps = {
  size: 44,
};

export default React.memo(AccountPopover);
