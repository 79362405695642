import { api, profileFragment } from './query.base';
import { privateQuery } from './privateBaseQuery';
import {
  Account, GraphqQLResponse, PaginatedQuery,
} from '../../types';

interface SubscriptionEntry {
  _id?: string
  from?: Account;
  to?: Account;
}

interface SubscriptionResult {
  total?: number;
  count: number;
  isAmong?: boolean;
  data?: SubscriptionEntry[];
}

enum Tags {
  Subscription = 'Subscription',
}

const subscribeApi = api
  .enhanceEndpoints({
    addTagTypes: [Tags.Subscription],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      fetchSubscribers: builder.query<SubscriptionResult, { address: string; follower?: string }>({
        query: ({ address, follower }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            query FetchSubscribers($address: String!, $follower: String) {
              listSubscribers(address: $address, follower: $follower) {
                count
                isAmong
              }
            }`,
            variables: {
              address,
              follower,
            },
          },
        }),
        transformResponse: async (
          r: GraphqQLResponse<SubscriptionResult>
        ): Promise<SubscriptionResult> => r.data?.listSubscribers,
        providesTags: (result, error, arg) => [{ type: Tags.Subscription, id: arg.address }],
      }),
      fetchSubscriptions: builder.query<SubscriptionResult, { to?: string; from?: string; filters?: PaginatedQuery }>({
        query: ({ to, from, filters }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            ${profileFragment}

            query FetchSubsctiptions($query: FollowingQueryInput, $filters: FilterPaginationInput) {
              fetchFollowings(query: $query, filters: $filters) {
                total
                limit
                offset
                data {
                  to {
                    ...profileFields
                  }
                }
              }
            }`,
            variables: {
              query: {
                ...(to && {
                  to,
                }),
                ...(from && {
                  from,
                }),
              },
              filters: filters || {
                offset: 0,
              },
            },
          },
        }),
        transformResponse: async (
          r: GraphqQLResponse<SubscriptionResult>
        ): Promise<SubscriptionResult> => r.data?.fetchFollowings,
        providesTags: (result, error, arg) => [
          { type: Tags.Subscription, id: 'LIST' },
          ...result.data.map((d) => ({ type: Tags.Subscription, id: d.to.address })),
        ],
      }),
    }),
  });

const subscribePrivateApi = api
  .enhanceEndpoints({
    addTagTypes: [Tags.Subscription],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      subscribeChannel: builder.mutation<SubscriptionEntry, string>({
        queryFn: async (channel, bqApi, extraOptions) => privateQuery({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
              mutation SubscribeChannel($channel: String!) {
                subscribeChannel(to: $channel) {
                  _id
                }
              }`,
            variables: {
              channel,
            },
          },
        },
        bqApi,
        extraOptions),
        invalidatesTags: (_result, _error, id) => [{ type: Tags.Subscription, id }],
      }),
      unsubscribeChannel: builder.mutation<SubscriptionEntry, string>({
        queryFn: async (channel, bqApi, extraOptions) => privateQuery({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
              mutation UnsubscribeChannel($channel: String!) {
                unsubscribeChannel(to: $channel)
              }`,
            variables: {
              channel,
            },
          },
        },
        bqApi,
        extraOptions),
        invalidatesTags: (_result, _error, id) => [{ type: Tags.Subscription, id }],
      }),
    }),
  });

export { subscribeApi, subscribePrivateApi };

export const {
  useFetchSubscribersQuery,
  useFetchSubscriptionsQuery,
} = subscribeApi;

export const {
  useSubscribeChannelMutation,
  useUnsubscribeChannelMutation,
} = subscribePrivateApi;
