/* eslint-disable max-len */
import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography as MuiTypography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export interface UserAgreementContextValue {
  agreed?: boolean;
  acceptAgreement: () => Promise<void>;
  promptAgreement: () => Promise<boolean>;
}

export const UserAgreementContext = React.createContext<UserAgreementContextValue>({
  agreed: false,
  acceptAgreement: () => Promise.resolve(),
  promptAgreement: () => Promise.resolve(false),
});

// --- Agreement modal
const Typography = styled(MuiTypography)({
  '&.MuiTypography-h6': {
    paddingTop: 16,
  },
});

interface AgreementModalProps {
  open?: boolean;
  onClose?: () => void;
  onConfirm?: () => Promise<void>;
}

const AgreementModal: React.FC<AgreementModalProps> = ({ open, onClose, onConfirm }: AgreementModalProps) => {
  const [read, setRead] = React.useState<boolean>(false);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={Boolean(open)}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle>
        <Typography variant="h5" fontWeight="bold">
          Purchase Agreement of NFT
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom paragraph>
          This Purchase Agreement of NFT (&quot;Agreement&quot;) between you and the Platform governs your participating
          in purchase NFT (as defined below) on the Platform.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph>
          THE PLATFORM IS NOT A BROKER, FINANCIAL INSTITUTION, OR CREDITOR. THE SERVICES ARE ADMINISTRATIVE ONLY. YOU
          BEAR FULL RESPONSIBILITY FOR VERIFYING THE IDENTITY, LEGITIMACY, AND AUTHENTICITY OF NFT YOU PURCHASED ON THE
          PLATFORM. NOT WITHSTANDING INDICATORS AND MESSAGES THAT SUGGEST VERIFICATION, THE PLATFORM MAKES NO CLAIMS
          ABOUT THE IDENTITY, LEGITIMACY, OR AUTHENTICITY OF NFTS ON THE PLATFORM.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph>
          In this Agreement, &quot;you&quot; and &quot;your&quot; refers to you, the purchaser of the crypto assets;
          “we”, “our” and ”us” refer to the trading platform or the site, the service provider (the “Platform”).
        </Typography>

        <Typography variant="h6">Description of the Services</Typography>

        <Typography variant="body2" gutterBottom paragraph>
          NFT refers to unique non-fungible tokens, which represent pieces of programmable arts in the form of
          non-fungible ERC721 or ERC1155 digital assets that themselves may be created by reference to a smart contract
          on the Elastos blockchain. The Platform is an online trading platform where users can sell, purchase, list for
          auction, make offers, and bid on NFTs. You can obtain an NFT by making an offer accepted by the seller,
          purchasing at a list price, or bidding on NFT available in smart contract-enabled auctions. You will be able
          to search, browse, click on links, and purchase NFTs that represent the digital Artworks on Platform. A
          purchaser that becomes an owner of Artwork (“Collector”) will have no intellectual property rights to that
          Artwork.
        </Typography>

        <Typography variant="h6">Ownership</Typography>

        <Typography variant="body2" gutterBottom paragraph>
          Owning a NFT is similar to owning a piece of physical art. You own a cryptographic token representing the
          Artist’s creative Artwork as a piece of property, but you do not own the creative Artwork itself. Collectors
          may show off their ownership of collected NFTs by displaying and sharing the underlying Artwork, but
          Collectors do not have any legal ownership, right, or title to any copyrights, trademarks, or other
          intellectual property rights to the underlying Artwork, excepting the limited license granted by these terms
          to underlying Artwork. The Artist reserves all exclusive copyrights to Artworks underlying NFTs on the
          Platform, including but not limited to the right to reproduce, to prepare derivative works, to display, to
          perform, and to distribute the Artworks. The Collectors may not infringe on any of the exclusive rights of the
          copyright the Artist.
        </Typography>

        <Typography variant="h6">Purchasing NFT with a List Price</Typography>

        <Typography variant="body2" gutterBottom paragraph>
          NFTs are optionally offered for immediate acceptance at a List Price established by the seller on platform.
          You can purchase NFT with a List Price through the platform by sending an equivalent amount of cryptocurrency
          to a Smart Contract configured to initiate a transfer of the NFT, plus additional fees and gas.
        </Typography>

        <Typography variant="h6">Making Offers on NFT</Typography>

        <Typography variant="body2" gutterBottom paragraph>
          You can make offers on all listed NFT through Platform. Offers on Platform are legally binding, revocable
          offers to purchase the NFT capable of immediate acceptance by the seller of the NFT. By making an offer, you
          agree to temporarily send and lose control over an amount of offered cryptocurrency to a Smart Contract. The
          Smart Contract is configured to hold the offered cryptocurrency until either the offer is accepted by the
          seller, a higher offer is received, or the offer is revoked. The seller of the NFT has the unilateral
          authority to accept the bid.
        </Typography>

        <Typography variant="h6">Fees</Typography>

        <Typography variant="body2" gutterBottom paragraph>
          Every transaction on Platform is subject to fees collected to support the Platform. You agree and understand
          that all fees, commissions, and royalties are transferred, processed, or initiated directly through one or
          more of the Smart Contracts on the Elastos blockchain network. By transacting on the Platform and by using
          Smart Contracts, you hereby acknowledge, consent to, and accept all automated fees, commissions, and royalties
          for the sale of NFT on the Platform. You hereby consent to and agree to be bound by the Smart Contracts’
          execution and distribution of the fees, commissions, and royalties. You hereby waive any entitlement to
          royalties, commissions, or fees paid to another by operation of the Smart Contracts.
        </Typography>

        <Typography variant="h6">Gas</Typography>

        <Typography variant="body2" gutterBottom paragraph>
          All transactions on the Platform, including without limitation bidding, listing, offering, purchasing, or
          confirming, are facilitated by Smart Contracts existing on the Elastos network. The Elastos network requires
          the payment of a transaction fee (a “Gas fee”) for every transaction that occurs on the Elastos network, and
          thus every transaction occurring on Platform. The value of the Gas Fee changes, often unpredictably, and is
          entirely outside of the control of Platform. You acknowledges that under no circumstances will a contract,
          agreement, offer, sale, bid, or other transaction on Platform be invalidated, revocable, retractable, or
          otherwise unenforceable on the basis that the Gas Fee for the given transaction was unknown, too high, or
          otherwise unacceptable to you. You also acknowledge and agree that gas is non-refundable under all
          circumstances.
        </Typography>

        <Typography variant="h6">No Representations or Warranties</Typography>

        <Typography variant="body2" gutterBottom paragraph>
          Digital artworks and its descriptions are posted for informational purposes only and may not be independently
          verified by Platform and/or its partners. Therefore, your reliance on such information is at your own risk.
          You should always verify information on the Platform before making a bid or purchase. Because we do not
          control User Content and/or other third-party sites and resources, you acknowledge and agree that we are not
          responsible for the accuracy or availability of any User Content and materials and/or external sites or
          resources. We make no guarantees regarding the accuracy, currency, suitability, or quality of any User
          Content. Your interactions with other Site users are solely between you and such users. You agree that
          Platform will not be responsible for any loss or damage incurred as the result of any such interactions. If
          there is a dispute between you and any Site user, Platform is under no obligation to become involved.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph>
          You acknowledge and consent to the risk that the price of an NFT purchased on the Platform may have been
          influenced by user activity outside of the control of the Platform. The Platform does not represent,
          guarantee, or warrant the accuracy or fairness of the price of any NFT sold or offered for sale on the
          Platform. You agree and acknowledge that the Platform is not a fiduciary nor owes any duties to any user of
          the Platform, including the duty to ensure fair pricing of NFTs or to influence user behaviour on the
          Platform.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph>
          Transactions that take place on Platform are managed and confirmed via the Elastos blockchain. You understand
          that your Elastos public address will be made publicly visible whenever you engage in a transaction on
          Platform. We neither own nor control Google Chrome, the Elastos network, or any other third party site,
          product, or service that you might access, visit, or use for the purpose of enabling you to use the various
          features of the Platform. We will not be liable for the acts or omissions of any such third parties, nor will
          we be liable for any damage that you may suffer as a result of your transactions or any other interaction with
          any such third parties. Platform facilitates transactions between the purchaser and seller of an NFT. Platform
          is not the custodian of any NFT. You affirm that you are aware and acknowledge that Platform is a
          non-custodial service provider and has no responsibility to custody the NFT you purchased.
        </Typography>

        <Typography variant="h6">Assumption of Risk</Typography>

        <Typography variant="body2" gutterBottom paragraph>
          Participating in the purchase of NFTs involves significant risks and potential financial losses, including but
          not limited to the following, you accept and acknowledge:
          <ul>
            <li>
              You hereby acknowledge and assume the risk of initiating, interacting with, participating in transactions
              and take full responsibility and liability for the outcome of any transaction they initiate. Users hereby
              represent that they are knowledgeable, experienced and sophisticated in using blockchain technology, the
              Platform, and in initiating Elastos-based transactions.
            </li>

            <li>
              There are risks associated with purchasing user generated content, including but not limited to, the risk
              of purchasing counterfeit assets, mislabeled assets, assets that are vulnerable to metadata decay, assets
              on smart contracts with bugs, and assets that may become untransferable. The Platform assumes no liability
              or responsibility to you for any losses in transactions.
            </li>

            <li>
              The Platform does not store, send or receive an NFT. The NFT is transferred on the Smart Contract
              maintained by the Platform. Any transfers of NFTs occur via the Smart Contract located on the Elastos
              blockchain and not on the Platform. Further, as NFTs are non-fungible, they are unrecoverable once damaged
              or lost. You confirm that you will transfer the NFTs out of the Platform for custody once after the
              completion of purchasing, and that Platform will not be responsible or liable to you for any loss in case
              the NFTs are damaged or lost.
            </li>

            <li>
              There are risks associated with purchasing blockchain based tokens, including but not limited to, the risk
              of losing private keys, theft of cryptocurrency or tokens due to hackers finding out your private key,
              lack of a secondary market, significant price volatility, hard forks and disruptions to the Elastos
              blockchain. You accept and acknowledge that transfers on the Elastos blockchain are irreversible and as a
              result, it is not possible for the Platform to issue refunds on NFT purchases.
            </li>

            <li>
              Platform is not responsible for losses due to blockchains or any other features of the Elastos network or
              any Elastos compatible browser or wallet, including but not limited to late report by developers or
              representatives (or no report at all) of any issues with the blockchain supporting the Elastos network,
              including forks, technical node issues, or any other issues having fund losses as a result.
            </li>

            <li>
              You acknowledge and agree that the smart contracts may be subject to bugs, malfunctions, timing errors,
              hacking and theft, or changes to the protocol rules of the Elastos blockchain, which can adversely affect
              the smart contracts and may expose you to a risk of total loss, forfeiture of your digital currency or
              NFT, or lost opportunities to buy or sell NFTs. The Platform assumes no liability or responsibility for
              any such smart contract or related failures, risks, or uncertainties.
            </li>

            <li>
              You acknowledge that Platform is subject to flaws and acknowledge that you are solely responsible for
              evaluating any code provided by the Platform. This warning and others provided in this Agreement by
              Platform in no way evidence or represent an ongoing duty to alert you to all of the potential risks of
              utilizing or accessing the Platform.
            </li>

            <li>
              You are solely responsible for determining what, if any, taxes apply to your purchases and sales of NFTs.
              The Platform is not responsible for determining the taxes that apply to NFT transactions.
            </li>
          </ul>
        </Typography>

        <Typography variant="h6">Representations and Warranties</Typography>

        <Typography variant="body2" gutterBottom paragraph>
          Before you purchase any NFT, you hereby represent and warrant that:
          <ul>
            <li>
              You are an eligible purchaser of the asset and have read and understood all the terms of this Agreement
              and User Agreement of the Platform and fully understand all the risks herein and are willing to assume all
              the liabilities and losses.
            </li>

            <li>
              You have sufficient understanding of the transaction and the NFT and make informed decision after
              performing your own due diligence.
            </li>

            <li>
              By purchasing any NFT, you are certifying to the Platform that the activities in connection with the
              purchase will comply with this Agreement and all applicable laws, rules and regulations. The NFT you
              receive will not be used in any forms of illegal activity, including but not limited to participating in
              or supporting any illegal activities.
            </li>
          </ul>
        </Typography>

        <Typography variant="h6">No Liability</Typography>

        <Typography variant="body2" gutterBottom paragraph>
          To the maximum extent permitted by law, in no event shall Platform be liable to you or any third party for any
          lost profits, lost data, or any indirect, consequential, exemplary, incidental, special or punitive damages
          arising from or relating to these terms or your use of, or inability to use, the site, even if Platform has
          been advised of the possibility of such damages. Access to, and use of, the site is at your own discretion and
          risk, and you will be solely responsible for any damage to your device or computer system, or loss of data
          resulting therefrom.
        </Typography>

        <Typography variant="h6">Indemnification</Typography>

        <Typography variant="body2" gutterBottom paragraph>
          You agree to indemnify and hold harmless the Platform and its affiliates from and against any and all claims,
          costs, proceedings, demands, losses, damages, and expenses (including, without limitation, reasonable
          attorney’s fees and legal costs) of any kind or nature, arising from or relating to, any actual or alleged
          breach of the Agreement by you, a co-conspirator, or anyone using your account. If we assume the defence of
          such a matter, you will reasonably cooperate with us in such defence.
        </Typography>

        <Typography variant="h6">Entire Terms.</Typography>

        <Typography variant="body2" gutterBottom paragraph>
          This Agreement along with User Agreement on the Platform constitute the entire agreement between you and us
          relating to your access to and use of the Services and Content, and your participation in the Transaction.
        </Typography>

        <Typography variant="h6">Updates to Terms and Conditions</Typography>

        <Typography variant="body2" gutterBottom paragraph>
          The Platform reserves the right to update, change or modify the terms of Agreement at any time and in our sole
          discretion. If we make changes to the terms, we will provide notice of such changes. If you do not agree to
          the revised Agreement, you may not access or use the Services.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ my: 3, justifyContent: 'center' }}>
        <Box>
          <FormControlLabel
            control={(
              <Checkbox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setRead(e.target.checked);
                }}
              />
            )}
            label="I've read, understood and agreed to ela.city's NFT service agreement"
          />
        </Box>
        <Button size="large" type="submit" variant="contained" onClick={onConfirm} disabled={!read}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
// --- /Agreement modal

interface UserAgreementProviderProps {}

declare type PromiseResolver<T> = (value: T) => void;

export const UserAgreementProvider: React.FC<React.PropsWithChildren<UserAgreementProviderProps>> = ({
  children,
}: React.PropsWithChildren<UserAgreementProviderProps>) => {
  const acceptResolver = React.useRef<PromiseResolver<boolean>>(null);

  const [agreed, setAgreed] = React.useState<boolean>(
    // take previous value from local storage (browser-based value)
    // @todo: use database to store this value according to account (if connected)
    // if not connected, we will not really ned to make the prompt
    localStorage.getItem('__elacity_ua') === 'yes'
  );
  const [open, openModal] = React.useState<boolean>(false);
  const acceptAgreement = async () => {
    acceptResolver.current?.call(null, true);

    setAgreed(true);
    setTimeout(() => {
      openModal(false);
    }, 500);
  };

  const promptAgreement = () => {
    openModal(true);

    return new Promise<boolean>((resolve) => {
      acceptResolver.current = resolve;
    });
  };

  React.useEffect(() => {
    if (agreed) {
      localStorage.setItem('__elacity_ua', 'yes');
    }
  }, [agreed]);

  React.useEffect(() => {}, []);

  return (
    <UserAgreementContext.Provider
      value={{
        agreed,
        acceptAgreement,
        promptAgreement,
      }}
    >
      {children}
      <AgreementModal open={open} onClose={() => openModal(false)} onConfirm={acceptAgreement} />
    </UserAgreementContext.Provider>
  );
};

// high order component
export const withUserAgreement =
  <T, >(Component: React.ComponentType<T>) => (props: T) => {
    const { agreed, promptAgreement } = React.useContext(UserAgreementContext);
    React.useEffect(() => {
      // on load, check whether the user has already agreed with the NFTs Agreement
      // if so, we can just skip prompt
      // otherwise, we should show modal to allow user to confirm it
      if (!agreed) {
        console.log('prompting agreement...');
        promptAgreement()
          .then((accepted: boolean) => {
            console.log({ accepted });
          })
          .catch((e: Error) => console.error('Agreement Error', e));
      }
    }, []);

    return <Component {...props} />;
  };
