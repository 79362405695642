import { GraphqQLResponse, Affiliation } from 'src/types';
import { authenticatedApi } from './query.private';

enum Tags {
  AffiliationTag = 'Affiliation',
}

const affiliationApi = authenticatedApi
  .enhanceEndpoints({
    addTagTypes: [Tags.AffiliationTag],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getHolderAffiliation: builder.query<Affiliation[], void>({
        query: () => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            query {
              getHolderAffiliation {
                _id
                refKey
                owner
                percentage
                used
                refereeAddress
                ledger
                tokenID
                hexTokenID
                archived
                token {
                  name
                }
                earnt
              }
            }
          `,
            variables: {},
          },
        }),
        transformResponse:
          async (r: GraphqQLResponse<Affiliation[]>): Promise<Affiliation[]> => r.data?.getHolderAffiliation || [],
      }),
      getAffiliation: builder.query<Affiliation, { refKey: string }>({
        query: ({ refKey }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            query GetAffiliation($refKey: String!) {
              getAffiliation(refKey: $refKey) {
                _id
                refKey
                owner
                percentage
                used
                archived
              }
            }
          `,
            variables: {
              refKey,
            },
          },
        }),
        transformResponse:
          async (r: GraphqQLResponse<Affiliation>): Promise<Affiliation> => r.data?.getAffiliation,
      }),
      generateAffiliationLink: builder.mutation<GraphqQLResponse<Affiliation>, { owner: string, percentage: number }>({
        query: ({ owner, percentage }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            mutation GenerateAffiliationLink($owner: String!, $percentage: Float!) {
              generateAffiliationLink(owner: $owner, percentage: $percentage) {
                _id
                refKey
                owner
                percentage
                used
                archived
              }
            }`,
            variables: {
              owner, percentage,
            },
          },
        }),
      }),
      updateAffiliationLink: builder.mutation<GraphqQLResponse<Affiliation>, { refKey: string; input: Partial<Affiliation> }>({
        query: ({ refKey, input }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            mutation UpdateAffiliationLink($refKey: String!, $input: UpdateAffiliationInput!) {
              updateAffiliationLink(refKey: $refKey, input: $input) {
                _id
                refKey
                owner
                percentage
                used
                refereeAddress
                ledger
                tokenID
                hexTokenID
                archived
                token {
                  name
                }
                earnt
              }
            }`,
            variables: {
              refKey, input,
            },
          },
        }),
      }),
      removeAffiliationLink: builder.mutation<GraphqQLResponse<Affiliation>, { id: string }>({
        query: ({ id }) => ({
          url: '/2.0/graphql',
          method: 'POST',
          body: {
            query: `
            mutation RemoveAffiliationLink($id: String!!) {
              removeAffiliationLink(id: $id) {
                _id
                refKey
                owner
                percentage
                used
                refereeAddress
                ledger
                tokenID
                hexTokenID
                archived
                earnt
              }
            }
            `,
            variables: {
              id,
            },
          },
        }),
      }),
    }),
  });

export { affiliationApi };

export const {
  useGetHolderAffiliationQuery,
  useGetAffiliationQuery,
  useGenerateAffiliationLinkMutation,
  useRemoveAffiliationLinkMutation,
  useUpdateAffiliationLinkMutation,
} = affiliationApi;
