import React from 'react';
import { Icon, IconifyIcon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import homeFill from '@iconify/icons-eva/home-fill';
import starFill from '@iconify/icons-eva/star-fill';
// import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import ImageIcon from '@mui/icons-material/Image';

const getIcon = (name: string | IconifyIcon) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Home',
    path: '/home',
    icon: getIcon(homeFill),
    children: [
      {
        title: 'My Shop',
        path: '/home/profile',
      },
      {
        title: 'My Vault',
        path: '/home/profile/vault',
      },
      // {
      //   title: 'Map',
      //   path: '/home/map',
      // },
    ],
  },
  {
    title: 'Explore',
    path: '/marketplace',
    icon: <ImageIcon />,
    children: [
      {
        title: 'Explore',
        path: '/marketplace/explore',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Collections',
        path: '/marketplace/collections',
        icon: getIcon(starFill),
      },
      // {
      //   title: 'Shops',
      //   path: '/marketplace/shops',
      //   icon: getIcon(pieChart2Fill),
      // },
    ],
  },
  // {
  //   title: 'AI',
  //   path: '/ai',
  //   icon: <EmojiObjectsIcon />,
  // },
  {
    title: 'Cinema',
    path: '/cinema',
    icon: <SmartDisplayIcon />,
  },
];

export default sidebarConfig;
