/* eslint-disable @typescript-eslint/no-explicit-any */
// export interface Listener<T> {
//   (event: T, data?: any): any;
// }

export interface _Listener {
  (data?: any): any;
}

export interface ListenerList {
  [name: string]: _Listener[];
}

export interface Disposable {
  dispose();
}

/** passes through events as they happen. You will not get events from before you start listening */
export default class TypedEvent {
  protected listeners: ListenerList = {};

  protected listenersOncer: ListenerList = {};

  pushEvent(event: string, listener: _Listener, list: ListenerList) {
    list[event] = [];
    list[event].push(listener);

    return this;
  }

  on(event: string, listener: _Listener): Disposable {
    this.pushEvent(event, listener, this.listeners);
    return {
      dispose: () => this.off(event, listener),
    };
  }

  once(event: string, listener: _Listener): void {
    this.pushEvent(event, listener, this.listenersOncer);
  }

  off(event: string, listener: _Listener) {
    const callbackIndex = this.listeners[event].indexOf(listener);
    if (callbackIndex > -1) this.listeners[event].splice(callbackIndex, 1);
  }

  flush() {
    this.listeners = {};
    this.listenersOncer = {};
  }

  emit(event: string, data?: any) {
    /** Update any general listeners */
    if (Array.isArray(this.listeners[event])) {
      this.listeners[event].forEach((listener) => listener(data));
    }

    /** Clear the `once` queue */
    if (Array.isArray(this.listenersOncer[event]) && this.listenersOncer[event].length > 0) {
      this.listenersOncer[event].forEach((listener) => listener(data));
      this.listenersOncer[event] = [];
    }
  }
}
