import { useMemo } from 'react';
import { NFTArtApiResponse } from 'src/types';
import { RootState, useSelector } from 'src/state/store';

export default (data: NFTArtApiResponse) => {
  const likes = useSelector(
    (state: RootState) => state.token.likes || {}
  );

  return useMemo(() => ({
    ...(data?.data || {}),
    tokens: (data?.data?.tokens || []).map(
      (d) => {
        const key = `${d.contractAddress}-${d.tokenID}`;

        return {
          ...d,
          ...(likes[key] || {}),
          liked: likes[key]?.liked || 0,
          ...(Boolean(likes[key]?.isLiked) && {
            isLiked: true,
          }),
        };
      }
    ),
  }), [likes, data]);
};
