/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import {
  ErrorNoWalletConnected,
  ErrorWrongNetwork,
  ErrorBanned,
  toIpfsGateway,
  TokenID,
  INTERFACE_ERC721,
  INTERFACE_ERC1155,
  INTERFACE_MARKETPLACE,
  INTERFACE_FACTORY_ERC721_PRIVATE,
  ChainID,
  INTERFACE_AUCTION,
} from '@elacity-js/lib';
import { hexDataSlice } from '@ethersproject/bytes';
import { parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import {
  Contract, ContractReceipt, ContractTransaction,
} from '@ethersproject/contracts';
import { HiveFileHelper } from 'src/lib/did/services/hive.file.helper';
import { selectCurrency } from 'src/constants/currencies';
import { user as userApi, file as fileApi } from 'src/api';
import { TxExecutable } from 'src/lib/web3/executable/tx';
import {
  INftCreateParams, IRoyaltyRegisterer, IUploadHelper, IUploadResult, IStorageType,
} from './types';
import ContractExecutor from './ContractExecutor';
import events from './events';
import MarketplaceAggregator, { ContractCallParams } from './MarketplaceAggregator';

export default class NftContractExecutor extends ContractExecutor {
  protected aggregator: MarketplaceAggregator;

  protected _name: string;

  protected _image: any;

  protected _description: string;

  protected _symbol: string;

  protected _xtra: string;

  protected _royalty: number;

  protected _nftAddress: string;

  protected _supply: string;

  protected _type: string;

  protected _collection: string;

  protected _royaltyRegisterer: IRoyaltyRegisterer;

  protected _salesContractAddress: string;

  protected _auctionContractAddress: string;

  protected _private721Factory: string;

  protected _uploader: IUploadHelper;

  public constructor({
    account,
    chainId,
    name,
    image,
    description,
    symbol,
    xtra,
    royalty,
    nftAddress,
    supply,
    type,
    collection,
    salesContract,
    auctionContract,
    private721Factory,
  }: INftCreateParams) {
    super({ account, chainId: chainId ? chainId.toString() : '' });

    const _royalty = parseFloat(royalty?.toString()) * 100;

    this._name = name;
    this._image = image;
    this._description = description;
    this._xtra = xtra;
    this._symbol = symbol;
    this._royalty = Number.isNaN(_royalty) ? 0 : _royalty;
    this._nftAddress = nftAddress;
    this._supply = supply;
    this._type = type;
    this._collection = collection;
    this._salesContractAddress = salesContract;
    this._auctionContractAddress = auctionContract;
    this._private721Factory = private721Factory;
  }

  protected isPrivateCollection(address: string): Promise<boolean> {
    return (new Contract(this._private721Factory, INTERFACE_FACTORY_ERC721_PRIVATE, this._provider)).exists(address);
  }

  /**
   * Set the upload helper according to target file storage
   * Possible values are among (ipfs, hive:owned, hive:public)
   *
   * @param uploadHelper
   */
  public setUploadHelper(uploadHelper: IUploadHelper) {
    this._uploader = uploadHelper;
  }

  public setUploadHelperByType(storageType: IStorageType, ...args: any[]) {
    console.info('setup uploader for storageType: ', storageType);
    switch (storageType) {
      default:
      case IStorageType.IPFS:
        this.setUploadHelper(
          (formData: FormData): Promise<IUploadResult> => fileApi.upload<IUploadResult['data']>(
            '/ipfs/uploadImage2Server',
            formData,
            this._client
          )
        );
        break;
      case IStorageType.HIVE_OWNED:
        this.setUploadHelper(
          (formData: FormData): Promise<IUploadResult> => HiveFileHelper.uploadNFTByUser(args[0] as string, formData)
        );
        break;
      case IStorageType.HIVE_PUBLIC:
        this.setUploadHelper(
          (formData: FormData): Promise<IUploadResult> => fileApi.upload<IUploadResult['data']>(
            '/hive/uploadNFT',
            formData,
            this._client
          )
        );
        break;
    }
  }

  public setRoyaltyRegisterer(registerer: IRoyaltyRegisterer) {
    this._royaltyRegisterer = registerer;
  }

  protected validateMetadata(): boolean {
    return this._name !== '' && this.account !== '' && this._image;
  }

  protected async imageToBase64() {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(this._image);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
    });
  }

  protected async _buildFormData(additionaPayload?: Record<string, any>): Promise<FormData> {
    const formData = new FormData();
    const base64: any = await this.imageToBase64();
    formData.append('image', base64);
    formData.append('blob', this._image);
    formData.append('name', this._name);
    formData.append('account', this.account);
    formData.append('description', this._description);
    formData.append('symbol', this._symbol);
    formData.append('collection', this._collection);
    formData.append('royalty', this._royalty.toString());
    if (additionaPayload) {
      Object.entries(additionaPayload).forEach(
        ([key, _v]: [string, any]) => {
          formData.append(key, _v);
        }
      );
    }

    return formData;
  }

  protected async mandatoryValidate(additionaPayload?: Record<string, any>) {
    if (!this.account || this.account === '' || this.account === undefined) {
      throw new ErrorNoWalletConnected();
    }

    if (![ChainID.MAINNET, ChainID.TESTNET].includes(Number(this.chainId))) {
      throw new ErrorWrongNetwork();
    }

    const isBanned = await userApi.checkBan(this.account, this._client);

    if (isBanned) {
      throw new ErrorBanned();
    }
  }

  /**
   * Upload NFT file into IPFS via elacity-api
   * 3 methods are actually supported: see `this.setUploadHelperByType`
   * - using POST /ipfs/uploadImage2Server
   * - using POST /hive/uploadNFT
   * - using HiveFileHelper.uploadNFTByUser
   *
   * @param additionaPayload
   * @returns
   */
  protected async uploadNFT(additionaPayload?: Record<string, any>): Promise<string> {
    await this.mandatoryValidate(additionaPayload);

    if (!this._uploader) {
      return Promise.reject(new Error('Uploader not supported'));
    }

    this.emit(events.IS_MINTING, true);

    if (!this.validateMetadata()) {
      this.emit(events.VALIDATE_FAILED);
      return;
    }

    this.emit(events.UPLOADING_TO_IPFS);
    const formData = await this._buildFormData(additionaPayload);

    const fileUploadResults = await this._uploader(formData);
    const { jsonHash, status } = fileUploadResults.data;
    if (status !== 'success' || !jsonHash) {
      this.emit(events.PROCESS_ERRORED, new Error('Upload failed'));
      return Promise.reject(new Error('NFT Upload failed'));
    }

    return jsonHash;
  }

  /**
   * Configure the MarketplaceAggregator contract to be able to mint the NFT to right chain
   */
  public setupAggregator() {
    this.aggregator = MarketplaceAggregator.setupFromProvider(
      Number(this.chainId),
      this._provider.getSigner()
    );
  }

  protected async ensureApproved(contract: any, operator: string) {
    // check if approved
    const approved = await contract.isApprovedForAll(this.account, operator);

    // if not, let's approve the operator
    if (!approved) {
      console.log(`Approving ${operator}...`);
      const tx = await contract.setApprovalForAll(operator, true);

      await tx.wait();
    }
  }

  /**
   * Mint and do all additional actions in same transaction using MarketplaceAggregator contract
   *
   * @param additionaPayload
   */
  public async mintWithAggregator(additionaPayload?: Record<string, any>): Promise<void> {
    this.emit(events.IS_MINTING, true);

    try {
      const { toAuction, toListing, ...uploadPayload } = additionaPayload;
      const jsonHash = await this.uploadNFT(uploadPayload);

      this.emit(events.START_MINTING);

      // prepare aggregator contract
      this.setupAggregator();

      // NFT contract operations
      const contract = this._loadContract(
        this._nftAddress,
        this._type === '721' ? INTERFACE_ERC721 : INTERFACE_ERC1155
      );

      // /!\ TEMPORARILY FIX to address `Ownable: caller is not the owner`
      // when bundling mint with private collection
      const isPrivate = await this.isPrivateCollection(this._nftAddress);
      console.log({ isPrivate });
      let newTokenId: number;
      let txBuilder: (p: ContractCallParams[], o?: any) => Promise<ContractTransaction>;
      if (isPrivate) {
        newTokenId = await this.mintOnly(contract, jsonHash);
        txBuilder = (p: ContractCallParams[], _o?: any) => this.aggregator.multicall(p);
      } else {
        // @updates 2022-10-04: addressing fix fot failure about wrong `tokenId` during
        // pipeline execution, here we will guess the next value of the tokenId of the
        // newly created NFT in a theorical way. We will count `Minted` event then iterate it
        const mintEvents = await contract.queryFilter(
          contract.filters.Minted()
        );
        newTokenId = mintEvents.length + 1;
        txBuilder = (p: ContractCallParams[], o?: any) => this.aggregator.pipeMint(this._nftAddress, jsonHash, p, o);
      }

      const pipeline = this.buildPipelineData(Number(newTokenId), additionaPayload);
      console.log({ newTokenId, pipeline });

      if (pipeline.length === 0 && isPrivate) {
        // terminate process
        this.emit(events.LAST_MINTED_TX_ID, '');
        this.emit(events.NFT_MINT_FINISHED, '');
        return;
      }

      // ensure approval of marketplace/auction contract
      if (toAuction) {
        await this.ensureApproved(contract, this._auctionContractAddress);
      } else if (toListing) {
        await this.ensureApproved(contract, this._salesContractAddress);
      }

      // retrieve platform fee
      const platformFee = await contract.platformFee();

      // calculate gas parameters
      const networkGasPrice = await this._provider.getGasPrice();
      const options: any = {
        gasPrice: BigNumber.from(networkGasPrice).mul(1),
        ...(Boolean(platformFee) && {
          value: platformFee,
        }),
      };

      const tx = await txBuilder(pipeline, options);
      await tx.wait();

      this.emit(events.LAST_MINTED_TX_ID, tx.hash);
      this.emit(events.NFT_MINT_FINISHED, '');
    } catch (e) {
      console.error('caught failure during mint process', e);
      this.emit(events.PROCESS_ERRORED, e);
    }
  }

  /**
   * Setup pipeline actions to pass through Aggregator contract after mint method
   *
   * @param tokenId
   * @param additionalPayload
   * @returns
   */
  protected buildPipelineData(tokenId: number, { toListing, toAuction }: Record<string, any>): ContractCallParams[] {
    const pipeline = [];

    if (this._royalty > 0) {
      pipeline.push({
        // define register royalty parameters to pass through aggregator
        target: this._salesContractAddress,
        abi: INTERFACE_MARKETPLACE,
        func: 'pipeRegisterRoyalty',
        data: [this._nftAddress, tokenId, this._royalty],
      });
    }

    if (toListing) {
      const payToken = selectCurrency(parseInt(this.chainId, 10), toListing?.payToken);
      pipeline.push({
        // define listItem parameters
        target: this._salesContractAddress,
        abi: INTERFACE_MARKETPLACE,
        func: 'pipeListItem',
        data: [
          this._nftAddress, tokenId,
          BigNumber.from(toListing?.quantity || 1),
          payToken.address,
          parseUnits(toListing?.pricePerItem, payToken.decimals),
          BigNumber.from(120 + Math.floor((new Date().getTime()) / 1000)),
        ],
      });
    } else if (toAuction) {
      const payToken = selectCurrency(parseInt(this.chainId, 10), toAuction?.payToken);
      pipeline.push({
        // define createAuction parameters
        target: this._auctionContractAddress,
        abi: INTERFACE_AUCTION,
        func: 'pipeCreateAuction',
        data: [
          this._nftAddress, tokenId,
          payToken.address,
          parseUnits(`${toAuction?.reservePrice}`, payToken.decimals),
          BigNumber.from(120 + Math.floor((toAuction?.startTime || new Date().getTime()) / 1000)),
          toAuction?.minBidReserve || false,
          BigNumber.from(Math.floor(toAuction?.endTime.getTime() / 1000)),
        ],
      });
    }

    return pipeline;
  }

  protected async mintOnly(contract: Contract, jsonHash: string): Promise<number> {
    const args = this._type === '721'
      ? [this.account, toIpfsGateway(jsonHash)]
    // @todo: make ERC1155 support (especially when aggregating tx)
      : [this.account, this._supply, toIpfsGateway(jsonHash)];

    // retrieve platform fee
    const platformFee = await contract.platformFee();

    const { receipt } = await TxExecutable.invoke(
      'mint NFT',
      { callee: contract, method: 'mint' }, { args: () => args },
      { estimateGas: true, value: platformFee }
    );

    let mintedTkId: BigNumber;
    if (this._type === '721') {
      const evtCaught = receipt.logs[0].topics;
      mintedTkId = BigNumber.from(evtCaught[3]);
    } else {
      mintedTkId = BigNumber.from(hexDataSlice(receipt.logs[1].data, 0, 32));
    }

    return Number(mintedTkId);
  }
}
