import { api } from './query.base';
import { AuthResponse } from './types';
import { GraphqQLResponse } from '../../types';

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    signin: builder.mutation<AuthResponse, {address?: string; signature?: string}>({
      query: (body) => ({
        url: '/2.0/graphql',
        method: 'POST',
        body: {
          query: `
            mutation UserLogin($address: String!, $signature: String!) {
              userLogin(address: $address, signature: $signature) {
                token
                expiresIn
                isAdmin
              }
            }`,
          variables: body,
        },
      }),
      transformResponse: async (r: GraphqQLResponse<AuthResponse>): Promise<AuthResponse> => r.data?.userLogin,
    }),
    getNonce: builder.query<number, string>({
      query: (address) => ({
        url: '/2.0/graphql',
        method: 'POST',
        body: {
          query: `
          query {
            getNonce(address:"${address}")
          }`,
        },
      }),
      transformResponse: async (r: GraphqQLResponse<number>): Promise<number> => r.data?.getNonce,
    }),
  }),
});

export { authApi };

export const {
  useSigninMutation,
  useLazyGetNonceQuery,
} = authApi;
