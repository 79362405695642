import React from 'react';
import { Web3Provider } from '@ethersproject/providers';
import {
  Box, Typography, IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Add as PlusIcon, CompareArrows as CompareArrowsIcon } from '@mui/icons-material';
import {
  useProfile, usePayment, useWeb3Application, useAppSettings,
} from 'src/hooks';
import Price from 'src/components/@ui/Price';
import SwapDialog from 'src/components/SwapDialog';
import { ADDRESS } from 'src/lib/nfts/constants';
import { ConnectorName } from 'src/lib/web3/connectors';
import { useNetworkProvisioner } from 'src/lib/web3/network';
import Scrollbar from 'src/components/@ui/Scrollbar';

const Wrapper = styled(Box)({
  cursor: 'pointer',
  width: 220,
});

export default () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { account, balance } = useProfile();
  const { network: currentNetwork } = useNetworkProvisioner();
  const { values } = useAppSettings();
  const { supportedMap: currencies } = usePayment();
  const { library, chainId } = useWeb3Application();

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleAddToken = React.useCallback((addr: string) => async () => {
    console.log('[handleAddToken]', addr);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const provider = library ? (library as Web3Provider)?.provider || null : (window as any).ethereum;
    await provider?.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: addr,
          symbol: currencies[addr]?.symbol,
          decimals: currencies[addr]?.decimals,
        },
      },
    });
  }, [currencies, chainId]);

  const handleClickGlide = (addr: string) => () => {
    console.log('[handleClickGlide]', addr);
    window.open(`https://glidefinance.io/info/token/${addr}`);
  };

  if (!account) {
    return null;
  }

  return (
    <Scrollbar
      sx={{
        maxHeight: 140,
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
        overflowX: 'hidden',
      }}
    >
      <Wrapper>
        <Typography variant="subtitle1" noWrap fontWeight={500} onClick={() => setOpen(true)} component="div">
          Balance
        </Typography>
        {Object.entries(balance).map(([tokenAddress, value]: [string, number]) => (
          <Typography
            key={tokenAddress}
            variant="body2"
            sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center' }}
            fontFamily="monospace"
            component="div"
          >
            {tokenAddress === ADDRESS.ZERO ? (
              <div style={{ minWidth: 40 }}>{currentNetwork?.symbol}</div>
            ) : (
              <div style={{ minWidth: 40 }}>{currencies[tokenAddress.toLowerCase()]?.symbol}</div>
            )}
            <Price.View value={value} size={14} currency={tokenAddress} precision={4} sx={{ ml: 1, width: 90, mr: 1 }} hideUSD />
            {values.walletProvider === ConnectorName.Metamask && (library as Web3Provider)?.provider?.isMetaMask && (
              <IconButton
                size="small"
                onClick={handleAddToken(tokenAddress)}
                sx={{ mr: 1 }}
                disabled={[
                  ADDRESS.ZERO,
                  '0x4ba6ba29e6164bdf8baebe52c169eca24ef04228',
                  '0x1814dDE844554e649EE295C767C7A652845A1c0A',
                ].map((a) => a.toLowerCase()).includes(tokenAddress)}
              >
                <PlusIcon />
              </IconButton>
            )}
            <IconButton size="small" onClick={handleClickGlide(tokenAddress)}>
              <CompareArrowsIcon />
            </IconButton>
          </Typography>
        ))}
      </Wrapper>
      <SwapDialog open={open} handleClose={handleDialogClose} />
    </Scrollbar>
  );
};
