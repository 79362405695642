/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { TokenID } from '@elacity-js/lib';
import { ContractReceipt } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';
import { TxExecutable } from 'src/lib/web3/executable/tx';
import events from '../events';
import { ABIS } from '../constants';

// @todo [tkid]
interface BurnOptions<T = any> {
  tokenID?: TokenID;
  data?: T;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type GenericConstructor<T = {}> = new (...args: any[]) => T;

export default <T extends GenericConstructor<any>>(BaseClass: T) => class MixedClass extends BaseClass {
  /**
   * Burn a token
   *
   * @param to
   * @param params
   * @returns
   */
  public async burn<Op = any>(params: BurnOptions<Op>): Promise<ContractReceipt> {
    const args = [
      params.tokenID.toBigNumber(),
    ];

    const contract = this._loadContract(
      this._nftAddress,
      this._type === '721' ? ABIS.SINGLE_NFT_ABI : ABIS.MULTI_NFT_ABI
    );

    this.emit(events.CONFIRMING_TX);
    try {
      const { receipt } = await TxExecutable.invoke(
        'burn NFT',
        { callee: () => contract, method: 'burn' },
        { args: () => args }
      );

      this.emit(events.NFT_BURN_TX_CONFIRMED, receipt);
      this.emit(events.NFT_BURN_DONE);

      return receipt;
    } catch (e) {
      this.emit(events.PROCESS_ERRORED, e);
      return Promise.reject(e);
    }
  }
};
