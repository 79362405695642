import { FilterByValue } from 'src/state/slices/types';

export interface PriceRange {
  paymentToken: string;
  from?: number;
  to?: number;
}

export type NFTType = 'single' | 'bundle' | 'all';

export type StorageType = 'ipfs' | 'hive' | 'all';

export type LayoutValue = 'normal' | 'large';

// mostly used for UI
export interface QueryFilter<L extends string = LayoutValue> {
  sortBy?: string;
  filterBy?: FilterByValue[];
  searchBy?: string | null;
  offset?: number;
  limit?: number;

  // since ELACITY-258: Improve filtering options
  // @version 1.2
  category?: string | null;
  categories?: string[];
  collectionAddresses?: string[];
  price?: PriceRange;

  // since ELACITY-369: Filter Update
  // @version 1.4
  layout?: L;

  // @since ELACITY-634: Sidebar filter
  // @version 1.6.3
  storageType?: StorageType[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

export enum ThumbnailSize {
  // deterministic size
  large = 460,
  medium = 340,
  small = 220,
  // free size
  s320 = 320,
  s1920 = 1920,

  empty = 0,
}

// used for API query
export interface NFTQueryParams {
  thumbSize?: ThumbnailSize;
  type?: NFTType;
  from: number;
  count: number;
  filterby?: FilterByValue[];
  address?: string;
  category?: string;
  sortby?: string;
  price?: PriceRange;
  collectionAddresses?: string[];
}

export type DeltaValue = [number, number];
