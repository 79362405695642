import React from 'react';
import { ipfsLinkFor } from '@elacity-js/lib';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AddressZero } from '@ethersproject/constants';
import {
  Box as MuiBox, Typography, IconButton as MuiIconButton, Avatar as MuiAvatar,
} from '@mui/material';
import type { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import type { BoxProps as MuiBoxProps } from '@mui/material/Box';
import {
  styled, alpha, Theme,
} from '@mui/material/styles';
import {
  CopyAll as CopyAllIcon, Check as CheckIcon, CheckCircle,
} from '@mui/icons-material';
import { ifThumbnail } from 'src/utils';
import { useWeb3Application } from 'src/hooks';
import { NETWORKS } from 'src/lib/web3/network';
import { useGetProfileQuery, useFetchCollectionQuery } from 'src/state/api';
import { RouterLink } from 'src/components/Link';
import DIDBadge from 'src/components/profile/DIDBadge';
import { IProfile } from 'src/types';
import { displayAddress } from '../utils';

declare type Color = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'vivid' | 'vivid2';

interface BoxProps extends MuiBoxProps {
  shrink?: boolean;
  color?: Color;
}

interface IconButtonProps extends Omit<MuiIconButtonProps, 'color'> {
  color?: Color;
}

const IconButton = styled(MuiIconButton)<IconButtonProps>(({ color }) => ({
  '&.MuiIconButton-sizeSmall': {
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },
  '& .MuiSvgIcon-root': {
    color,
  },
}));

const Box = styled(MuiBox, {
  // shouldForwardProp: (prop: string) => ['color'].includes(prop),
})<BoxProps>(({ theme, shrink, color }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: theme.spacing(4),
  padding: theme.spacing(1, 1.25),
  background: alpha(theme.palette[color].light, 0.1),
  color: theme.palette[color].main,
  cursor: 'pointer',
  ...(shrink && {
    padding: theme.spacing(0.25, 1),
  }),
}));

const ClickIcon = styled(CopyAllIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const ClickedIcon = styled(CheckIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const CheckCircleIcon = styled(CheckCircle)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  color: theme.palette.info.main,
}));

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  marginRight: theme.spacing(1),
}));

interface AddressProps {
  address: string;
  label?: string | React.ReactNode;
  length?: number;
  explore?: boolean;
  avatar?: string;
  prefix?: string;
  size?: IconButtonProps['size'];
  shrink?: boolean;
  color?: Color;

  // @todo: v2 implementation
  // pre-load profile data so that we don't trigger
  // getAccount anymore
  profile?: IProfile;
}

const getNetworkUrl = (chainId: number, addr: string) => `${NETWORKS[chainId].explorerUrl}/address/${addr}`;

const Address = ({ label, address, length, explore, avatar, prefix, size, shrink, color }: AddressProps) => {
  const { chainId } = useWeb3Application();
  const [copied, setCopied] = React.useState<boolean>(false);
  const handleCopy = React.useCallback(() => {
    if (copied) {
      return;
    }

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  }, []);

  const linkProps = {
    ...(prefix
      ? {
        component: RouterLink,
        to: `${prefix}/${address}`,
      }
      : {
        onClick: () => {
          window.open(getNetworkUrl(chainId, address));
        },
      }),
  };

  if (explore) {
    return (
      <Box shrink={shrink} color={color}>
        {avatar && <Avatar src={avatar} alt={address} imgProps={{ crossOrigin: 'anonymous' }} />}
        <Typography
          variant="subtitle2"
          fontWeight="bolder"
          fontFamily="monospace"
          fontSize={shrink ? '0.72rem' : '0.875rem'}
          sx={{ textDecoration: 'none', color: 'inherit' }}
          {...linkProps}
        >
          {label || displayAddress(address, (length || 12) - 4)}
        </Typography>
        <CopyToClipboard text={address} onCopy={handleCopy}>
          <IconButton
            sx={{ ml: shrink ? 0.5 : 2, p: 0, '& .MuiSvgIcon-root': { color: (t: Theme) => t.palette[color].main } }}
            size={size || shrink ? 'small' : 'medium'}
            disabled={copied}
          >
            {copied ? <ClickedIcon /> : <ClickIcon />}
          </IconButton>
        </CopyToClipboard>
      </Box>
    );
  }

  return (
    <CopyToClipboard text={address} onCopy={handleCopy}>
      <Box shrink={shrink} color={color}>
        {avatar && <Avatar src={avatar} alt="address" imgProps={{ crossOrigin: 'anonymous' }} />}
        <Typography variant="subtitle2" fontWeight="bolder" fontFamily="monospace" fontSize={shrink ? '0.72rem' : '0.875rem'}>
          {label || displayAddress(address, (length || 12) - 4)}
        </Typography>
        <IconButton
          sx={{ ml: shrink ? 0.5 : 2, p: 0, '& .MuiSvgIcon-root': { color: (t: Theme) => t.palette[color].main } }}
          size={size || shrink ? 'small' : 'medium'}
          disabled={copied}
        >
          {copied ? <ClickedIcon /> : <ClickIcon />}
        </IconButton>
      </Box>
    </CopyToClipboard>
  );
};

Address.defaultProps = {
  color: 'primary',
};

export default Address;

export const UserAddress = ({
  address,
  length,
  explore,
  size,
  shrink,
}: Omit<AddressProps, 'avatar' | 'prefix' | 'label' | 'alias' | 'image'>) => {
  const { data: profile } = useGetProfileQuery(
    {
      address: address?.toLowerCase(),
    },
    {
      skip: !address || address === AddressZero,
    }
  );

  return (
    <Address
      address={address}
      label={
        profile?.did ? (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span>{profile?.did?.credentials?.name}</span>
            <DIDBadge did={profile?.did?.did} level={profile?.did?.level || 1} />
          </span>
        ) : (
          profile?.alias
        )
      }
      avatar={
        profile?.did?.credentials?.avatar?.base64
          ? ifThumbnail(profile?.did?.credentials?.avatar?.base64, 24)
          : ipfsLinkFor(ifThumbnail(profile?.image || 'QmdjDCeQ2pmyZkJUUnsT54mWBQruvef3bKmzQQGV6FeAQe'))
      }
      length={length || 16}
      explore={explore}
      prefix="/marketplace/shops"
      size={size}
      shrink={shrink}
    />
  );
};

export const CollectionAddress = ({
  address,
  length,
  explore,
  size,
  shrink,
}: Omit<AddressProps, 'avatar' | 'prefix' | 'label' | 'alias' | 'image'>) => {
  const { data: collection } = useFetchCollectionQuery(address?.toLowerCase(), {
    skip: !address || address === AddressZero,
  });

  return (
    <Address
      address={address}
      label={collection?.data.name}
      avatar={ipfsLinkFor(ifThumbnail(collection?.data.logoImageHash || 'QmdjDCeQ2pmyZkJUUnsT54mWBQruvef3bKmzQQGV6FeAQe', 24))}
      length={length || 16}
      explore={explore}
      prefix="/marketplace/collections"
      size={size}
      shrink={shrink}
    />
  );
};
