/* eslint-disable max-len */
import React from 'react';
import { styled } from '@mui/material/styles';
import { createSvgIcon } from '@mui/material/utils';

const SvgContainer = styled('svg')(({ theme }) => ({
  '& .st0': {
    fill: '#CBCBCB',
  },
  '& .st1': {
    fill: 'url(#SVGID_1_)',
  },
  '& .st2': {},
  '& .st3': {
    opacity: 0.3,
  },
  '& .st4': {
    fill: '#F6921A',
  },
  '& .st5': {
    fill: '#091C3F',
  },
  '& .st6': {},
  '& .st7': {},
  '& .st8': {},
  '& .st9': {},
  '& .st10': {},
  '& .st11': {},
  '& .st12': {
    fill: '#FFFFFF',
  },
  '& .st13': {},
  '& .st14': {},
  '& .st15': {},
  '& .st16': {},
  '& .st17': {},
  '& .st18': {},
}));

export default createSvgIcon(
  (
    <SvgContainer
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px" y="0px"
      viewBox="0 0 184 129.4"
    >
      <g>
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="91.9988" y1="2.61" x2="91.9988" y2="88.865" gradientTransform="matrix(1 0 0 -1 0 132.005)">
          <stop offset="0" style={{ stopColor: '#F6921A' }} />
          <stop offset="1" style={{ stopColor: '#F66D1A' }} />
        </linearGradient>
        <path
          className="st1" d="M178.6,62l-21-12.2l-8.9-5.2c-3.4-2-7.5-2-10.8,0L97.4,68.1c-3.3,1.9-7.5,1.9-10.8,0L46.1,44.6 c-3.3-2-7.5-2-10.8,0l-8.9,5.2L5.4,62c-7.2,4.2-7.2,14.5,0,18.7l35.3,20.7l45.9,26.7c3.3,1.9,7.5,1.9,10.8,0l45.9-26.7l35.3-20.7 C185.8,76.4,185.8,66.1,178.6,62L178.6,62z"
        />

        <linearGradient id="SVGID_00000119817579991493194810000005099841221359615923_" gradientUnits="userSpaceOnUse" x1="91.9938" y1="132.005" x2="91.9938" y2="45.73" gradientTransform="matrix(1 0 0 -1 0 132.005)">
          <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
          <stop offset="0.16" style={{ stopColor: '#FFFCFA' }} />
          <stop offset="0.35" style={{ stopColor: '#FFF6EC' }} />
          <stop offset="0.54" style={{ stopColor: '#FFEBD5' }} />
          <stop offset="0.74" style={{ stopColor: '#FFDCB6' }} />
          <stop offset="0.95" style={{ stopColor: '#FFC88D' }} />
          <stop offset="1" style={{ stopColor: '#FFC382' }} />
        </linearGradient>
        <path
          style={{ fill: 'url(#SVGID_00000119817579991493194810000005099841221359615923_)' }} d="M178.6,18.8L148.7,1.5 c-3.4-2-7.5-2-10.8,0L97.4,25c-3.3,2-7.5,2-10.8,0L46.1,1.5c-3.3-2-7.5-2-10.8,0L5.4,18.8c-7.2,4.1-7.2,14.5,0,18.6l21,12.3 l14.3,8.4l45.9,26.7c3.3,1.9,7.5,1.9,10.8,0l45.9-26.7l14.3-8.4l21-12.3C185.8,33.3,185.8,23,178.6,18.8L178.6,18.8z"
        />
        <g className="st3">
          <path
            className="st4" d="M86.6,84.8c3.3,1.9,7.5,1.9,10.8,0l45.9-26.7l14.3-8.4l-8.9-5.2c-3.4-2-7.5-2-10.8,0L97.4,68.1 c-3.3,1.9-7.5,1.9-10.8,0L46.1,44.6c-3.3-2-7.5-2-10.8,0l-8.9,5.2l14.3,8.4L86.6,84.8L86.6,84.8z"
          />
        </g>
      </g>
    </SvgContainer>
  ),
  'ElastosBel2'
);
