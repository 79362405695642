/* eslint-disable @typescript-eslint/no-explicit-any */
import clientHttp from './client';

export const checkBan = async (address: string, client: typeof clientHttp) => {
  const data = { address };
  const results = await client.call<any>({
    method: 'get',
    url: '/ban/banUser',
    data: JSON.stringify(data),
  });

  return results.status === 'success' && results.data?.status === 'success';
};

export const banUser = async (address, signature, signatureAddress, client: typeof clientHttp) => {
  const data = { address, signature, signatureAddress };
  const results = await client.call({
    method: 'post',
    url: '/ban/banUser',
    data: JSON.stringify(data),
  });

  return results.data;
};

export const unbanUser = async (address, signature, signatureAddress, client: typeof clientHttp) => {
  const data = { address, signature, signatureAddress };
  const results = await client.call({
    method: 'post',
    url: '/ban/removeBan',
    data: JSON.stringify(data),
  });

  return results.data;
};

export const getNounce = async (account, client: typeof clientHttp) => {
  const results = await client.call({
    method: 'get',
    url: `/account/nonce/${account}`,
  });
  return results.data;
};
