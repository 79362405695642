import React from 'react';

export interface SelectContextValue<T> {
  value: T;
  setValue?: (value: T) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default React.createContext<SelectContextValue<any>>({
  value: null,
});
