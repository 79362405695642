import React, { CSSProperties } from 'react';

interface LogoProps {
  height?: number;
  style?: CSSProperties;
}

export default ({ height, style }: LogoProps) => (
  <img
    src="/static/icons/hive.png"
    alt="HIVE"
    style={{
      width: 'auto',
      height: height || 16,
      marginRight: 4,
      ...style,
    }}
  />
);
