import React from 'react';

interface LogoProps {
  height?: number;
}

export default ({ height }: LogoProps) => (
  <img
    crossOrigin="anonymous"
    src="/static/icons/ipfs.png"
    alt="IPFS"
    style={{
      width: 'auto',
      height: height || 16,
      marginRight: 4,
    }}
  />
);
