import React from 'react';
import {
  Box, IconButton, Toolbar as MuiToolbar, Tabs as MuiTabs, Tab as MuiTab,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Scrollbar from 'src/components/@ui/Scrollbar';
import { HiveLogo } from 'src/assets/others';
import c from '../../../config';
import ConfigTab from './DrawerConfig';
import HiveTab from './HiveTab';
import PromptTab from './PromptTab';

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  minHeight: c.layout.APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: c.layout.APPBAR_DESKTOP,
    padding: theme.spacing(0, 1),
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

const Tabs = styled(MuiTabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
}));

const Tab = styled(MuiTab)(({ theme }) => ({
  '&.MuiTab-root': {
    minWidth: 80,
    opacity: 0.6,
    '&.Mui-selected': {
      opacity: 1,
    },
  },
}));

const DrawerContainer = styled(Box)({
  width: 310,
});

interface DrawerContentProps {
  handleClose?: () => void;
}

const DrawerContent = ({ handleClose }: DrawerContentProps) => {
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <DrawerContainer>
      <Scrollbar
        sx={{
          maxHeight: 'calc(100vh - 12px)',
          '& .simplebar-content': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
          overflowX: 'hidden',
        }}
      >
        <Toolbar />
        <DrawerHeader>
          <IconButton onClick={handleClose}>
            <ChevronRightIcon />
          </IconButton>
          <Tabs variant="fullWidth" centered value={currentTab} onChange={handleTabChange} aria-label="icon tabs">
            <Tab icon={<SettingsSuggestIcon />} aria-label="settings" />
            <Tab icon={<LibraryAddIcon />} aria-label="prompt" />
            <Tab
              icon={(
                <HiveLogo
                  height={28}
                  style={{
                    ...(currentTab !== 2 && {
                      filter: 'grayscale(1)',
                      WebkitFilter: 'grayscale(1)',
                      MozFilter: 'grayscale(1)',
                    }),
                  }}
                />
              )}
              aria-label="hive"
            />
          </Tabs>
        </DrawerHeader>
        <Box>
          {currentTab === 0 && <ConfigTab />}
          {currentTab === 1 && <PromptTab />}
          {currentTab === 2 && <HiveTab />}
        </Box>
      </Scrollbar>
    </DrawerContainer>
  );
};

export default DrawerContent;
