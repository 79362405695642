import {
  ApiResponse, DeltaValue, QuotedApiResponse,
} from 'src/types';
import { api } from './query.base';

type SummaryKey = 'auctions' | 'artworks' | 'shops';
type SummaryValue = {all: number};

const statsApi = api
  .injectEndpoints({
    endpoints: (builder) => ({
      fetchSummaryStats: builder.query<ApiResponse<Record<SummaryKey, SummaryValue>>, void>({
        query: () => '/stats/summary',
      }),
      fetchCollectionVolume: builder.query<QuotedApiResponse<Record<string, DeltaValue>>, string>({
        query: (rangeText) => ({
          method: 'POST',
          url: '/collection/volumes',
          body: {
            rangeText,
          },
        }),
      }),
    }),
  });

export { statsApi };

export const {
  useFetchSummaryStatsQuery,
  useFetchCollectionVolumeQuery,
} = statsApi;
