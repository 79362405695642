import {
  createSlice, PayloadAction, createSelector,
} from '@reduxjs/toolkit';
import { toIpfsGateway } from '@elacity-js/lib';
import type {
  NFTArtApiResponse, INFTArt, NFTGalleryItem,
} from 'src/types';
import type { RootState } from 'src/state/store';

type RequestId = string;

interface GalleryState {
  items?: Record<RequestId, NFTGalleryItem[]>;
  currentSlot?: RequestId;
  currentMedia?: Partial<NFTGalleryItem>;
  groups?: Record<string, RequestId[]>;
}

const initialState: GalleryState = {
  items: {},
  groups: {},
};

const finder = (media: Partial<NFTGalleryItem>) => (item: NFTGalleryItem) => {
  if (media.contractAddress && media.tokenID) {
    return item.contractAddress === media?.contractAddress && item.tokenID === media?.tokenID;
  }

  return toIpfsGateway(item.mediaURL) === toIpfsGateway(media?.mediaURL);
};

const slice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    addGalleryData: (state: GalleryState, { payload }: PayloadAction<{requestId: RequestId, result: NFTArtApiResponse}>) => {
      state.items[payload.requestId] = payload?.result?.data?.tokens.map(
        ({ contractAddress, tokenID, hexTokenID, metadata }: INFTArt) => ({
          contractAddress,
          tokenID,
          hexTokenID,
          mimeType: metadata?.mimeType,
          type: metadata?.type || 'image',
          mediaURL: metadata?.image,
        })
      );
    },
    invalidateGallerySlot: (state: GalleryState, { payload }: PayloadAction<string>) => {
      if (state.items[payload]) {
        delete state.items[payload];
      }
    },
    setCurrentSlot: (state: GalleryState, { payload }: PayloadAction<string>) => {
      state.currentSlot = payload;
    },
    setCurrentMedia: (state: GalleryState, { payload }: PayloadAction<Partial<NFTGalleryItem>>) => {
      state.currentMedia = payload;
    },
    addRequestInGroup: (state: GalleryState, { payload }: PayloadAction<{requestGroupId: string; requestId: RequestId}>) => {
      if (!state.groups[payload.requestGroupId]) {
        state.groups[payload.requestGroupId] = [];
      }

      state.groups[payload.requestGroupId].push(
        payload.requestId
      );
    },
    goPrevMediaAmong: (state: GalleryState, { payload }: PayloadAction<NFTGalleryItem[]>) => {
      const currentIndex = payload.findIndex(finder(state.currentMedia));

      if (currentIndex <= 0) {
        state.currentMedia = payload.slice(-1).pop() as Partial<NFTGalleryItem>;
      } else {
        state.currentMedia = payload[currentIndex - 1];
      }
    },
    goNextMediaAmong: (state: GalleryState, { payload }: PayloadAction<NFTGalleryItem[]>) => {
      const currentIndex = payload.findIndex(finder(state.currentMedia));

      if (currentIndex >= payload.length - 1) {
        state.currentMedia = (payload[0] || null) as Partial<NFTGalleryItem>;
      } else {
        state.currentMedia = payload[currentIndex + 1];
      }
    },
  },
});

export const { reducer } = slice;

export const {
  actions: {
    addGalleryData,
    invalidateGallerySlot,
    setCurrentSlot,
    addRequestInGroup,
    setCurrentMedia,
    goPrevMediaAmong,
    goNextMediaAmong,
  },
} = slice;

export const itemsSelector = createSelector(
  [
    (state: RootState) => state.gallery.items,
    (state: RootState) => {
      // if no current slot, then just return empty
      if (!state.gallery.currentSlot) {
        return [];
      }

      // check the current slod within all existing group
      const reqList = Object.entries(state.gallery.groups || {})
        .filter(([_, items]) => items.includes(state.gallery.currentSlot))
        .map(([groupId, items]) => ({ groupId, items }))
        .pop();

      if (!reqList) {
        return [state.gallery.currentSlot];
      }

      return reqList.items;
    },
  ],
  (items: Record<RequestId, NFTGalleryItem[]>, group: RequestId[]) => group.reduce(
    (combinedItems: NFTGalleryItem[], slotId: RequestId) => [
      ...combinedItems,
      ...(slotId ? (items[slotId] || []) : []),
    ], []
  )
);

export const mediaSelector = createSelector(
  [
    itemsSelector,
    (state: RootState) => state.gallery.currentMedia,
  ],
  (items: NFTGalleryItem[], media: Partial<NFTGalleryItem>) => {
    if (!media) {
      return [-1, null] as [number, NFTGalleryItem | null];
    }

    return [
      items.findIndex(finder(media)),
      items.find(finder(media)),
    ] as [number, NFTGalleryItem | null];
  }
);
