/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import { baseURL } from '@elacity-js/lib';
import { downloadImage, sanitizeReplicateURL } from 'src/utils';
import { aiApi } from 'src/api';
import type { AIResultHandlers, BuilderParams } from './types';

export default class PredictionHandlers implements AIResultHandlers {
  constructor(protected params: BuilderParams) {}

  onCarouselMove(now?: number, prev?: number) {
    this.params.navigate?.(`/ai/flint-ai-district/r/${this.params.predictionId || 'unknown'}/${now}`);
  }

  onReturn() {
    this.params.navigate?.('/ai/flint-ai-district');
  }

  async onMint(): Promise<void> {
    const {
      input: { prompt },
    } = this.params.prediction;
    this.params.navigate?.(
      // eslint-disable-next-line max-len
      `/home/assets/mint?image=${encodeURIComponent(sanitizeReplicateURL(this.params.currentView))}&prompt=${encodeURIComponent(prompt)}&name=${this.params.predictionId}-${this.params.currentIndex + 1}.png`
    );
  }

  async onUpscale(): Promise<void> {
    this.params.wrapProcess('upscaling', async () => {
      console.log(`Upscaling ${this.params.currentView}...`);
      const response = await aiApi.upscalePredict(
        this.params.currentView,
        await this.params.getClient()
      );
      if (response.status === 'succeeded') {
        await downloadImage(response.output, `upscale-8x-${response.id}.png`);
      }
    });
  }

  handleDownload(index: number) {
    return async () => {
      const fileName = `${this.params.predictionId}-${index + 1}.png`;
      await this.processDownload(fileName);
    };
  }

  async onDownload() {
    this.processDownload();
  }

  private async processDownload(fileName?: string) {
    this.params.wrapProcess('downloading', async () => {
      console.log(`Downloading ${this.params.currentView}...`);
      await downloadImage(this.params.currentView, fileName || 'prediction-output.png');
    });
  }
}
