import { useCallback } from 'react';
import { useParams } from 'react-router';
import { ipfsLinkFor, TokenID } from '@elacity-js/lib';
import { useFetchLedgerTokensQuery, useLazyFetchLedgerTokenQuery } from 'src/state/api';
import { useAddresses } from '../lib/web3/hooks';
import {
  MediaTokenAsset, MediaTokenMetadata, MediaViewState,
} from '../types';

export const fetchTokenByURI = async (tokenURI: string): Promise<MediaTokenMetadata> => {
  const rs = await fetch(ipfsLinkFor(tokenURI));
  const metadata = await rs.json();

  return metadata;
};

export const mediaFormatter = async (item: MediaTokenAsset) => {
  const metadata = await fetchTokenByURI(item.tokenURI);

  return {
    ...item,
    name: metadata.name,
    description: metadata.description,
    image: ipfsLinkFor(metadata.image),
    mediaURL: ipfsLinkFor(metadata.media.uri),
  };
};

interface AdditionalParams {
  ledger?: string;
}

export const useFetchLedgerTokens = (owner?: string, o?: AdditionalParams) => {
  const { DIGITAL_ASSET_LEDGER } = useAddresses();

  const { isLoading, data } = useFetchLedgerTokensQuery(
    {
      address: o?.ledger || DIGITAL_ASSET_LEDGER,
      query: { offset: 0, owner },
    },
    {
      skip: !(DIGITAL_ASSET_LEDGER || o?.ledger),
    }
  );

  return {
    loading: isLoading,
    ...data,
    items: (data?.items || []).map(({ tokenId, ...item }) => ({
      ...item,
      tokenId,
      ...(['string', 'number'].includes(typeof tokenId) && {
        tokenId: TokenID.from(tokenId?.toString() || '0x0'),
      }),
      ...(typeof tokenId === 'object' && {
        tokenId: TokenID.fromObject(tokenId),
      }),
    })),
  };
};

export const useFetchSiblingsVideos = (owner: string, o?: AdditionalParams) => {
  const { DIGITAL_ASSET_LEDGER } = useAddresses();
  const { id: current } = useParams();

  const { isLoading, data } = useFetchLedgerTokensQuery(
    {
      address: o?.ledger || DIGITAL_ASSET_LEDGER,
      query: {
        offset: 0,
        limit: 12,
        ...(owner && {
          // @dev: this type of sort will be parsed in backed side to
          // extract the owner address then use it to upper all videos
          // published by `owner`
          orderBy: `ownership$${owner}`,
          excludes: [Number(current)],
        }),
      },
    },
    {
      skip: !(DIGITAL_ASSET_LEDGER || o?.ledger) || !owner,
    }
  );

  return {
    loading: isLoading,
    ...data,
    items: (data?.items || []).map(({ tokenId, ...item }) => ({
      ...item,
      tokenId,
      ...(['string', 'number'].includes(typeof tokenId) && {
        tokenId: TokenID.from(tokenId?.toString() || '0x0'),
      }),
      ...(typeof tokenId === 'object' && {
        tokenId: TokenID.fromObject(tokenId),
      }),
    })),
  };
};

export const useLoadLedgerToken = (ledger: string, owner?: string): [MediaViewState, (id?: string) => void] => {
  const [trigger, { data: result, isFetching, isSuccess }] = useLazyFetchLedgerTokenQuery();

  const fetchToken = useCallback(
    (tokenId: string) => {
      if (tokenId) {
        console.log('useLoadLedgerToken', { ledger, tokenId, owner });
        trigger({
          address: ledger,
          tokenId,
          owner,
        }, false);
      }
    },
    [ledger, owner]
  );

  return [{ loading: isFetching, loaded: isSuccess, ...result }, fetchToken];
};
