import React from 'react';
import classNames from 'classnames';
import {
  Container,
  Box,
  Typography,
  Link as Anchor,
  Switch,
  FormControl,
  FormControlLabel,
  Grid,
  CircularProgress,
  Button,
} from '@mui/material';
import { styled, lighten } from '@mui/material/styles';
import { CheckBox as CheckBoxIcon } from '@mui/icons-material';
import { RouterLink as Link } from 'src/components/Link';
import {
  useAppSettings, useProfile, usePrediction,
} from 'src/hooks';
import { FullLogo as Logo } from 'src/assets/Logo';

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(10),
  padding: theme.spacing(0.1, 1.25),
  boxShadow: theme.shadows[0],

  '&.UiSelected': {
    background: lighten(theme.palette.primary.main, 0.8),
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

const ImageGrid = styled(Grid)(({ theme }) => ({
  '& img': {
    transition: 'transform 200ms',
    width: '100%',
    // height: 'auto',
    '&:hover': {
      transform: 'scale(1.15)',
    },
  },
}));

const SwitchControl = styled(FormControl)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    ...theme.typography.body2,
  },
}));

const HiveContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
  height: 'calc(100vh - 120px)',
  overflowX: 'hidden',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.3em',
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: 10,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[400],
  },
}));

interface SelectionWrapperProps {
  onSelect: () => void;
  selected?: boolean;
}

const SelectionWrapper = ({ children, onSelect, selected }: React.PropsWithChildren<SelectionWrapperProps>) => (
  <Box
    sx={{
      position: 'relative',
      cursor: 'pointer',
      ...(selected && {
        '& img': { opacity: 0.7 },
      }),
    }}
    onClick={onSelect}
  >
    {selected && (
      <Box sx={{ position: 'absolute', zIndex: 100 }}>
        <CheckBoxIcon sx={{ color: (t) => t.palette.primary.main, background: '#fff' }} />
      </Box>
    )}
    {children}
  </Box>
);

interface HiveVaultProps {
  userDid: string;
}

const HiveVault = ({ userDid }: HiveVaultProps) => {
  const [selectedFiles, selectFiles] = React.useState<string[]>([]);
  const [isSelectMode, setSelectMode] = React.useState<boolean>(false);
  const [removing, setRemoving] = React.useState<boolean>(false);
  const { loadHiveFiles, isLoadingHive, hiveFiles, removeHiveFile } = usePrediction();
  React.useEffect(() => {
    if (userDid) {
      loadHiveFiles(userDid);
    }
  }, [userDid]);

  const handleSelect = React.useCallback(
    (filepath: string) => () => {
      if (selectedFiles.includes(filepath)) {
        // remove
        selectFiles((prev) => prev.filter((f) => f !== filepath));
      } else {
        // add
        selectFiles((prev) => [...prev, filepath]);
      }
    },
    [selectedFiles]
  );

  const toggleSelectMode = () => {
    setSelectMode((prev) => !prev);
    selectFiles([]);
  };

  const handleRemove = React.useCallback(async () => {
    if (userDid) {
      setRemoving(true);
      console.log({ selectedFiles });
      try {
        await Promise.all(
          selectedFiles.map(async (f: string) => {
            await removeHiveFile(userDid, f);
          })
        );
      } catch (e) {
        console.warn('Error occured when deleting hive file', e.message);
      }
      setRemoving(false);
      setSelectMode(false);
      selectFiles([]);
    }
  }, [selectedFiles, userDid]);

  return (
    <HiveContainer>
      <Box sx={{ pb: 1, display: 'flex', justifyContent: 'center', '& > .MuiButton-root': { mx: 0.5 } }}>
        <ActionButton
          variant="outlined"
          color="inherit"
          disableElevation
          size="small"
          onClick={toggleSelectMode}
          className={classNames({ UiSelected: isSelectMode })}
          disabled={isLoadingHive}
        >
          {isSelectMode ? 'Cancel' : 'Select'}
        </ActionButton>
        <ActionButton
          variant="contained"
          color="error"
          disableElevation
          size="small"
          onClick={handleRemove}
          disabled={isLoadingHive || !isSelectMode || selectedFiles.length === 0}
          startIcon={removing && <CircularProgress size={14} />}
        >
          Remove
        </ActionButton>
      </Box>
      <Grid container spacing={1}>
        {hiveFiles.map(({ url, path }) => (
          <ImageGrid item key={path} xs={6} sm={3}>
            {isSelectMode ? (
              <SelectionWrapper onSelect={handleSelect(path)} selected={selectedFiles.includes(path)}>
                <img crossOrigin="anonymous" alt="hive" src={url} />
              </SelectionWrapper>
            ) : (
              <Link to={`/ai/flint-ai-district/hive#${path}`}>
                <img crossOrigin="anonymous" alt="hive" src={url} />
              </Link>
            )}
          </ImageGrid>
        ))}
      </Grid>
      {isLoadingHive && (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 1 }}>
          <Box sx={{ width: 20, height: 20 }}>
            <CircularProgress size={18} />
          </Box>
        </Box>
      )}
    </HiveContainer>
  );
};

const HiveTab = () => {
  const { values: settings } = useAppSettings();
  const { profile, updateProfile } = useProfile();

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log('Saving user settings...', event.target.name, event.target.checked);
      updateProfile({
        $set: {
          'settings.autoSaveAI': event.target.checked,
        },
      });
    },
    [profile?.address]
  );

  return (
    <Container>
      {settings.walletProvider !== 'Elastos DID' ? (
        <Box sx={{ mt: 2 }}>
          <Logo size={120} />
          <Typography variant="body2" sx={{ mt: 2, textAlign: 'center', '& a': { textDecoration: 'none' } }}>
            Elacity is dedicated to data ownership and digital participation. As a non-custodial Web5 cloud city, only you should
            hold and save your data, connecting in and out at will. If you would like to save your AI generations without minting
            them today,
            {' '}
            <Anchor href="https://elink.elastos.net/download" target="_blank">
              download Essentials Web5 super wallet app
            </Anchor>
            ! You will gain a decentralised identity (DID), decentralised storage and access to popular chains for managing your
            finances and ownership rights. Your generated images will show here, connecting to your Web5 controlled data vault.
            #ownyourdata
          </Typography>
        </Box>
      ) : (
        <Box>
          <HiveVault userDid={profile?.did?.did} />
          <Box sx={{ pt: 2, position: 'absolute', bottom: 12 }}>
            <SwitchControl fullWidth>
              <FormControlLabel
                control={<Switch checked={profile?.settings?.autoSaveAI} onChange={handleChange} name="settings.autoSaveAI" />}
                label="Auto-save generated images to my Hive vault"
              />
            </SwitchControl>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default HiveTab;
