/* eslint-disable max-len */
import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography as MuiTypography,
  Link as MuiLink,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Scrollbar from 'src/components/@ui/Scrollbar';
import FlintLogo from 'src/assets/FlintLogo';

const Link = styled(MuiLink)({
  textDecoration: 'none',
  fontWeight: 500,
});

export interface FlintServiceAgreementValue {
  agreed?: boolean;
  acceptAgreement: () => Promise<void>;
  promptAgreement: () => Promise<boolean>;
}

export const FlintAgreementContext = React.createContext<FlintServiceAgreementValue>({
  agreed: false,
  acceptAgreement: () => Promise.resolve(),
  promptAgreement: () => Promise.resolve(false),
});

// --- Agreement modal
const Typography = styled(MuiTypography)({
  '&.MuiTypography-h6': {
    paddingTop: 16,
  },
});

interface AgreementModalProps {
  open?: boolean;
  onClose?: () => void;
  onConfirm?: () => Promise<void>;
}

const AgreementModal: React.FC<AgreementModalProps> = ({ open, onClose, onConfirm }: AgreementModalProps) => {
  const [read, setRead] = React.useState<boolean>(false);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={Boolean(open)}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        <FlintLogo size={260} />
      </DialogTitle>
      <DialogContent>
        <Scrollbar>
          <Typography variant="body2" gutterBottom paragraph>
            Welcome to Elacity’s Flint AI District! By continuing, you agree to
            {' '}
            <Link href="https://docs.ela.city/terms-of-service/elacity-ai" target="_blank">
              Elacity’s Terms of Service
            </Link>
            {' '}
            and acknowledge that you’ve read our
            {' '}
            <Link href="https://docs.ela.city/privacy-policy" target="_blank">
              Privacy Policy
            </Link>
            . You also agree not to use the Model or Derivatives of the Model
          </Typography>

          <Typography variant="body2" gutterBottom paragraph>
            <ul>
              <li>
                In any way that violates any applicable national, federal, state, local or international law or
                regulation;
              </li>

              <li>For the purpose of exploiting, harming or attempting to exploit or harm minors in any way;</li>

              <li>
                To generate or disseminate verifiably false information and/or content with the purpose of harming
                others;
              </li>

              <li>
                To generate or disseminate personal identifiable information that can be used to harm an individual;
              </li>

              <li>To defame, disparage or otherwise harass others;</li>

              <li>
                For fully automated decision making that adversely impacts an individual’s legal rights or otherwise
                creates or modifies a binding, enforceable obligation;
              </li>

              <li>
                For any use intended to or which has the effect of discriminating against or harming individuals or
                groups based on online or offline social behavior or known or predicted personal or personality
                characteristics;
              </li>

              <li>
                To exploit any of the vulnerabilities of a specific group of persons based on their age, social,
                physical or mental characteristics, in order to materially distort the behavior of a person pertaining
                to that group in a manner that causes or is likely to cause that person or another person physical or
                psychological harm;
              </li>

              <li>
                For any use intended to or which has the effect of discriminating against individuals or groups based on
                legally protected characteristics or categories;
              </li>

              <li>To provide medical advice and medical results interpretation;</li>

              <li>
                To generate or disseminate information for the purpose to be used for administration of justice, law
                enforcement, immigration or asylum processes, such as predicting an individual will commit fraud/crime
                commitment (e.g. by text profiling, drawing causal relationships between assertions made in documents,
                indiscriminate and arbitrarily-targeted use).
              </li>
            </ul>
          </Typography>
        </Scrollbar>
      </DialogContent>
      <DialogActions sx={{ my: 3, mx: 2, justifyContent: 'center' }}>
        <Box>
          <FormControlLabel
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '0.8rem',
              },
            }}
            control={(
              <Checkbox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setRead(e.target.checked);
                }}
              />
            )}
            label="I've read, understood and agreed to ela.city's terms and restrictions of use agreement"
          />
        </Box>
        <Button size="large" type="submit" variant="contained" onClick={onConfirm} disabled={!read}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
// --- /Agreement modal

interface FlintAgreementProviderProps {}

declare type PromiseResolver<T> = (value: T) => void;

export const FlintServiceAgreementProvider: React.FC<React.PropsWithChildren<FlintAgreementProviderProps>> = ({
  children,
}: React.PropsWithChildren<FlintAgreementProviderProps>) => {
  const acceptResolver = React.useRef<PromiseResolver<boolean>>(null);

  const [agreed, setAgreed] = React.useState<boolean>(
    // take previous value from local storage (browser-based value)
    // @todo: use database to store this value according to account (if connected)
    // if not connected, we will not really ned to make the prompt
    localStorage.getItem('__elacity_flint_agree') === 'yes'
  );
  const [open, openModal] = React.useState<boolean>(false);
  const acceptAgreement = async () => {
    acceptResolver.current?.call(null, true);

    setAgreed(true);
    setTimeout(() => {
      openModal(false);
    }, 500);
  };

  const promptAgreement = () => {
    openModal(true);

    return new Promise<boolean>((resolve) => {
      acceptResolver.current = resolve;
    });
  };

  React.useEffect(() => {
    if (agreed) {
      localStorage.setItem('__elacity_flint_agree', 'yes');
    }
  }, [agreed]);

  React.useEffect(() => {}, []);

  return (
    <FlintAgreementContext.Provider
      value={{
        agreed,
        acceptAgreement,
        promptAgreement,
      }}
    >
      {children}
      <AgreementModal open={open} onClose={() => openModal(false)} onConfirm={acceptAgreement} />
    </FlintAgreementContext.Provider>
  );
};

// high order component
export const withFlintServiceAgreement =
  <T, >(Component: React.ComponentType<T>) => (props: T) => {
    const { agreed, promptAgreement } = React.useContext(FlintAgreementContext);
    React.useEffect(() => {
      // on load, check whether the user has already agreed with the NFTs Agreement
      // if so, we can just skip prompt
      // otherwise, we should show modal to allow user to confirm it
      if (!agreed) {
        promptAgreement().catch((e: Error) => console.error('Agreement Error', e));
      }
    }, []);

    return <Component {...props} />;
  };
