import React from 'react';
import { Box, Typography } from '@mui/material';

interface ErrorProps {
  inset?: boolean;
  message?: string;
  code?: string | number;
}

export default ({ code, message, inset }: ErrorProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      textAlign: 'center',
      position: 'fixed',
      ...(inset && {
        position: 'relative',
        height: 'calc(100% - 25vh)',
      }),
    }}
  >
    <Box sx={{ width: 320 }}>
      <Typography variant="h4">
        {code}
        {' '}
        Error
      </Typography>
      {message && <p style={{ display: 'block' }}>{message}</p>}
    </Box>
  </Box>
);
