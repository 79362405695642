import { formatEther } from '@ethersproject/units';
import useWeb3Application from './useWeb3Application';

export default () => {
  const { library } = useWeb3Application();
  const balanceOf = async (address: string) => {
    const balance = await library.getBalance(address);
    return formatEther(balance);
  };

  return {
    balanceOf,
  };
};
