import React, { useCallback, useMemo } from 'react';
import { JsonLocalStorage } from 'src/lib/storage';
import { useSigninMutation } from 'src/state/api';
import { JWT_VAULT, useCurrentUser } from 'src/state/slices/user';
import { AuthResponse } from 'src/state/api/types';
import useWeb3Application from './useWeb3Application';
import useSignature from './useSignature';

interface Params {
  onError?: (err: Error) => void;
}

declare type Result = [
  (refresh?: boolean) => Promise<AuthResponse>,
  {
    isLoading: boolean;
    isSuccess: boolean;
    isSigned: boolean;
  },
];

export default ({ onError }: Params): Result => {
  const storage = new JsonLocalStorage<Record<string, AuthResponse &
  {createdAt?: number, signed?: boolean}>>(JWT_VAULT, {});
  const {
    isRequestingSigning,
    setUserToken,
    setUserRequestSigning,
  } = useCurrentUser();
  const { account } = useWeb3Application();
  const [signin, { isLoading, isSuccess }] = useSigninMutation();
  const web3Sign = useSignature({ onError });

  const isSigned: boolean = useMemo(() => {
    const { signed } = storage.get(account) || {};
    return signed;
  }, [account]);

  return [useCallback(
    async () => {
      if (account) {
        const { createdAt, expiresIn, ...cached } = storage.get(account) || {};
        // tokens usually expire within 14 Days, when created date exceeds that time, we need to re-sign
        // otherwise we return the cached token
        if (cached && Number(createdAt) > Date.now() - Number(expiresIn)) {
          setUserToken({ account, ...cached });
          return cached;
        }

        if (isRequestingSigning) {
          return Promise.reject(new Error('user already request signin'));
        }

        setUserRequestSigning({ isRequestingSigning: true });

        // We moved the notification that was here into ApiContext
        // for more unified flow between useLogin hook & privateBaseQuery signin

        // Make sure to store the account address at this point until the token is set
        setUserToken({ account, token: null });

        try {
          const sig = await web3Sign();
          const credentials: AuthResponse = await signin(sig).unwrap();
          const userData = {
            ...credentials,
            createdAt: Date.now(),
            signed: true,
          };

          storage.set({
            [account]: userData,
          });

          setUserToken({ account, ...userData });

          return { ...credentials };
        } catch (err) {
          // closeError(snackbarKey);
          return Promise.reject(new Error(`failed to signin: ${err.message}`));
        } finally {
          setUserRequestSigning({ isRequestingSigning: false });
        }
      }

      return Promise.reject(new Error('no wallet connected'));
    }, [account, web3Sign]
  ), { isLoading, isSuccess, isSigned }];
};
