import { useCallback } from 'react';
import { Contract } from '@ethersproject/contracts';
import useWeb3Application from '../useWeb3Application';
import { ABIS } from '../../lib/nfts/constants';
import { commonErc20Abi } from '../../lib/web3/contract';

const useContract = () => {
  const { chainId, library, account } = useWeb3Application();

  const loadContract = useCallback(
    (address, abi) => new Contract(address, abi, account ? library?.getSigner(account) : library),
    [chainId, library, account]
  );

  const loadERC721Contract = useCallback(
    (address) => new Contract(address, ABIS.SINGLE_NFT_ABI, account ? library?.getSigner(account) : library),
    [chainId, library, account]
  );

  const loadERC20Contract = useCallback(
    (address) => new Contract(address, commonErc20Abi, account ? library?.getSigner(account) : library),
    [chainId, library, account]
  );

  return { loadContract, loadERC721Contract, loadERC20Contract };
};

export default useContract;
