/* eslint-disable no-case-declarations */
import React from 'react';
import { IStorage, JsonLocalStorage } from '@elacity-js/lib';
import { IUserPreferences } from '../types';

interface LogoSet {
  primary: string;
  alt?: string;
  minimized?: string;
}

interface AppLinks {
  documentation?: string;
  socials?: {
    provider: string;
    url: string;
  }[];
}

export interface AppSettingsContextValue {
  // related to app itself
  appName: string;
  logo: LogoSet;
  links?: AppLinks;

  values: IUserPreferences;
  setValues: (v: Partial<IUserPreferences>) => void;
  load: () => void;
}

const defaultValues: IUserPreferences = {
  theme: 'light',
  lang: (navigator.language || 'en').substr(0, 2),
  searchbar: false,
  sidebarMinimized: true,
};

interface AppSettingsProviderProps {
  appName: string;
  logo: LogoSet;
  storage: IStorage<IUserPreferences>;
  links?: AppLinks;
}

const AppSettingsContext = React.createContext<AppSettingsContextValue>({
  appName: '',
  logo: { primary: '' },
  values: defaultValues,
  setValues: () => {},
  load: () => {},
});

type LoadAction = {
  type: 'LOAD';
};

type SetAction = {
  type: 'SET';
  payload: Partial<IUserPreferences>;
};

type Action = LoadAction | SetAction;

export const LOCAL_KEY = '__ela_app_settings';

const reducer = (state: IUserPreferences, action: Action): IUserPreferences => {
  const storage = new JsonLocalStorage(LOCAL_KEY, { defaultValue: state });
  switch (action.type) {
    case 'LOAD':
      return storage.toJSON();

    case 'SET':
      return storage.set(action.payload).toJSON();
    default:
      return state;
  }
};

export const AppSettingsProvider = ({
  appName,
  logo,
  links,
  storage,
  children,
}: React.PropsWithChildren<AppSettingsProviderProps>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let storedValue: any = localStorage.getItem(LOCAL_KEY);
  if (storedValue) {
    storedValue = JSON.parse(storedValue) as IUserPreferences;
  }

  const [values, dispatch] = React.useReducer(reducer, storedValue || defaultValues);

  const load = () => dispatch({
    type: 'LOAD',
  });

  const setValues = (payload: Partial<IUserPreferences>) => dispatch({
    type: 'SET',
    payload,
  });

  React.useEffect(() => {
    load();
    setValues(values);
  }, []);

  return (
    <AppSettingsContext.Provider
      value={{
        appName,
        logo,
        links,
        values,
        setValues,
        load,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
};

export default AppSettingsContext;
