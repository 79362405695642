import { UnsupportedChainIdError } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import {
  isMobile, isMetaMaskBuiltInBrowser, baseURL,
} from 'src/utils';
import {
  ActivateFunc, ConnectResponse, getChainIds,
} from '../network';
import { ConnectorName } from './types';

// Metamask needs this
export const injected = new InjectedConnector({
  supportedChainIds: getChainIds(),
});

export default {
  name: ConnectorName.Metamask,
  handler: injected,
  icon: baseURL('/static/elacity/MetaMask_Fox.png'),
  connect: async (chainId: number, activate: ActivateFunc): Promise<ConnectResponse> => {
    if (window?.elastos && Number(window?.ethereum?.chainId) < 0) {
      // EE is connected to a non-EVM chain
      // without this checking, it will throw an invariant error
      throw new UnsupportedChainIdError(-1);
    }

    if (isMobile() && !isMetaMaskBuiltInBrowser()) {
      window.open(`https://metamask.app.link/dapp/${window.location.host}`, '_blank');
      return {
        chainId: null,
      };
    }
    // see https://github.com/NoahZinsmeister/web3-react/tree/v6/docs#understanding-error-bubbling
    // for more details about error handling
    await activate(injected, undefined, true);
    return {
      chainId: Number(await injected.getChainId()),
      provider: await injected.getProvider(),
    };
  },
  resurrect: async (activate: ActivateFunc): Promise<void> => {
    const authorized = await injected.isAuthorized();
    console.log('[Connect.metamask.connector] resurecting...', { authorized });
    if (authorized) {
      await activate(injected, undefined, true);
    }
  },
};
