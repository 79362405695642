import { useCallback } from 'react';
import useWeb3Application from './useWeb3Application';
import Client from '../api/client';

export default () => {
  const { account } = useWeb3Application();

  const getClient = useCallback(async () => {
    await Client.authenticate(account);

    return Client;
  }, [account]);

  return { getClient };
};
