import React from 'react';
import type { Currency } from '@elacity-js/lib';
import { BigNumberish } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { ADDRESS } from 'src/lib/nfts/constants';
import { ConnectorName } from 'src/lib/web3';
import { SnackbarKey } from 'notistack';
import { JsonLocalStorage } from 'src/lib/storage';
import { DIdentity } from 'src/lib/did/types';

import { useErrorHandler } from 'src/hooks';
import { StackButton } from 'src/hooks/useErrorHandler';
import { ApiResponse, IProfile } from '../types';
import { useSelector } from '../state/store';
import type { RootState } from '../state/store';
import {
  useGetFollowingsQuery,
  useGetProfileQuery,
  useLinkDIDMutation,
  useUpdateAccountMutation,
} from '../state/api';
import useWeb3Application from '../hooks/useWeb3Application';
import useMounted from '../hooks/useMounted';
import usePayment from '../hooks/usePayment';
import useLoginFlow from '../hooks/useLogin';
import useAppSettings from '../hooks/useAppSettings';
import { useFetchCollectionsQuery } from '../hooks/collection';
import { DID_STORAGE_KEY } from '../lib/did';

export interface ApiContextValue {
  profile?: IProfile | null;
  token?: string;
  account?: string;
  isAdmin?: boolean;
  isLoading: boolean;
  isLoadingBalance?: boolean;
  isAuthenticated: boolean;
  api?: {
    baseUrl: string;
  };
  balance?: Record<string, number>;
  loadBalances?: () => Promise<RawBalanceOutput[]>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateProfile?: (body: any) => Promise<ApiResponse<Partial<IProfile> & { address: string }>>;
}

interface ApiProviderProps {}

interface RawBalanceOutput {
  address: string;
  decimals: number;
  value: BigNumberish;
}

const ApiContext = React.createContext<ApiContextValue>({
  isLoading: true,
  isLoadingBalance: true,
  isAuthenticated: false,
});

export const ApiProvider = ({ children }: React.PropsWithChildren<ApiProviderProps>) => {
  const didJson = new JsonLocalStorage(DID_STORAGE_KEY);
  const { account, payment, library, chainId } = useWeb3Application();
  const { supportedArr: currencies } = usePayment();
  const { values } = useAppSettings();
  const mounted = useMounted();
  const [isAuthenticated, setAuthenticated] = React.useState<boolean>(false);
  const [accountBalance, setBalance] = React.useState<Record<string, number>>({});
  const [isLoadingBalance, setLoadingBalance] = React.useState<boolean>(true);
  const { isRequestingSigning } = useSelector((state: RootState) => state.user);
  const { throwError, closeError } = useErrorHandler();

  const setBalanceFor = (c: RawBalanceOutput) => {
    setBalance((prev) => ({
      ...prev,
      [c.address]: parseFloat(formatUnits(c.value.toString(), c.decimals)),
    }));
  };

  const initBalance = () => {
    setBalance({});
  };

  const [updateAccount] = useUpdateAccountMutation();
  const [linkDID] = useLinkDIDMutation();
  const [login, { isLoading, isSuccess }] = useLoginFlow({});
  const { data: profile, isLoading: profileLoading } = useGetProfileQuery(
    {
      address: account?.toLowerCase(),
    },
    {
      skip: Boolean(!account),
    }
  );

  // load network mandatory data (collections,... [some others will come])
  const { isLoading: collectionsLoading } = useFetchCollectionsQuery();

  // load some user data
  useGetFollowingsQuery(account, {
    skip: Boolean(!account) || !isSuccess,
  });

  const { token, isAdmin } = useSelector((state: RootState) => state.user);

  const loadBalances = React.useCallback(async () => {
    if (library) {
      initBalance();
      const promise = Promise.all<RawBalanceOutput>([
        new Promise((resolve, reject) => {
          library
            .getBalance(account)
            .then((value: BigNumberish) => resolve({ address: ADDRESS.ZERO, value, decimals: 18 }))
            .catch(reject);
        }),
        ...currencies.map(async (c: Currency) => {
          const balance = await payment?.use(c.address).balanceOf(account);
          return Promise.resolve({ address: c.address, value: balance, decimals: c.decimals });
        }),
      ]);

      promise
        .then(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (results: RawBalanceOutput[]) => {
            results.forEach(setBalanceFor);

            return results;
          }
        )
        .catch((e: Error) => {
          console.error('failed to load balances', e);
        })
        .finally(() => setLoadingBalance(false));

      return promise;
    }

    return Promise.resolve([]);
  }, [account, library, chainId]);

  React.useEffect(() => {
    if (account) {
      login()
        .then(() => {
          setAuthenticated(true);
          if (values.walletProvider === ConnectorName.ElastosDID) {
            // link the account to DID credentials
            const { exists, ...did }: DIdentity & { exists: boolean } = didJson.toJSON();
            if (exists) {
              linkDID(did)
                .unwrap()
                .then(({ status, data }) => {
                  // add social credentials registered in database
                  if (status === 'success') {
                    didJson.set(data);
                  }
                });
            }
          }
        })
        .catch((e: Error) => {
          console.error('login error', e.message);
        });

      loadBalances();
    }
  }, [account, library, chainId]);

  const notifySignin = React.useCallback(() => {
    // eslint-disable-next-line max-len
    throwError(new Error('Please check your wallet and accept the request to sign in.'), {
      variant: 'warning',
      autoHideDuration: 7000,
      action: (key: SnackbarKey) => (
        <StackButton
          size="small" variant="text"
          onClick={() => closeError(key)}
        >
          Dismiss
        </StackButton>
      ),
    });
  }, []);

  React.useEffect(() => {
    if (isRequestingSigning) {
      notifySignin();
    }
  }, [isRequestingSigning]);

  React.useEffect(() => {
    if (!process.env.REACT_APP_BACKEND_URL) {
      console.warn('backend_url has not been specified, we will use a local link');
    }
  }, []);

  const contentLoading = React.useMemo(
    () => isLoading || collectionsLoading || profileLoading || !mounted.current,
    [isLoading, collectionsLoading, profileLoading, mounted.current]
  );

  return (
    <ApiContext.Provider
      value={{
        isAuthenticated: isAuthenticated && Boolean(token),
        isLoading: contentLoading,
        profile,
        token,
        account,
        balance: accountBalance,
        loadBalances,
        ...(isAdmin && {
          isAdmin,
        }),
        isLoadingBalance,
        api: {
          baseUrl: process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001',
        },
        // @ts-ignore
        updateProfile: (body: unknown) => updateAccount(body),
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContext;
